import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { postMissingItems } from "../../actions/priceLists"
import { priceListWritePermission } from "../../utils/permissionValidation"
import "./missing-item-modal.scss"

const RequestMissingItemModal = (props) => {
  const { t } = useTranslation()
  const { selectedMissingItem, handleClose, isLoading } = props
  const [missingItems, setMissingItems] = useState([
    {
      partNumber: selectedMissingItem?.partNo || "",
      itemName: selectedMissingItem?.itemName || "",
      comments: selectedMissingItem?.comments || "",
    }
  ])
  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    const hasFilledFields = missingItems.some(item => item.partNumber || item.itemName)
    const hasCommentsWithoutIdentifier = missingItems.some(item => item.comments && (!item.partNumber && !item.itemName))
    setCanSubmit(hasFilledFields && !hasCommentsWithoutIdentifier)
  }, [missingItems])

  const footerContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button"
          onClick={handleClose}
        >
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton
          className="submit-button" 
          onClick={handleSubmit}
          disabled={isLoading || !canSubmit}
          requiredPermission={priceListWritePermission}
        >
          {t("labels.submit")}
        </SimpleButton>
      </>
    )
  }

  const handleChangeField = (field, value, idx) => {
    const updatedMissingItem = missingItems.map((item, index) => {
      if (index === idx) {
        return { ...item, [field]: value }
      }

      return item
    })

    setMissingItems(updatedMissingItem)
  }

  const handleAddItem = () => {
    setMissingItems(prevValues => [
      ...prevValues,
      {
        partNumber: "",
        itemName: "",
        comments: "",
      },
    ])
  }
  
  const handleSubmit = () => {
    props.actions.postMissingItems(missingItems)
      .then(() => handleClose())
  }

  return (
    <PageModal
      toggle
      className={"missing-item-modal" + (selectedMissingItem ? " hide-footer" : "")}
      onToggle={handleClose}
      title={t("dealer-portal.request_missing_item")}
      footerContent={footerContent()}
    >
      <div className="missing-item-modal-content">
        <div className="row-wrapper">
          <div className="input-wrapper">
            <div className="modal-section-disclaimer">
              {selectedMissingItem ? (
                <>
                  <p>
                    Hello, <br /> Thank you for contacting us. We’ve received your
                    query and our team is looking into it.
                  </p>
                  <p>
                    We do our best to respond to every email within 24 hours, so
                    you should be hearing back from one of our customer service
                    team members soon.
                  </p>
                </>
              ) : (
                <p>
                  {t("dealer-portal.request_missing_item_disclaimer")}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="row-wrapper">
          <div className="section-title">
            {t("dealer-portal.missing-items")}
          </div>
        </div>
        {missingItems.map((item, index) =>
          <>
            <div className="row-wrapper">
              <div className="input-wrapper">
                <p className="input-option">{t("dealer-portal.part-number")}</p>
                <input
                  type="text" 
                  className={"input-item"}
                  value={item.partNumber}
                  onChange={(e) => handleChangeField("partNumber", e.target.value, index)}
                  placeholder="ABC 12345"
                  readOnly={selectedMissingItem ? true : false}
                />
              </div>
            </div>
            <div className="row-wrapper">
              <div className="input-wrapper">
                <p className="input-option">{t("dealer-portal.item-name")}</p>
                <input
                  type="text" 
                  className={"input-item"}
                  value={item.itemName}
                  onChange={(e) => handleChangeField("itemName", e.target.value, index)}
                  readOnly={selectedMissingItem ? true : false}
                />
              </div>
            </div>
            <div className="row-wrapper">
              <div className="input-wrapper">
                <p className="input-option">{t("dealer-portal.comments")}</p>
                <textarea
                  className={"textarea-item"}
                  value={item.comments}
                  onChange={(e) => handleChangeField("comments", e.target.value, index)}
                  readOnly={selectedMissingItem ? true : false}
                />
              </div>
            </div>
            {index < missingItems.length - 1 && (
              <div className="separator" />
            )}
          </>)
        }
        {!selectedMissingItem && (
          <button className="add-btn" onClick={handleAddItem}>
            {t("dealer-portal.add-missing")}
          </button>
        )}
      </div>
    </PageModal>
  )
}

function stateToProps({ priceLists }) {
  return {
    isLoading: priceLists?.isLoading,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        postMissingItems,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(RequestMissingItemModal)