import React, { useRef } from "react"

const ImageSlide = ({ child, sliderWidth, sliderHeight, scaleOnDrag = false }) => {
  const slideRef = useRef("slide")

  const onMouseDown = () => {
    if (scaleOnDrag) {
      slideRef.current.style.transform = "scale(0.9)"
    } 
  }

  const onMouseUp = () => {
    if (scaleOnDrag) {
      slideRef.current.style.transform = "scale(1)";
    }
  }

  return (
    <div 
      ref={slideRef} 
      sliderWidth={`${sliderWidth}px`} 
      sliderHeight={`${sliderHeight}px`} 
      className="image-slide"
    >
      <div
        unselectable="on"
        className="slide-inner"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchEnd={onMouseUp}
        onMouseLeave={onMouseUp}
        onDragStart={(e) => {
          e.preventDefault()
          e.stopPropagation()
          return false
        }}
      >
        {child}
      </div>
    </div>
  )
}

export default ImageSlide
