import React, { useEffect, useState } from "react"
import { CaretRightIcon, ColtenePlaceholderProduct } from "../../../icons"

const ImageSwiper = (props) => {
  const [images, setImages] = useState([])
  const [displayedImageIndex, setDisplayedImageIndex] = useState(0)
  const [showPrevious, setShowPrevious] = useState(false)
  const [showNext, setShowNext] = useState(true)
  
  useEffect(() => {
    if (props.images?.length > 0) {
      setImages(props.images)
    }    
  }, [props.images])

  useEffect(() => {
    setShowNext(displayedImageIndex !== images.length - 1)

    setShowPrevious(displayedImageIndex !== 0)
  }, [displayedImageIndex, images])

  const nextImage = () => {
    if (showNext) {
      setDisplayedImageIndex((prevImage) => prevImage + 1)
    }    
  }
  const prevImage = () => {
    if (showPrevious) {
      setDisplayedImageIndex((prevImage) => prevImage - 1)
    }    
  }

  return (
    <>
      <div className={images.length > 0 ? "img-swiper-container d-flex flex-justify-center flex-align-center" : "swiper-container d-flex flex-justify-center flex-align-center"}>
        {images.length > 0 ? 
          <img src={images[displayedImageIndex].url} alt="product"/>
        : 
          <ColtenePlaceholderProduct className="svg-placeholder" />
        }
        {images.length > 1 &&
          <>
            <button className={"previous-arrow" + (!showPrevious ? " hidden" : "")} onClick={() => prevImage()}>
              <CaretRightIcon />
            </button>
            <button className={"next-arrow" + (!showNext ? " hidden" : "")} onClick={() => nextImage()}>
              <CaretRightIcon />
            </button>
          </>
        }
      </div>
      {images.length > 1 &&
        <div className="product-images">
          {images.map((img, index) =>
            <img
              key={index}
              className={`single-product-image ${index === displayedImageIndex && "selected-image"}`}
              onClick={() => setDisplayedImageIndex(index)}
              src={img.url}
              alt="product"
            />)
          }
        </div>
      }
    </>
  )
}

export default ImageSwiper
