import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "../../../libs/hooks";
import "./select-field.scss";
import { CaretDownIcon } from "../../../icons";

/**
 *
 * @param {Object} param0 options e.g. = {en-CA:'English', fr-CA: 'French'}
 */
const SelectField = ({
  selectIcon,
  selectLbl,
  selectedIndex = "",
  sublabel = "",
  options = {},
  name,
  value = "",
  onChange,
  isRequired = false,
  error = "",
  isSubmitting = false,
  disabled = false,
  resetOnDisable = false,
  ...props
}) => {
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const [width] = useWindowSize();
  const [selectedOption, setSelectedOption] = useState(options[value] ? options[value]: "");
  const [selectOptions, setSelectOptions] = useState(options);
  const [originalOptions, setOriginalOptions] = useState(options);
  const [selectedKey, setSelectedKey] = useState(value);
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    if (disabled && resetOnDisable) {
      setSelectedOption("")
      setSelectedKey("")
      return
    }

    setSelectedOption(options[selectedKey || value] || "")
  }, [disabled, resetOnDisable, options, selectedKey, value])

  useEffect(() => {
    const updateSelectedOption = () => {
      if (selectedKey || value) {
        setSelectedOption(options[selectedKey || value]);
      } else if (selectedIndex) {
        const sOption = Object.keys(options).filter((option) => selectedIndex.toLowerCase() === option.toLowerCase());
        setSelectedOption(options[sOption[0] || ""]);
      }

      setSelectOptions(options);
      setOriginalOptions(options);
    };

    updateSelectedOption();
  }, [options, selectedIndex, selectedKey, value]);

  useEffect(() => {
    const closeSelectOptions = (e) => {
      const cl = e.target.getAttribute("class") || "";
      const selectWrappers = document.querySelectorAll(".select-col-wrapper");

      for (let i = 0; i < selectWrappers.length; i++) {
        if (!cl.includes("select")) {
          const targetElem = selectWrappers[i].querySelector(".select-options");

          targetElem.style.display = "none";
          setIsVisible(false);
          setOptionsExpanded(false);
        }
      }
    };

    document.addEventListener("click", closeSelectOptions);

    return (_) => {
      document.removeEventListener("click", closeSelectOptions);
    };
  }, [name, isVisible]);

  const createDetailsEvent = (e) => {
    let event = new CustomEvent("event", {
      bubbles: true,
      detail: {
        name: e.target.getAttribute("data-name"),
        value: e.target.getAttribute("data-val"),
      },
    });
    onChange(event);
  };

  const handleChange = (e) => {
    e.target.parentNode.style.display = "none";

    setSelectedKey(e.target.getAttribute("data-val"));
    createDetailsEvent(e);
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    let newOpts = {};
    setSelectedOption(val);
    showOptions(e);

    Object.keys(originalOptions)
      .filter((option) => options[option].toLowerCase().includes(val.toLowerCase()))
      .forEach((k) => (newOpts[k] = options[k]));

    setSelectOptions(newOpts);
  };

  const showOptions = (e) => {
    e.preventDefault();

    const parentElem = getParentElem(e.currentTarget);
    const targetElem = parentElem.querySelector(".select-options");
    const elemVisible = targetElem ? isElemVisible(targetElem) : "";

    //close all other visible options
    closeOtherOptions(parentElem);

    if (elemVisible === "none") {
      targetElem.style.display = "block";

      setIsVisible(true);

      if (width < 415) {
        setOptionsExpanded(true);
      }
    }
  };

  const closeOtherOptions = (currentElem) => {
    const selectWrappers = document.querySelectorAll(".select-col-wrapper");

    for (let i = 0; i < selectWrappers.length; i++) {
      if (selectWrappers[i].className !== currentElem.className) {
        const targetElem = selectWrappers[i].querySelector(".select-options");

        targetElem.style.display = "none";
      }
    }
  };

  const isElemVisible = (elem) => {
    return elem.currentStyle ? elem.currentStyle.display : getComputedStyle(elem, null).display;
  };

  const getParentElem = (el) => {
    let parentElem = el.parentNode;
    const pClass = parentElem !== null ? parentElem.getAttribute("class") : "";

    if (parentElem.tagName.toLowerCase() === "svg" || pClass.indexOf("-col-") === -1) {
      parentElem = parentElem.parentNode;

      if (parentElem.className.indexOf("-col-") === -1) {
        parentElem = parentElem.parentNode;
      }
    }

    return parentElem;
  };

  return (
    <div className={`select-col-wrapper ${name}`}>
      <div id={name} className={"select-wrapper " + (disabled ? " disabled " : "") + (selectIcon ? " has-icon " : "") + (error && isSubmitting ? " select-error" : "")} onClick={showOptions}>
        {selectIcon !== null && selectIcon}
        <input
          type="text"
          name={name}
          value={selectedOption || ""}
          data-key={selectedKey}
          onChange={handleInputChange}
          autoComplete="nope"
          {...props}
          className={`select-input ${props.className}`}
        />
        <div className="placeholder select-placeholder">
          {selectLbl}
          {isRequired && <span className="select-required">*</span>}
        </div>
        {optionsExpanded ? <CaretDownIcon className="icon select-icon" /> : <CaretDownIcon className="icon select-icon" />}
      </div>
      <div className="select-options">
        <div className="select-label">{selectLbl}</div>
        {Object.keys(selectOptions).map((k) => (
          <div key={k} id={`${name}-${k}`} className="select-option" onClick={handleChange} data-name={name} data-val={k}>
            {options[k]}
          </div>
        ))}
      </div>
      {sublabel && <div className="sub-label">{sublabel}</div>}
      {error && isSubmitting && <div className="error-message">{error}</div>}
    </div>
  );
};

SelectField.propTypes = {
  inputIcon: PropTypes.element,
  selectLbl: PropTypes.string,
  options: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  resetOnDisable: PropTypes.bool,
};

export default SelectField;
