
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { SelectMenu } from "../common/SelectMenu"
import { putMember } from "../../actions/members"
import { membersWritePermission } from "../../utils/permissionValidation"
import { InformationIcon } from "../../icons"
import "./edit-member-modal.scss"

const EditMemberModal = (props) => {
  const { t } = useTranslation()
  const { selectedMember, showRoleDetails, closeModal, branches, companyRoles, companyId, isLoading } = props
  const [selectedBranchId, setSelectedBranchId] = useState(selectedMember.branchId)
  const [selectedRoleId, setSelectedRoleId] = useState(null)
  const [roleOptions, setRoleOptions] = useState([])

  useEffect(() => {
    if (companyRoles.length > 0) {
      const mappedRoles = companyRoles.map((role) => ({
        key: role.id,
        value: role.name.replace(/Dealer |Dentist /g, ""),
      }))

      setRoleOptions(mappedRoles)
    }
  }, [companyRoles])

  const getFooter = () => {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={closeModal}>
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={handleSubmit}
          disabled={isLoading || hasNoChanges()}
          requiredPermission={membersWritePermission}
        >
          {t("labels.save")}
        </SimpleButton>
      </>
    )
  }

  const hasNoChanges = () => {
    return (
      selectedMember.branchId === selectedBranchId &&
      selectedMember.role?.replace(/Dealer |Dentist /g, "") === roleOptions.find((r) => r.key === selectedRoleId)?.value
    )
  }

  const handleSubmit = () => {
    props.actions.putMember(companyId, selectedMember.uuid, {
      firstName: selectedMember.firstname,
      lastName: selectedMember.lastname,
      email: selectedMember.email,
      branchId: selectedBranchId || selectedMember.branchId,
      roleId: selectedRoleId || roleOptions.find((role) => role.value === selectedMember.role?.split("-")[0]?.replace(/Dealer |Dentist /g, ""))?.key,
    })
      .then(() => {
        closeModal()
      })
  }

  return (
    <>
      <PageModal 
        toggle 
        onToggle={closeModal}
        className="edit-member-modal"
        title={t("dealer-portal.edit_member")} 
        footerContent={getFooter()}
      >
        <div className="edit-member-modal-content">
          <div className="row-wrapper">
            <div className="input-wrapper">
              <p className="input-option">{t("profile-labels.first_name")}</p>
              <div className="value">
                {selectedMember?.firstname || ""}
              </div>
            </div>
            <div className="input-wrapper">
              <p className="input-option">{t("profile-labels.last_name")}</p>
              <div className="value">
                {selectedMember?.lastname || ""}
              </div>
            </div>
          </div>
          <div className="row-wrapper">
            <div className="input-wrapper">
              <p className="input-option">{t("profile-labels.email")}</p>
              <div className="value">
                {selectedMember?.email || ""}
              </div>
            </div>
            <div className="input-wrapper">
              <p className="input-option">{t("dealer-portal.role")}</p>
              <div className="value">
                {selectedMember.role?.split("-")[0]?.replace(/Dealer |Dentist /g, "") || ''}
              </div>
            </div>
          </div>
          <div className="section-title">{t("account_context.change_role")}</div>
          <div className="row-wrapper">
            <div className="input-wrapper">
              <p className="input-option">{t('dealer-portal.branch')}</p>
              <SelectMenu
                className="disabled-dropdown"
                options={branches?.map((b) => ({ key: b.id, value: b.branchName })) || []}
                initialSelectedKey={selectedBranchId}
                onChange={(option) => setSelectedBranchId(option.key)}
                placeholder={t("select")}
              />
            </div>
            <div className="input-wrapper">
              <p className="input-option">
                {t('dealer-portal.role')}
                <InformationIcon onClick={() => showRoleDetails()}/>
              </p>
              <SelectMenu
                options={roleOptions}
                initialSelectedKey={selectedRoleId}
                onChange={(option) => setSelectedRoleId(option.key)}
                placeholder={t("select")}
              />
            </div>
          </div>
        </div>
      </PageModal>
    </>
  )
}

function stateToProps({ members, companies }) {
  return {
    companyId: members?.companyId,
    branches: members?.branches || [],
    isLoading: members?.isLoading || companies?.isLoading,
    companyRoles: companies?.roles || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        putMember,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(EditMemberModal)
