import React, { useEffect, useState } from "react"
import "./toggle-switch.scss"

const ToggleSwitch = (props) => {
  const { checked, onChange, isDisabled } = props
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const triggerToggle = () => {
    if (onChange) {
      setIsChecked(!isChecked)
      onChange()
    }
  }

  return (
    <div 
      onClick={triggerToggle} 
      className={"toggle-wrapper" + (isChecked ? " active-toggle" : "") + (isDisabled ? " disabled-toggle" : "")}
    >
      <div className="toggle-switch"></div>
    </div>
  )
}

export default ToggleSwitch
