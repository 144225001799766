import {
  LOAD_NOTIFICATION_SETTINGS_PENDING,
  LOAD_NOTIFICATION_SETTINGS_FULFILLED,
  LOAD_NOTIFICATION_SETTINGS_REJECTED,

  LOAD_NEW_NOTIFICATION_SETTINGS_PENDING,
  LOAD_NEW_NOTIFICATION_SETTINGS_FULFILLED,
  LOAD_NEW_NOTIFICATION_SETTINGS_REJECTED,

  POST_NOTIFICATION_SETTING_PENDING,
  POST_NOTIFICATION_SETTING_FULFILLED,
  POST_NOTIFICATION_SETTING_REJECTED,

  PUT_NOTIFICATION_SETTING_PENDING,
  PUT_NOTIFICATION_SETTING_FULFILLED,
  PUT_NOTIFICATION_SETTING_REJECTED,

  PATCH_NOTIFICATION_SETTING_PENDING,
  PATCH_NOTIFICATION_SETTING_FULFILLED,
  PATCH_NOTIFICATION_SETTING_REJECTED,

  DELETE_NOTIFICATION_SETTING_PENDING,
  DELETE_NOTIFICATION_SETTING_FULFILLED,
  DELETE_NOTIFICATION_SETTING_REJECTED,



  LOAD_NOTIFICATION_MESSAGES_PENDING,
  LOAD_NOTIFICATION_MESSAGES_FULFILLED,
  LOAD_NOTIFICATION_MESSAGES_REJECTED,

  LOAD_NEW_NOTIFICATION_MESSAGES_PENDING,
  LOAD_NEW_NOTIFICATION_MESSAGES_FULFILLED,
  LOAD_NEW_NOTIFICATION_MESSAGES_REJECTED,

  POST_NOTIFICATION_MESSAGE_PENDING,
  POST_NOTIFICATION_MESSAGE_FULFILLED,
  POST_NOTIFICATION_MESSAGE_REJECTED,

  PUT_NOTIFICATION_MESSAGE_PENDING,
  PUT_NOTIFICATION_MESSAGE_FULFILLED,
  PUT_NOTIFICATION_MESSAGE_REJECTED,

  PATCH_NOTIFICATION_MESSAGE_PENDING,
  PATCH_NOTIFICATION_MESSAGE_FULFILLED,
  PATCH_NOTIFICATION_MESSAGE_REJECTED,

  DELETE_NOTIFICATION_MESSAGE_PENDING,
  DELETE_NOTIFICATION_MESSAGE_FULFILLED,
  DELETE_NOTIFICATION_MESSAGE_REJECTED,



  LOAD_EVENT_GROUPS_PENDING,
  LOAD_EVENT_GROUPS_FULFILLED,
  LOAD_EVENT_GROUPS_REJECTED,

  LOAD_NOTIFICATION_DELIVERY_METHODS_PENDING,
  LOAD_NOTIFICATION_DELIVERY_METHODS_FULFILLED,
  LOAD_NOTIFICATION_DELIVERY_METHODS_REJECTED,

  LOAD_NOTIFICATIONS_CONFIGURATION_PENDING,
  LOAD_NOTIFICATIONS_CONFIGURATION_FULFILLED,
  LOAD_NOTIFICATIONS_CONFIGURATION_REJECTED,

  LOAD_CURRENT_USER_NOTIFICATIONS_PENDING,
  LOAD_CURRENT_USER_NOTIFICATIONS_FULFILLED,
  LOAD_CURRENT_USER_NOTIFICATIONS_REJECTED,

  LOAD_EMAIL_TEMPLATES_PENDING,
  LOAD_EMAIL_TEMPLATES_FULFILLED,
  LOAD_EMAIL_TEMPLATES_REJECTED,

  POST_NOTIFICATION_CONFIGURATION_PENDING,
  POST_NOTIFICATION_CONFIGURATION_FULFILLED,
  POST_NOTIFICATION_CONFIGURATION_REJECTED,

  POST_CURRENT_USER_NOTIFICATIONS_READ_PENDING,
  POST_CURRENT_USER_NOTIFICATIONS_READ_FULFILLED,
  POST_CURRENT_USER_NOTIFICATIONS_READ_REJECTED,

  POST_CURRENT_USER_NOTIFICATION_READ_PENDING,
  POST_CURRENT_USER_NOTIFICATION_READ_FULFILLED,
  POST_CURRENT_USER_NOTIFICATION_READ_REJECTED,

  DELETE_NOTIFICATION_CONFIGURATION_PENDING,
  DELETE_NOTIFICATION_CONFIGURATION_FULFILLED,
  DELETE_NOTIFICATION_CONFIGURATION_REJECTED,

  CLEAR_NOTIFICATIONS_MESSAGES,
  CLEAR_NOTIFICATIONS_CONFIGURATION,
  
  LOAD_NOTIFICATION_REGIONS_PENDING,
  LOAD_NOTIFICATION_REGIONS_FULFILLED,
  LOAD_NOTIFICATION_REGIONS_REJECTED,

  LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_PENDING,
  LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_FULFILLED,
  LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_REJECTED,
} from "../actions/notifications"
import i18n from 'i18next'
import { find, forEach, camelCase } from "lodash"

export default function notifications(state = {}, action) {
  const alreadyMemberCode = "alreadyMember"

  switch (action.type) {
    case LOAD_NOTIFICATION_SETTINGS_PENDING:
    case LOAD_NEW_NOTIFICATION_SETTINGS_PENDING:
    case LOAD_NOTIFICATION_MESSAGES_PENDING:
    case LOAD_NEW_NOTIFICATION_MESSAGES_PENDING:
    case LOAD_EVENT_GROUPS_PENDING:
    case LOAD_NOTIFICATION_DELIVERY_METHODS_PENDING:
    case LOAD_EMAIL_TEMPLATES_PENDING:
    case LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_PENDING:
    case LOAD_NOTIFICATIONS_CONFIGURATION_PENDING:
    case POST_NOTIFICATION_SETTING_PENDING:
    case POST_NOTIFICATION_CONFIGURATION_PENDING:
    case PUT_NOTIFICATION_SETTING_PENDING:
    case PATCH_NOTIFICATION_SETTING_PENDING:
    case DELETE_NOTIFICATION_SETTING_PENDING:
    case DELETE_NOTIFICATION_CONFIGURATION_PENDING:
    case LOAD_NOTIFICATION_REGIONS_PENDING:
    case POST_NOTIFICATION_MESSAGE_PENDING:
    case PUT_NOTIFICATION_MESSAGE_PENDING:
    case PATCH_NOTIFICATION_MESSAGE_PENDING:
    case DELETE_NOTIFICATION_MESSAGE_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        successMessage: null,
        errorMessageNotificationSettings: null,
        successMessageNotificationSettings: null
      }
    case LOAD_CURRENT_USER_NOTIFICATIONS_PENDING:
    case POST_CURRENT_USER_NOTIFICATIONS_READ_PENDING:
    case POST_CURRENT_USER_NOTIFICATION_READ_PENDING:
      return {
        ...state
      }
    case LOAD_NOTIFICATION_SETTINGS_REJECTED:
    case LOAD_NEW_NOTIFICATION_SETTINGS_REJECTED:
    case LOAD_NOTIFICATION_MESSAGES_REJECTED:
    case LOAD_NEW_NOTIFICATION_MESSAGES_REJECTED:
    case LOAD_EVENT_GROUPS_REJECTED:
    case LOAD_NOTIFICATION_DELIVERY_METHODS_REJECTED:
    case LOAD_EMAIL_TEMPLATES_REJECTED:
    case LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_REJECTED:
    case POST_NOTIFICATION_SETTING_REJECTED:
    case PUT_NOTIFICATION_SETTING_REJECTED:
    case PATCH_NOTIFICATION_SETTING_REJECTED:
    case DELETE_NOTIFICATION_SETTING_REJECTED:
    case LOAD_NOTIFICATION_REGIONS_REJECTED:
    case POST_NOTIFICATION_MESSAGE_REJECTED:
    case PUT_NOTIFICATION_MESSAGE_REJECTED:
    case PATCH_NOTIFICATION_MESSAGE_REJECTED:
    case DELETE_NOTIFICATION_MESSAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_NOTIFICATIONS_CONFIGURATION_REJECTED:
      const errorCodeLoad = action.payload.response?.data?.code
  
      return {
        ...state,
        notificationsConfiguration: null,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
        errorMessageNotificationSettings: errorCodeLoad === alreadyMemberCode ? i18n.t("notifications.error_member_email") : i18n.t([`error.${errorCodeLoad}`, "error.something_wrong"])
      }
    case POST_NOTIFICATION_CONFIGURATION_REJECTED:
    case DELETE_NOTIFICATION_CONFIGURATION_REJECTED:
      const errorCodePost = action.payload.response?.data?.code
      return {
        ...state,
        isLoading: false,
        errorMessageNotificationSettings: errorCodePost === alreadyMemberCode ? i18n.t("notifications.error_member_email") : i18n.t([`error.${errorCodePost}`, "error.something_wrong"])
      }
    case LOAD_CURRENT_USER_NOTIFICATIONS_REJECTED:
    case POST_CURRENT_USER_NOTIFICATIONS_READ_REJECTED:
    case POST_CURRENT_USER_NOTIFICATION_READ_REJECTED:
      return {
        ...state
      }
    case LOAD_NOTIFICATION_SETTINGS_FULFILLED:
      const notificationSettings = state?.notificationSettings || []
      const newNotificationSettingsIds = action.payload?.map(item => item.id)
      const difference = notificationSettings
        .filter(x => !newNotificationSettingsIds.includes(x.id))
        .concat(action.payload)

      return {
        ...state,
        notificationSettings: difference,
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE),
        isLoading: false,
      }
    case LOAD_NEW_NOTIFICATION_SETTINGS_FULFILLED:
      return {
        ...state,
        notificationSettings: action.payload,
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE),
        isLoading: false,
      }
    case LOAD_EVENT_GROUPS_FULFILLED:
      return {
        ...state,
        eventGroups: action.payload,
        isLoading: false,
      }
    case LOAD_NOTIFICATION_DELIVERY_METHODS_FULFILLED:
      return {
        ...state,
        deliveryMethods: action.payload,
        isLoading: false,
      }
    case LOAD_NOTIFICATION_REGIONS_FULFILLED:
      return {
        ...state,
        notificationRegions: action.payload,
        isLoading: false,
      }
    case LOAD_EMAIL_TEMPLATES_FULFILLED:
      return {
        ...state,
        emailTemplates: action.payload,
        isLoading: false,
      }
    case LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_FULFILLED:
      return {
        ...state,
        notificationPreferences: action.payload,
        isLoading: false,
      }
    case LOAD_NOTIFICATIONS_CONFIGURATION_FULFILLED:
      return {
        ...state,
        notificationsConfiguration: action.payload,
        isLoading: false,
      }
    case LOAD_CURRENT_USER_NOTIFICATIONS_FULFILLED:
      return {
        ...state,
        notificationsTimeline: action.payload,
        isLoading: false,
      }
    case POST_NOTIFICATION_SETTING_FULFILLED:
      const initialNotificationSettings = state?.notificationSettings || [];

      return {
        ...state,
        notificationSettings: [...initialNotificationSettings, action.payload],
        isLoading: false,
        successMessage: i18n.t("events.success-add-notification-setting")
      }
    case POST_NOTIFICATION_CONFIGURATION_FULFILLED:
      const initialNotificationsConfiguration = state?.notificationsConfiguration || [];

      return {
        ...state,
        notificationsConfiguration: [...initialNotificationsConfiguration, ...action.meta.body],
        isLoading: false,
        successMessageNotificationSettings: action.meta.withoutMessage ? null : i18n.t("notifications.success-add-notification-settings")
      }
    case POST_CURRENT_USER_NOTIFICATIONS_READ_FULFILLED:
      const notificationsTimelineAllRead = state?.notificationsTimeline
      forEach(notificationsTimelineAllRead, (arr) => {
        forEach(arr, (obj) => {
          obj.is_read = 1;
        });
      });
      return {
        ...state,
        notificationsTimeline: notificationsTimelineAllRead,
        isLoading: false,
      }
    case POST_CURRENT_USER_NOTIFICATION_READ_FULFILLED:
      const notificationsTimelineWithNotificationRead = state?.notificationsTimeline
      const target = find(notificationsTimelineWithNotificationRead[camelCase(action.meta.type)],{id: action.meta.id})
      if (target) {
        target.is_read = 1;
      }
      return {
        ...state,
        notificationsTimeline: notificationsTimelineWithNotificationRead,
        isLoading: false,
      }
    case PUT_NOTIFICATION_SETTING_FULFILLED:
      const updatedNotificationSettings = state?.notificationSettings?.map((item) => item.id === action.payload.id ? {...action.payload} : item)

      return {
        ...state,
        notificationSettings: updatedNotificationSettings,
        isLoading: false,
        successMessage: i18n.t("events.success-edit-notification-setting")
      }
    case PATCH_NOTIFICATION_SETTING_FULFILLED:
      const patchedNotificationSettings = state?.notificationSettings?.map((item) => item.id === action.payload.id ? {...action.payload} : item)

      return {
        ...state,
        notificationSettings: patchedNotificationSettings,
        isLoading: false,
        successMessage: i18n.t("events.success-edit-notification-setting")
      }
    case DELETE_NOTIFICATION_SETTING_FULFILLED:
      const remainingNotificationSettings = state?.notificationSettings.filter((item) => item.id !== action.meta.id)
      
      return {
        ...state,
        notificationSettings: remainingNotificationSettings,
        isLoading: false,
        successMessage: i18n.t("events.success-delete-notification-setting")
      }
    case DELETE_NOTIFICATION_CONFIGURATION_FULFILLED:
      const remainingNotificationsConfiguration = state?.notificationsConfiguration.filter((item) => item.email !== action.meta.email)
      
      return {
        ...state,
        notificationsConfiguration: remainingNotificationsConfiguration,
        isLoading: false,
        successMessageNotificationSettings: i18n.t("notifications.success-delete-notification-settings")
      }
    case CLEAR_NOTIFICATIONS_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        errorMessageNotificationSettings: null,
        successMessageNotificationSettings: null,
      }
    }
    case CLEAR_NOTIFICATIONS_CONFIGURATION: {
      return {
        ...state,
        notificationsConfiguration: null,
      }
    }
    case LOAD_NOTIFICATION_MESSAGES_FULFILLED:
      const notificationMessages = state?.notificationMessages || []
      const newNotificationMessagesIds = action.payload.data?.map(item => item.id)
      const differenceMessages = notificationMessages
        .filter(x => !newNotificationMessagesIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        notificationMessages: differenceMessages,
        canLoadMore: action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_NOTIFICATION_MESSAGES_FULFILLED:
      return {
        ...state,
        notificationMessages: action.payload.data,
        canLoadMore: action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case POST_NOTIFICATION_MESSAGE_FULFILLED:
      const initialNotificationMessages = state?.notificationMessages || [];

      return {
        ...state,
        notificationMessages: [...initialNotificationMessages, action.payload],
        isLoading: false,
        successMessage: i18n.t("events.success-add-notification-message")
      }
    case PUT_NOTIFICATION_MESSAGE_FULFILLED:
      const updatedNotificationMessages = state?.notificationMessages?.map((item) => item.id === action.payload.id ? {...action.payload} : item)

      return {
        ...state,
        notificationMessages: updatedNotificationMessages,
        isLoading: false,
        successMessage: i18n.t("events.success-edit-notification-message")
      }
    case PATCH_NOTIFICATION_MESSAGE_FULFILLED:
      const patchedNotificationMessages = state?.notificationMessages?.map((item) => item.id === action.payload.id ? {...action.payload} : item)

      return {
        ...state,
        notificationMessages: patchedNotificationMessages,
        isLoading: false,
        successMessage: i18n.t("events.success-edit-notification-message")
      }
    case DELETE_NOTIFICATION_MESSAGE_FULFILLED:
      const remainingNotificationMessages = state?.notificationMessages.filter((item) => item.id !== action.meta.id)
      
      return {
        ...state,
        notificationMessages: remainingNotificationMessages,
        isLoading: false,
        successMessage: i18n.t("events.success-delete-notification-message")
      }
    default:
      return state
  }
}
