import {
  SET_USER_PRODUCT,
  SET_PRODUCTS_LOADING,
  SET_PRODUCTS_LOADING_OFF,
} from '../actions/userProducts'

export default function userProducts (state=null, action) {
  switch (action.type) {
    case SET_USER_PRODUCT:
      return {
        ...state,
        products: action.products,
        loading: false,
        loaded: true
      }
    case SET_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true
      }
    case SET_PRODUCTS_LOADING_OFF:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}