import React from 'react'
import './data-placeholder.scss'

const DataPlaceholder = (props) => {
  const { titlePlaceholder, subtitlePlaceholder } = props

  return (
    <div className="data-placeholder">
      <div className="item-placeholder item-selected">
        <div className="icon-placeholder icon-selected">
          <div className="icon-checkmark">L</div>
        </div>
        <div className="lines-placeholder">
          <div className="short-line line-selected"/>
          <div className="long-line line-selected"/>
        </div>
      </div>
      <div className="item-placeholder">
        <div className="icon-placeholder"/>
        <div className="lines-placeholder">
          <div className="short-line"/>
          <div className="long-line"/>
        </div>
      </div>
      <div className="item-placeholder">
        <div className="icon-placeholder"/>
        <div className="lines-placeholder">
          <div className="short-line"/>
          <div className="long-line"/>
        </div>
      </div>
      <p className="title-placeholder">{titlePlaceholder}</p>
      <p className="subtitle-placeholder">{subtitlePlaceholder}</p>
    </div>
  )
}

export default DataPlaceholder
