import { getUserProducts } from "../utils/requests/usersAPI"

export const SET_AUTH_USER = 'SET_AUTH_USER'
export const SET_USER_PRODUCT = 'SET_USER_PRODUCT'
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING'
export const SET_PRODUCTS_LOADING_OFF = 'SET_PRODUCTS_LOADING_OFF'

export function setUserProducts(products) {
  return {
    type: SET_USER_PRODUCT,
    products
  }
}

export function setProductLoading() {
  return {
    type: SET_PRODUCTS_LOADING
  }
}

export function setProductLoadingOff() {
  return {
    type: SET_PRODUCTS_LOADING_OFF
  }
}

export function handleSetUserProducts({ cognitoSub }) {
  return (dispatch) => {
    dispatch(setProductLoading())

    return getUserProducts({cognitoSub: cognitoSub}).then((products) => {
      dispatch(setUserProducts(products))
    })
  }
}