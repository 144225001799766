import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next"
import Breadcrumb from "../common/Breadcrumb"
import SearchWithFilter from '../common/SearchWithFilter'
import ShowMoreText from "../common/ShowMoreText"
import LoadingSpinner from "../common/LoadingSpinner"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import GenericTable from "../common/GenericTable"
import EmptyPageResults from '../common/EmptyPageResults'
import ExpandableText from '../common/ExpandableText'
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { getImageByModel } from "../../utils/functions"
import {formatDate, convertToSimpleDateWithFullTime} from "../../utils/filters/date"
import {getModelBySerial, getProductCyclesFaultsNotesBySn} from "../../utils/requests/productsAPI"
import './product-notes.scss'

const ProductNotes = (props) => {
  const {t} = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const product = props.location?.state?.items?.length > 0 && props.location?.state?.items[0]
  const serialNumber = props.serialNumber || props.location.state?.items?.length > 0 ? props.location.state?.items[0]?.serial_number : props.match.params.id
  const hideBackButton = props.hideBackButton
  const hideBreadcrumb = props.hideBreadcrumb
  const [model, setModel] = useState("")
  const [notes, setNotes] = useState([])
  const [notesTableData, setNotesTableData] = useState([])
  const [expandedNote, setExpandedNote] = useState(0)
  const [cycleFaultsOptions, setCycleFaultsOptions] = useState([])
  const [selectedCycleFaults, setSelectedCycleFaults] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  
  useEffect(() => {
    const model_id = product?.model_id || ""

    getModelBySerial({SN: serialNumber, model_id})
      .then((data) => {
        setModel(data.status ? data.data[0].model : "No-Image")
      })
  },[])

  useEffect(() => {
    toggleSpinner(true)

    getProductCyclesFaultsNotesBySn(serialNumber)
      .then((data) => {
        if (data.data) {
          const cycleFaults = data.data.map(cycle => cycle.cycle_fault)
          const uniqueCycleFaults = Array.from(new Set(cycleFaults))
          setCycleFaultsOptions(uniqueCycleFaults)

          setNotes(data.data)
        }

        toggleSpinner(false)
        setIsFirstLoad(false)
      })
      .catch(() => {
        toast.error(t('error.something_wrong'))
        toggleSpinner(false)

        setIsFirstLoad(false)
      })
  }, [])

  useEffect(() => {
    const filteredNotes = notes.filter(note => {
      if (selectedCycleFaults.length > 0 && selectedDate) {
        return selectedCycleFaults.includes(note.cycle_fault) && formatDate(note.note_date) === formatDate(selectedDate)
      }

      if (selectedCycleFaults.length > 0) {
        return selectedCycleFaults.includes(note.cycle_fault)
      }

      if (selectedDate) {
        return formatDate(note.note_date) === formatDate(selectedDate)
      }

      return note
    })

    const tableData = filteredNotes.map((note) => {
      return {
        identifier: note.id,
        serialNumber: serialNumber,
        cycle: note.cycle_number,
        cycleFault: note.cycle_fault,
        cycleDate: formatDate(note.cycle_date),
        comment: note.note,
        user: note.posted_by,
        commentDate: convertToSimpleDateWithFullTime(note.note_date),
      }
    })

    setNotesTableData(tableData)
  }, [selectedCycleFaults, selectedDate, notes])

  const toggleSpinner = (val) => {
    setShowSpinner(val)
  }

  const handleResetFilters = () => {
    setSelectedCycleFaults([])
    setSelectedDate(null)
  }

  return (
    <div className="products-wrapper">
      {!hideBreadcrumb && (
        <Breadcrumb path={window.location.pathname} withBackNavigation={!hideBackButton} />
      )}
      <DashboardHeader headerText={t("products.notes.title")} />
      <div className="separator" />
      <div className="actions-wrapper">
        <SearchWithFilter
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedCycleFaults.length > 0 || selectedDate}
          counterBadge={(selectedCycleFaults.length ) + (selectedDate && 1)}
        />
      </div>
      <div className="product mt-25 mb-25">
        <img src={getImageByModel(model)} alt={model} />
        <div className="ml-15">
          <h2 className="serial-number">
            {t("products.notes.product.serial_number")}:{" "}
            {serialNumber}
          </h2>
          <p className="name">{model}</p>
        </div>
      </div>
      {!isMobile && notesTableData.length > 0 &&
        <GenericTable
          data={notesTableData}
          headers={[
            {
              title: t("nav.serial_number")
            },
            {
              title: t("products.notes.cycle")
            },
            {
              title: t("products.notes.cycle_fault")
            },
            {
              title: t("products.notes.cycle-date")
            },
            {
              title: t("products.notes.comment")
            },
            {
              title: t("products.notes.user")
            },
            {
              title: t("products.notes.comment-date")
            },
          ]}
          keys={[
            'serialNumber',
            'cycle',
            'cycleFault',
            'cycleDate',
            'comment',
            'user',
            'commentDate',
          ]}
          keyRenderer={{
            cycleFault: (item) => {
              return (
                <div className="cycle-fault-wrapper">
                  {item.cycleFault}
                </div>
              )
            },
            comment: (item) => {
              return (
                <div className="comment-wrapper">
                  <ExpandableText text={item.comment} />
                </div>
              )
            },
            user: (item) => {
              return (
                <div className="posted-by-wrapper">
                  {product && 
                    <>
                      {product.handeled_by && product.handeled_by.avatar_url ?
                        <img src={product.handeled_by.avatar_url} alt="avatar" />
                      :
                        <div className="no-image">
                          {product.handeled_by &&
                            product.handeled_by.first_name &&
                            product.handeled_by.last_name
                            ? product.handeled_by.first_name
                                .charAt(0)
                                .toUpperCase() +
                              product.handeled_by.last_name
                                .charAt(0)
                                .toUpperCase()
                            : product?.handeled_by?.email?.substring(0, 2)
                            ? product.handeled_by.email.substring(0, 2)
                            : ""
                          }
                        </div>
                      }
                    </>
                  }
                  {item.user}
                </div>
              )
            },
          }}
        />
      }
      {isMobile && notesTableData.map((note, index) =>
        <div 
          key={index}
          className={"card"} 
          onClick={() => setExpandedNote(index)}
        >
          <div className={"card-item" + (expandedNote !== index ? " align-center" : "")}>
            <div className="card-item-title">{t("nav.serial_number")}</div>
            <div className={"card-item-body" + (expandedNote !== index ? " align-center" : "")}>
              <div>{note.serialNumber}</div>
            </div>
          </div>
          {expandedNote === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.cycle")}</div>
                <div className="card-item-body">{note.cycle}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.cycle_fault")}</div>
                <div className="card-item-body cycle-fault">{note.cycleFault}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.cycle-date")}</div>
                <div className="card-item-body">{note.cycleDate}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.comment")}</div>
                <div className="card-item-body">{note.comment}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.user")}</div>
                <div className="card-item-body">
                  <div className="card-item-body-posted-by">
                  {product && 
                    <>
                      {product.handeled_by && product.handeled_by.avatar_url ?
                        <img src={product.handeled_by.avatar_url} alt="avatar" />
                      :
                        <div className="no-image">
                          {product.handeled_by &&
                          product.handeled_by.first_name &&
                          product.handeled_by.last_name
                            ? product.handeled_by.first_name
                                .charAt(0)
                                .toUpperCase() +
                              product.handeled_by.last_name
                                .charAt(0)
                                .toUpperCase()
                            : product?.handeled_by?.email?.substring(0, 2)
                            ? product.handeled_by.email.substring(0, 2)
                            : ""}
                        </div>
                      }
                    </>
                  }
                  {note.user}
                  </div>
                </div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.comment-date")}</div>
                <div className="card-item-body">{note.commentDate}</div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && notesTableData.length === 0 &&  (
        <EmptyPageResults 
          title={t("no_data_available")}
        />
      )}
      {showFiltersModal &&
        <FiltersModal
          filters={[
            {
              name: t("products.notes.comment-date"),
              type: FilterType.dateFixed,
              dataset: null,
              input: selectedDate,
              output: (filterDate) => {
                setSelectedDate(filterDate)
              },
            },
            {
              name: t("products.notes.cycle_faults"),
              type: FilterType.multiSelect,
              dataset: cycleFaultsOptions,
              input: selectedCycleFaults,
              output: (filterCycleFaults) => {
                setSelectedCycleFaults(filterCycleFaults)
              },
            },
          ]}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      }
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

function mapStateToProps({productTypes}) {
  return {
    productTypes,
  }
}

export default connect(mapStateToProps)(ProductNotes)
