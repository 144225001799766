import React from "react"
import { useTranslation } from "react-i18next"
import TemperatureIcon from "../../icons/temperature.svg"
import PressureIcon from "../../icons/pressure.svg"
import HoldTimeIcon from "../../icons/hold_time.svg"
import "./reports.scss";

const Legend = (props) => {
  const { t } = useTranslation();
  
  return (
    <div className="reports-wrapper">
      <div className="legend">
        <div className="left">
          <div className="icon">
            <img src={HoldTimeIcon} alt="hold-time" />
            {t("reports.hold_time")}
          </div>
          <div className="icon">
            <img src={TemperatureIcon} alt="hold-temp" />
            {t("reports.hold_temperature")}
          </div>
          <div className="icon">
            <img src={PressureIcon} alt="hold-press" />
            {t("reports.hold_pressure")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
