import React, { useState, useEffect } from "react"
import { withRouter, Redirect, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Auth } from "aws-amplify"
import { isEmpty } from "lodash"
import { loadStripe } from '@stripe/stripe-js'
import RemoteAccessLogin from "./RemoteAccessLogin" // Not in feature dev
import RegisterProductModern from './RegisterProductModern'
import OnlineAccess from "./OnlineAccess"
import G4OnlineAccess from './OnlineAccess/g4-online-access'
import Banner from "./common/Banner"
import Nav from "./common/Nav"
import Footer from "./common/Footer"
import Routes from "./common/Routes"
import SimpleButton from "./common/SimpleButton"
import { toast } from "./common/Toast"
import LoadingSpinner from "./common/LoadingSpinner"
import Modal from "./common/Modal"
import { ColteneModernLogoBlack } from '../icons'
import { AppContext } from "../libs/contextLib"
import { processError } from "../libs/processError"
import { useDeepCompareEffect } from "../hooks/useDeepCompareEffect"
import { endRemoteSession } from "../utils/requests/remoteAccess"
import {handleReportTicket} from "../actions/reportTicket"
import { getWarranty } from '../utils/requests/warrantiesAPI'
import { putEmailClick, putG4OnlineAccess, putSuccessLogin } from '../utils/requests/onlineActivation'
import { getCurrentUserPermissions } from '../utils/requests/usersAPI'
import config from '../utils/config'
import { compareVersions } from '../utils/versions'
import { sendMail } from "../utils/requests/mqttPublishAPI"
import { handleSetCurrentUserPermissions } from '../actions/userPermissions'
import { checkPermission, hasEndUserSiteAccess, hasNoSiteAccess, hasOrganizationAdminModulesPermission, myOrganizationPermission } from "../utils/permissionValidation"
import { QUERY_PARAMS_KEY, handleSetQueryParams } from "../actions/queryParams"
import { handleSetAuthedUser } from "../actions/authedUser"
import { handleIntitialData } from "../actions/shared"
import { handleSetOnlineAccess } from "../actions/onlineAccess"
import { handleSetWarranty } from '../actions/warranty'
import { handleLoadUserCompany } from "../actions/userCompany"
import { handleUserLogout, REMOTE_SESSION_TIME, REMOTE_SESSION_DATA} from "../actions/root"
import packageJson from '../../package.json'
import "./App.scss"

function App(props) {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { dispatch } = props
  const urlParams = new URLSearchParams(window.location.search)
  const action = new URL(window.location.href)
  const [showFeatureDev, setShowFeatureDev] = useState(config.stage === 'dev' || config.stage === 'uat')
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [isAuthenticating, setAuthenticating] = useState(true)
  const [showOnlineAccess, setShowOnlineAccess] = useState(false)
  const [showG4Access, setShowG4Access] = useState(false)
  const [email, setEmail] = useState("")
  const [expandNavMenu, setExpandNavMenu] = useState(false)
  const [remoteModal, setRemoteModal] = useState(false) //Not in Feature Dev
  const [remoteSession, setRemoteSession] = useState(JSON.parse(localStorage.getItem(REMOTE_SESSION_DATA)))
  const [warrantyModal, setWarrantyModal] = useState({
    headerTitle: "",
    serial: "",
    message: "",
    closeLabel: "",
    onClose: () => {},
  })
  const [shouldCloseWithCleanState, setShouldCloseWithCleanState] = useState(true) 
  const [showSpinner, setShowSpinner] = useState(false)
  const [hiddenNav] = useState(urlParams.get('_hidden_nav'))
  const [disabledNav] = useState(urlParams.get('_disabled_nav'))
  const [disabledBanner] = useState(urlParams.get('_disabled_banner'))
  const [sessionWindow, setSessionWindow] = useState(null)
  const [isSuccessOnlineActivation, setIsSuccessOnlineActivation] = useState(false)
  const [showRegisterProduct, setShowRegisterProduct] = useState(false)
  const [supportsColteneStore, setSupportsColteneStore] = useState(false)
  const [hasOrganizationAccess, setHasOrganizationAccess] = useState(false)
  const [stripe, setStripe] = useState(null)
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)
  const [showInstructionsModal,  setShowInstructionsModal] = useState(false)
  const organizationInviteRoleId = urlParams.get('roleId') || ""
  const organizationInviteEmail = urlParams.get('email') || ""
  const organizationInviteId = urlParams.get('inviteId') || ""
  const savedSearchParams = JSON.parse(localStorage.getItem(QUERY_PARAMS_KEY) || "{}")
  const versionPollingIntervalMinutes = (process.env.REACT_APP_VERSION_POLLING_FREQUENCY_SECONDS || 180) / 60
  
  useEffect(() => { 
      if (isAuthenticated && !isAuthenticating && props.authedUser == null) {
        Auth.signOut()
        .then(() => {
          setShowSessionExpiredModal(true)
        })
      }
  }, [isAuthenticated, isAuthenticating, props.authedUser])

  useEffect(() => {
    let intervalId;

    const shouldForceRefresh = (latestVersion, currentVersion) => {
      const comparisonResult = compareVersions(latestVersion, currentVersion);
      return comparisonResult === 1;
    }

    const checkVersion = () => {
      try {
        const versionsJsonUrl = `${process.env.PUBLIC_URL}/versions.json?t=${new Date().getTime()}`;

        fetch(versionsJsonUrl, {cache: 'no-cache'})
            .then((response) => response.json())
            .then((versions) => {
                const latestVersion = versions.version
                const currentVersion = packageJson.version

                if (shouldForceRefresh(latestVersion, currentVersion)) {
                  window.location.reload(true)
                }
            });
      } catch {
        console.log(`Error while trying to fetch the latest version, from versions.json file.`);
      }
    }

    checkVersion();

    intervalId = setInterval(() => {
      checkVersion();
    }, (60000 * versionPollingIntervalMinutes));

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [])
  
  useEffect(() => {
    if (action.searchParams.get("action") === "warranty") {
      dispatch(
        handleSetWarranty({
          action: "warranty",
          accountCreated: false,
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    const { warranty, userPermissions } = props
    if (warranty?.action === "warranty") {
      if (action.searchParams.has("locale")) {
        i18n.changeLanguage(action.searchParams.get("locale"))
      }

      const isLoggedIn = !isAuthenticating && isAuthenticated && !isEmpty(userPermissions)

      if (isLoggedIn) {
        if (hasEndUserSiteAccess(userPermissions)) {
          setShowRegisterProduct(true)
        } else {
          dispatch(handleSetWarranty({ action: null, accountCreated: false }))
        }
      }
    }
  }, [dispatch, i18n, props.userPermissions, isAuthenticated, isAuthenticating, props.warranty])
  
  useEffect(() => {
    const controller = new AbortController();
    const onLoad = () => {
      if (action.searchParams.get('action') === 'onlineaccess' && !props?.onlineAccess) {
        dispatch(handleSetOnlineAccess({
          email: action.searchParams.get('email') || null ,
          sn: action.searchParams.get('sn') || null,
          token: action.searchParams.get('token') || null,
          publish: action.searchParams.get('pub') || null,
          type: action.searchParams.get('type') || null,
          action: action.searchParams.get('action') || null,
          refId: action.searchParams.get('ref_id') || null,
          etk: action.searchParams.get('etk') || null,
          model_id: action.searchParams.get('model_id') || null,
        }))
      }
      
      if (action.searchParams.get('vid')) {
        dispatch(handleSetQueryParams({
          vid: action.searchParams.get("vid") || null
        }))
      }
      
      if (organizationInviteRoleId && organizationInviteEmail && organizationInviteId) {
        dispatch(handleSetQueryParams({
          role: organizationInviteRoleId,
          email: atob(organizationInviteEmail),
          inviteId: organizationInviteId
        }))
      }

      if (action.searchParams.get("ticket")?.length && !props?.ticket?.ticket) {
        dispatch(handleReportTicket({ ticket: action.searchParams.get("ticket") }));
      }

      try {
        Auth.currentSession()
          .then(async (res) => {
            setAuthenticated(true);

            const permissions =  await getCurrentUserPermissions()
            dispatch(
              handleSetCurrentUserPermissions(permissions)
            )

            const credentials = await Auth.currentCredentials();
            const { identityId } = credentials;

            const userAttr = res.getIdToken().payload;
            const encryptedEmail = btoa(userAttr["email"]);
            const cognitoSub = userAttr["sub"];
            setEmail(userAttr["email"]);
            dispatch(
              handleSetAuthedUser({
                email: userAttr["email"],
                encryptedEmail: userAttr["email"] ? encryptedEmail : "",
                firstname: userAttr["given_name"] || "",
                lastname: userAttr["family_name"] || "",
                language: userAttr["locale"] || "",
                company: userAttr["custom:company"] || "",
                phone: userAttr["custom:phone_number"] || "",
                city: userAttr["custom:city"] || "",
                stateprovince: userAttr["custom:state"] || "",
                country: userAttr["custom:country"] || "",
                postalcode: userAttr["custom:postal_code"] || "",
                userGroup: userAttr["custom:user_group"] || "dentist", //scican_technician //dentist
                //privacyAccepted: userAttr['custom:privacy'] || 'true',
                newsletter: userAttr["custom:newsletter"] || "",
                companyPhone: userAttr["custom:company_phone"] || "",
                cognitoUser: res.getAccessToken().payload.username,
                cognitoSub: userAttr["sub"],
                identity: identityId,
                //features: userAttr['custom:user_features'] || ''
                features: "colteneStore",
                companyAccountType: userAttr["custom:company_account_type"] || "individual",
                address: userAttr["custom:address"] || "",
              })
            );

            return {
              cognitoSub: cognitoSub,
              permissions: permissions,
            };
          })
          .then((cognitoSub, permissions) => {
            setAuthenticating(false);

            dispatch(handleIntitialData(cognitoSub, permissions));
          })
          .catch((e) => {
            if (e !== "No current user") {
              console.info(e);
            }
            // const em = e.code ? t(`error.${e.code}`) : e.message
            processError(e);
            setAuthenticating(false);
          });
      } catch (e) {
        if (e !== "No current user") {
          console.info(e);
        }
        // const em = e.code ? t(`error.${e.code}`) : e.message
        processError(e);
        setAuthenticating(false);
      }
    };

    const onSessionInactive = () => {
      Auth.signOut()
        .then(() => {
          setShowSessionExpiredModal(true)
        })
    }

    onLoad();

    const tout = 8 * 1000 * 60 * 60;
    let exp;

    if (isAuthenticated) {
      exp = setTimeout(() => onSessionInactive(), tout);
      if (props?.ticket?.ticket && window.location.pathname !== "/report-problem") {
        history.push(`/report-problem`);
      }
    }

    return () => {
      controller.abort();

      if (exp) {
        clearTimeout(exp);
      }
    }
  }, [t, props.ticket, dispatch, isAuthenticated, isSuccessOnlineActivation])
  
  useEffect(() => {
    const closeModalsWithCleanState = () => {
      setShowG4Access(false)
      setShowOnlineAccess(false)
      dispatch(handleSetOnlineAccess(null))
    }

    const toggleShowG4WithoutWarranty = () => {
      setShowRegisterProduct(true)
      
      setShowG4Access(false)
      dispatch(handleSetOnlineAccess(null))
    }

    const hasDifferentEmail = () => {
      return email.toLowerCase() !== props.onlineAccess?.email?.toLowerCase()
    }

    const showEmailErrorPromptWithCleanState = () => {
      toast.error(
        t("product.onlineaccess.email_does_not_match"), {
          autoClose: 10000,
          hideProgressBar: false,
          icon: true
        }
      )
      dispatch(handleSetOnlineAccess(null))
    }

    if (isAuthenticated && email && Object.keys(props?.onlineAccess ?? []).length && props.productsLoaded) {
      if (!hasEndUserSiteAccess(props.userPermissions)) {
        closeModalsWithCleanState()
        return
      }

      const isG4Access = props.onlineAccess.type?.toLowerCase() === "g4"

      if ((isG4Access || !props.onlineAccess.token) && hasDifferentEmail()) {
        showEmailErrorPromptWithCleanState()
        return
      }

      if (isG4Access && props.onlineAccess.action === "onlineaccess") {
        setShowG4Access(true)

        const existingProductSerialNumber = Object.entries(props.products)
          .find((item) => item[1].serial_number === props.onlineAccess?.sn)

        if (existingProductSerialNumber) {
          if (!showInstructionsModal) {
            setWarrantyModal({
              message: t("online-access.continue_registration"),
              closeLabel: t("labels.continue"),
              serial: props.onlineAccess?.sn,
              headerTitle: t("online-access.online_access_registration"),
              onClose: async () => {
                setShowG4Access(false)
                const waitForPhaseCall = new Promise(resolve => {
                  setTimeout(() => {
                    resolve()
                  }, 500)
                })

                setShowSpinner(true)
                await waitForPhaseCall

                try {
                  const data = await putG4OnlineAccess({
                    state: "pending",
                    email: props.onlineAccess?.email,
                    sn: props.onlineAccess?.sn,
                    ref_id: props.onlineAccess?.refId,
                    etk: props.onlineAccess?.etk,
                    model_id: props.onlineAccess?.model_id ?? "",
                  })

                  setShowSpinner(false)
                  setShowG4Access(true)

                  if (data.status === 1) {
                    setShowInstructionsModal(true)
                    setIsSuccessOnlineActivation(true)
                    setWarrantyModal({
                      headerTitle: t("online-access.online_access_registration"),
                      message: t('online-access.follow_instructions'),
                      closeLabel: t("labels.close"),
                      onClose: async () => {
                        closeModalsWithCleanState()
                      },
                    })
                  } else {
                    closeModalsWithCleanState()

                    if (data?.error?.code) {
                      toast.error(t(`error.${data["error"]["code"]}`))
                      return
                    }

                    toast.error(t("error.failure_msg"));
                  }                 
                } catch (error) {
                  setShowSpinner(false)
                  closeModalsWithCleanState()

                  if (error?.response?.data?.error?.code) {
                    toast.error(t(`error.${error.response.data["error"]["code"]}`))
                    return
                  }

                  toast.error(t("error.failure_msg"))
                }
              },
              onContinue: toggleShowG4WithoutWarranty,
            })
          }
        } else {
          setShowRegisterProduct(true)
          
          setShouldCloseWithCleanState(false)
          setWarrantyModal({
            headerTitle: t("online-access.online_access_registration"),
            serial: props.onlineAccess?.sn,
            message: t("online-access.register_warranty"),
            closeLabel: t("labels.close"),
            onClose: () => {
              setShowG4Access(false)
              setShowOnlineAccess(false)
            },
          })
        }
      } else if (!isG4Access && (props?.onlineAccess?.sn || props?.onlineAccess?.token)) {
        setShowOnlineAccess(true)
      }

      if (props?.onlineAccess?.action && props?.onlineAccess?.etk) {
        putSuccessLogin(props?.authedUser?.cognitoSub, {
          token: props?.onlineAccess?.etk,
        })

        putEmailClick(props?.authedUser?.cognitoSub, {
          token: action.searchParams.get("etk"),
        })
      }
    }
  }, [
    email,
    isAuthenticated,
    props.onlineAccess?.type,
    props.onlineAccess?.action,
    props.onlineAccess?.email,
    props.onlineAccess?.sn,
    props.onlineAccess?.token,
    props.onlineAccess?.etk,
    props.onlineAccess?.refId,
    props.userPermissions,
    props.productsLoaded
  ])

  useEffect(async () => {
    const supportedColteneStoreCountries = process.env.REACT_APP_COLTENE_STORE_COUNTRY_CODES?.toLowerCase()?.split(',')?.map((c) => c?.trim() || "") || []
    
    const isSupportedStoreCountry = props.authedUser?.country && supportedColteneStoreCountries.includes(props.authedUser.country.toLowerCase())
    setSupportsColteneStore(isSupportedStoreCountry)

    if (isSupportedStoreCountry) {
      try {
        const stripeForSupportedCountry = await loadStripe(process.env[`REACT_APP_STRIPE_KEY_${props.authedUser.country.toUpperCase()}`])
        setStripe(stripeForSupportedCountry)
      } catch {
        console.error(t("error.stripe_integration"))
      }
    }
  }, [props.authedUser?.country])

  useEffect(async () => {
    const individualUser = "individual"
    setHasOrganizationAccess(props.authedUser?.companyAccountType && props.authedUser?.companyAccountType !== individualUser)
  }, [props.authedUser?.companyAccountType])
  
  useDeepCompareEffect(() => {
    const isOrganizationRequestPending = props.authedUser?.companyAccountType === "organization_admin" && 
      props.userPermissions?.length > 0 && 
      !hasOrganizationAdminModulesPermission(props.userPermissions) &&
      !hasNoSiteAccess(props.userPermissions)

    if (isOrganizationRequestPending) {
      const sendCustomerServiceEmail = async () => {
        const emailTemplateVars = {
          company: `${props.authedUser.company}`,
          firstname: `${props.authedUser.firstname}`,
          lastname: `${props.authedUser.lastname}`,
          phone: `${props.authedUser.phone}`,
          country: `${props.authedUser.country}`,
          language: `${props.authedUser.language}`,
          email: `${props.authedUser.email}`,
        }
        const templateName = `organization_registration_get_help_en_CA`
        const email = config.organizationReviewEmailTemplate.email

        try {
          await sendMail(
            email,
            templateName,
            emailTemplateVars
          )

          toast.success(t("register.email_sent"))
        } catch (error) {
          toast.error(t("error.something_wrong"))
        }
      }

      toast.info(t("company.request_pending_review"), { autoClose: false }, t("register.email_customer_service"), sendCustomerServiceEmail)
    }
  }, [props.authedUser?.companyAccountType, props.userPermissions])

  useDeepCompareEffect(() => {
    const hasOrganizationAccess = Array.isArray(props.userPermissions) && 
      props.userPermissions.length > 0 && 
      checkPermission(props.userPermissions, myOrganizationPermission)

    if (hasOrganizationAccess) {
      dispatch(handleLoadUserCompany())
    }
  }, [props.userPermissions])

  // useEffect(() => {
  //   if (isAuthenticated && action.searchParams.get("action") && action.searchParams.get("etk")) {
  //     putEmailClick({
  //       token: action.searchParams.get("etk"),
  //     });
  //   }
  // }, [isAuthenticated]);

  const toggleNavDisplay = (val) => {
    document.body.className = !expandNavMenu ? document.body.className + " nav-open" : document.body.className.replace(" nav-open", "");
    setExpandNavMenu(!expandNavMenu);
  };

  const closeNavDisplay = () => {
    document.body.className = document.body.className.replace(" nav-open", "");
    setExpandNavMenu(false);
  };

  //If remoteSession = true enabled the /remote-access route //NOT in Feature dev
  const enableRemoteSession = () => {
    setRemoteSession(localStorage.getItem(REMOTE_SESSION_DATA));
    if (!sessionWindow || !sessionWindow.window || !sessionWindow.window.location || !sessionWindow.window.location.reload) {
      setSessionWindow(window.open('/remote-access-session?_disabled_nav=1&_disabled_banner=1'))
    } else {
      sessionWindow.window.focus()
    }
  };

  const disableRemoteSession = () => {
    if (remoteSession && remoteSession.guid) {
      const data = {
        guid: remoteSession.guid,
        ip: remoteSession.ip ? remoteSession.ip : "127.0.0.1",
      };

      endRemoteSession(data)
        .then((res) => {
          setRemoteSession(false);
          localStorage.removeItem(REMOTE_SESSION_TIME);
          localStorage.removeItem(REMOTE_SESSION_DATA);
          if (sessionWindow) {
            if (sessionWindow.window) {
              sessionWindow.close();
            }
            setSessionWindow(null);
          } else if (disabledBanner && disabledNav) {
            window.close();
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const toggleShowOnlineAccess = (item = null) => {
    if (showOnlineAccess) {
      window.history.replaceState("object or string", "Title", window.location.href.split("?")[0]);
    }
    dispatch(handleSetOnlineAccess(null));
    setShowOnlineAccess(item);
  };

  const toggleHideG4Access = () => {
    dispatch(handleSetOnlineAccess(null))
    setShowG4Access(false)
  }
  
  useEffect(() => {
    if (isAuthenticated && savedSearchParams?.vid) {
      const vid = savedSearchParams.vid
      dispatch(handleSetQueryParams({ vid: null }))

      if (window.location.pathname !== "/videos") {
        history.push(`/videos?vid=${vid}`)
      }
    }
  }, [dispatch, history, isAuthenticated, savedSearchParams?.vid])

  return !isAuthenticating ? (
    <div id="ms-dashboard" className={!isAuthenticated ? "App container u-page" : "App container"}>
      <AppContext.Provider value={{ isAuthenticated, setAuthenticated, showFeatureDev, supportsColteneStore, hasOrganizationAccess, stripe }}>
        {!disabledBanner && (
          <Banner
            toggleShowOnlineAccess={toggleShowOnlineAccess}
            disableRemoteSession={disableRemoteSession}
            toggleNavDisplay={!disabledNav ? toggleNavDisplay : null}
            navDisplay={expandNavMenu}
            closeNavDisplay={closeNavDisplay}
          />
        )}
        <div id={!isAuthenticated ? "unauthenticted-content" : "page-content"} className={disabledBanner ? " disabled-banner" : ""}>
          {!isAuthenticated && (
            <div className="logo-wrapper center">
              <ColteneModernLogoBlack />
            </div>
          )}
          {!disabledNav && (!hiddenNav || expandNavMenu) && (
            <Nav
              expandNavMenu={expandNavMenu}
              closeNavDisplay={closeNavDisplay}
              disabledBanner={disabledBanner}
              setExpandNavMenu={setExpandNavMenu}
              toggleShowOnlineAccess={toggleShowOnlineAccess}
            />
          )}
          <div id="main" className={(disabledNav || (hiddenNav && !expandNavMenu) ? " disabled-nav" : "") + (expandNavMenu ? " nav-expand" : "")}>
            <Routes setRemoteSession={setRemoteSession} />
          </div>
          <Footer />

          {showRegisterProduct && <RegisterProductModern closeModal={() => setShowRegisterProduct(false)} />}
          {showG4Access && (
            <Modal
              toggle={showG4Access}
              onToggle={() => (shouldCloseWithCleanState ? toggleHideG4Access() : setShowG4Access(false))}
              className="online-activation"
            >
              <G4OnlineAccess
                headerTitle={warrantyModal.headerTitle}
                serial={warrantyModal.serial}
                message={warrantyModal.message}
                closeLabel={warrantyModal.closeLabel}
                onClose={warrantyModal.onClose}
              />
            </Modal>
          )}
          {!showG4Access && showOnlineAccess && (
            <Modal toggle={showOnlineAccess} onToggle={() => toggleShowOnlineAccess(false)} className="online-activation">
              <OnlineAccess toggleShowOnlineAccess={() => toggleShowOnlineAccess(false)} setIsSuccessOnlineActivation={setIsSuccessOnlineActivation} />
            </Modal>
          )}
        </div>
        {showSessionExpiredModal && (
          <Modal toggle={showSessionExpiredModal} onToggle={() => {}} showClose={false} className="session-expired-modal">
            <div>
              <h2>{t("modal.session_expired_title")}</h2>
              <p>{t("modal.session_expired_msg")}</p>
              <SimpleButton
                onClick={() => {
                  setShowSessionExpiredModal(false)
                  setAuthenticated(false)
                  dispatch(handleUserLogout())
                }}
              >
                {t("labels.ok")}
              </SimpleButton>
            </div>
          </Modal>
        )}
        {showSpinner && <LoadingSpinner />}
      </AppContext.Provider>
    </div>
  ) : (
    isAuthenticating && <LoadingSpinner />
  )
}

function mapStateToProps({ ticket, onlineAccess, userPermissions, warranty, queryParams, authedUser, userProducts }) {
  return {
    userPermissions,
    ticket,
    onlineAccess,
    warranty,
    queryParams,
    authedUser,
    products: userProducts?.products || {},
    productsLoaded: userProducts?.loaded,
  };
}

export default withRouter(connect(mapStateToProps)(App));
