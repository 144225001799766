// import { getProductTypes } from "../utils/productsAPI"
import { getProductTypes } from "../utils/requests/productsAPI"
export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES'

export function setProductTypes (types) {
    return {
        type: SET_PRODUCT_TYPES,
        types
    }
}

export function handleSetProductTypes () {
    return (dispatch) => {

        return getProductTypes()
        .then ( (types) => {
            dispatch(setProductTypes(types.data))
        })
    }
}
