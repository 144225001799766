import { _baseGetFetch, _basePostFetch, ApiEnum } from "./baseFetch"

const path = "/dealers"
const maxCsvResults = 1000

export const getDealerBulletins = async (queryParams) => {
  return _baseGetFetch(`${path}/bulletins`, queryParams, ApiEnum.admin)
}

export const getDealerBulletinsFilterOptions = async (queryParams) => {
  return _baseGetFetch(`${path}/bulletins/filter-options`, queryParams, ApiEnum.admin)
}

// -------------------------------------------------
//             REQUEST HELP API'S
// -------------------------------------------------

export const getProblemType = async () => {
  return _baseGetFetch(`${path}/request-help/problem-type`, undefined, ApiEnum.admin)
}

export const postRequestHelp = async (bodyParams) => {
  return _basePostFetch(`${path}/request-help`, bodyParams, ApiEnum.admin)
}

// -------------------------------------------------
//             PRICE LISTS API'S
// -------------------------------------------------

export const getPriceLists = (queryParams) => {
  return _baseGetFetch(`${path}/pricelist`, queryParams, ApiEnum.admin)
}

export const getPriceListsFilterOptions = () => {
  return _baseGetFetch(`${path}/pricelist/filter-options`, undefined, ApiEnum.admin)
}

export const getPriceListsRequests = (queryParams) => {
  return _baseGetFetch(`${path}/current/requests`, queryParams, ApiEnum.admin)
}

export const getRequestReviewOptions = () => {
  return _baseGetFetch(`${path}/requests/review-options`, undefined, ApiEnum.admin)
}

export const getRequestReviewById = (id) => {
  return _baseGetFetch(`${path}/current/requests/review/${id}`, undefined, ApiEnum.admin)
}

export const getRequestMissingItemById = (id) => {
  return _baseGetFetch(`${path}/requests/missing-item/${id}`, undefined, ApiEnum.admin)
}

export const postRequestReview = (bodyParams) => {
  return _basePostFetch(`${path}/current/requests/review`, bodyParams, ApiEnum.admin)
}

export const postRequestMissingItems = (bodyParams) => {
  return _basePostFetch(`${path}/requests/missing-item`, bodyParams, ApiEnum.admin)
}

export const downloadPricelists = (queryParams) => {
  return _baseGetFetch(`${path}/pricelist/download`, { ...queryParams, maxResults: maxCsvResults }, ApiEnum.admin)
}
