import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import LatestVersion from "./LatestVersion"
import ReleaseNotes from "./ReleaseNotes"
import VersionHistory from "./VersionHistory"
import FirmwareDetailsModal from './FirmwareDetailsModal'
import Breadcrumb from "../common/Breadcrumb"
import LoadingSpinner from "../common/LoadingSpinner"
import Search from "../common/Search"
import WrappedTabs from "../common/WrappedTabs"
import EmptyPageResults from "../common/EmptyPageResults"
import DashboardHeader from '../common/DashboardHeader/DashboardHeader'
import DataPlaceholder from '../common/DataPlaceholder'
import { loadFirmwareDownload } from "../../actions/firmware"
import { useWindowSize } from "../../libs/hooks"
import { getImageByModel } from '../../utils/functions'
import { convertToSimpleDate } from '../../utils/filters/date'
import "./firmwaredownload.scss"
import { PdfModernIcon, ICDocIcon, ICExeIcon, ICIsoIcon, ICTxtIcon, ICZipIcon } from "../../icons"
import { useLocation } from 'react-router-dom';

const FirmwareDownload = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isMobile = useWindowSize()[0] <= 768
  const [devices, setDevices] = useState([])
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [selectedTab, setSelectedTab] = useState(null)
  const [versionExpanded, setVersionExpanded] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [tabs, setTabs] = useState([])
  
  const fileIcons = {
    doc: <ICDocIcon className="doc-icon" />,
    pdf: <PdfModernIcon className="doc-icon" />,
    txt: <ICTxtIcon className="doc-icon" />,
    iso: <ICIsoIcon className="doc-icon" />,
    zip: <ICZipIcon className="doc-icon" />,
    exe: <ICExeIcon className="doc-icon" />,
    uc3: <ICExeIcon className="doc-icon" />
  };
  
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return fileIcons[extension] || <PdfModernIcon className="doc-icon" />;
  };

  useEffect(() => {
    const tabData = [
      { 
        key: 0,
        title: t("firmware.latest-version"),
        content: <LatestVersion selectedDevice={selectedDevice} getFileIcon={getFileIcon}/> 
      },
      { 
        key: 1,
        title: t("firmware.release-notes"),
        content: <ReleaseNotes selectedDevice={selectedDevice} getFileIcon={getFileIcon}/> 
      },
      { 
        key: 2,
        title: t("firmware.version-history"), 
        content: <VersionHistory selectedDevice={selectedDevice} versionExpanded={versionExpanded} toggleExpandVersion={(index) => toggleExpandVersion(index)} getFileIcon={getFileIcon}/> 
      },
    ]

    if (selectedDevice?.versions?.length > 0) {
      setTabs(tabData)
      setSelectedTab(selectedTab ? tabData[selectedTab.key] : tabData[0])
    }
    
  }, [selectedDevice, versionExpanded])

  useEffect(() => {
    props.actions.loadFirmwareDownload()
      .then(() => setIsFirstLoad(false))
  }, [props.actions])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])
  
  useEffect(() => {
    setDevices(props.firmwares)
    
    const { state } = location
    if (state?.modelInNotification) {
      setSelectedDevice(
        props.firmwares.find(e => e.model === state.modelInNotification)
      )
      window.history.replaceState(null, '')
    }
  }, [props.firmwares, location])

  const searchDevice = (value) => {
    if (value) {
      const filteredDevices = props.firmwares.filter((item) => item.model.toLowerCase().includes(value.toLowerCase()))
      setDevices(filteredDevices)
    } else {
      setDevices(props.firmwares)
    }
  }

  const selectDevice = (item) => {
    setSelectedDevice(item)
    setVersionExpanded(null)

    document.getElementById('page-content').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const handleTabClick = (tabKey) => {
    const tab = tabs.find((tab) => tab.key === tabKey)
    setSelectedTab(tab)
  }

  const toggleExpandVersion = (index) => {
    setVersionExpanded(index === versionExpanded ? null : index)
  }

  return (
    <div className="firmware-download-wrapper d-flex flex-column">
      <Breadcrumb path={window.location.pathname}/>
      <DashboardHeader headerText={t('nav.firmware')} />
      <div className="separator"/>
      {!isMobile ? 
        <div className="content-firmware-download">
          <div className="devices">
            <div className="devices-title">
              {t("firmware.devices")}
            </div>
            <div className="separator-line-device" />
            <Search placeholder={t("events.search")} onSearch={(e) => searchDevice(e)} />

            {devices.map((item, index) => 
              <div
                key={index}
                className={"card d-flex flex-align-center" + (selectedDevice === item ? " selected-card" : "")}
                onClick={() => selectDevice(item)}
              >
                <div className="image-wrapper d-flex flex-align-center">
                  <img 
                    src={getImageByModel(item.model)}
                    className="img" 
                    alt={item.model}
                  />
                </div>
                <div className="device-name-wrapper d-flex flex-column">
                  <p className="device-name">{item.model}</p>
                  <div className="versions d-flex">
                    {item.update_available ?
                      <div className="latest-version">
                        {t("firmware.new-version-available")}
                      </div>
                    :
                      <div className="current-version">
                        <div className="label">
                          <span>{t("firmware.last-update")}</span>
                          {item.versions?.length > 0 ? convertToSimpleDate(item.versions[0].created_at) : ""}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>)
            }
          </div>

          {selectedDevice ?
            <div className="device-firmware-wrapper">
              <div className="device-firmware-header">
                <div className="image-wrapper d-flex flex-align-center">
                  <img 
                    src={getImageByModel(selectedDevice.model)} 
                    className="img" 
                    alt={selectedDevice.model}
                  />
                </div>

                <div className="firmware-title-header">
                  <div className="device-name">{selectedDevice.model}</div>
                  <div className="firmware-latest-release">{`${t("firmware.latest-release")} ${selectedDevice.versions?.length > 0 ? convertToSimpleDate(selectedDevice.versions[0].created_at) : ""}`}</div>
                  {selectedDevice.update_available &&
                    <div className="firmware-version-available">{t("firmware.new-version-available")}</div>
                  }
                </div>
              </div>

              <WrappedTabs
                tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))}
                activeTab={selectedTab?.key}
                changeTab={handleTabClick}
              />
              {selectedTab?.content && selectedTab.content}
            </div>
          :
            <>
              {devices.length > 0 ? 
                <DataPlaceholder
                  titlePlaceholder={t('firmware.no-device-selected')}
                  subtitlePlaceholder={t('firmware.no-data-subtitle')}
                />
              :
                <>
                  {!isFirstLoad && 
                    <EmptyPageResults
                      title={t('firmware.no-data')}
                    />
                  }
                </>
              }
            </>
          }          
        </div>
      :
        <div className="content-firmware-download">
          <div className="devices-title">
            {t("firmware.devices")}
          </div>
          <Search placeholder={t("events.search")} onSearch={(e) => searchDevice(e)} />
          
          {devices.map((item, index) => 
            <div
              key={index}
              className={"card d-flex flex-align-center" + (selectedDevice === item ? " selected-card" : "")}
              onClick={() => selectDevice(item)}
            >
              <div className="image-wrapper d-flex flex-align-center">
                <img 
                  src={getImageByModel(item.model)}
                  className="img" 
                  alt={item.model}
                />
              </div>
              <div className="device-name-wrapper d-flex flex-column">
                <p className="device-name">{item.model}</p>
                <div className="versions d-flex">
                  {item.update_available ?
                    <div className="latest-version">
                      {t("firmware.new-version-available")}
                    </div>
                  :
                    <div className="current-version">
                      <div className="label">
                        <span>{t("firmware.last-update")}</span>
                        {item.versions?.length > 0 ? convertToSimpleDate(item.versions[0].created_at) : ""}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>)
          }
          {!isFirstLoad && devices.length === 0 && 
            <EmptyPageResults title={t("firmware.no-data")} />
          }
        </div>
      }
      {isMobile && selectedDevice &&
        <FirmwareDetailsModal
          selectedDevice={selectedDevice}
          tabs={tabs}
          selectedTab={selectedTab}
          handleTabClick={handleTabClick}
          closeModal={() => setSelectedDevice(null)}
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      }
    </div>
  )
}

function stateToProps({ firmwareDownload }) {
  return {
    firmwares: firmwareDownload?.firmwares || [],
    isLoading: firmwareDownload?.isLoading,
    errorMessage: firmwareDownload?.errorMessage,
    successMessage: firmwareDownload?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadFirmwareDownload,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(FirmwareDownload)
