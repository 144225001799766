
const config = {
  MAX_ATTACHMENT_SIZE: 2000000, //kb
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_GATEWAY,
  },
  trackingApiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_TRACKING_API_GATEWAY,
  },
  recordsApiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_RECORDS_API_GATEWAY,
  },
  telemetryApiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_TELEMETRY_API_GATEWAY,
  },
  adminApiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_ADMIN_API_GATEWAY,
  },
  apiGatewayStore: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_STORE,
  },
  maps: {
    placeURL: "https://www.google.com/maps/embed/v1/place",
    staticURL: "https://maps.googleapis.com/maps/api/staticmap",
    searchURL: "https://www.google.com/maps/search/"
  },
  publish: {
    URL: process.env.REACT_APP_PUBLISH_URL,
    emailTopic: process.env.REACT_APP_EMAIL_TOPIC,
    emailSender: process.env.REACT_APP_EMAIL_SENDER,
    xApiKey: process.env.REACT_APP_MQTT_X_API_KEY,
    environment: process.env.REACT_APP_MQTT_ENV,
  },
  dealerTechEmailTemplate: {
    name: "Rebecca",
    email: process.env.REACT_APP_DEALER_APPROVER_EMAIL,
  },
  organizationReviewEmailTemplate: {
    name: "Customer Service",
    email: process.env.REACT_APP_ORGANIZATION_APPROVER_EMAIL,
  },
  host: process.env.REACT_APP_HOST,
  distribution_email: process.env.REACT_APP_DISTRIBUTION_EMAIL,
  requestHelp_email: process.env.REACT_APP_REQUEST_HELP_EMAIL,
  stage: process.env.REACT_APP_STAGE
}

export const isDevOrUAT = ['dev', 'uat'].includes(config.stage);

export default config
