import {
  SET_APERTURES_LOADING_OFF,
  SET_APERTURES_LOADING,
  SET_APERTURES,
} from '../actions/aperture'

export default function apertures (state=null, action) {
  switch (action.type) {
    case SET_APERTURES:
      return {
        ...state,
        apertures: action.payload,
        loading: false
      }
    case SET_APERTURES_LOADING:
      return {
        ...state,
        loading: true
      }
    case SET_APERTURES_LOADING_OFF:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}