import { _baseGetFetch, _basePostFetch, _baseDeleteFetch } from "./baseFetch";

export const getReports = (queryParams) => {
  return _baseGetFetch(`/report-problem`, queryParams)
}

export const getReportsCsv = async (queryParams) => {
  return _baseGetFetch(`/report-problem/download`, queryParams)
}

export const getReportsBySn = (sn,queryParams) => {
  return _baseGetFetch(`/products/${sn}/report-problem`, queryParams)
}

export const postReport = (queryParams) => {
  return _basePostFetch(`/report-problem `, queryParams)
}

export const getUsersEmail = () => {
  return _baseGetFetch(`/report-problem/notifications/users`)
}

export const deleteReport = (id) => {
  return _baseDeleteFetch(`/report-problem/${id}`)
}
