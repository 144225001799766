import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import SimpleButton from "../../common/SimpleButton"
import { Roles } from "../../../utils/roles"
import { TooltipFillable } from "../../../icons"
import { formatStoreProductPrice } from "../../../utils/filters/filters"

const ServicePlan = ({ plan = {}, isWarning, checked, handleClick }) => {
  const { t } = useTranslation()
  const { recurrenceInterval, productOffer, amount, additionalDetails } = plan
  const [hasOffer, setHasOffer] = useState(false)
  const isDays = productOffer?.duration < 28
  
  useEffect(() => {
  if(productOffer){
    const hasNonNullOrUndefinedValue = Object.values(productOffer).some(value => value !== null && value !== undefined);
    setHasOffer(hasNonNullOrUndefinedValue)
  }
  },[productOffer])
  
  const toMonths = (duration) => {
    return Math.ceil(duration / 30);
  }
  
  return (
    <>
      {isWarning ? (
        <div className="service-warning-wrapper">
          <TooltipFillable />
          <div className="warning-wrapper">
            <div className="title">{t("coltene_store.product_details.already_subscribed")}</div>
            <div className="warning-description">{t("coltene_store.product_details.subscription_issues")}</div>
            <div className="warning-actions">
              <SimpleButton className="action-button" onClick={() => window.open("https://www.scican.com/contact/", "_blank", "noopener,noreferrer")}>
                {t("contact_us")}
              </SimpleButton>
              <Link to={Roles.userSubscriptions.route}>{t("coltene_store.product_details.view_subscriptions")}</Link>
            </div>
          </div>
        </div>
      ) : (
        <div className={"service-plan-wrapper " + (checked ? "selected" : "")}>
          <div className="service-plan-radio-row">
            <div className="servie-title-button">
              <div className="service-title">{t([`coltene_store.create_product.${recurrenceInterval}_plan`, recurrenceInterval || ""])}</div>
            </div>
            <div className="service-amount">
              {hasOffer
                ? isDays
                  ? t("coltene_store.days_offer_info", { duration: (productOffer?.duration), price: amount, plan: recurrenceInterval })
                  : t("coltene_store.offer_info", { duration: toMonths(productOffer?.duration), price: amount, plan: recurrenceInterval })
                : formatStoreProductPrice(plan.amount, plan.currencyCode)}
            </div>
            {additionalDetails && <div className="service-offer">{additionalDetails}</div>}
          </div>
        </div>
      )}
    </>
  )
}

export default ServicePlan
