import React, { useState, useLayoutEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import ActionsMenu from '../ActionsMenu'
import ExpandableText from '../ExpandableText'
import { SortingArrow, EnabledIcon, PendingIcon, DisabledIcon, MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon, InProgressIcon, ClosedIcon, NotesIcon, FlagAUIcon, FlagCAIcon, FlagCNIcon, FlagDEIcon, FlagJPIcon, FlagUSIcon } from "../../../icons"
import { getImageByModel } from '../../../utils/functions'
import "./responsive-table.scss"

const ResponsiveTable = (props) => {
  const { t } = useTranslation()
  const { headers, data, expandingColumn, activeSort, onRowClick, className, hasActionsMenu } = props
  const [ allHeaders, setAllHeaders ] = useState([])
  const [containerWidth, setContainerWidth] = useState(0)
  const [minWidths, setMinWidths] = useState({})
  const [maxWidths, setMaxWidths] = useState({})
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const containerRef = useRef(null)
  const classNameSelector = className ? `.${className} ` : ""
  const specialFields = ["status", "menu", "note", "product", "country"]
  const statuses = [
    {
      values: ["activated"],
      className: "activated",
      translation: t("filters.enabled"),
      icon: <EnabledIcon />,
    },
    {
      values: ["pending"],
      className: "pending",
      translation: t("filters.pending"),
      icon: <PendingIcon />,
    },
    {
      values: ["disabled"],
      className: "disabled",
      translation: t("filters.disabled"),
      icon: <DisabledIcon />,
    },
    {
      values: ["in_progress"],
      className: "in-progress",
      translation: t("filters.in_progress"),
      icon: <InProgressIcon />,
    },
    {
      values: ["closed"],
      className: "closed",
      translation: t("filters.closed"),
      icon: <ClosedIcon />,
    },
    
  ]

  useLayoutEffect(() => {
    function handleResize() {
      setContainerWidth(containerRef.current?.offsetWidth || 0)
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useLayoutEffect(() => {
    const tableHeaders = headers || []

    if (hasActionsMenu) {
      tableHeaders.push({
        field: "menu",
        translation: "",
        hasOrdering: false,
      })
    }

    setAllHeaders(tableHeaders)
  },[headers, hasActionsMenu])

  useLayoutEffect(() => {
    // Must be a constrain that the header's width's sum are at most equal to the container width
    const newMinWidths = {}
    const actionsMenuIconWidth = 24

    const headerElements = document.querySelectorAll(`${classNameSelector}.table-column.header .header-item-wrapper`)

    headerElements.forEach((element, index) => {
      const elementWidth = (hasActionsMenu && index === headerElements.length - 1) ? actionsMenuIconWidth : element.getBoundingClientRect().width
      newMinWidths[index] = elementWidth
    })

    setMinWidths(newMinWidths)
  },[allHeaders])

  useLayoutEffect(() => {
    const newMaxWidths = {}

    headers.forEach((header, index) => {
      let columnElements = []
      let specialWidthForFixedElements = 0

      switch (header.field) {
        case "status":
          columnElements = document.querySelectorAll(
            `${classNameSelector}.table-column:nth-child(${index + 1}) .status-wrapper`
          )
          break
        case "menu":
          columnElements = document.querySelectorAll(
            `${classNameSelector}.table-column:nth-child(${index + 1}) .actions-wrapper`
          )
          break
        case "product":
          columnElements = document.querySelectorAll(
            `${classNameSelector}.table-column:nth-child(${index + 1}) .product-wrapper .expandable-text-wrapper .expandable-text .text`
          )
          specialWidthForFixedElements = 32
          break
        case "country":
          columnElements = document.querySelectorAll(
            `${classNameSelector}.table-column:nth-child(${index + 1}) .country-wrapper`
          )
          break
        default:
          columnElements = document.querySelectorAll(
            `${classNameSelector}.table-column:nth-child(${index + 1}) .expandable-text-wrapper .expandable-text .text`
          )
          break
      }   

      let maxColumnWidth = 0
      columnElements.forEach((element) => {
        const elementWidth = element.getBoundingClientRect().width + specialWidthForFixedElements
        if (elementWidth > maxColumnWidth) {
          maxColumnWidth = elementWidth
        }
      })

      newMaxWidths[header.field] = maxColumnWidth
    })

    const totalWidth = headers
      .filter((header, index) => index !== expandingColumn)
      .reduce((accumulator, currentValue) => {
        return accumulator + newMaxWidths[currentValue.field]
      }, 0)

    const expandingColumnWidth = containerWidth - totalWidth
    newMaxWidths[headers[expandingColumn].field] = expandingColumnWidth > 0 ? expandingColumnWidth : 0

    setMaxWidths(newMaxWidths)
  }, [headers, data, containerWidth, expandingColumn])

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  const handleRowClick = (e, item) => {
    e.stopPropagation()

    onRowClick(item)
  }

  const renderCountry = (country) => {
    switch (country) {
      case "AU":
        return (
          <div className="country-wrapper">
            <FlagAUIcon />
            <p>{t("filters.AU")}</p>
          </div>
        )
      case "CA":
        return (
          <div className="country-wrapper">
            <FlagCAIcon />
            <p>{t("filters.CA")}</p>
          </div>
        )
      case "CN":
        return (
          <div className="country-wrapper">
            <FlagCNIcon />
            <p>{t("filters.CN")}</p>
          </div>
        )
      case "DE":
        return (
          <div className="country-wrapper">
            <FlagDEIcon />
            <p>{t("filters.DE")}</p>
          </div>
        )
      case "JP":
        return (
          <div className="country-wrapper">
            <FlagJPIcon />
            <p>{t("filters.JP")}</p>
          </div>
        )
      case "US":
        return (
          <div className="country-wrapper">
            <FlagUSIcon />
            <p>{t("filters.US")}</p>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className={`responsive-table-wrapper ${className}`} ref={containerRef}>
      <div className="table-row header">
        {headers.map((header, index) =>
          <div
            key={index}
            className={"table-column header"}
            style={{
              minWidth: minWidths[index],
              maxWidth: maxWidths[header.field]
            }}
          >
            <div className="header-item-wrapper">
              {header.translation}
              {header.hasOrdering && 
                (<div className="header-sort">
                  <SortingArrow
                    className={activeSort?.headerField === header.field && activeSort.order === "asc" ? "active-sort" : "inactive-sort"}
                    onClick={() => header.onOrderAscending() }
                  />
                  <SortingArrow
                    className={activeSort?.headerField === header.field && activeSort.order === "desc" ? "active-sort" : "inactive-sort"}
                    onClick={() => header.onOrderDescending()}
                  />
                </div>)
              }
            </div>
          </div>)
        }
      </div>
      {data.map((item, index) => (
        <div
          key={index} 
          className={"table-row" + (onRowClick ? " row-effects": "")}
          onClick={(e) => onRowClick && handleRowClick(e, item)}
        >
          {headers.map((header, headerIndex) => (
            <div
              key={headerIndex}
              className="table-column"
              style={{
                minWidth: minWidths[headerIndex],
                maxWidth: maxWidths[header.field] 
              }}
            >
              {header.field === "product" &&
                <div className="product-wrapper">
                  <div className="product-image">
                    <img 
                      src={getImageByModel(item[header.field])}
                      alt={item[header.field]}
                    />
                  </div>
                  <ExpandableText text={item[header.field]} />
                </div>
              }
              {header.field === "country" &&
                <>
                  {renderCountry(item[header.field])}
                </>
              }
              {header.field === "note" && item[header.field] &&
                <NotesIcon />
              }
              {header.field === "status" &&
                <div className="status-wrapper">
                  <div className="status-icon">
                    {statuses.find((status) => status.values.includes(item[header.field]))?.icon}
                  </div>
                  <div className={`status-text ${statuses.find((status) => status.values.includes(item[header.field]))?.className}`}>
                    {statuses.find((status) => status.values.includes(item[header.field]))?.translation}
                  </div>
                </div>
              }
              {header.field === "menu" && 
                <div className="actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                    />
                  }
                </div>
              }
              {!specialFields.includes(header.field) &&
                <ExpandableText text={item[header.field]} />
              }
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ResponsiveTable