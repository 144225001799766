import React, { useEffect, useState } from "react"
import getSymbolFromCurrency from "currency-symbol-map"
import "./price-input.scss"

const PriceInput = (props) => {
  const { value, currency, hasError, onChange, disabled } = props
  const [rawValue, setRawValue] = useState(value)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const defaultCurrency = "USD"
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  useEffect(() => {
    setRawValue(isFirstLoad && value ? formatter.format(value) : value)
    setIsFirstLoad(false)
  }, [value])

  return (
    <div className={"price-input-wrapper" + (hasError ? " with-price-error" : "")}>
      <input
        type="text"
        className="price-input-item"
        value={rawValue}
        onChange={(e) => {
          const inputValue = e.target.value;
          let parsedInput = inputValue.replace(/[^\d.]/g, '')
          parsedInput = parsedInput.replace(/(\..*)\./g, '$1')

          const parts = parsedInput.split('.')
          if (parts.length > 1 && parts[1].length > 2) {
            parsedInput = `${parts[0]}.${parts[1].substring(0, 2)}`
          }

          setRawValue(parsedInput)

          const parsedValue = parseFloat(parsedInput)
          onChange(!isNaN(parsedValue) ? parsedValue : "")
        }}
        onBlur={() => {
          const parsedValue = parseFloat(rawValue)
          if (!isNaN(parsedValue)) {
            setRawValue(formatter.format(parsedValue))
          }
        }}
        disabled={disabled}
      />
      <div className="price-separator"/>
      <div className="price-currency">
        {currency ? getSymbolFromCurrency(currency) : getSymbolFromCurrency(defaultCurrency)}
      </div>
    </div>
  )
}

export default PriceInput