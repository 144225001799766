import React, { useEffect, useState, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import LinkCard from "./LinkCard"
import NewProductCard from "./NewProductCard"
import RegisterProductModern from '../RegisterProductModern'
import SimpleButton from "../common/SimpleButton"
import Breadcrumb from "../common/Breadcrumb"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import { useWindowSize } from "../../libs/hooks"
import { Pages } from "../../utils/pages"
import { currentDay } from "../../utils/filters/date"
import { hasInstrumentTrackingPermission } from "../../utils/permissionValidation"

function DentistDashboard(props) {
  const isMobile = useWindowSize()[0] <= 768
  const { t } = useTranslation()
  const { linkCards } = props
  const [cardsData, setCardsData] = useState([])
  const [userPermissions, setUserPermissions] = useState([])
  const [showRegisterProduct, setShowRegisterProduct] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const history = useHistory()
  
  useEffect(() => {
    async function fetchData() {
      let groupedProducts = []
  
      if(Object.keys(props.userProducts).length > 0) {
        groupedProducts = Object.values(props.userProducts)?.reduce((acc, userProduct) => {
          const model = userProduct.model
  
          if(!acc[model]) {
            acc[model] = []
          }
  
          acc[model].push(userProduct)
  
          return acc
        }, [])
  
        groupedProducts?.sort((a,b) => { 
          return a.model?.toLowerCase()?.localeCompare(b.model?.toLowerCase())
        })
      }
  
      if (props.apertures?.length > 0) {
        const groupedAperturesByType = props.apertures.reduce((acc, aperture) => {
          if (aperture.devices?.length > 0) {
            const type = aperture.type
    
            if (!acc[type]) {
              acc[type] = []
            }
  
            acc[type].push(...aperture.devices.map((device) => { 
              return {
                model: aperture.type,
                model_id: device.model_id,
                serial_number: device.serial_number,
                unit_nickname: device.nickname,
              } 
            }) || [])
          }
  
          return acc
        }, [])
        
        if (hasInstrumentTrackingPermission(props.userPermissions)) {
          groupedProducts = { ...groupedProducts, ...groupedAperturesByType }
        }
      }
  
      setCardsData(groupedProducts)
    }

    fetchData()
  }, [props.apertures, props.userProducts, props.userPermissions])

  useEffect(() => {
    setShowSpinner(props.productsLoading)
  }, [props.productsLoading])

  useEffect(() => {
    const permissionArr = props.userPermissions ? (Array.isArray(props.userPermissions) ? props.userPermissions : Object.values(props.userPermissions)) : []
    setUserPermissions(permissionArr)
  }, [props.userPermissions])

  return (
    <div className="dentist-dashboard-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <div className={`dashboard-title d-flex ${isMobile ? "flex-column" : "flex-row"} flex-justify-between`}>
        <h1>{t("dashboard.welcome", { firstName: props.authedUser?.firstname, lastName: props.authedUser?.lastname })}</h1>
        <div>{currentDay()}</div>
      </div>
      <div className={`dashboard-content d-flex ${isMobile ? "flex-column" : "flex-row"} flex-justify-between`}>
        <div className={`links-wrapper d-flex ${isMobile ? "with-order-2 flex-column" : "flex-row"}`}>
          {linkCards.map((card, index) => 
            <Fragment key={index}>
              {userPermissions.includes(card.permission) &&
                <LinkCard cardContent={card} />
              }
            </Fragment>)
          }
        </div>
        <div className={`products-wrapper ${isMobile ? "with-order-1" : ""}`}>
          <div className="products-title">{t("dashboard.your_products")}</div>
          <div className="products-list d-flex flex-column">
            {!showSpinner &&
              Object.entries(cardsData)
                .sort()
                .map(([model, machines], index) => {
                  return <NewProductCard key={`${model}-${index}`} isICTrackProduct={["washer", "sterilizer"].includes(model)} model={model} machines={machines}/>
                })}
            {!showSpinner && Object.keys(cardsData).length === 0 &&
              <EmptyPageResults title={t("dashboard.no-products-found")} />
            }
          </div>
          <div className="products-actions d-flex flex-justify-between">
            <SimpleButton 
              onClick={() => setShowRegisterProduct(true)}
              className="cancel-button"
            >
              {t("dashboard.register_product")}
            </SimpleButton>
            <SimpleButton 
              className="submit-button" 
              onClick={() => history.push({ pathname: Pages.myProducts.route })}
            >
              {t("dashboard.view_products")}
            </SimpleButton>
          </div>
        </div>
      </div>
      {showRegisterProduct && (
        <RegisterProductModern
          closeModal={() => setShowRegisterProduct(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ authedUser, apertures, userProducts, userPermissions }) {
  return {
    authedUser,
    userProducts: userProducts?.products || {},
    productsLoading: userProducts?.loading || false,
    apertures: apertures?.apertures || [],
    userPermissions
  }
}

export default connect(mapStateToProps)(DentistDashboard)
