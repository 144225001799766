import {
  LOAD_ADMIN_STORE_PRODUCTS_PENDING,
  LOAD_ADMIN_STORE_PRODUCTS_FULFILLED,
  LOAD_ADMIN_STORE_NEW_PRODUCTS_PENDING,
  LOAD_ADMIN_STORE_NEW_PRODUCTS_FULFILLED,
  LOAD_ADMIN_STORE_COUNTRIES_PENDING,
  LOAD_ADMIN_STORE_COUNTRIES_FULFILLED,
  LOAD_ADMIN_STORE_PRICE_PLANS_PENDING,
  LOAD_ADMIN_STORE_PRICE_PLANS_FULFILLED,
  LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_PENDING,
  LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_FULFILLED,
  LOAD_ADMIN_STORE_PRODUCT_BY_ID_PENDING,
  LOAD_ADMIN_STORE_PRODUCT_BY_ID_FULFILLED,
  LOAD_ADMIN_STORE_SERVICES_PENDING,
  LOAD_ADMIN_STORE_SERVICES_FULFILLED,
  LOAD_ADMIN_STORE_PRODUCTS_REJECTED,
  LOAD_ADMIN_STORE_COUNTRIES_REJECTED,
  LOAD_ADMIN_STORE_PRICE_PLANS_REJECTED,
  LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_REJECTED,
  LOAD_ADMIN_STORE_PRODUCT_BY_ID_REJECTED,
  LOAD_ADMIN_STORE_SERVICES_REJECTED,
  LOAD_ADMIN_STORE_NEW_PRODUCTS_REJECTED,
  POST_PRODUCT_PENDING,
  PUT_PRODUCT_PENDING,
  DELETE_PRODUCT_PENDING,
  PUT_PRODUCT_STATUS_PENDING,
  CLEAR_PRODUCT_MESSAGES,
  POST_PRODUCT_REJECTED,
  PUT_PRODUCT_REJECTED,
  DELETE_PRODUCT_REJECTED,
  PUT_PRODUCT_STATUS_REJECTED,
  POST_PRODUCT_FULFILLED,
  PUT_PRODUCT_FULFILLED,
  DELETE_PRODUCT_FULFILLED,
  PUT_PRODUCT_STATUS_FULFILLED,
  PUT_PRODUCT_PRICES_PENDING,
  PUT_PRODUCT_PRICES_FULFILLED,
  PUT_PRODUCT_PRICES_REJECTED,
  CLEAR_NEW_PRODUCT
} from "../actions/adminStore"
import i18n from "i18next"

export default function adminStore(state = {}, action) {
  switch (action.type) {
    case LOAD_ADMIN_STORE_COUNTRIES_PENDING:
      return {
        ...state,
        productLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ADMIN_STORE_PRICE_PLANS_PENDING:
      return {
        ...state,
        productLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_PENDING:
      return {
        ...state,
        productLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ADMIN_STORE_SERVICES_PENDING:
      return {
        ...state,
        servicesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ADMIN_STORE_PRODUCT_BY_ID_PENDING:
      return {
        ...state,
        productLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ADMIN_STORE_PRODUCTS_PENDING:
    case LOAD_ADMIN_STORE_NEW_PRODUCTS_PENDING:
      return {
        ...state,
        productsLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case POST_PRODUCT_PENDING:
      return {
        ...state,
        errorMessage: null,
        sucessMessage: null,
      }
    case PUT_PRODUCT_PENDING:
      return {
        ...state,
        errorMessage: null,
        sucessMessage: null,
      }
    case PUT_PRODUCT_PRICES_PENDING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    case DELETE_PRODUCT_PENDING:
      return {
        ...state,
        productsLoading: true,
        errorMessage: null,
        sucessMessage: null,
      }
    case PUT_PRODUCT_STATUS_PENDING:
      return {
        ...state,
        productLoading: true,
        errorMessage: null,
        sucessMessage: null,
      }
    case LOAD_ADMIN_STORE_PRODUCTS_FULFILLED:
      const products = state?.products || []
      return {
        ...state,
        products: [...products, ...action.payload],
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE),
        productsLoading: false,
      }
    case LOAD_ADMIN_STORE_NEW_PRODUCTS_FULFILLED:
      return {
        ...state,
        products: action.payload,
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE),
        productsLoading: false,
      }
    case LOAD_ADMIN_STORE_COUNTRIES_FULFILLED:
      return {
        ...state,
        countries: action.payload,
        productLoading: false,
      }
    case LOAD_ADMIN_STORE_PRICE_PLANS_FULFILLED:
      return {
        ...state,
        pricePlans: action.payload,
        productLoading: false,
      }
    case LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_FULFILLED:
      return {
        ...state,
        productCategories: action.payload,
        productLoading: false,
      }
    case LOAD_ADMIN_STORE_PRODUCT_BY_ID_FULFILLED:
      return {
        ...state,
        productDetails: action.payload,
        productLoading: false,
      }
    case LOAD_ADMIN_STORE_SERVICES_FULFILLED:
      return {
        ...state,
        services: action.payload,
        isLoading: false,
      }
    case POST_PRODUCT_FULFILLED:
      const initialStoreProducts = state?.products || [];

      return {
        ...state,
        products: [action.payload, ...initialStoreProducts],
        newProduct: { id: action.payload.id, createdAt: new Date() },
        successMessage: i18n.t("coltene_store.create_product.product_created"),
      }
    case PUT_PRODUCT_FULFILLED:
      const updatedStoreProducts = state?.products?.map((item) => (item.id === action.payload.id ? { ...action.payload } : item)) || []

      return {
        ...state,
        products: updatedStoreProducts,
        sucessMessage: i18n.t("coltene_store.create_product.product_updated"),
      }
    case PUT_PRODUCT_PRICES_FULFILLED:
      return {
        ...state,
        products: state?.products.map((item) => (item.id === action.meta.productId ? { ...item, prices: action.payload } : item)),
        successMessage: i18n.t("coltene_store.create_product.product_updated"),
      }
    case PUT_PRODUCT_STATUS_FULFILLED:
      const productsWithUpdatedStatus = state?.products?.map((item) => item.id === action.meta.id ? { ...item, isPublished: action.meta.shouldPublish } : item) || []

      const productDetails = state?.productDetails?.id === action.meta.id ? { ...state.productDetails, isPublished: action.meta.shouldPublish } : state?.productDetails

      return {
        ...state,
        products: productsWithUpdatedStatus,
        productDetails: productDetails,
        productLoading: false,
        successMessage: i18n.t("coltene_store.create_product.product_updated"),
      }
    case DELETE_PRODUCT_FULFILLED:
      return {
        ...state,
        products: state?.products.filter((product) => product.id !== action.meta.id),
        productsLoading: false,
        successMessage: i18n.t("coltene_store.create_product.product_deleted"),
      }
    case LOAD_ADMIN_STORE_COUNTRIES_REJECTED:
      return {
        ...state,
        productLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ADMIN_STORE_PRICE_PLANS_REJECTED:
      return {
        ...state,
        productLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_REJECTED:
      return {
        ...state,
        productLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ADMIN_STORE_SERVICES_REJECTED:
      return {
        ...state,
        servicesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ADMIN_STORE_PRODUCT_BY_ID_REJECTED:
      return {
        ...state,
        productLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }

    case LOAD_ADMIN_STORE_NEW_PRODUCTS_REJECTED:
    case LOAD_ADMIN_STORE_PRODUCTS_REJECTED:
      return {
        ...state,
        productsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case POST_PRODUCT_REJECTED:
      return {
        ...state,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PUT_PRODUCT_REJECTED:
      return {
        ...state,
        productLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PUT_PRODUCT_PRICES_REJECTED:
      return {
        ...state,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case DELETE_PRODUCT_REJECTED:
      return {
        ...state,
        productsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PUT_PRODUCT_STATUS_REJECTED:
      return {
        ...state,
        productLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case CLEAR_NEW_PRODUCT: 
      return {
        ...state,
        newProduct: null,
      }
    case CLEAR_PRODUCT_MESSAGES:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    default:
      return state
  }
}
