import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PolicyModal from "./PolicyModal"
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import ConfirmationModal from "../common/ConfirmationModal"
import ClickOutside from "../common/ClickOutside"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import EmptyPageResults from "../common/EmptyPageResults"
import SimpleButton from "../common/SimpleButton"
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { DisabledIcon, EnabledIcon, MenuKebabVerticalBackgroundIcon, CaretDownIcon, MenuKebabVerticalIcon } from "../../icons"
import { loadPolicies, loadPolicyTypes, clearPoliciesMessages, updatePolicy, removePolicy } from "../../actions/policies"
import { checkPermission, policyDeletePermission, policyWritePermission } from "../../utils/permissionValidation"

const orderingFields = [
  {
    field: "name",
    order: "asc",
    key: "name_asc",
  },
  {
    field: "name",
    order: "desc",
    key: "name_desc",
  },
  {
    field: "policyType",
    order: "asc",
    key: "policyType_asc",
  },
  {
    field: "policyType",
    order: "desc",
    key: "policyType_desc",
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc",
  },
]

const Policies = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const statuses = ["Enabled", "Disabled"]
  const [policiesTableData, setPoliciesTableData] = useState([])
  const [policyTypes, setPolicyTypes] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const [selectedFilterStatuses, setSelectedFilterStatuses] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showAddEditViewPolicyModal, setShowAddEditViewPolicyModal] = useState({ isOpen: false, isEdit: false, isClone: false })
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [showDeletePolicyConfirmModal, setShowDeletePolicyConfirmModal] = useState(false)
  const [policyIdToDelete, setPolicyIdToDelete] = useState(null)
  const [expandedPolicy, setExpandedPolicy] = useState(0)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showActionsMenu, setShowActionsMenu] = useState(null)

  useEffect(() => {
    if (props.policyTypes.length === 0) {
      props.actions.loadPolicyTypes()
    }
  }, [props.actions])

  useEffect(() => {
    setPolicyTypes(props.policyTypes)
  }, [props.policyTypes])

  useEffect(() => {
    const tableData = props.policies.map((policy) => {
      return {
        identifier: policy.id,
        name: policy.name,
        type: getPolicyTypeNameById(policy.policyTypeId),
        description: policy.description,
        status: policy.isEnabled,
        actions: [
          {
            label: "labels.view-details",
            onActionClick: (e) => { e.stopPropagation(); handleOpenAddViewEditPolicy(policy.id, false) },
          },
          {
            label: "labels.clone",
            onActionClick: (e) => { e.stopPropagation(); handleOpenClonePolicy(policy.id) },
          },
          {
            label: "labels.edit",
            onActionClick: (e) => { e.stopPropagation(); handleOpenAddViewEditPolicy(policy.id, true) },
          },
          {
            label: policy.isEnabled ? "labels.disable" : "labels.enable",
            onActionClick: (e) => { e.stopPropagation(); handleChangeStatusPolicy(policy.id) },
            requiredPermission: policyWritePermission
          },
          {
            label: "labels.delete",
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(policy.id) },
            isDisabled: policy.isEnabled,
            requiredPermission: policyDeletePermission
          },
        ]
      }
    })

    setPoliciesTableData(tableData)
  }, [props.policies, policyTypes])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (props.errorMessage) {
          toast.error(props.errorMessage)

          props.actions.clearPoliciesMessages()
        }
      },
      props.errorMessage ? 300 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearPoliciesMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadPolicies(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadPolicies(true)
        }
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [filterQuery, selectedFilterStatuses, orderBy])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])
 
  const handleLoadPolicies = (withReset) => {
    let queryParams = {
      startPage: withReset ? 1 : selectedPage,
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery }
    }

    if (selectedFilterStatuses.length > 0) {
      queryParams = { ...queryParams, status: selectedFilterStatuses }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    props.actions.loadPolicies(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedFilterStatuses([])
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`policies.${orderBy.key}`) : t("events.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`policies.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const handleOpenAddViewEditPolicy = (policyId, isEditEnabled) => {
    const policy = props.policies.find((policy) => policy.id === policyId)

    setSelectedPolicy(policy)
    setShowAddEditViewPolicyModal({ isOpen: true, isEdit: isEditEnabled, isClone: false })
  }

  const handleOpenClonePolicy = (policyId) => {
    const policy = props.policies.find((policy) => policy.id === policyId)

    setSelectedPolicy(policy)
    setShowAddEditViewPolicyModal({ isOpen: true, isEdit: false, isClone: true })
  }

  const handleChangeStatusPolicy = (policyId) => {
    if (!checkPermission(props.userPermissions, policyWritePermission)) {
      return
    }
    
    const policy = props.policies.find((policy) => policy.id === policyId)
    props.actions.updatePolicy(policy.id, { ...policy, isEnabled: !policy.isEnabled })
  }

  const showConfirmationModal = (id) => {
    setPolicyIdToDelete(id)
    setShowDeletePolicyConfirmModal(true)
  }

  const handleDeletePolicy = () => {
    setShowDeletePolicyConfirmModal(false)
    setShowAddEditViewPolicyModal({ isOpen: false, isEdit: false, isClone: false })
    setSelectedPolicy(null)
    props.actions.removePolicy(policyIdToDelete)
  }

  const getPolicyTypeNameById = (id) => {
    const policyName = policyTypes?.find((policyType) => policyType.id === id)
    return policyName?.name ?? ""
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <Fragment>
      <div className="actions-wrapper">
        <SearchWithFilter
          onSearch={(e) => handleSearch(e)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterStatuses.length > 0}
          counterBadge={selectedFilterStatuses.length}
        />
        {isMobile && policiesTableData.length > 0 && (
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        )}
      </div>
      <div className="add-policy-button">
        <SimpleButton className="submit-button" onClick={() => handleOpenAddViewEditPolicy(null, false)}>
          {t("policies.add-policy")}
        </SimpleButton>
      </div>
      {!isMobile && policiesTableData.length > 0 &&
        <GenericTable
          data={policiesTableData}
          headers={[
            {
              title: t("policies.name"),
              orderKey: 'name'
            },
            {
              title: t("policies.policy-type"),
              orderKey: 'policyType'
            },
            {
              title: t("policies.description")
            },
            {
              title: t("policies.status"),
              orderKey: 'status'
            },
            {
              title: ''
            },
          ]}
          keys={[
            'name',
            'type',
            'description',
            'status',
            'action',
          ]}
          keyRenderer={{
            status: (item) => {
              return (
                <div className="status-wrapper">
                  {item.status ?
                    <div className="status-active">
                      <EnabledIcon /> 
                      <p>{t("labels.enabled")}</p>
                    </div>
                  : 
                    <div className="status-inactive">
                      <DisabledIcon /> 
                      <p>{t("labels.disabled")}</p>
                    </div>
                  }
                </div>
              )
            },
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(policy) => handleOpenAddViewEditPolicy(policy.identifier, false)}
          activeSort={orderBy}
          onSort={(orderingField) => handleSort(orderingField)}
          isLoading={showSpinner}
        />
      }
      {isMobile && policiesTableData.map((policy, index) =>
        <div 
          key={index}
          className="card"
          onClick={() => setExpandedPolicy(index)}
        >
          <div className={"card-item" + (expandedPolicy !== index ? " align-center" : "")}>
            <div className="card-item-title">{t("policies.name")}</div>
            <div className={"card-item-body" + (expandedPolicy !== index ? " align-center" : "")}>
              <div>{policy.name}</div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={policy.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedPolicy === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t("policies.policy-type")}</div>
                <div className="card-item-body">{policy.type}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("policies.description")}</div>
                <div className="card-item-body">{policy.description}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("policies.status")}</div>
                <div className="card-item-body">
                  <div className="card-item-body-status">
                    {policy.status ? 
                      <div className="status-active">
                        <EnabledIcon /> 
                        <p>{t("labels.enabled")}</p>
                      </div>
                    : 
                      <div className="status-inactive">
                        <DisabledIcon /> 
                        <p>{t("labels.disabled")}</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && policiesTableData.length === 0 && (
        <EmptyPageResults 
          title={t("policies.no-policies-found")} 
          subtitle={t("policies.no-policies-found-extra")} 
        />
      )}
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('buttons.load_more')}
          </SimpleButton>
        </div>)
      }
      {showAddEditViewPolicyModal?.isOpen && (
        <PolicyModal
          policyTypes={policyTypes}
          isEditMode={showAddEditViewPolicyModal.isEdit}
          isCloneMode={showAddEditViewPolicyModal.isClone}
          selectedPolicy={selectedPolicy}
          handleOpenEditModal={(e) => {
            handleOpenAddViewEditPolicy(selectedPolicy.id, true)
          }}
          handleChangeStatusPolicy={(policyId) => {
              if (showSpinner) {
                return
              }

              handleChangeStatusPolicy(policyId)
            }
          }
          handleDeletePolicy={(e) => {
            e.stopPropagation()
            showConfirmationModal(selectedPolicy.id)
          }}
          closeModal={() => {
            setShowAddEditViewPolicyModal({ isOpen: false, isEdit: false, isClone: false })
            setSelectedPolicy(null)
          }}
          isLoading={showSpinner}
          showSpinner={() => setShowSpinner(true)}
          hideSpinner={() => setShowSpinner(false)}
        />
      )}
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("policies.status"),
              type: FilterType.multiSelect,
              dataset: statuses,
              input: selectedFilterStatuses,
              output: (filterStatuses) => {
                setSelectedFilterStatuses(filterStatuses)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showDeletePolicyConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeletePolicyConfirmModal(false)}
          onAccept={() => handleDeletePolicy()}
          onCancel={() => setShowDeletePolicyConfirmModal(false)}
          message={t("policies.confirm-delete-policy")}
          acceptButtonText={t("labels.delete")}
          isWarning
          requiredPermission={policyDeletePermission}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </Fragment>
  )
}

function stateToProps({ policies, userPermissions }) {
  return {
    policies: policies?.policies || [],
    policyTypes: policies?.policyTypes || [],
    isLoading: policies?.policiesLoading || policies?.isLoadingPolicyTypes || policies?.isLoadingPermissions || policies?.isLoadingPolicyPermissions,
    canLoadMore: policies?.canLoadMore,
    errorMessage: policies?.errorMessage,
    successMessage: policies?.successMessage,
    userPermissions
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadPolicies,
        loadPolicyTypes,
        updatePolicy,
        removePolicy,
        clearPoliciesMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Policies)
