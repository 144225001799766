import {_baseGetFetch, _basePostFetch, _basePutFetch} from './baseFetch'

export const getVideosCategories = () => {
  return _baseGetFetch(`/videos/categories`)
}

export const getVideosList = (queryParams) => {
  return _baseGetFetch(`/videos`, queryParams)
}

export const getVideosListFavorites = ({username, ...queryParams}) => {
  return _baseGetFetch(`/users/${username}/videos/favourites`, queryParams)
}

export const putVideoFavourites = (username,video_id, isFav) => {
  return _basePutFetch(`/users/${username}/videos/favourites/${video_id}`,{
      "is_favourite": isFav
  })
}

export const postVideoFavourites = (username, favourite_id, isFav) => {
  return _basePostFetch(`/users/${username}/videos/favourites`,{
      "video_id": favourite_id,
      "is_favourite": isFav
  })
}

export const getVideosModels = () => {
  return _baseGetFetch(`/videos/models`)
}


