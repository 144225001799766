import {
  getAllCompanies,
  addCompany,
  updateCompany,
  deleteCompany,
  getCompaniesCountryOptions,
  getCompanyRoles,
} from "../utils/requests/companyAPI"

export const LOAD_COMPANIES = "LOAD_COMPANIES"
export const LOAD_COMPANIES_PENDING = "LOAD_COMPANIES_PENDING"
export const LOAD_COMPANIES_FULFILLED = "LOAD_COMPANIES_FULFILLED"
export const LOAD_COMPANIES_REJECTED = "LOAD_COMPANIES_REJECTED"

export const LOAD_NEW_COMPANIES = "LOAD_NEW_COMPANIES"
export const LOAD_NEW_COMPANIES_PENDING = "LOAD_NEW_COMPANIES_PENDING"
export const LOAD_NEW_COMPANIES_FULFILLED = "LOAD_NEW_COMPANIES_FULFILLED"
export const LOAD_NEW_COMPANIES_REJECTED = "LOAD_NEW_COMPANIES_REJECTED"

export const POST_COMPANY = "POST_COMPANY"
export const POST_COMPANY_PENDING = "POST_COMPANY_PENDING"
export const POST_COMPANY_FULFILLED = "POST_COMPANY_FULFILLED"
export const POST_COMPANY_REJECTED = "POST_COMPANY_REJECTED"

export const PUT_COMPANY = "PUT_COMPANY"
export const PUT_COMPANY_PENDING = "PUT_COMPANY_PENDING"
export const PUT_COMPANY_FULFILLED = "PUT_COMPANY_FULFILLED"
export const PUT_COMPANY_REJECTED = "PUT_COMPANY_REJECTED"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_PENDING = "DELETE_COMPANY_PENDING"
export const DELETE_COMPANY_FULFILLED = "DELETE_COMPANY_FULFILLED"
export const DELETE_COMPANY_REJECTED = "DELETE_COMPANY_REJECTED"

export const LOAD_COMPANIES_COUNTRY_OPTIONS = "LOAD_COMPANIES_COUNTRY_OPTIONS"
export const LOAD_COMPANIES_COUNTRY_OPTIONS_PENDING = "LOAD_COMPANIES_COUNTRY_OPTIONS_PENDING"
export const LOAD_COMPANIES_COUNTRY_OPTIONS_FULFILLED = "LOAD_COMPANIES_COUNTRY_OPTIONS_FULFILLED"
export const LOAD_COMPANIES_COUNTRY_OPTIONS_REJECTED = "LOAD_COMPANIES_COUNTRY_OPTIONS_REJECTED"

export const LOAD_COMPANY_ROLES = "LOAD_COMPANY_ROLES"
export const LOAD_COMPANY_ROLES_PENDING = "LOAD_COMPANY_ROLES_PENDING"
export const LOAD_COMPANY_ROLES_FULFILLED = "LOAD_COMPANY_ROLES_FULFILLED"
export const LOAD_COMPANY_ROLES_REJECTED = "LOAD_COMPANY_ROLES_REJECTED"

export const CLEAR_COMPANIES_MESSAGES = "CLEAR_COMPANIES_MESSAGES"
export const CLEAR_COMPANY_ROLES = "CLEAR_COMPANY_ROLES"

export function loadCompanies(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_COMPANIES : LOAD_COMPANIES,
    payload: getAllCompanies(queryParams)
  }
}

export function createCompany(bodyParams) {
  return {
    type: POST_COMPANY,
    payload: addCompany(bodyParams)
  }
}

export function putCompany(companyId, bodyParams) {
  return {
    type: PUT_COMPANY,
    payload: updateCompany(companyId, bodyParams)
  }
}

export function removeCompany(companyId) {
  return {
    type: DELETE_COMPANY,
    payload: deleteCompany(companyId),
    meta: { companyId }
  }
}

export function loadCompaniesCountryOptions() {
  return {
    type: LOAD_COMPANIES_COUNTRY_OPTIONS,
    payload: getCompaniesCountryOptions()
  }
}

export function loadCompanyRoles(companyId) {
  return {
    type: LOAD_COMPANY_ROLES,
    payload: getCompanyRoles(companyId)
  }
}

export function clearCompaniesMessages() {
  return {
    type: CLEAR_COMPANIES_MESSAGES
  }
}

export function clearCompanyRoles() {
  return {
    type: CLEAR_COMPANY_ROLES
  }
}

