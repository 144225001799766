import React from "react"
import DOMPurify from "dompurify"

const ProductFeatures = ({ features, description }) => {
  return (
    <div className="product-features-wrapper">
      {description?.length > 0 && (
        <div className="product-feature">
          <div className="feature-description">{description}</div>
        </div>
      )}
      {features.map((feature, index) =>
        <div key={index} className="product-feature">
          <div className="feature-title">{feature.title}</div>
          <div className="feature-description" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(feature.details)}} />
        </div>
      )}
    </div>
  )
}

export default ProductFeatures
