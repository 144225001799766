import { SET_ONLINE_ACCESS } from '../actions/onlineAccess'

export default function onlineAccess (state = null, action) {
  switch (action.type) {
    case SET_ONLINE_ACCESS:
      return {
        ...action.querystring
      }
    default:
      return state
  }
}
