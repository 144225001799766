import { SET_WARRANTY } from '../actions/warranty'

export default function warranty (state = null, action) {
  switch (action.type) {
    case SET_WARRANTY:
      return {
        ...action.querystring
      }
    default:
      return state
  }
}
