export const SET_BACK_NAVIGATION_STATE = "SET_BACK_NAVIGATION_STATE"
export const CLEAR_BACK_NAVIGATION_STATE = "CLEAR_BACK_NAVIGATION_STATE"

export function setBackNavigationState(state) {
  return {
    type: SET_BACK_NAVIGATION_STATE,
    payload: { backNavigationState: state },
  }
}

export function clearBackNavigationState() {
  return {
    type: CLEAR_BACK_NAVIGATION_STATE
  }
}

export function handleClearBackNavigationState() {
  return (dispatch) => {
    return dispatch(clearBackNavigationState())
  }
}
