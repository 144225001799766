import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PageModal from "../common/PageModal"
import SimpleButton from "../common/SimpleButton"
import { toast } from "../common/Toast"
import {
  addPolicyType,
  updatePolicyType,
} from "../../actions/policies";
import { policyTypeWritePermission } from "../../utils/permissionValidation"
import './policy-modal.scss'

const PolicyModalType = (props) => {
  const { t } = useTranslation()
  const { selectedPolicyType, closeModal, isEditMode, handleDeletePolicyType, isLoading } = props
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [initialTitle, setInitialTitle] = useState("")
  const [initialDescription, setInitialDescription] = useState("")
  const [haveError, setHaveError] = useState({})

  useEffect(() => {
    if (selectedPolicyType) {
      setTitle(selectedPolicyType.name)
      setDescription(selectedPolicyType.description)

      setInitialTitle(selectedPolicyType.name)
      setInitialDescription(selectedPolicyType.description)
    }
  },[selectedPolicyType])

  const policyHeader = () => {
    return (
      <div className="status-wrapper"></div>
    )
  }

  const policyFooter = () => {
    return (
      <>
        {(isEditMode || !selectedPolicyType) && (
          <div className="modal-buttons-footer d-flex w-100 flex-justify-between">
            {isEditMode && (
              <SimpleButton 
                className="action-delete-button" 
                onClick={handleDeletePolicyType}
                requiredPermission={policyTypeWritePermission}
              >
                {t('labels.delete')}
              </SimpleButton>
            )}

            <div className="modal-buttons-footer d-flex flex-justify-end w-100">
              <SimpleButton 
                className="cancel-button" 
                onClick={() => closeModal()}
              >
                {t('buttons.cancel')}
              </SimpleButton>
              <SimpleButton 
                className="submit-button"
                disabled={isDisabledSaveButton() || isLoading}
                onClick={() => handleAddEditPolicyType()}
                requiredPermission={policyTypeWritePermission}
              >
                {isEditMode ? t('labels.save') : t('labels.add')}
              </SimpleButton>
            </div>
          </div>
        )}
      </>
    )
  }

  const isDisabledSaveButton = () => {
    return isEditMode && 
      title === initialTitle && 
      description === initialDescription
  }

  const isValidInput = () => {
    setHaveError({})
    let isValid = true

    if (!title.trim()) {
      setHaveError({ ...haveError, title: true })
      isValid = false
    }

    return isValid;
  }

  const handleAddEditPolicyType = () => {
    if (!isValidInput()) {
      toast.error(t("error.fill_all_fields"))
      return
    }

    const bodyParams = {
      name: title.trim(),
      description: description.trim(),
    }

    if (isEditMode) {
      props.actions.updatePolicyType(selectedPolicyType.id, bodyParams)
    } else {
      props.actions.addPolicyType(bodyParams)
    }

    closeModal()
  }

  return (
    <Fragment>
      <PageModal
        toggle
        className="policies-modal"
        title={selectedPolicyType ? t('policies.edit-policy-type') : t('policies.add-policy-type-title')}
        onToggle={() => closeModal()}
        headerContent={policyHeader()}
        footerContent={policyFooter()}
      >
        <div className="policy-modal-content">
          <div className="dropdown-wrapper">
            <div className="type-wrapper">
              <p className="input-option">{t('policies.policy-name')}*</p>
              <input
                type="text"
                className={"name-input" + (haveError["title"] ? " has-error" : "")}
                value={title ?? ""}
                onChange={(e) => setTitle(e.target.value)}
                disabled={selectedPolicyType}
              />
            </div>
          </div>

          <p className="input-option">{t('policies.description')}</p>
          <textarea
            className="description-input"
            value={description ?? ""}
            onChange={(e) => setDescription(e.target.value)}>
          </textarea>
        </div>
      </PageModal>
    </Fragment>
  )
}

function stateToProps({ policies }) {
  return {
    isLoading: policies?.policiesLoading,
  };
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addPolicyType,
        updatePolicyType,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps,dispatchToProps)(PolicyModalType)
