import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { uniqBy } from "../../utils/filters/filters"
import { DownloadModernIcon, ExpandLessIcon, ExpandMoreIcon, PdfIcon, ZipIcon } from "../../icons"
import { downloadFirmware } from "../../utils/requests/firmwareAPI"
import { convertToSimpleDate } from '../../utils/filters/date'
import "./firmware-version-history.scss"
import { groupBy } from "lodash"

const VersionHistory = (props) => {
  const { t } = useTranslation()
  const { selectedDevice, versionExpanded, toggleExpandVersion, getFileIcon } = props
  const [downloadFiles, setDownloadFiles] = useState({})
  const [isRunning, setIsRunning] = useState(false)

  useEffect(() => {
    let files = []

    selectedDevice.versions.forEach((version) => {
      files.push(
        version.files.map((file) => {
          return {
            ...file,
            version_id: version.id,
          }
        })
      )
    })

    const versions = uniqBy(selectedDevice.versions, "id")

    const mappedVersionsWithFiles = versions.map((version) => {
      version.files = []

      files = [].concat(...files)
      files.forEach((file) => {
        if (file.version_id === version.id) {
          version.files.push(file)
        }
      })

      return version
    })

    setDownloadFiles({ versions: mappedVersionsWithFiles })
  }, [selectedDevice])

  const downloadFile = (file) => {
    setIsRunning(true)

    const body = {
      filename: file.filename,
      reference_id: file.reference_id,
      prefix: selectedDevice.prefix,
      prefix_number: selectedDevice.prefix_number,
      email: props.authedUser.email,
      country: props.authedUser.country,
    }

    downloadFirmware(file.id, file.filename, file.filepath, body)
      .then(() =>{
        setIsRunning(false)
      })
      .catch(() => {
        setIsRunning(false)
      })
  }

  const extension = (filename) => {
    const re = /(?:\.([^.]+))?$/
    const ext = re.exec(`${filename}`)[1]
    return ext
  }

  const isValidNote = (note) => {
    return note?.length > 0 && note[0]?.length > 0
  }
  
  const renderFilesByCategory = (version) => {
    const groupBySoftwareType = groupBy(version.files, "software_type")

    return (
      <>
        {Object.entries(groupBySoftwareType).map(([software_type, files]) => {
          return (
            <div key={software_type} className="firmware-documents">
              <div className="inner-separator" />
              <div className="section-title">{software_type}</div>
              <div className="firmware-executables-wrapper">
                {files.map((file) => {
                  return (
                    <>
                      <div className="firmware-executable">
                        <button className="download-document" onClick={() => downloadFile(file)} disabled={isRunning}>
                          <DownloadModernIcon className="download-icon" />
                        </button>
                        {getFileIcon(file.filename)}
                        <div className="file-name">
                          {file.label
                            ?.split("_")
                            .join(" ")
                            .replace(/\.[^/.]+$/, "")}
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className="firmware-version-history">
      {selectedDevice.versions.map((version, index) =>
        <div key={`version-${index}`} className="version-expand-wrapper">
        <div
          className={`version-expand-header d-flex flex-justify-between flex-align-center ${index === versionExpanded ? "highlight" : "border-bottom"}`}
          onClick={() => toggleExpandVersion(index)}
        >
          <div className="title">
            <div>{version.name}</div>
            <div>{t("firmware.uploaded") + convertToSimpleDate(version.created_at)}</div>
          </div>
          <div className="icon">{versionExpanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        </div>
        {versionExpanded === index &&
          <div className="version-expand-body">
            <div className="versions-notes-list">
              {version.release_notes?.filter((n) => isValidNote(n)).map((note, id) => <div
                key={`note-${id}`}
                className="note-item"
              >
                <div className="bullet"></div>
                {note}
              </div>)}
            </div>
            <div className="versions-download-list">
              {renderFilesByCategory(version)}
            </div>
          </div>}
      </div>)
      }
    </div>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  }
}

export default connect(mapStateToProps)(VersionHistory)
