import {SET_REPORT_TICKET} from '../actions/reportTicket'

export default function reportTicket(state = null, action) {
  switch (action.type) {
    case SET_REPORT_TICKET:
      return {
        ...state,
        ...action.ticket
      }
    default:
      return state
  }
}
