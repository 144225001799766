import React from "react"
import { useTranslation } from "react-i18next"
import { convertToSimpleDateWithTime } from '../../utils/filters/date'
import "./software-version.scss"

const SoftwareVersion = (props) => {
  const { t } = useTranslation()
  const { softwareVersion } = props
  const softwareVersionData = softwareVersion.softwareVersionData || {}

  return (
    <div className="software-version-wrapper">
      <div className="list-wrapper bg-white d-flex">
        <div className="list-info">
          <div className="section-title">{t("product.unit.first_update_date").toUpperCase()}</div>
          <div className="data">
            <span className="label">{t("product.unit.software_versions.unit_instruction_sversion")}: </span>
            <span className="label-data">{softwareVersionData.instruction || t("labels.n_a")}</span>
          </div>
          <div className="data">
            <span className="label">{t("product.unit.software_versions.unit_interface_t_sversion")}: </span>
            <span className="label-data">{softwareVersionData.interface_t || t("labels.n_a")}</span>
          </div>
          <div className="data">
            <span className="label">{t("product.unit.software_versions.unit_interface_m_sversion")}: </span>
            <span className="label-data">{softwareVersionData.interface_m || t("labels.n_a")}</span>
          </div>
          <div className="data">
            <span className="label">{t("product.unit.software_versions.unit_interface_d_sversion")}: </span>
            <span className="label-data">{softwareVersionData.interface_d || t("labels.n_a")}</span>
          </div>
        </div>
      </div>
      <div className="list-wrapper bg-white d-flex">
        <div className="list-info">
          <div className="data">
            <span className="label">{t("product.unit.software_versions.unit_screensaver_sversion")}: </span>
            <span className="label-data">{softwareVersionData.screen_saver || t("labels.n_a")}</span>
          </div>
          <div className="data">
            <span className="label">{t("product.unit.software_versions.unit_interface_w_sversion")}: </span>
            <span className="label-data">{softwareVersionData.interface_w || t("labels.n_a")}</span>
          </div>
          <div className="data">
            <span className="label">{t("product.unit.software_versions.unit_interface_s_sversion")}: </span>
            <span className="label-data">{softwareVersionData.interface_s || t("labels.n_a")}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SoftwareVersion
