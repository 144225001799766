import {
  deleteService,
  getServiceById,
  getServices,
  postService,
  putActivateService,
  putDeactivateService,
  putService,
} from "../utils/requests/services&businessRulesAPI"

export const LOAD_SERVICES = "LOAD_SERVICES"
export const LOAD_SERVICES_PENDING = "LOAD_SERVICES_PENDING"
export const LOAD_SERVICES_FULFILLED = "LOAD_SERVICES_FULFILLED"
export const LOAD_SERVICES_REJECTED = "LOAD_SERVICES_REJECTED"

export const LOAD_NEW_SERVICES = "LOAD_NEW_SERVICES"
export const LOAD_NEW_SERVICES_PENDING = "LOAD_NEW_SERVICES_PENDING"
export const LOAD_NEW_SERVICES_FULFILLED = "LOAD_NEW_SERVICES_FULFILLED"
export const LOAD_NEW_SERVICES_REJECTED = "LOAD_NEW_SERVICES_REJECTED"

export const LOAD_SERVICE_BY_ID = "LOAD_SERVICE_BY_ID"
export const LOAD_SERVICE_BY_ID_PENDING = "LOAD_SERVICE_BY_ID_PENDING"
export const LOAD_SERVICE_BY_ID_FULFILLED = "LOAD_SERVICE_BY_ID_FULFILLED"
export const LOAD_SERVICE_BY_ID_REJECTED = "LOAD_SERVICE_BY_ID_REJECTED"

export const POST_SERVICE = "POST_SERVICE"
export const POST_SERVICE_PENDING = "POST_SERVICE_PENDING"
export const POST_SERVICE_FULFILLED = "POST_SERVICE_FULFILLED"
export const POST_SERVICE_REJECTED = "POST_SERVICE_REJECTED"

export const PUT_SERVICE = "PUT_SERVICE"
export const PUT_SERVICE_PENDING = "PUT_SERVICE_PENDING"
export const PUT_SERVICE_FULFILLED = "PUT_SERVICE_FULFILLED"
export const PUT_SERVICE_REJECTED = "PUT_SERVICE_REJECTED"

export const PUT_SERVICE_STATUS = "PUT_SERVICE_STATUS"
export const PUT_SERVICE_STATUS_PENDING = "PUT_SERVICE_STATUS_PENDING"
export const PUT_SERVICE_STATUS_FULFILLED = "PUT_SERVICE_STATUS_FULFILLED"
export const PUT_SERVICE_STATUS_REJECTED = "PUT_SERVICE_STATUS_REJECTED"

export const DELETE_SERVICE = "DELETE_SERVICE"
export const DELETE_SERVICE_PENDING = "DELETE_SERVICE_PENDING"
export const DELETE_SERVICE_FULFILLED = "DELETE_SERVICE_FULFILLED"
export const DELETE_SERVICE_REJECTED = "DELETE_SERVICE_REJECTED"

export const CLEAR_SERVICES_MESSAGES = "CLEAR_SERVICES_MESSAGES"

export function loadServices(queryParams, refetch = false, withoutLimit = false) {
  return {
    type: refetch ? LOAD_NEW_SERVICES : LOAD_SERVICES,
    payload: getServices(queryParams, withoutLimit),
  }
}

export function loadServiceById(id) {
  return {
    type: LOAD_SERVICE_BY_ID,
    payload: getServiceById(id),
  }
}

export function createService(bodyParams, showToast) {
  return {
    type: POST_SERVICE,
    payload: postService(bodyParams),
    meta: { showToast: showToast },
  }
}

export function updateService(id, bodyParams, showToast) {
  return {
    type: PUT_SERVICE,
    payload: putService(id, bodyParams),
    meta: { showToast: showToast },
  }
}

export function changeServiceStatus(id, operation, showToast) {
  return {
    type: PUT_SERVICE_STATUS,
    payload: operation === "enable" ? putActivateService(id) : putDeactivateService(id),
    meta: { id: id, operation: operation, showToast: showToast },
  }
}

export function delService(id) {
  return {
    type: DELETE_SERVICE,
    payload: deleteService(id),
    meta: { id: id },
  }
}

export function clearServicesMessages() {
  return {
    type: CLEAR_SERVICES_MESSAGES,
  }
}
