import { SET_AUTH_USER, UPDATE_AUTH_USER } from "../actions/authedUser"

export default function authedUser(state = null, action) {
  switch (action.type) {
    case SET_AUTH_USER:
      return action.authedUser
    case UPDATE_AUTH_USER:
      return {
        ...state,
        ...action.authedUser,
      }
    default:
      return state
  }
}
