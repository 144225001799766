import React from 'react';
import { LineChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, Line } from "recharts";

const celsius = "\u2103"
const kPas = "kPas"

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function DTChart3(props) {

    const { data } = props

    return (
        <LineChart width={props.width} height={300} data={data}>
            <CartesianGrid/>
            <XAxis dataKey="time" style={{ fontSize: "0.8em" }}/>
            <YAxis yAxisId="left" unit={celsius} style={{ fontSize: "0.8em" }} type="number" domain={['auto', 'auto']}/>
            <YAxis yAxisId="right" type="number" domain={['auto', 'auto']} orientation="right" unit={kPas} style={{ fontSize: "0.8em" }}/>
            <Tooltip/>
            <Legend layout="vertical" verticalAlign="middle" align="right" wrapperStyle={{ padding: "0 60px 0 30px" }}/>           
            <Line yAxisId="left" type="monotone" dataKey="Chamber Temp" stroke="#3D89BC" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/> 
            <Line yAxisId="right" type="monotone" dataKey="Pressure Chamber" stroke="#000000" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
        </LineChart>
    )
};