import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const Graph = (props) => {
  const { t } = useTranslation()
  const { data } = props
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })

    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)

    }
  })

  const renderLegend = () => {
    const data = [{ value: 'unique_sn_cf' }, { value: 'total_cf' }, { value: 'total_connected' }];
    return (
      <div className="legend-wrapper">
        {
          data.map((entry, index) => (
            <div key={`item-${index}`} className={"legend-item"}>
              <div className={`legend-item-dot ${entry.value}`} />
              {t(`monitoring.${entry.value}`)}
            </div>
          ))
        }
      </div>
    )
  }

  const tickFormatter = (date) => {
    if (date) {
      const [year, month] = date.split('-');
      return `${year}-${Number(month)}`;
    }

    return ''
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data} margin={{
        top: 10,
        right: dimensions.width < 480 ? 0 : 30,
        left: dimensions.width < 480 ? -20 : 0,
        bottom: 0
      }}>
        {
          dimensions.width > 480 &&
          <Legend content={renderLegend} />
        }
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tickFormatter={tickFormatter} />
        <YAxis />
        <Tooltip />
        <Area
          dataKey="unique_sn"
          fill="none"
          stroke=" #21a2ee"
          strokeWidth="2"
          name={t("monitoring.unique_sn_cf")}
        />
        <Area
          dataKey="total_faults"
          fill="none"
          stroke="#f04438"
          strokeWidth="2"
          name={t("monitoring.total_cf")}
        />
        <Area
          dataKey="total_connected"
          fill="none"
          stroke="#12b76a"
          strokeWidth="2"
          name={t("monitoring.total_connected")}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default Graph