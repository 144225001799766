import PropTypes from 'prop-types'
import './status-legend.scss'

const StatusLegend = (props) => {
  const { statusData } = props

  return (
    <div className="status-legend d-flex">
      <ul className={`d-flex ${props.className}` }>
        {
          statusData.map((data, index) => {
            return (
            <li key={data.label.replace(/ /g, '-') + index}>
              { data.icon }
              <span>{data.label}</span>
            </li>)
          })}
      </ul>
      
    </div>
  )
}
/**
 * array containing icon, label
 */
 StatusLegend.propTypes = {
  statusData: PropTypes.array.isRequired,
}

export default StatusLegend