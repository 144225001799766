import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const RBAC = ({permission, children}) => {
  const userPermissions = useSelector(state => state.userPermissions)
  let accessAllowed = false

  const permissionArr = userPermissions ? (Array.isArray(userPermissions) ? userPermissions : Object.values(userPermissions)) : []
  
  if (Array.isArray(permission)) {
    accessAllowed = permission.some(p => permissionArr.includes(p?.toLowerCase()))
    return accessAllowed && children
  }

  accessAllowed =  permissionArr.includes(permission?.toLowerCase())
  return accessAllowed && children
}

RBAC.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element,
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
}

export default RBAC