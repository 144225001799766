import { _baseGetFetch, ApiEnum } from "./baseFetch"

const path = "/events"
const systemEvents = "/system"
const maxCsvResults = 1000

export const getSystemEvents = async (queryParams) => {
  return _baseGetFetch(`${path}${systemEvents}`, { ...queryParams, maxResults: process.env.REACT_APP_PAGINATION_SIZE}, ApiEnum.admin)
}

export const getSystemEventsTypes = async () => {
  return _baseGetFetch(`${path}${systemEvents}/types`, undefined, ApiEnum.admin);
}

export const getSystemEventsCsv = async (queryParams) => {
  return _baseGetFetch(`${path}${systemEvents}/download`, { ...queryParams, maxResults: maxCsvResults }, ApiEnum.admin)
}