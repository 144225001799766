import { useTranslation } from "react-i18next"
import { toast } from "../common/Toast"
import { pending_approval } from "../../images"
import { Person } from "../../icons"
import config from "../../utils/config"
import { sendMail } from "../../utils/requests/mqttPublishAPI"
import "./pending_approval.scss"

const PendingApproval = (props) => {
  const { t } = useTranslation()
  const organizationRegistrationTemplate = `organization_registration_get_help_en_CA`
  const dealerWhitelistTemplate = `ocp_dealer_technician_whitelist_registrations_en_CA`
  
  const sendEmail = async () => {
    const isOrganization = props?.authedUser?.companyAccountType === "organization_admin"

    const emailTemplateVars = {
      ...(!isOrganization && { name: `${props?.authedUser?.firstname}` }),
      firstname: `${props?.authedUser?.firstname}`,
      lastname: `${props?.authedUser?.lastname}`,
      phone: `${props?.authedUser?.phone}`,
      company: `${props?.authedUser?.company}`,
      country: `${props?.authedUser?.country}`,
      language: `${props?.authedUser?.language}`,
      email: `${props?.authedUser?.email}`,
    }
    const templateName = isOrganization ? organizationRegistrationTemplate : dealerWhitelistTemplate
    const email = isOrganization ? config.organizationReviewEmailTemplate.email : config.dealerTechEmailTemplate.email

    try {
      await sendMail(
        email,
        templateName,
        emailTemplateVars
      )

      toast.success(t("register.email_sent"))
    } catch (error) {
      toast.error(t("error.something_wrong"))
    }
  }

  return (
    <div className="approval-wrapper">
      <img src={pending_approval} alt="stericenter" />
      <div className="message">
        <div className="icon-text">
          <Person />
          <div>
            <div>{t("register.account_pending_approval")}</div>
            <div className="email-text" onClick={sendEmail}>{t("register.email_customer_service")}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingApproval
