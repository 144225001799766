const languages = {
  'en-CA': 'English',
  'fr-CA': 'French',
  'de-DE': 'German',
  'it-IT': 'Italian',
  'zh-CN': 'Chinese',
  'ja-JP': 'Japanese',
  'es-MX': 'Spanish',
  'pl-PL': 'Polish'
}

const iso639_2LocaleMapping =  {
  'en-CA': 'ENG',
  'fr-CA': 'FRA',
  'de-DE': 'DEU',
  'it-IT': 'ITA',
  'zh-CN': 'ZHO',
  'ja-JP': 'JPN',
  'es-MX': 'SPA',
  'pl-PL': 'POL'
}

export const getIso639_2FromLocale = (locale) => {
  return iso639_2LocaleMapping[locale]
}

export const getLocaleFromIso639_2 = (iso639_2) => {
  return Object.keys(iso639_2LocaleMapping).find(key => iso639_2LocaleMapping[key] === iso639_2)
}

export const getLanguageFromLocale = (locale) => {
  return languages[locale]
}

export default languages;