import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router"
import ProductCard from "./ProductCard"
import OrderStatusModal from "./components/OrderStatusModal"
import StoreBasket from "../ColteneStore/StoreBasket"
import Breadcrumb from "../common/Breadcrumb"
import SimpleButton from "../common/SimpleButton"
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import WrappedTabs from "../common/WrappedTabs"
import { CaretDownIcon } from "../../icons"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import { clearBasket, loadStoreDigitalProducts, loadStorePhyiscalProducts } from "../../actions/colteneStore"
import { clearBackNavigationState } from "../../actions/backNavigationState"
import { getUserSubscriptions } from "../../utils/requests/productsAPI"
import "./coltenestore.scss"

const sortingOptions = [
  {
    key:"amount_asc",
    label: "coltene_store.amount_asc",
  },
  {
    key:"amount_desc",
    label: "coltene_store.amount_desc",
  }
]

const ColteneStore = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const canGoBack = location.state && location.state.withBackNavigation;
  const { basket, isLoading } = props
  const [showStoreBasket, setShowStoreBasket] = useState(false)
  const [showSuccessOrderModal, setShowSuccessOrderModal] = useState(false)
  const [successOrderId, setSuccessOrderId] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [selectedSortingOption, setSelectedSortingOption] = useState(null)
  const [searchParams, setSearchParams] = useState(null)
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [canLoadMorePhysicalProducts, setCanLoadMorePhysicalProducts] = useState(false)
  const [canLoadMoreDigitalProducts, setCanLoadMoreDigitalProducts] = useState(false)
  const [physicalProducts, setPhysicalProducts] = useState([])
  const [digitalProducts, setDigitalProducts] = useState([])
  const [activeSubscriptions, setActiveSubscriptions] = useState([])
  const [currentPhysicalSearchPage, setCurrentPhysicalSearchPage] = useState(1)
  const [currentDigitalSearchPage, setCurrentDigitalSearchPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false)
  const [activeTab, setActiveTab] = useState(null)
  const ongoingSubscriptionStatuses = ["active", "trialing", "past_due"]
  const physicalTab = -1
  const digitalTab = 1

  const tabs = useMemo(() => ([
    {
      id: -1,
      name: t("coltene_store.products"),
    },
    {
      id: 1,
      name: t("coltene_store.subscriptions"),
    },
  ]), [])

  useLayoutEffect(() => {
    const activeIndex = props.backNavigationState?.activeIndex
    const currentTab = tabs.find((tab) => tab.id === activeIndex) || tabs[0]
    setActiveTab(currentTab)

    if (activeIndex) {
      props.actions.clearBackNavigationState()
    }
  },[tabs])

  useEffect(() => {
    setShowSpinner(isLoading)
  }, [isLoading])

  useEffect(() => {
    setPhysicalProducts(props.physicalProducts)
  }, [props.physicalProducts])

  useEffect(() => {
    setDigitalProducts(props.digitalProducts)
  }, [props.digitalProducts])

  useEffect(() => {
    getUserSubscriptions()
      .then((subscriptions) => {
        const filteredActiveSubscriptions = subscriptions?.filter((subs) => ongoingSubscriptionStatuses.includes(subs.status))?.map(s => s.product?.id)
        setActiveSubscriptions(filteredActiveSubscriptions  || [])
      })
      .finally(() => {
        setSubscriptionsLoaded(true)
      })
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const status = queryParams.get("order_status")
    const orderId = queryParams.get("order")

    if (status === "successful") {
      props.actions.clearBasket()
      history.replace({ search: "" })
      setShowSuccessOrderModal(true)
      setSuccessOrderId(orderId)
    }
  }, [location])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setCurrentPhysicalSearchPage(1)
          setCurrentDigitalSearchPage(1)
          handleLoadProducts(true)
        }

        setShouldApplyFilters(true)
      },
      searchParams ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [searchParams, selectedSortingOption])

  useEffect(() => {
    if (currentPhysicalSearchPage && currentPhysicalSearchPage > 1) {
      handleLoadProducts(false, physicalTab)
    }
  }, [currentPhysicalSearchPage])

  useEffect(() => {
    if (currentDigitalSearchPage && currentDigitalSearchPage > 1) {
      handleLoadProducts(false, digitalTab)
    }
  }, [currentDigitalSearchPage])

  useEffect(() => {
    setCanLoadMorePhysicalProducts(props.canLoadMorePhysicalProducts)
  }, [props.canLoadMorePhysicalProducts])

  useEffect(() => {
    setCanLoadMoreDigitalProducts(props.canLoadMoreDigitalProducts)
  }, [props.canLoadMoreDigitalProducts])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.id === tabKey)
    setActiveTab(tab)
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParams(value)
    } else if (value?.length === 0 && searchParams?.length > 0) {
      setShowSpinner(true)
      setSearchParams(null)
    }
  }

  const handleLoadProducts = (withReset, withCurrentTab) => {
    const queryParams = createQueryParams()

    const physicalProductsQueryParams = {
      ...queryParams,
      startPage: withReset ? 1 : currentPhysicalSearchPage
    }

    const digitalProductsQueryParams = {
      ...queryParams,
      startPage: withReset ? 1 : currentDigitalSearchPage
    }

    if (!withCurrentTab || withCurrentTab === physicalTab) {
      props.actions.loadStorePhyiscalProducts(physicalProductsQueryParams, withReset)
        .then(() => {
          if (activeTab && activeTab.id !== digitalTab) {
            setIsFirstLoad(false)
          }
      })
    }
    

    if (!withCurrentTab || withCurrentTab === digitalTab) {
      props.actions.loadStoreDigitalProducts(digitalProductsQueryParams, withReset)
        .then(() => {
          if (activeTab && activeTab.id === digitalTab) {
            setIsFirstLoad(false)
          }
      })
    }
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParams) {
      queryParams = { ...queryParams, title: searchParams }
    }

    if (selectedSortingOption) {
      queryParams = { ...queryParams, orderBy: selectedSortingOption.key.split("_")[0], order: selectedSortingOption.key.split("_")[1] }
    }

    return queryParams
  }

  const handleLoadMorePhysicalProducts = () => {
    setCurrentPhysicalSearchPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleLoadMoreDigitalProducts = () => {
    setCurrentDigitalSearchPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const onSelectOptionInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectSortingOption = (option, toggleVisibility) => {
    setSelectedSortingOption(option)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectSortInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className="select-input">
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectOptionInput(isComponentVisible, toggleVisibility)}>
        <div className="text-wrapper d-flex flex-align-center h-100">{selectedSortingOption ? t(`${selectedSortingOption.label}`) : ""}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectSortOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {sortingOptions.map((sortingOption, index) => (
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (sortingOption === selectedSortingOption ? " selected-option" : "")}
          onClick={() => selectSortingOption(sortingOption, toggleVisibility)}
        >
          {t(`${sortingOption.label}`)}
        </div>
      ))}
    </div>
  )

  return (
    <div className="coltene-store-wrapper">
      <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack}/>
      <DashboardHeader headerText={t("nav.coltene-store")} />
      <WrappedTabs tabs={tabs} activeTab={activeTab?.id} changeTab={handleChangeTab} />
      <div className="coltene-store-header">
        <SearchWithFilter
          onSearch={handleSearch}
        />
        <div className="dropdown-area">
          <div className="sorting-label">
          	{t("coltene_store.sorting_options.sort_by")}
          </div>
          <ClickOutside
          	itemRef="click-outside-wrapper"
          	eventItem={selectSortInput}
          	toDisplayItem={selectSortOptions}
          />
        </div>
      </div>
      <div className="product-container">
        {activeTab && (activeTab.id === digitalTab ? digitalProducts : physicalProducts).flatMap((product) => {
          return product.prices.map(price => (
            <ProductCard
              key={price.id}
              product={product}
              price={price}
              numInBasket={basket[product.id]}
              setShowStoreBasket={() => setShowStoreBasket(true)}
              activeSubscriptions={subscriptionsLoaded && activeSubscriptions}
            />
          ))
        })}
      </div>
      {!showSpinner && !isFirstLoad && activeTab && ((activeTab.id === physicalTab && physicalProducts.length === 0) || (activeTab.id === digitalTab && digitalProducts.length == 0)) && 
        <EmptyPageResults 
          title={t("coltene_store.cart.no_products_found")} 
        />
      }
      {activeTab && ((canLoadMorePhysicalProducts && activeTab.id === physicalTab) || (canLoadMoreDigitalProducts && activeTab.id === digitalTab)) && (
        <div className="load-more-wrapper d-flex flex-justify-center">
          <SimpleButton className="load-more-button" onClick={() => activeTab.id === digitalTab ? handleLoadMoreDigitalProducts() : handleLoadMorePhysicalProducts()}>
            {t("buttons.load_more")}
          </SimpleButton>
        </div>
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showSuccessOrderModal && 
        <OrderStatusModal
          onClose={() => setShowSuccessOrderModal(false)}
          orderId={successOrderId}
        />
      }
      <StoreBasket 
        toggle={showStoreBasket} 
        onToggle={() => setShowStoreBasket(false)} 
      />
    </div>
  )
}

function stateToProps({ colteneStore, backNavigationState }) {
  return {
    physicalProducts: colteneStore?.physicalProducts || [],
    digitalProducts: colteneStore?.digitalProducts || [],
    isLoading: colteneStore?.isLoading,
    basket: colteneStore?.basket || {},
    canLoadMorePhysicalProducts: colteneStore?.canLoadMorePhysicalProducts,
    canLoadMoreDigitalProducts: colteneStore?.canLoadMoreDigitalProducts,
    backNavigationState
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadStorePhyiscalProducts,
        loadStoreDigitalProducts,
        clearBasket,
        clearBackNavigationState,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(ColteneStore)
