import React from "react"
import { useTranslation } from "react-i18next"
import ImageContainer from "./ImageContainer"

const FormProductImages = (props) => {
  const { t } = useTranslation()
  const { productImagesFormData, productImagesErrors, updateProductImages } = props

  const handleUploadImage = (newImage) => {
    const imagesWithNew = [...productImagesFormData, newImage]
    updateProductImages(imagesWithNew)
  }

  const handleRemoveImage = (index) => {
    const imagesWithoutRemoved = productImagesFormData.filter((image, idx) => idx !== index)
    updateProductImages(imagesWithoutRemoved)
  }

  return (
    <div className="product-images-wrapper">
      <div className="section-subtitle">{t("coltene_store.create_product.images")}</div>
      <div className="images-instruction-box-text">
        {t("coltene_store.create_product.images-instructions")}
      </div>
      <div className="images-wrapper">
        <ImageContainer
          index={0}
          fileSrc={productImagesFormData[0]?.imageSrc}
          canUpload={productImagesFormData.length === 0}
          hasError={productImagesErrors?.includes(0)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
        <ImageContainer
          index={1}
          fileSrc={productImagesFormData[1]?.imageSrc}
          canUpload={productImagesFormData.length === 1}
          hasError={productImagesErrors?.includes(1)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
        <ImageContainer
          index={2}
          fileSrc={productImagesFormData[2]?.imageSrc}
          canUpload={productImagesFormData.length === 2}
          hasError={productImagesErrors?.includes(2)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
        <ImageContainer
          index={3}
          fileSrc={productImagesFormData[3]?.imageSrc}
          canUpload={productImagesFormData.length === 3}
          hasError={productImagesErrors?.includes(3)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
        <ImageContainer
          index={4}
          fileSrc={productImagesFormData[4]?.imageSrc}
          canUpload={productImagesFormData.length === 4}
          hasError={productImagesErrors?.includes(4)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
        <ImageContainer
          index={5}
          fileSrc={productImagesFormData[5]?.imageSrc}
          canUpload={productImagesFormData.length === 5}
          hasError={productImagesErrors?.includes(5)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
        <ImageContainer
          index={6}
          fileSrc={productImagesFormData[6]?.imageSrc}
          canUpload={productImagesFormData.length === 6}
          hasError={productImagesErrors?.includes(6)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
        <ImageContainer
          index={7}
          fileSrc={productImagesFormData[7]?.imageSrc}
          canUpload={productImagesFormData.length === 7}
          hasError={productImagesErrors?.includes(7)}
          handleUploadImage={handleUploadImage}
          handleRemoveImage={handleRemoveImage}
        />
      </div>
    </div>
  )
}

export default FormProductImages
