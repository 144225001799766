import React, { useState, useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import PageModal from "../common/PageModal"
import SimpleButton from '../common/SimpleButton'
import { convertToSimpleDateWithFullTime } from "../../utils/filters/date"
import { getImageByModel } from "../../utils/functions"
import "./cycle-modal.scss"

const CycleModal = (props) => {
  const { t } = useTranslation()
  const { selectedEvent, selectedModel, isLoading, handleOpenCycleGraph, closeModal } = props
  const [cycleStatus, setCycleStatus] = useState('')
 
  useLayoutEffect(() => {
    if (selectedEvent) {
      let status = "in_progress"

      if (selectedEvent.status?.toLowerCase().includes('complete')) {
        status = "completed"
      }

      if (selectedEvent.status?.toLowerCase() === 'cycle_power_interrupt') {
        status = "cycle_interrupted"
      }

      if (selectedEvent.status?.toLowerCase().includes('fault') || selectedEvent.status?.toLowerCase() === 'cycle_aborted' || selectedEvent.status?.toLowerCase() === 'door_open') {
        status = "error"
      }

      setCycleStatus(status)
    }
  }, [selectedEvent])

  const cycleDetailsFooterContent = () => {
    return (
      <SimpleButton 
        className="submit-button" 
        onClick={() => handleViewCycleGraph()}
        disabled={isDisabledCycleGraph() || isLoading}
      >
        {t('tracking.cycle_details')}
      </SimpleButton>
    )
  }

  const isDisabledCycleGraph = () => {
    const missingCycleIdentificationData = !(selectedEvent.deviceSerialNumber ||
      selectedEvent.deviceNickname) ||
      !selectedEvent.cycleNumber || 
      (!selectedEvent.status?.toLowerCase()?.includes('complete') && 
      !selectedEvent.status?.toLowerCase()?.includes('fault') && 
      selectedEvent.status?.toLowerCase() !== 'cycle_power_interrupt' && 
      selectedEvent.status?.toLowerCase() !== 'cycle_aborted' && 
      selectedEvent.status?.toLowerCase() !== 'door_open')

    let userHasAccessToDevice = false

    const userProduct = props.products && Object.entries(props.products)?.find((item) => item[1].serial_number === selectedEvent.deviceSerialNumber && item[1].association_active)

    if (userProduct) {
      userHasAccessToDevice = true
    }

    return missingCycleIdentificationData || !userHasAccessToDevice
  }
  
  const getDurationCycle = (startDate, endDate) => {
    const diff =(endDate.getTime() - startDate.getTime()) / 1000;
    const diffh = diff / (60 * 60);
    const diffm = (diff - (Math.abs(Math.trunc(diffh)) * (60 * 60))) / 60
    return Math.abs(Math.trunc(diffh)) + "h" + Math.trunc(diffm) + "m"
  }

  const handleViewCycleGraph = () => {
    handleOpenCycleGraph()
  }

  const handleOnClose = () => {
    if (closeModal) {
      closeModal()
    }
  }
  
  return (
    <PageModal
      className="cycle-modal-wrapper"
      toggle
      onToggle={() => handleOnClose()}
      title={selectedEvent.deviceType === "sterilizer" ? t("tracking.sterilize") : t("tracking.wash")}
      footerContent={cycleDetailsFooterContent()}
    >
      <div className="cycle-modal-content">
        <div className={`status-wrapper ${cycleStatus}` }>
          {t([`tracking.${cycleStatus}`, cycleStatus])}
        </div>
        <div className="device-wrapper">
          <div className="image-wrapper d-flex flex-align-center">
            <img
              src={getImageByModel(selectedModel)}
              className="img"
              alt={selectedModel}
            />
          </div>
          <div className="device-info-wrapper">
            <div className="device-model">
            {selectedEvent.model?.toLowerCase()?.includes('aperture') ? t(`products.ic_track`) : selectedEvent.model }
            </div>
            <div className="device-serial-number">
              {selectedEvent.deviceSerialNumber ? `${t("sn")}:${selectedEvent.deviceSerialNumber}` : selectedEvent.deviceNickname}
            </div>
            <div className="cycle-instrument">
            {Object.keys(selectedEvent?.test_cycle || {}).length === 0 ? 
              <>
                <span>
                  {selectedEvent.pouches?.length || 0}
                  {" "}
                  {t("tracking.pouches")}
                </span>
                <span> • </span>
                <span>
                  {(selectedEvent.tags?.length || 0) + (selectedEvent.manualTags?.length || 0)}
                  {" "}
                  {t("tracking.cassettes")}
                </span>
                <span> • </span>
                <span> 
                  {selectedEvent.looseInstruments?.length || 0}
                  {" "}
                  {t("tracking.loose_instruments")}
                </span>
              </> : t("reports.test_cycle")
            }
            </div>
            <div className="cycle-info">
              <div className="cycle-data-wrapper">
                <div className="data-title">
                  {t("tracking.start_time")}
                </div>
                <div className="data-value">
                  {convertToSimpleDateWithFullTime(new Date(selectedEvent.cycleStartTime))}
                </div>
              </div>
              <div className="cycle-data-wrapper">
                <div className="data-title">
                  {t("tracking.finished_time")}
                </div>
                <div className="data-value">
                  {selectedEvent.cycleEndTime ? convertToSimpleDateWithFullTime(new Date(selectedEvent.cycleEndTime)) : 'N/A'}
                </div>
              </div>
            </div>
            <div className="cycle-info">
              <div className="cycle-data-wrapper">
                <div className="data-title">
                  {t("tracking.duration")}
                </div>
                <div className="data-value">
                  {selectedEvent.cycleEndTime ? <div>~{getDurationCycle(new Date(selectedEvent.cycleStartTime), new Date(selectedEvent.cycleEndTime))}</div> : 'N/A'}
                </div>
              </div>
              <div className="cycle-data-wrapper">
                <div className="data-title">
                  {t("tracking.cycle_number")}
                </div>
                <div className="data-value">
                  {selectedEvent.cycleNumber || 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageModal>
  )
}

function stateToProps({ userProducts }) {
  return {
    products: userProducts?.products || {},
  }
}

export default connect(stateToProps)(CycleModal)
