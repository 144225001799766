import {
  SET_BACK_NAVIGATION_STATE,
  CLEAR_BACK_NAVIGATION_STATE,
} from "../actions/backNavigationState"

export default function backNavigationState(state = {}, action) {
  switch (action.type) {
    case SET_BACK_NAVIGATION_STATE:
      return action.payload.backNavigationState
    case CLEAR_BACK_NAVIGATION_STATE:
      return null
    default:
      return state
  }
}
