import React, { Fragment, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PageModal from "../common/PageModal"
import ClickOutside from "../common/ClickOutside"
import ToggleSwitch from "../common/ToggleSwitch"
import SimpleButton from "../common/SimpleButton"
import WrappedTabs from '../common/WrappedTabs'
import { toast } from "../common/Toast"
import { CloseSimpleIcon, CaretDownIcon, SuccessTick } from "../../icons"
import {
  loadPermissions,
  loadPolicyPermissions,
  addPolicy,
  updatePolicy,
  updatePolicyPermissions,
  POST_POLICY_FULFILLED,
  PUT_POLICY_FULFILLED,
} from "../../actions/policies"
import { policyDeletePermission, policyWritePermission } from "../../utils/permissionValidation"
import "./policy-modal.scss"

const PolicyModal = (props) => {
  const { t } = useTranslation()
  const {
    selectedPolicy,
    policies,
    policyTypes,
    closeModal,
    isEditMode,
    isCloneMode,
    isLoading,
    hideSpinner,
    handleOpenEditModal,
    handleDeletePolicy,
    handleChangeStatusPolicy,
  } = props
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [permissions, setPermissions] = useState([])
  const [policyPermissions, setPolicyPermissions] = useState([])
  const [permissionsGroups, setPermissionsGroups] = useState([])
  const [selectedType, setSelectedType] = useState(null)
  const [isEnabled, setIsEnabled] = useState(false)
  const [initialTitle, setInitialTitle] = useState("")
  const [initialDescription, setInitialDescription] = useState("")
  const [initialPolicyPermissionsIds, setInitialPolicyPermissionsIds] = useState([])
  const [initialSelectedType, setInitialSelectedType] = useState(null)
  const [initialIsEnabled, setInitialIsEnabled] = useState(false)
  const [activeTab, setActiveTab] = useState("")
  const [haveError, setHaveError] = useState({})
  const sitePermissions = "site"
  const nonePermissionId = -1
  const nonePermission = { id: nonePermissionId, name: t("policies.none"), isEnabled: true, type: sitePermissions }

  useEffect(() => {
    if (props.permissions.length ===0) {
      props.actions.loadPermissions()
    }    

    if (selectedPolicy) {
      props.actions.loadPolicyPermissions(selectedPolicy.id)
    }
  }, [props.actions])

  useEffect(() => {
    setPermissions(props.permissions)
  }, [props.permissions])

  useEffect(() => {
    if (selectedPolicy) {
      const initialPolicyPermissions = props.policyPermissions

      const policyPermissionsIds = props.policyPermissions.map((permission) => permission.id)

      const hasSiteAccess = props.policyPermissions.find((permission) => permission.type === sitePermissions)

      if (!hasSiteAccess) {
        initialPolicyPermissions.push(nonePermission)
        policyPermissionsIds.push(nonePermissionId)
      }

      setPolicyPermissions(initialPolicyPermissions)
      setInitialPolicyPermissionsIds(policyPermissionsIds)
    }
  }, [props.policyPermissions])

  useEffect(() => {
    const policyPermissionsSortedAfterGroup = permissions?.sort((a, b) => a.type.localeCompare(b.type))
    const policyPermissionsGroup = policyPermissionsSortedAfterGroup?.reduce((groups, item) => {
      const group = groups[item.type] || []
      group.push(item)
      groups[item.type] = group
      return groups
    }, {})

    if (isEditMode) {
      policyPermissionsGroup[sitePermissions]?.unshift(nonePermission)
    }    

    const sortedKeys = Object.keys(policyPermissionsGroup).sort((a, b) => {
      if (a.includes("API") && !b.includes("API")) {
        return 1
      } else if (!a.includes("API") && b.includes("API")) {
        return -1
      } else {
        return a.localeCompare(b)
      }
    })

    const sortedPolicyPermissionsGroup = sortedKeys.reduce((sortedObj, key) => {
      sortedObj[key] = policyPermissionsGroup[key]?.sort((a,b) => { return a.name?.localeCompare(b.name) }) || []
      return sortedObj
    }, {})

    setPermissionsGroups(sortedPolicyPermissionsGroup)

    if (!activeTab) {
      setActiveTab(Object.keys(sortedPolicyPermissionsGroup)[0])
    }

    const policy = policies.find((policy) => policy.id === selectedPolicy?.id)
    if (policy) {
      setTitle(isCloneMode ? `CLONE - ${policy.name}`: policy.name)
      setDescription(policy.description)
      setIsEnabled(policy.isEnabled)
      setSelectedType(policyTypes?.find((policyType) => policyType.id === policy.policyTypeId))

      setInitialTitle(policy.name)
      setInitialDescription(policy.description)
      setInitialIsEnabled(policy.isEnabled)
      setInitialSelectedType(policyTypes?.find((policyType) => policyType.id === policy.policyTypeId))
    }
  }, [policies, selectedPolicy, policyTypes, permissions, isEditMode])

  const handleChangeTab = (item) => {
    setActiveTab(item)
  }

  const policyHeader = () => {
    return (
      <div className="status-wrapper">
        <p className="input-option">{t("policies.status")}</p>
        <div 
          className="status-option"
          onClick={() => {
            isEditMode || isCloneMode || !selectedPolicy ? 
              setIsEnabled((prevValue) => !prevValue) : 
              handleChangeStatusPolicy(selectedPolicy.id)
          }}
        >
          <ToggleSwitch checked={isEnabled} />
          <p className="text-status-option">{isEnabled ? t("labels.enabled") : t("labels.disabled")}</p>
        </div>
      </div>
    )
  }

  const policyFooter = () => {
    return (
      <>
        {!isEditMode && !isCloneMode && selectedPolicy && (
          <div className="modal-buttons-footer d-flex w-100 flex-justify-between">
            <SimpleButton 
              className="action-delete-button" 
              disabled={isEnabled} 
              onClick={handleDeletePolicy}
              requiredPermission={policyDeletePermission}
            >
              {t("labels.delete")}
            </SimpleButton>
            <SimpleButton className="action-button" onClick={() => handleOpenEditModal()}>
              {t("labels.edit")}
            </SimpleButton>
          </div>
        )}

        {(isEditMode || isCloneMode || !selectedPolicy) && (
          <div className="modal-buttons-footer d-flex w-100 flex-justify-end">
            <SimpleButton 
              className="cancel-button" 
              onClick={() => handleCloseModal()}
            >
              {t("buttons.cancel")}
            </SimpleButton>
            <SimpleButton 
              className="submit-button"
              disabled={isDisabledSaveButton() || isLoading}
              onClick={() => handleAddEditPolicy()}
              requiredPermission={policyWritePermission}
            >
              {t("labels.save")}
            </SimpleButton>
          </div>
        )}
      </>
    )
  }
  
  const buildModalTitle = () => {
    const title = t("policies.add-policy-title")

    if (!isEditMode && !isCloneMode && selectedPolicy) {
      return t("policies.policy-details-title")
    }

    if (isEditMode) {
      return t("policies.edit-policy-title")
    }

    if (isCloneMode) {
      return t("policies.clone-policy-title")
    }

    return title           
  }

  const isDisabledSaveButton = () => {
    const selectedPolicyPermissionsIds = policyPermissions.map((policyPermission) => policyPermission.id)

    let hasDifferentPermissions = false

    if (selectedPolicyPermissionsIds.length !== initialPolicyPermissionsIds.length) {
      hasDifferentPermissions = true
    } else {
      selectedPolicyPermissionsIds.every((policyPermissionId) => {
        if (!initialPolicyPermissionsIds.includes(policyPermissionId)) {
          hasDifferentPermissions = true
          return false
        }

        return true
      })
    }

    return isEditMode && 
      title === initialTitle && 
      description === initialDescription && 
      isEnabled === initialIsEnabled && 
      selectedType === initialSelectedType &&
      !hasDifferentPermissions
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectTypeInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{selectedType ? selectedType.name : t("policies.select-policy-type")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectType = (type, toggleVisibility) => {
    setSelectedType(type)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectTypeOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {policyTypes?.map((type, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (type.id === selectedType?.id ? " selected-option" : "")}
          key={index}
          onClick={() => selectType(type, toggleVisibility)}
        >
          {type.name}
        </div>
      ))}
    </div>
  )

  const handleSelectPermission = (permission, isRadioSelect) => {
    const permissionSelected = policyPermissions.find((item) => item.id === permission.id)

    if (!isRadioSelect) {
      setPolicyPermissions((prev) => permissionSelected ? prev.filter((item) => item.id !== permission.id) : [...prev, permission])
      return
    }

    if (permissionSelected) {
      return
    }

    const permissionsWithoutPreviousRadioSelection = policyPermissions.filter((item) => item.type !== sitePermissions) 
    
    setPolicyPermissions([...permissionsWithoutPreviousRadioSelection, permission])
  }

  const isValidInput = () => {
    let errors = {}
    let isValid = true

    if (!title.trim()) {
      errors = { ...errors, title: true }
      isValid = false
    }

    if (!selectedType) {
      errors = { ...errors, selectedType: true }
      isValid = false
    }

    setHaveError(errors)
    return isValid
  }

  const handleAddEditPolicy = () => {
    if (!isValidInput()) {
      toast.error(t("error.fill_all_fields"))
      return
    }

    const bodyParams = {
      name: title.trim(),
      description: description.trim(),
      policyTypeId: selectedType.id,
      isEnabled: isEnabled,
    }

    const policyPermissionsIds = []
    policyPermissions.map((it) => policyPermissionsIds.push(it.id))

    const policyPermissionsIdsWithoutNonePermission = policyPermissionsIds.filter((id) => id !== nonePermissionId)

    if (isEditMode) {
      props.actions.updatePolicy(selectedPolicy.id, bodyParams).then((result) => {
        if (result?.action?.type === PUT_POLICY_FULFILLED) {
          props.actions.updatePolicyPermissions(selectedPolicy.id, policyPermissionsIdsWithoutNonePermission)
        }
      })
    } else {
      props.actions.addPolicy(bodyParams).then((result) => {
        if (result?.action?.type === POST_POLICY_FULFILLED) {
          props.actions.updatePolicyPermissions(result.value?.id, policyPermissionsIdsWithoutNonePermission)
        }
      })
    }
    handleCloseModal()
  }

  const handleCloseModal = () => {
    hideSpinner()

    if (closeModal) {
      closeModal()
    }
  }

  return (
    <Fragment>
      <PageModal
        toggle
        className="policies-modal"
        title={buildModalTitle()}
        onToggle={() => handleCloseModal()}
        headerContent={policyHeader()}
        footerContent={policyFooter()}
      >
        <div className="policy-modal-content">
          <div className="dropdown-wrapper">
            <div className="type-wrapper">
              <p className="input-option">{t("policies.policy-name")}*</p>
              {isEditMode || isCloneMode || !selectedPolicy ? (
                <input 
                  type="text" 
                  className={"name-input" + (haveError["title"] ? " has-error" : "")}
                  value={title ?? ""} 
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={isEditMode}
                />
              ) : (
                <div className="text-item-view">{title ?? ""}</div>
              )}
            </div>
            <div className="policy-wrapper policy-type">
              <p className="input-option">{t("policies.type")}*</p>
              {isEditMode || isCloneMode || !selectedPolicy ? (
                <ClickOutside 
                  itemRef="click-outside-wrapper"
                  className={haveError["selectedType"] ? "has-error" : ""}
                  eventItem={selectTypeInput} 
                  toDisplayItem={selectTypeOptions} 
                />
              ) : (
                <div className="text-item-view">{selectedType?.name ?? ""}</div>
              )}
            </div>
          </div>

          <p className="input-option">{t("policies.description")}</p>
          {isEditMode || isCloneMode || !selectedPolicy ? (
            <textarea className="description-input" value={description ?? ""} onChange={(e) => setDescription(e.target.value)}></textarea>
          ) : (
            <div className="text-item-view description-text">{description ?? ""}</div>
          )}

          <p className="input-option">{t("policies.applied")}</p>

          {!props.isLoading ? (
            <>
              {permissionsGroups && Object.keys(permissionsGroups).length > 0 &&
                <WrappedTabs
                  tabs={Object.keys(permissionsGroups).map((key) => ({ id: key, name: t('policies.tab-' + key) }))}
                  activeTab={activeTab} 
                  changeTab={handleChangeTab} 
                />
              }

              {activeTab ? (
                <div>
                  <p className="input-option">{t("policies.policy-permission-" + activeTab)}</p>
                  <div className="permissions-wrapper">
                    <div className="columns-wrapper">
                      {permissionsGroups[activeTab].map((permission, index) => (
                        <div
                          className={`permission-wrapper ${!isEditMode ? "view-mode" : ""} ${
                            policyPermissions && policyPermissions?.find((item) => item.id === permission.id) && "selected-permission"
                          }`}
                          key={index}
                        >
                          <div className="checkbox-wrapper">
                            <React.Fragment>
                              {isEditMode || isCloneMode || !selectedPolicy ?
                                <>
                                  {activeTab === sitePermissions ?
                                    <input
                                      id={'permission-' + permission.id}
                                      type="radio"
                                      name={permission.id}
                                      onChange={() => handleSelectPermission(permission, true)}
                                      checked={policyPermissions && policyPermissions?.find((item) => item.id === permission.id) || false}
                                    />
                                  :
                                    <input
                                      id={'permission-' + permission.id}
                                      type="checkbox"
                                      name={permission.id}
                                      onChange={() => handleSelectPermission(permission)}
                                      checked={policyPermissions && policyPermissions?.find((item) => item.id === permission.id) || false}
                                    />
                                  }
                                </>
                              : 
                                <>
                                  {policyPermissions && policyPermissions?.find((item) => item.id === permission.id)
                                    ? <SuccessTick />
                                    : <CloseSimpleIcon />
                                  }
                                </>
                              }
                              <div className="checkmark" />
                              <label htmlFor={'permission-' + permission.id}>{permission.name}</label>
                            </React.Fragment>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </PageModal>
    </Fragment>
  )
}

function stateToProps({ policies }) {
  return {
    policies: policies?.policies || [],
    policyPermissions: policies?.policyPermissions || [],
    permissions: policies?.permissions || [],
    isLoading: policies?.isLoadingPermissions || policies?.isLoadingPolicyPermissions,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadPermissions,
        loadPolicyPermissions,
        addPolicy,
        updatePolicy,
        updatePolicyPermissions,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(PolicyModal)
