import React, { useEffect } from "react"
import Modal from '../Modal'
import { disableScrolling, enableScrolling } from '../../../utils/functions'
import "./video-modal.scss"

const VideoModal = (props) => {
  const { title, videoId, onCloseVideo } = props

  useEffect(() => {
    disableScrolling()
  }, [])

  const getVideoEmbedUrl = () => {
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`
  }

  const handleCloseVideoModal = () => {
    if (onCloseVideo) {
      enableScrolling()
      onCloseVideo()
    }
  }

  return (
    <Modal
      onToggle={handleCloseVideoModal}
      toggle={true}
      className="video-modal"
    >
      <div className="iframe-wrapper">
        <iframe
          title={title}
          src={getVideoEmbedUrl()}
          className="iframe"
          allow="autoplay; fullscreen;"
        ></iframe>
      </div>
    </Modal>
  )
}

export default VideoModal
