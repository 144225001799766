import React, { useState } from 'react'
import './tooltiped-icon.scss'

const TooltipedIcon = (props) => {
  const { icon, tooltipText, orientation } = props
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <div className="tooltiped-icon-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={`tooltip ${showTooltip ? 'visible' : ''} ${orientation || ""}`}>
        <span className="tooltiptext">{tooltipText}</span>
      </div>
      {icon}
    </div>
  )
}

export default TooltipedIcon
