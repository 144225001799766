import {
  LOAD_SYSTEM_EVENTS_PENDING,
  LOAD_SYSTEM_EVENTS_FULFILLED,
  LOAD_SYSTEM_EVENTS_REJECTED,
  LOAD_NEW_SYSTEM_EVENTS_PENDING,
  LOAD_NEW_SYSTEM_EVENTS_FULFILLED,
  LOAD_NEW_SYSTEM_EVENTS_REJECTED,
  LOAD_SYSTEM_EVENTS_TYPES_PENDING,
  LOAD_SYSTEM_EVENTS_TYPES_FULFILLED,
  LOAD_SYSTEM_EVENTS_TYPES_REJECTED,
  CLEAR_SYSTEM_EVENTS_MESSAGES,
} from "../actions/events"
import i18n from 'i18next'

export default function systemEvents(state = {}, action) {
  switch (action.type) {
    case LOAD_SYSTEM_EVENTS_PENDING:
    case LOAD_NEW_SYSTEM_EVENTS_PENDING:
    case LOAD_SYSTEM_EVENTS_TYPES_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }
    case LOAD_SYSTEM_EVENTS_REJECTED:
    case LOAD_NEW_SYSTEM_EVENTS_REJECTED:
    case LOAD_SYSTEM_EVENTS_TYPES_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_SYSTEM_EVENTS_FULFILLED:
      const systemEvents = state?.systemEvents || []
      const newSystemEventsIds = action.payload.data?.map(item => item.id)
      const difference = systemEvents
        .filter(x => !newSystemEventsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        systemEvents: difference,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_SYSTEM_EVENTS_FULFILLED:
      return {
        ...state,
        systemEvents: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_SYSTEM_EVENTS_TYPES_FULFILLED:
      return {
        ...state,
        systemEventsTypes: action.payload,
        isLoading: false,
      }
    case CLEAR_SYSTEM_EVENTS_MESSAGES: {
      return {
        ...state,
        errorMessage: null
      }
    }
    default:
      return state
  }
}
