import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./online-access.scss";

const G4OnlineAccess = ({
  headerTitle,
  onClose,
  message,
  closeLabel,
  serial,
}) => {
  return (
    <div className="g4-container">
      <div className="header-container">
        <h2 className="header-title">{headerTitle}</h2>
        <div className="message">{message}</div>
      </div>
      {serial && (
        <div className="serial-container">
          <h3 className="title">Serial Number</h3>
          <h2 className="serial">{serial}</h2>
        </div>
      )}

      <div className="btn-container">
        <button className="btn" onClick={onClose}>
          {closeLabel}
        </button>
      </div>
    </div>
  );
};
function mapStateToProps({ onlineAccess }) {
  return {
    onlineAccess,
  };
}

export default withRouter(connect(mapStateToProps)(G4OnlineAccess));
