import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { bindActionCreators } from "redux"
import { useHistory, useLocation } from 'react-router-dom'
import OrderDetails from "./OrderDetails"
import ActionsMenu from "../../common/ActionsMenu"
import ConfirmationModal from "../../common/ConfirmationModal"
import EmptyPageResults from "../../common/EmptyPageResults"
import LoadingSpinner from "../../common/LoadingSpinner"
import SimpleButton from "../../common/SimpleButton"
import GenericTable from "../../common/GenericTable"
import { toast } from "../../common/Toast"
import { CaretDownIcon, MenuKebabVerticalBackgroundIcon, MenuKebabVerticalIcon } from "../../../icons"
import { convertToSimpleDateWithTime } from "../../../utils/filters/date"
import { requestUserRefund } from "../../../utils/requests/productsAPI"
import { loadUserOrders, requestOrderRefund, loadUserOrderDocuments } from "../../../actions/colteneStore"
import { formatStoreProductPrice } from "../../../utils/filters/filters"
import { useWindowSize } from "../../../libs/hooks"
import "./my-orders.scss"

const MyOrders = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const isMobile = useWindowSize()[0] <= 768
  const [ordersTableData, setOrdersTableData] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [refundLoading, setRefundLoading] = useState(false)
  const [cancelOrderId, setCancelOrderId] = useState(null)
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [offset, setOffset] = useState(0)
  const [orderBy, setOrderBy] = useState({ field: "purchasedAt", order: "desc", key: "purchasedAt_desc" })
  const [expandedOrder, setExpandedOrder] = useState(0)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  
  useEffect(() => {
    if (props.orders.length > 0) {
      if (location.state?.orderId) {
        viewOrderDetails(location.state.orderId)
        const state = location.state
        delete state?.orderId
        history.replace(`${history.location.pathname}`, { ...state })
      }

      const tableData = props.orders.map((order) => {
        return {
          identifier: order.id,
          date: convertToSimpleDateWithTime(order.purchasedAt),
          purchaseBy: `${order.firstName || ""} ${order.lastName || ""}`,
          amount: formatStoreProductPrice(order.paymentAmount, order.paymentCurrency),
          status: order.status,
          actions: [
            {
              label: "labels.view_details",
              onActionClick: (e) => { e.stopPropagation(); viewOrderDetails(order.id) },
            },
            ...(canRequestRefund(order.id) ? [
              {
                label: "labels.cancel",
                onActionClick: (e) => { e.stopPropagation(); handleCancelOrder(order.id) },
              }
            ]
            :[]),
          ]
        }
      })

      setOrdersTableData(tableData)
    }
  }, [props.orders])

  useEffect(() => {
    if (offset > 0) {
      loadOrders(offset, false)
    }
  }, [offset])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        loadOrders(0, true)
        setOffset(0)
      },
      0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [orderBy])

  const createQueryParams = () => {
    let queryParams = {}

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const loadOrders = (offset, withReset) => {
    props.actions.loadUserOrders({ ...createQueryParams(), offset: offset }, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleLoadMore = () => {
    setOffset((prevValue) => (prevValue ? prevValue + 10 : 10))
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleCancelOrder = (orderId) => {
    setCancelOrderId(orderId)
    setShowCancelOrderModal(true)
  }

  const handleRequestRefund = (orderId) => {
    if (refundLoading) {
      return
    }
    
    setRefundLoading(true)

    requestUserRefund(orderId)
      .then(() => {
        toast.success(t("coltene_store.order_history.refund-requested"))

        setRefundLoading(false)

        props.actions.requestOrderRefund(orderId)
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))

        setRefundLoading(false)
      })

    setShowCancelOrderModal(false)
  }

  const viewOrderDetails = (orderId) => {
    const order = props.orders.find((order) => order.id === orderId)

    if (order) {
      setSelectedOrder(order)

      document.getElementById("page-content").scroll({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  const canRequestRefund = (orderId) => {
    const order = props.orders.find((order) => order.id === orderId)

    const twentyFourHrsInMs = 24 * 60 * 60 * 1000
    const twentyFourHrsAgo = Date.now() - twentyFourHrsInMs

    return order?.status === "Paid" && order?.items?.some((item) => !item.product?.isDigital) && new Date(order.purchasedAt) > twentyFourHrsAgo
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <div className="my-orders-wrapper">
      {selectedOrder &&
        <div className="section-wrapper">
          <OrderDetails
            order={selectedOrder}
            refundLoading={refundLoading}
            onToggle={() => setSelectedOrder(null)}
            canRequestRefund={canRequestRefund}
            handleRequestRefund={handleRequestRefund}
          />
        </div>
      }
      {!selectedOrder &&
        <div className="section-wrapper">
          <div className="wrapper-header">
            <p className="wrapper-title">{t("account.orders")}</p>
          </div>
          {!isMobile && ordersTableData.length > 0 &&
            <GenericTable
              data={ordersTableData}
              headers={[
                {
                  title: t("coltene_store.order_history.date"),
                  orderKey: 'purchasedAt'
                },
                {
                  title: t("coltene_store.order_history.order-number"),
                  orderKey: 'id'
                },
                {
                  title: t("coltene_store.order_history.purchase-by"),
                },
                {
                  title: t("coltene_store.order_history.total-amount"),
                  orderKey: 'paymentAmount'
                },
                {
                  title: t("coltene_store.order_history.status"),
                  orderKey: 'status'
                },
                {
                  title: ''
                }
              ]}
              keys={[
                'date',
                'identifier',
                'purchaseBy',
                'amount',
                'status',
                'action',
              ]}
              keyRenderer={{
                status: (item) => {
                  return (
                    <div className="status-wrapper">
                      <div
                        className={`${
                          item.status === "Paid" || item.status === "Complete" || item.status === "Shipped"
                          ? "processed"
                          : item.status === "Refund In Progress" || item.status === "Refund Requested"
                          ? "processing"
                          : "cancelled"
                        }`}
                      >
                        {item.status}
                      </div>
                    </div>
                  )
                },
                action: (item) => {
                  return (
                    <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                      {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                      {showActionsMenu === item["identifier"] &&
                        <ActionsMenu
                          setHideMenu={() => setShowActionsMenu(null)}
                          actions={item.actions}
                          isLoading={showSpinner}
                        />
                      }
                    </div>
                  )
                }           
              }}
              onRowClick={(order) => viewOrderDetails(order.identifier)}
              activeSort={orderBy}
              onSort={(orderingField) => handleSort(orderingField)}
              isLoading={showSpinner}
            />
          }
          {isMobile && ordersTableData.map((order, index) =>
            <div
              key={index}
              className={"card" + (expandedOrder === index ? " with-padding-bottom" : "")} 
              onClick={() => setExpandedOrder(index)}
            >
              <div className={"card-item" + (expandedOrder !== index ? " align-center" : "")}>
                <div className="card-item-title">{t("coltene_store.order_history.date")}</div>
                <div className={"card-item-body" + (expandedOrder !== index ? " align-center" : "")}>
                  <div>{order.date}</div>
                  <div 
                    className="card-actions"
                    onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                  >                  
                    {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === index &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={order.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("coltene_store.order_history.order-number")}</div>
                <div className="card-item-body">{order.identifier}</div>
              </div>
              {expandedOrder === index && (
                <>
                  <div className="card-item">
                    <div className="card-item-title">{t("coltene_store.order_history.purchase-by")}</div>
                    <div className="card-item-body">{order.purchaseBy}</div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-title">{t("coltene_store.order_history.total-amount")}</div>
                    <div className="card-item-body">{order.amount}</div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-title">{t("coltene_store.order_history.status")}</div>
                    <div className="card-item-body">
                      <div className="card-item-body-status">
                        <div
                          className={`${
                            order.status === "Paid" || order.status === "Complete" || order.status === "Shipped"
                            ? "processed"
                            : order.status === "Refund In Progress" || order.status === "Refund Requested"
                            ? "processing"
                            : "cancelled"
                          }`}
                        >
                          {order.status}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {expandedOrder !== index && 
                <div className="expander-wrapper">
                  <CaretDownIcon />
                </div>
              }
            </div>)
          }
          {!showSpinner && !isFirstLoad && ordersTableData.length === 0 &&
            <EmptyPageResults 
              className="empty-page-wrapper" 
              title={t("coltene_store.order_history.no-orders")} 
            />
          }
          {canLoadMore && (
            <div className="buttons-wrapper">
              <SimpleButton className="load-more-button" onClick={handleLoadMore}>
                {t("buttons.load_more")}
              </SimpleButton>
            </div>
          )}
        </div>
      }
      {(showSpinner || refundLoading) && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showCancelOrderModal && (
        <ConfirmationModal
          onToggle={() => {
            setCancelOrderId(null)
            setShowCancelOrderModal(false)
          }}
          onCancel={() => {
            setCancelOrderId(null)
            setShowCancelOrderModal(false)
          }}
          onAccept={() => handleRequestRefund(cancelOrderId)}
          message={t("coltene_store.order_history.cancelation-confirmation")}
        />
      )}
    </div>
  )
}

export function stateToProps({ colteneStore }) {
  return {
    isLoading: colteneStore?.isLoading,
    orders: colteneStore?.orders || [],
    canLoadMore: colteneStore?.canLoadMore,
    orderRefund: colteneStore?.refunded_orders,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadUserOrders,
        requestOrderRefund,
        loadUserOrderDocuments,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(MyOrders)
