import React, { useEffect, useState } from "react"
import { TickIcon } from "../../../icons"
import { keyboardEvents } from "../../../utils/keyboardEvents"
import "./checkbox-input.scss"

const CheckboxInput = (props) => {
  const { checked, onChange, text, disabled, icon, className } = props
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const triggerCheckbox = (e) => {
    if (onChange) {
      setIsChecked(!isChecked)
      onChange(e)
    }
  }

  return (
    <div className={"checkbox-wrapper " + className}>
      <div
        className={"checkbox-switch" + (isChecked ? " active-checkbox-switch" : "") + (disabled ? " disabled-checkbox-switch" : "")}
        onClick={(e) => { triggerCheckbox(e)}}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => e.key === keyboardEvents.ENTER && triggerCheckbox(e)}
      >
        {icon ? icon : <TickIcon />}
      </div>
      <div className={"checkbox-text" + (disabled ? " disabled-checkbox-text" : "")}>{text}</div>
    </div>
  )
}

export default CheckboxInput
