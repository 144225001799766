import {
  getNotificationsSettings,
  getEventGroups,
  getNotificationDeliveryMethods,
  postNotificationSetting,
  putNotificationSetting,
  patchNotificationSetting,
  deleteNotificationSetting,
  getEmailTemplates,
  getNotificationMsgs,
  getNotificationRegions,
  postNotificationMsg,
  putNotificationMsg,
  patchNotificationMsg,
  deleteNotificationMsg
} from "../utils/requests/notificationsAPI"
import {
  getCurrentUserNotificationPreferences,
  putCurrentUserNotificationPreference,
  getCurrentUserNotifications,
  postCurrentUserNotificationsRead,
  postCurrentUserNotificationRead
} from "../utils/requests/usersAPI"
import {
  getProductNotificationsConfiguration,
  postProductNotificationConfiguration,
  deleteProductNotificationConfiguration
} from "../utils/requests/productsAPI"

export const LOAD_NOTIFICATION_SETTINGS = "LOAD_NOTIFICATION_SETTINGS"
export const LOAD_NOTIFICATION_SETTINGS_PENDING = "LOAD_NOTIFICATION_SETTINGS_PENDING"
export const LOAD_NOTIFICATION_SETTINGS_FULFILLED = "LOAD_NOTIFICATION_SETTINGS_FULFILLED"
export const LOAD_NOTIFICATION_SETTINGS_REJECTED = "LOAD_NOTIFICATION_SETTINGS_REJECTED"

export const LOAD_NEW_NOTIFICATION_SETTINGS = "LOAD_NEW_NOTIFICATION_SETTINGS"
export const LOAD_NEW_NOTIFICATION_SETTINGS_PENDING = "LOAD_NEW_NOTIFICATION_SETTINGS_PENDING"
export const LOAD_NEW_NOTIFICATION_SETTINGS_FULFILLED = "LOAD_NEW_NOTIFICATION_SETTINGS_FULFILLED"
export const LOAD_NEW_NOTIFICATION_SETTINGS_REJECTED = "LOAD_NEW_NOTIFICATION_SETTINGS_REJECTED"

export const POST_NOTIFICATION_SETTING = "POST_NOTIFICATION_SETTING"
export const POST_NOTIFICATION_SETTING_PENDING = "POST_NOTIFICATION_SETTING_PENDING"
export const POST_NOTIFICATION_SETTING_FULFILLED = "POST_NOTIFICATION_SETTING_FULFILLED"
export const POST_NOTIFICATION_SETTING_REJECTED = "POST_NOTIFICATION_SETTING_REJECTED"

export const PUT_NOTIFICATION_SETTING = "PUT_NOTIFICATION_SETTING"
export const PUT_NOTIFICATION_SETTING_PENDING = "PUT_NOTIFICATION_SETTING_PENDING"
export const PUT_NOTIFICATION_SETTING_FULFILLED = "PUT_NOTIFICATION_SETTING_FULFILLED"
export const PUT_NOTIFICATION_SETTING_REJECTED = "PUT_NOTIFICATION_SETTING_REJECTED"

export const PATCH_NOTIFICATION_SETTING = "PATCH_NOTIFICATION_SETTING"
export const PATCH_NOTIFICATION_SETTING_PENDING = "PATCH_NOTIFICATION_SETTING_PENDING"
export const PATCH_NOTIFICATION_SETTING_FULFILLED = "PATCH_NOTIFICATION_SETTING_FULFILLED"
export const PATCH_NOTIFICATION_SETTING_REJECTED = "PATCH_NOTIFICATION_SETTING_REJECTED"

export const DELETE_NOTIFICATION_SETTING = "DELETE_NOTIFICATION_SETTING"
export const DELETE_NOTIFICATION_SETTING_PENDING = "DELETE_NOTIFICATION_SETTING_PENDING"
export const DELETE_NOTIFICATION_SETTING_FULFILLED = "DELETE_NOTIFICATION_SETTING_FULFILLED"
export const DELETE_NOTIFICATION_SETTING_REJECTED = "DELETE_NOTIFICATION_SETTING_REJECTED"



export const LOAD_NOTIFICATION_MESSAGES = "LOAD_NOTIFICATION_MESSAGES"
export const LOAD_NOTIFICATION_MESSAGES_PENDING = "LOAD_NOTIFICATION_MESSAGES_PENDING"
export const LOAD_NOTIFICATION_MESSAGES_FULFILLED = "LOAD_NOTIFICATION_MESSAGES_FULFILLED"
export const LOAD_NOTIFICATION_MESSAGES_REJECTED = "LOAD_NOTIFICATION_MESSAGES_REJECTED"

export const LOAD_NEW_NOTIFICATION_MESSAGES = "LOAD_NEW_NOTIFICATION_MESSAGES"
export const LOAD_NEW_NOTIFICATION_MESSAGES_PENDING = "LOAD_NEW_NOTIFICATION_MESSAGES_PENDING"
export const LOAD_NEW_NOTIFICATION_MESSAGES_FULFILLED = "LOAD_NEW_NOTIFICATION_MESSAGES_FULFILLED"
export const LOAD_NEW_NOTIFICATION_MESSAGES_REJECTED = "LOAD_NEW_NOTIFICATION_MESSAGES_REJECTED"

export const POST_NOTIFICATION_MESSAGE = "POST_NOTIFICATION_MESSAGE"
export const POST_NOTIFICATION_MESSAGE_PENDING = "POST_NOTIFICATION_MESSAGE_PENDING"
export const POST_NOTIFICATION_MESSAGE_FULFILLED = "POST_NOTIFICATION_MESSAGE_FULFILLED"
export const POST_NOTIFICATION_MESSAGE_REJECTED = "POST_NOTIFICATION_MESSAGE_REJECTED"

export const PUT_NOTIFICATION_MESSAGE = "PUT_NOTIFICATION_MESSAGE"
export const PUT_NOTIFICATION_MESSAGE_PENDING = "PUT_NOTIFICATION_MESSAGE_PENDING"
export const PUT_NOTIFICATION_MESSAGE_FULFILLED = "PUT_NOTIFICATION_MESSAGE_FULFILLED"
export const PUT_NOTIFICATION_MESSAGE_REJECTED = "PUT_NOTIFICATION_MESSAGE_REJECTED"

export const PATCH_NOTIFICATION_MESSAGE = "PATCH_NOTIFICATION_MESSAGE"
export const PATCH_NOTIFICATION_MESSAGE_PENDING = "PATCH_NOTIFICATION_MESSAGE_PENDING"
export const PATCH_NOTIFICATION_MESSAGE_FULFILLED = "PATCH_NOTIFICATION_MESSAGE_FULFILLED"
export const PATCH_NOTIFICATION_MESSAGE_REJECTED = "PATCH_NOTIFICATION_MESSAGE_REJECTED"

export const DELETE_NOTIFICATION_MESSAGE = "DELETE_NOTIFICATION_MESSAGE"
export const DELETE_NOTIFICATION_MESSAGE_PENDING = "DELETE_NOTIFICATION_MESSAGE_PENDING"
export const DELETE_NOTIFICATION_MESSAGE_FULFILLED = "DELETE_NOTIFICATION_MESSAGE_FULFILLED"
export const DELETE_NOTIFICATION_MESSAGE_REJECTED = "DELETE_NOTIFICATION_MESSAGE_REJECTED"



export const LOAD_EVENT_GROUPS = "LOAD_EVENT_GROUPS"
export const LOAD_EVENT_GROUPS_PENDING = "LOAD_EVENT_GROUPS_PENDING"
export const LOAD_EVENT_GROUPS_FULFILLED = "LOAD_EVENT_GROUPS_FULFILLED"
export const LOAD_EVENT_GROUPS_REJECTED = "LOAD_EVENT_GROUPS_REJECTED"

export const LOAD_NOTIFICATION_DELIVERY_METHODS = "LOAD_NOTIFICATION_DELIVERY_METHODS"
export const LOAD_NOTIFICATION_DELIVERY_METHODS_PENDING = "LOAD_NOTIFICATION_DELIVERY_METHODS_PENDING"
export const LOAD_NOTIFICATION_DELIVERY_METHODS_FULFILLED = "LOAD_NOTIFICATION_DELIVERY_METHODS_FULFILLED"
export const LOAD_NOTIFICATION_DELIVERY_METHODS_REJECTED = "LOAD_NOTIFICATION_DELIVERY_METHODS_REJECTED"

export const LOAD_EMAIL_TEMPLATES = "LOAD_EMAIL_TEMPLATES"
export const LOAD_EMAIL_TEMPLATES_PENDING = "LOAD_EMAIL_TEMPLATES_PENDING"
export const LOAD_EMAIL_TEMPLATES_FULFILLED = "LOAD_EMAIL_TEMPLATES_FULFILLED"
export const LOAD_EMAIL_TEMPLATES_REJECTED = "LOAD_EMAIL_TEMPLATES_REJECTED"

export const LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES = "LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES"
export const LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_PENDING = "LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_PENDING"
export const LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_FULFILLED = "LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_FULFILLED"
export const LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_REJECTED = "LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES_REJECTED"

export const LOAD_NOTIFICATIONS_CONFIGURATION = "LOAD_NOTIFICATIONS_CONFIGURATION"
export const LOAD_NOTIFICATIONS_CONFIGURATION_PENDING = "LOAD_NOTIFICATIONS_CONFIGURATION_PENDING"
export const LOAD_NOTIFICATIONS_CONFIGURATION_FULFILLED = "LOAD_NOTIFICATIONS_CONFIGURATION_FULFILLED"
export const LOAD_NOTIFICATIONS_CONFIGURATION_REJECTED = "LOAD_NOTIFICATIONS_CONFIGURATION_REJECTED"

export const POST_NOTIFICATION_CONFIGURATION = "POST_NOTIFICATION_CONFIGURATION"
export const POST_NOTIFICATION_CONFIGURATION_PENDING = "POST_NOTIFICATION_CONFIGURATION_PENDING"
export const POST_NOTIFICATION_CONFIGURATION_FULFILLED = "POST_NOTIFICATION_CONFIGURATION_FULFILLED"
export const POST_NOTIFICATION_CONFIGURATION_REJECTED = "POST_NOTIFICATION_CONFIGURATION_REJECTED"

export const DELETE_NOTIFICATION_CONFIGURATION = "DELETE_NOTIFICATION_CONFIGURATION"
export const DELETE_NOTIFICATION_CONFIGURATION_PENDING = "DELETE_NOTIFICATION_CONFIGURATION_PENDING"
export const DELETE_NOTIFICATION_CONFIGURATION_FULFILLED = "DELETE_NOTIFICATION_CONFIGURATION_FULFILLED"
export const DELETE_NOTIFICATION_CONFIGURATION_REJECTED = "DELETE_NOTIFICATION_CONFIGURATION_REJECTED"

export const LOAD_CURRENT_USER_NOTIFICATIONS = "LOAD_CURRENT_USER_NOTIFICATIONS"
export const LOAD_CURRENT_USER_NOTIFICATIONS_PENDING = "LOAD_CURRENT_USER_NOTIFICATIONS_PENDING"
export const LOAD_CURRENT_USER_NOTIFICATIONS_FULFILLED = "LOAD_CURRENT_USER_NOTIFICATIONS_FULFILLED"
export const LOAD_CURRENT_USER_NOTIFICATIONS_REJECTED = "LOAD_CURRENT_USER_NOTIFICATIONS_REJECTED"

export const LOAD_NOTIFICATION_REGIONS = "LOAD_NOTIFICATION_REGIONS"
export const LOAD_NOTIFICATION_REGIONS_PENDING = "LOAD_NOTIFICATION_REGIONS_PENDING"
export const LOAD_NOTIFICATION_REGIONS_FULFILLED = "LOAD_NOTIFICATION_REGIONS_FULFILLED"
export const LOAD_NOTIFICATION_REGIONS_REJECTED = "LOAD_NOTIFICATION_REGIONS_REJECTED"

export const POST_CURRENT_USER_NOTIFICATIONS_READ = "POST_CURRENT_USER_NOTIFICATIONS_READ"
export const POST_CURRENT_USER_NOTIFICATIONS_READ_PENDING = "POST_CURRENT_USER_NOTIFICATIONS_READ_PENDING"
export const POST_CURRENT_USER_NOTIFICATIONS_READ_FULFILLED = "POST_CURRENT_USER_NOTIFICATIONS_READ_FULFILLED"
export const POST_CURRENT_USER_NOTIFICATIONS_READ_REJECTED = "POST_CURRENT_USER_NOTIFICATIONS_READ_REJECTED"

export const POST_CURRENT_USER_NOTIFICATION_READ = "POST_CURRENT_USER_NOTIFICATION_READ"
export const POST_CURRENT_USER_NOTIFICATION_READ_PENDING = "POST_CURRENT_USER_NOTIFICATION_READ_PENDING"
export const POST_CURRENT_USER_NOTIFICATION_READ_FULFILLED = "POST_CURRENT_USER_NOTIFICATION_READ_FULFILLED"
export const POST_CURRENT_USER_NOTIFICATION_READ_REJECTED = "POST_CURRENT_USER_NOTIFICATION_READ_REJECTED"

export const CLEAR_NOTIFICATIONS_MESSAGES = "CLEAR_NOTIFICATIONS_MESSAGES"
export const CLEAR_NOTIFICATIONS_CONFIGURATION = "CLEAR_NOTIFICATIONS_CONFIGURATION"

export function loadNotificationSettings(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_NOTIFICATION_SETTINGS : LOAD_NOTIFICATION_SETTINGS,
    payload: getNotificationsSettings(queryParams)
  }
}

export function loadEventGroups() {
  return {
    type: LOAD_EVENT_GROUPS,
    payload: getEventGroups()
  }
}

export function loadNotificationDeliveryMethods() {
  return {
    type: LOAD_NOTIFICATION_DELIVERY_METHODS,
    payload: getNotificationDeliveryMethods()
  }
}

export function loadEmailTemplates() {
  return {
    type: LOAD_EMAIL_TEMPLATES,
    payload: getEmailTemplates()
  }
}

export function createNotificationSetting(bodyParams) {
  return {
    type: POST_NOTIFICATION_SETTING,
    payload: postNotificationSetting(bodyParams)
  }
}

export function updateNotificationSetting(id, bodyParams) {
  return {
    type: PUT_NOTIFICATION_SETTING,
    payload: putNotificationSetting(id, bodyParams)
  }
}

export function partiallyUpdateNotificationSetting(id, bodyParams) {
  return {
    type: PATCH_NOTIFICATION_SETTING,
    payload: patchNotificationSetting(id, bodyParams)
  }
}

export function removeNotificationSetting(id) {
  return {
    type: DELETE_NOTIFICATION_SETTING,
    payload: deleteNotificationSetting(id),
    meta: { id }
  }
}

export function loadCurrentUserNotificationPreferences(cognitoSub) {
  return {
    type: LOAD_CURRENT_USER_NOTIFICATION_PREFERENCES,
    payload: getCurrentUserNotificationPreferences(cognitoSub)
  }
}

export function loadProductNotificationsConfiguration(serialNumber) {
  return {
    type: LOAD_NOTIFICATIONS_CONFIGURATION,
    payload: getProductNotificationsConfiguration(serialNumber)
  }
}

export function createNotificationConfiguration(serialNumber, bodyParams, withoutMessage) {
  return {
    type: POST_NOTIFICATION_CONFIGURATION,
    payload: postProductNotificationConfiguration(serialNumber, bodyParams),
    meta: { body: bodyParams, withoutMessage }
  }
}

export function removeNotificationConfiguration(serialNumber, email) {
  return {
    type: DELETE_NOTIFICATION_CONFIGURATION,
    payload: deleteProductNotificationConfiguration(serialNumber, email),
    meta: { email }
  }
}

export function loadCurrentUserNotifications(cognitoSub, isUnread) {
  return {
    type: LOAD_CURRENT_USER_NOTIFICATIONS,
    payload: getCurrentUserNotifications(cognitoSub, isUnread)
  }
}

export function markAllNotificationsRead(cognitoSub) {
  return {
    type: POST_CURRENT_USER_NOTIFICATIONS_READ,
    payload: postCurrentUserNotificationsRead(cognitoSub)
  }
}

export function markNotificationRead(cognitoSub, notificationId, type) {
  return {
    type: POST_CURRENT_USER_NOTIFICATION_READ,
    payload: postCurrentUserNotificationRead(cognitoSub, notificationId, type),
    meta: { 
      id: notificationId,
      type
    }
  }
}

export function clearNotificationsMessages() {
  return {
    type: CLEAR_NOTIFICATIONS_MESSAGES
  }
}

export function clearNotificationsConfiguration() {
  return {
    type: CLEAR_NOTIFICATIONS_CONFIGURATION
  }
}

export function loadNotificationMessages(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_NOTIFICATION_MESSAGES : LOAD_NOTIFICATION_MESSAGES,
    payload: getNotificationMsgs(queryParams)
  }
}

export function loadNotificationRegions() {
  return {
    type: LOAD_NOTIFICATION_REGIONS,
    payload: getNotificationRegions()
  }
}

export function createNotificationMsg(bodyParams) {
  return {
    type: POST_NOTIFICATION_MESSAGE,
    payload: postNotificationMsg(bodyParams)
  }
}

export function updateNotificationMsg(id, bodyParams) {
  return {
    type: PUT_NOTIFICATION_MESSAGE,
    payload: putNotificationMsg(id, bodyParams)
  }
}

export function partiallyUpdateNotificationMsg(id, bodyParams) {
  return {
    type: PATCH_NOTIFICATION_MESSAGE,
    payload: patchNotificationMsg(id, bodyParams)
  }
}

export function removeNotificationMsg(id) {
  return {
    type: DELETE_NOTIFICATION_MESSAGE,
    payload: deleteNotificationMsg(id),
    meta: { id }
  }
}
