import React from "react"
import {useTranslation} from "react-i18next"
import PageModal from '../common/PageModal'
import WrappedTabs from "../common/WrappedTabs"
import { ArrowBackThickIcon } from "../../icons"
import { getImageByModel } from '../../utils/functions'
import { convertToSimpleDate } from '../../utils/filters/date'
import './firmware-details-modal.scss'

const FirmwareDetailsModal = (props) => {
  const {t} = useTranslation()
  const { 
    selectedDevice,
    tabs,
    selectedTab,
    handleTabClick,
    closeModal, 
  } = props

  const firmwareHeaderContent = () => {
    return (
      <div className="firmware-header" onClick={() => handleCloseModal()}>
        <div className="back-button" >
          <div className="back-icon">
            <ArrowBackThickIcon />
          </div>
        </div>
        <p className="title">{t("buttons.back")}</p>
      </div>
    )
  }

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }
  
  return (
    <div className="firmware-details-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        headerContent={firmwareHeaderContent()}
      >
        <div className="firmware-details-modal-content">
          <div className="device-card">
            <div className="image-wrapper d-flex flex-align-center">
              <img
                src={getImageByModel(selectedDevice.model)}
                className="img"
                alt={selectedDevice.model}
              />
            </div>
            <div className="device-name-wrapper d-flex flex-column">
              <p className="device-name">{selectedDevice.model}</p>
              <div className="firmware-latest-release">{`${t("firmware.latest-release")} ${selectedDevice.versions?.length > 0 ? convertToSimpleDate(selectedDevice.versions[0].created_at) : ""}`}</div>
              {selectedDevice.update_available &&
                <div className="firmware-version-available">{t("firmware.new-version-available")}</div>
              }
            </div>
          </div>
          <WrappedTabs
            tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))}
            activeTab={selectedTab?.key}
            changeTab={handleTabClick}
          />
          {selectedTab?.content && selectedTab.content}
        </div>
      </PageModal>
    </div>
  )
}

export default FirmwareDetailsModal
