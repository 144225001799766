import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CaretDownIcon } from "../../../icons"
import ClickOutside from "../ClickOutside"
import "./dropdown-search-field.scss"

const DropdownSearch = (props) => {
  const { t } = useTranslation()
  const { onChange, selectLbl } = props
  const [searchText, setSearchText] = useState("")
  const [selected, setSelected] = useState()
  const [localOptions, setLocalOptions] = useState([])

  useEffect(() => {
    if (!selected) setLocalOptions(props.options ? props.options : [])
    if (props.defaultValue && !selected) setSelected({ value: props.defaultValue.name })
  }, [props])

  const handleSearch = (e) => {
    const value = e.target.value

    setSearchText(value)
    setLocalOptions(props.options.filter((it) => it.value.toLowerCase().includes(value.toLowerCase())))
  }

  const handleSelectOption = (option, toggleVisibility) => {
    setSelected({
      value: option.value,
    })
    onChange(option.id)
    toggleVisibility(false)
  }

  const input = ({ toggleVisibility, isComponentVisible }) => {
    return (
      <div
        className={`select-input ${props.error ? "input-error" : ""}`}
        onClick={() => (isComponentVisible ? toggleVisibility(false) : toggleVisibility(true))}
      >
        <div className="d-flex flex-align-center h-100 w-100">{selected ? selected.value : selectLbl}</div>
        <CaretDownIcon className={`${isComponentVisible ? "active" : ""}`} />
      </div>
    )
  }

  const options = ({ toggleVisibility }) => {
    return (
      <div className="select-options">
        <input className="search-input" type="text" onChange={handleSearch} value={searchText} placeholder={t("placeholders.search-filters")} />
        <div className="scrollable-wrapper">
          {localOptions?.map((option, index) => (
            <div className="option d-flex flex-align-center" key={`option-${index}`} onClick={() => handleSelectOption(option, toggleVisibility)}>
              {option.value}
            </div>
          ))}
          {localOptions?.length === 0 && <div className="no-data">{t("no_data_available")}</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="dropdown-with-search">
      <ClickOutside itemRef="checkbox" eventItem={input} toDisplayItem={options} />
    </div>
  )
}

export default DropdownSearch
