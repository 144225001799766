import {
  LOAD_DOCUMENTS_PENDING,
  LOAD_DOCUMENTS_FULFILLED,
  LOAD_DOCUMENTS_REJECTED,
  LOAD_NEW_DOCUMENTS_PENDING,
  LOAD_NEW_DOCUMENTS_FULFILLED,
  LOAD_NEW_DOCUMENTS_REJECTED,
  LOAD_TSB_DOCUMENTS_PENDING,
  LOAD_TSB_DOCUMENTS_FULFILLED,
  LOAD_TSB_DOCUMENTS_REJECTED,
  LOAD_NEW_TSB_DOCUMENTS_PENDING,
  LOAD_NEW_TSB_DOCUMENTS_FULFILLED,
  LOAD_NEW_TSB_DOCUMENTS_REJECTED,
  LOAD_DOCUMENT_MODELS_PENDING,
  LOAD_DOCUMENT_MODELS_FULFILLED,
  LOAD_DOCUMENT_MODELS_REJECTED,
  LOAD_DOCUMENT_TYPES_PENDING,
  LOAD_DOCUMENT_TYPES_FULFILLED,
  LOAD_DOCUMENT_TYPES_REJECTED,
  LOAD_DOCUMENT_LANGUAGES_PENDING,
  LOAD_DOCUMENT_LANGUAGES_FULFILLED,
  LOAD_DOCUMENT_LANGUAGES_REJECTED,
  LOAD_TSB_DOCUMENT_LANGUAGES_PENDING,
  LOAD_TSB_DOCUMENT_LANGUAGES_FULFILLED,
  LOAD_TSB_DOCUMENT_LANGUAGES_REJECTED,
  CLEAR_DOCUMENTS_MESSAGES,
} from "../actions/documents"
import i18n from 'i18next'

export default function documents(state = {}, action) {
  switch (action.type) {
    case LOAD_DOCUMENTS_PENDING:
    case LOAD_NEW_DOCUMENTS_PENDING:
    case LOAD_TSB_DOCUMENTS_PENDING:
    case LOAD_NEW_TSB_DOCUMENTS_PENDING:
    case LOAD_DOCUMENT_MODELS_PENDING:
    case LOAD_DOCUMENT_TYPES_PENDING:
    case LOAD_DOCUMENT_LANGUAGES_PENDING:
    case LOAD_TSB_DOCUMENT_LANGUAGES_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }
    case LOAD_DOCUMENTS_REJECTED:
    case LOAD_NEW_DOCUMENTS_REJECTED:
    case LOAD_TSB_DOCUMENTS_REJECTED:
    case LOAD_NEW_TSB_DOCUMENTS_REJECTED:
    case LOAD_DOCUMENT_MODELS_REJECTED:
    case LOAD_DOCUMENT_TYPES_REJECTED:
    case LOAD_DOCUMENT_LANGUAGES_REJECTED:
    case LOAD_TSB_DOCUMENT_LANGUAGES_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_DOCUMENTS_FULFILLED:
      const documents = state?.documents || []
      const newDocumentsIds = action.payload.data?.map(item => item.id)
      const difference = documents
        .filter(x => !newDocumentsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        documents: difference,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_DOCUMENTS_FULFILLED:
      return {
        ...state,
        documents: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_TSB_DOCUMENTS_FULFILLED: 
      const tsbDocuments = state?.tsbDocuments || []
      const newTsbDocumentsIds = action.payload.data?.map(item => item.id)
      const tsbDifference = tsbDocuments
        .filter(x => !newTsbDocumentsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        tsbDocuments: tsbDifference,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_TSB_DOCUMENTS_FULFILLED:
      return {
        ...state,
        tsbDocuments: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_DOCUMENT_MODELS_FULFILLED:
      return {
        ...state,
        documentModels: action.payload.data?.map(item => item.model),
        isLoading: false,
      }
    case LOAD_DOCUMENT_TYPES_FULFILLED:
      return {
        ...state,
        documentTypes: action.payload.data,
        isLoading: false,
      }
    case LOAD_DOCUMENT_LANGUAGES_FULFILLED:
      return {
        ...state,
        documentLanguages: action.payload.data,
        isLoading: false,
      }
    case LOAD_TSB_DOCUMENT_LANGUAGES_FULFILLED:
      return {
        ...state,
        tsbDocumentLanguages: action.payload.data,
        isLoading: false,
      }
    case CLEAR_DOCUMENTS_MESSAGES: {
      return {
        ...state,
        errorMessage: null
      }
    }
    default:
      return state
  }
}
