import {_basePutFetch} from "./baseFetch";

export const onlineActivation = (queryParams) => {
  return _basePutFetch(`/products/online-access`, queryParams)
}

export const onlineActivationWithSN = (serialNumber, queryParams) => {
  return _basePutFetch(`/products/${serialNumber}/online-access`, queryParams)
}

export const putG4OnlineAccess = (queryParams) => {
  return _basePutFetch(`/online-access/phase`, queryParams)
}

export const putEmailClick = (username, queryParams) => {
  return _basePutFetch(`/users/${username}/online-access/analytics/email/link-click`, queryParams)
}

export const putSuccessLogin = (username, queryParams) => {
  return _basePutFetch(`/users/${username}/online-access/analytics/email/confirm-login`, queryParams)
}