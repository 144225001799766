import { isEmpty } from "lodash";
import { isDevOrUAT } from "./config";
import { hasEndUserSiteAccess } from "./permissionValidation";

export function transformAperturesToUserProducts(apertures, userProducts, t) {
  return apertures?.reduce((acc, aperture) => {
    return {
      ...acc,
      ...aperture.devices?.reduce(
        (acc, device) => ({
          ...acc,
          ...(!Object.keys(userProducts).includes(aperture.serial_number)
            ? {
                [device.serial_number]: {
                  ...device,
                  aperture_id: aperture.aperture_id,
                  isAperture: true,
                  model: t("products.ic_track_type", {
                    type: aperture.type.toUpperCase(),
                  }),
                  aperture_serial_number: aperture.serial_number,
                  device_uuid: device.device_uuid,
                  unit_nickname: device.nickname,
                  warranty_registration_date: "",
                  association_active: false,
                  warranty_id: "",
                  update_available: false,
                },
              }
            : {}),
        }),
        {}
      ),
    }
  }, {})
}

export function combineUserProductsAndApertures(permissions, userProducts, apertures, t) {
  if (isEmpty(apertures) && isEmpty(userProducts))  {
    return {}
  }
  
  const isDentist = hasEndUserSiteAccess(permissions)
  if (!isDevOrUAT && !isDentist) {
    return userProducts
  }

  const apertureDevices = transformAperturesToUserProducts(apertures || [], userProducts, t)
  const apertureDevicesSerialNums = Object.values(apertureDevices || {}).map((device) => device.serial_number)
  const userProductsWithApertures = Object.entries(userProducts || {}).map(([key, value]) => {
    if (apertureDevicesSerialNums.includes(key)) {
      return {
        [key]: {
          ...value,
          aperture_id: apertureDevices[key].aperture_id,
          device_uuid: apertureDevices[key].device_uuid,
          isAperture: apertureDevices[key].isAperture,
          aperture_serial_number: apertureDevices[key].aperture_serial_number,
        },
      }
    }
    return { [key]: value }
  })

  if (Object.keys(userProductsWithApertures).length > 0) {
    return { ...apertureDevices, ...Object.assign(...userProductsWithApertures) }
  }

  return { ...apertureDevices }
}
