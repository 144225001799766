import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { createStore } from "redux"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { Amplify, Auth } from "aws-amplify"
import { ToastContainer, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import reducer from "./reducers"
import middleware from "./middleware"
import "./libs/i18n"
import config from "./utils/config"
import initializeGA from "./utils/ga4"
import LoadingSpinner from "./components/common/LoadingSpinner"
import ScrollToTop from "./components/common/ScrollToTop"
import App from "../src/components"
import "./index.scss"

const amplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "mySciCanApiGateway",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "mySciCanTrackingApiGateway",
        endpoint: config.trackingApiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "mySciCanRecordsApiGateway",
        endpoint: config.recordsApiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "mySciCanTelemetryApiGateway",
        endpoint: config.telemetryApiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "mySciCanAdminApiGateway",
        endpoint: config.adminApiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "mySciCanApiGatewayStore",
        endpoint: config.apiGatewayStore.URL,
        region: config.apiGateway.REGION,
      }
    ],
  },
  Storage: {
    AWSS3: {
      bucket: config.s3.BUCKET,
      region: config.s3.REGION,
    },
  },
}

//Use cognito authentication
  amplifyConfig.API.endpoints.forEach(
    (endpoint) =>
      (endpoint.custom_header = async () => {
        return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
      })
  )
Amplify.configure(amplifyConfig)

export const store = createStore(reducer, middleware)

initializeGA()

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner />}>
      <Provider store={store}>
        <ToastContainer
          position="top-center"
          transition={Slide}
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
)
