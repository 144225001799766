import React, { useState, useEffect,useRef } from "react"
import { CaretDownIcon } from "../../../icons"
import "./dropdown-menu-with-checkbox.scss"

const FoldableCheckboxList = ({ 
  options,
  onChange,
  placeholder,
  hasError,
  initialData
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(initialData);
  const [inputValue, setInputValue] = useState(initialData?.join(', '));
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (option) => {
    const selectedIndex = selectedOptions?.indexOf(option);
    let newSelectedOptions = [...selectedOptions];
    
    if (selectedIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(selectedIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);

    setInputValue(newSelectedOptions?.join(', '));
  };

  const toggleSelectAll = () => {
    let newSelectedOptions = [];

    if (selectedOptions.length !== options.length) {
      newSelectedOptions = [...options];
    }

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);

    setInputValue(newSelectedOptions.join(', '));
  };

  return (
    <div className={"dropdown-menu" + (hasError ? " has-error" : "")} ref={dropdownRef}>
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onClick={toggleDropdown}
      />
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <CaretDownIcon className={"caret-dropdown-icon" + (isOpen ? " icon-dropdown-open" : "")} />
      </button>
      
      {isOpen && (
        <div className="dropdown-content">
          <div className="select-all" onClick={toggleSelectAll}>
            <input
              type="checkbox"
              checked={selectedOptions?.length === options?.length}
              onChange={toggleSelectAll}
            />
            <span>Select All</span>
          </div>
          {options.map((option, index) => (
            <div key={index} className="checkbox-option">
              <input
                type="checkbox"
                value={option}
                checked={selectedOptions?.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FoldableCheckboxList;