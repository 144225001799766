import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import CreatePaymentMethodModal from './CreatePaymentMethodModal'
import SimpleButton from '../../common/SimpleButton'
import LoadingSpinner from "../../common/LoadingSpinner"
import ActionsMenu from '../../common/ActionsMenu'
import ConfirmationModal from '../../common/ConfirmationModal'
import EmptyPageResults from "../../common/EmptyPageResults"
import { toast } from "../../common/Toast"
import { VisaCardIcon, MasterCardIcon, EmailAtIcon, MenuKebabVerticalBackgroundIcon, MenuKebabVerticalIcon } from '../../../icons'
import { getPaymentMethods, postDefaultPaymentMethod, createSetupIntent, deletePaymentMethod } from "../../../utils/requests/paymentMethodsAPI"
import { useAppContext } from "../../../libs/contextLib"
import "./payment-methods.scss"

const PaymentMethods = (props) => {
  const { t } = useTranslation()
  const { stripe } = useAppContext()
  const [paymentMethods, setPaymentMethods] = useState([])
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState(null)
  const [showDeletePaymentMethodConfirmModal, setShowDeletePaymentMethodConfirmModal] = useState(false)
  const [methodIdToDelete, setMethodIdToDelete] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    setShowSpinner(true)

    getPaymentMethods()
      .then((response) => {
        if (response.length > 0) {
          const defaultPaymentMethod = response[0]
          setPaymentMethods([defaultPaymentMethod])
        }
        setIsFirstLoad(false)
        setShowSpinner(false)
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))

        setIsFirstLoad(false)
        setShowSpinner(false)
      })
  }, [])

  const getCardIcon = (brand) => {
    switch (brand?.toLowerCase()) {
      case "visa":
        return <VisaCardIcon />
      case "mastercard":
        return <MasterCardIcon />
      default:
        return null
    }
  }

  const getExpirationMonth = (month) => {
    if (month < 10) {
      return `0${month}`
    }

    return month
  }

  const handleGenerateSetupIntentClientSecret = () => {
    setShowSpinner(true)

    createSetupIntent()
      .then((response) => {
        setSetupIntentClientSecret(response)
        setShowSpinner(false)
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))

        setShowSpinner(false)
      })
  }

  // const handleSetDefaultPaymentMethod = (id) => {
  //   setShowActionsMenu(null)

  //   setShowSpinner(true)

  //   postDefaultPaymentMethod(id)
  //     .then(() => {
  //       const updatedPaymentMethods = paymentMethods.map((paymentMethod) => {
  //         paymentMethod.default = paymentMethod.id === id

  //         return paymentMethod
  //       })

  //       setPaymentMethods(updatedPaymentMethods)
        
  //       setShowSpinner(false)
  //     })
  //     .catch(() => {
  //       toast.error(t("error.something_wrong"))
  //       setShowSpinner(false)
  //     })
  // }

  const showConfirmationModal = (id) => {
    setShowActionsMenu(null)
    setMethodIdToDelete(id)
    setShowDeletePaymentMethodConfirmModal(true)
  }

  const handleDeletePaymentMethod = () => {
    setShowDeletePaymentMethodConfirmModal(false)
    
    setShowSpinner(true)

    deletePaymentMethod(methodIdToDelete)
      .then(() => {
        const remainingPaymentMethods = paymentMethods.filter((paymentMethod) => paymentMethod.id !== methodIdToDelete)

        setPaymentMethods(remainingPaymentMethods)
        
        setMethodIdToDelete(null)
        setShowSpinner(false)
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))

        setShowSpinner(false)
      })
  }

  return (
    <div className="payment-methods-wrapper d-flex flex-column">
      <p className="title">{t("account.my_payment_cards").toUpperCase()}</p>
      <div className="payment-methods-list">
        {paymentMethods.map((paymentMethod, index) =>
          <div
            key={index}
            className="payment-method"
          >
            <div className="card-data">
              <div className="brand">
                {getCardIcon(paymentMethod.brand)}
              </div>
              <div className="info">
                <div className="last-four">
                  <span>&#183;&#183;&#183;&#183;</span>
                  <span>{paymentMethod.last4}</span>
                </div>
                <div className="exp-date">
                  {`${t("account.expiry")} ${getExpirationMonth(paymentMethod.expirationMonth)}/${paymentMethod.expirationYear}`}
                </div>
              </div>
              <div 
                className={"actions with-margin-left"}
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon className="with-background" /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={[
                      {
                        label: t("account.change_card"),
                        onActionClick: (e) => { e.stopPropagation(); setMethodIdToDelete(paymentMethod.id); handleGenerateSetupIntentClientSecret()},
                      },
                      {
                        label: t("account.delete_card"),
                        onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(paymentMethod.id) },
                      }
                    ]}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>)
        }
      </div>
      {paymentMethods.length === 0 && !isFirstLoad &&
        <>
          <SimpleButton 
            className="action-button" 
            onClick={() => handleGenerateSetupIntentClientSecret()}
            disabled={showSpinner || !stripe}
          >
            {t("account.add_payment_method")}
          </SimpleButton>
          <EmptyPageResults 
            className="empty-list"
            title={t("account.no_payment_methods")}
          />
        </>
      }
      {setupIntentClientSecret && (
        <CreatePaymentMethodModal
          clientSecret={setupIntentClientSecret}
          paymenthMethodIdToDelete={methodIdToDelete}
          closeModal={() => setSetupIntentClientSecret(null)}
          showSpinner={() => setShowSpinner(true)}
          hideSpinner={() => setShowSpinner(false)}
        />
      )}
      {showDeletePaymentMethodConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeletePaymentMethodConfirmModal(false)}
          onAccept={() => handleDeletePaymentMethod()}
          onCancel={() => setShowDeletePaymentMethodConfirmModal(false)}
          message={t("account.confirm-delete-payment-method")}
          acceptButtonText={t("labels.delete")}
          isWarning
        />)
      }
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export function stateToProps({ authedUser }) {
  return {
    authedUser
  }
}

export default connect(stateToProps)(PaymentMethods)
