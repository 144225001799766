import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import logger from "./logger"
import { applyMiddleware, compose } from "redux"
import createLocalStoreMiddleware from "./local-store-middleware"
import resolvers from "../utils/local-store-resolvers"

const composeEnhancers =
  (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "prod" && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const localStore = createLocalStoreMiddleware(resolvers)

let middlewares = [thunk, localStore, promise]
if (process.env.REACT_APP_STAGE === "dev" || process.env.REACT_APP_DEBUG_MODE === "1") {
  middlewares.push(logger)
}

export default composeEnhancers(applyMiddleware(...middlewares))
