import {
  getCurrentCompany,
  getMembersByCompanyId,
  getRequestsByCompanyId,
  inviteUserToCompany,
  editMember,
  patchRequestStatus,
  deleteUserFromCompany,
  getMembersFilterOptions,  
} from "../utils/requests/companyAPI"

export const LOAD_MEMBERS = "LOAD_MEMBERS"
export const LOAD_MEMBERS_PENDING = "LOAD_MEMBERS_PENDING"
export const LOAD_MEMBERS_FULFILLED = "LOAD_MEMBERS_FULFILLED"
export const LOAD_MEMBERS_REJECTED = "LOAD_MEMBERS_REJECTED"

export const LOAD_NEW_MEMBERS = "LOAD_NEW_MEMBERS"
export const LOAD_NEW_MEMBERS_PENDING = "LOAD_NEW_MEMBERS_PENDING"
export const LOAD_NEW_MEMBERS_FULFILLED = "LOAD_NEW_MEMBERS_FULFILLED"
export const LOAD_NEW_MEMBERS_REJECTED = "LOAD_NEW_MEMBERS_REJECTED"

export const LOAD_REQUESTS = "LOAD_REQUESTS"
export const LOAD_REQUESTS_PENDING = "LOAD_REQUESTS_PENDING"
export const LOAD_REQUESTS_FULFILLED = "LOAD_REQUESTS_FULFILLED"
export const LOAD_REQUESTS_REJECTED = "LOAD_REQUESTS_REJECTED"

export const LOAD_NEW_REQUESTS = "LOAD_NEW_REQUESTS"
export const LOAD_NEW_REQUESTS_PENDING = "LOAD_NEW_REQUESTS_PENDING"
export const LOAD_NEW_REQUESTS_FULFILLED = "LOAD_NEW_REQUESTS_FULFILLED"
export const LOAD_NEW_REQUESTS_REJECTED = "LOAD_NEW_REQUESTS_REJECTED"

export const POST_MEMBER = "POST_MEMBER"
export const POST_MEMBER_PENDING = "POST_MEMBER_PENDING"
export const POST_MEMBER_FULFILLED = "POST_MEMBER_FULFILLED"
export const POST_MEMBER_REJECTED = "POST_MEMBER_REJECTED"

export const PUT_MEMBER = "PUT_MEMBER"
export const PUT_MEMBER_PENDING = "PUT_MEMBER_PENDING"
export const PUT_MEMBER_FULFILLED = "PUT_MEMBER_FULFILLED"
export const PUT_MEMBER_REJECTED = "PUT_MEMBER_REJECTED"

export const DELETE_MEMBER = "DELETE_MEMBER"
export const DELETE_MEMBER_PENDING = "DELETE_MEMBER_PENDING"
export const DELETE_MEMBER_FULFILLED = "DELETE_MEMBER_FULFILLED"
export const DELETE_MEMBER_REJECTED = "DELETE_MEMBER_REJECTED"

export const LOAD_CURRENT_COMPANY = "LOAD_CURRENT_COMPANY"
export const LOAD_CURRENT_COMPANY_PENDING = "LOAD_CURRENT_COMPANY_PENDING"
export const LOAD_CURRENT_COMPANY_FULFILLED = "LOAD_CURRENT_COMPANY_FULFILLED"
export const LOAD_CURRENT_COMPANY_REJECTED = "LOAD_CURRENT_COMPANY_REJECTED"

export const LOAD_MEMBERS_FILTER_OPTIONS = "LOAD_MEMBERS_FILTER_OPTIONS"
export const LOAD_MEMBERS_FILTER_OPTIONS_PENDING = "LOAD_MEMBERS_FILTER_OPTIONS_PENDING"
export const LOAD_MEMBERS_FILTER_OPTIONS_FULFILLED = "LOAD_MEMBERS_FILTER_OPTIONS_FULFILLED"
export const LOAD_MEMBERS_FILTER_OPTIONS_REJECTED = "LOAD_MEMBERS_FILTER_OPTIONS_REJECTED"

export const PATCH_REQUEST_STATUS = "PATCH_REQUEST_STATUS"
export const PATCH_REQUEST_STATUS_PENDING = "PATCH_REQUEST_STATUS_PENDING"
export const PATCH_REQUEST_STATUS_FULFILLED = "PATCH_REQUEST_STATUS_FULFILLED"
export const PATCH_REQUEST_STATUS_REJECTED = "PATCH_REQUEST_STATUS_REJECTED"

export const CLEAR_MEMBERS_MESSAGES = "CLEAR_MEMBERS_MESSAGES"

export function loadCurrentCompany() {
  return {
    type: LOAD_CURRENT_COMPANY,
    payload: getCurrentCompany()
  }
}

export function loadMembers(companyId, queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_MEMBERS : LOAD_MEMBERS,
    payload: getMembersByCompanyId(companyId, queryParams)
  }
}

export function loadRequests(companyId, queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_REQUESTS : LOAD_REQUESTS,
    payload: getRequestsByCompanyId(companyId, queryParams)
  }
}

export function inviteMember(companyId, bodyParams) {
  return {
    type: POST_MEMBER,
    payload: inviteUserToCompany(companyId, bodyParams),
    meta: { 
      companyId,
      branchId: bodyParams.branch,
      email: bodyParams.email,
    }
  }
}

export function putMember(memberId, userUuid, bodyParams) {
  return {
    type: PUT_MEMBER,
    payload: editMember(memberId, userUuid, bodyParams)
  }
}

export function changeRequestStatus(companyId, requestId, bodyParams) {
  return {
    type: PATCH_REQUEST_STATUS,
    payload: patchRequestStatus(companyId, requestId, bodyParams),
    meta: { requestId, action: bodyParams.action }
  }
}

export function removeMember(companyId, uuid, bodyParams) {
  return {
    type: DELETE_MEMBER,
    payload: deleteUserFromCompany(companyId, uuid, bodyParams),
    meta: { uuid }
  }
}

export function loadMembersFilterOptions(companyId) {
  return {
    type: LOAD_MEMBERS_FILTER_OPTIONS,
    payload: getMembersFilterOptions(companyId)
  }
}

export function clearMembersMessages() {
  return {
    type: CLEAR_MEMBERS_MESSAGES
  }
}

