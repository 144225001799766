import React, {useEffect, useState} from 'react'
import {ArrowLeftIcon, ArrowRightIcon} from "../../../icons";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import './pagination.scss'

const Pagination = (props) => {
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState([])
  const getPageNumber = () => {
    const pages = Math.ceil(props.total / props.perPage)
    let arr = []
    for (let i = 1; i <= pages; i++) {
      arr.push(i);
    }
    return arr
  }

  useEffect(() => {
    if (props.perPage && props.total && props.selected && !pageNumber.length) {
      props.setSelected(props.selected)
      setPageNumber(getPageNumber())
    }
    setPageNumber(getPageNumber())
  }, [props])

  const selectPage = (page) => {
    props.setSelected(page)
  }

  const nextPage = () => {
    if (props.selected !== pageNumber.length) {
      props.setSelected(props.selected + 1)
    }
  }

  const prevPage = () => {
    if (props.selected !== pageNumber[0]) {
      props.setSelected(props.selected - 1)
    }
  }


  return (
    <div className="Pagination-Wrapper d-flex flex-justify-center flex-align-center">
      <div className="page-index-wrapper d-flex flex-justify-center">
        {props.selected !== 1 &&
        <div className="prev-wrapper d-flex flex-align-center cursor-pointer" onClick={prevPage}>
          <ArrowLeftIcon/>
          <div>{t('prev')}</div>
        </div>
        }


        {pageNumber && pageNumber.length && pageNumber.map((page, index) => (
          ((page === 1 || page === pageNumber.length || page === props.selected) ?
            <div
              key={index}
              onClick={() => selectPage(page)}
              className={props.selected === page ? 'active' : 'cursor-pointer'}
            >
              {page}
            </div>
              :
           page === props.selected - 1 ?
             <div
               key={index}
               className="cursor-pointer"
               onClick={() => selectPage(page)}
             >
               {'...   ' + page}
             </div>
             :
             page === props.selected + 1 ?
              <div
                key={index}
                className="cursor-pointer"
                onClick={() => selectPage(page)}
              >
                {page + '   ...'}
              </div>
               :
               null
          )
        ))
        }
        {props.selected !== pageNumber.length &&
        <div className="next-wrapper d-flex flex-align-center cursor-pointer" onClick={nextPage}>
          <div>{t('next')}</div>
          <ArrowRightIcon/>
        </div>
        }
      </div>
    </div>
  )
}

Pagination.propTypes = {
  setSelected: PropTypes.func.isRequired,
  selected : PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired
}

export default Pagination
