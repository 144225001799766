import {
  getDealerBulletins,
  getDealerBulletinsFilterOptions
} from "../utils/requests/dealersAPI"

export const LOAD_BULLETINS = "LOAD_BULLETINS"
export const LOAD_BULLETINS_PENDING = "LOAD_BULLETINS_PENDING"
export const LOAD_BULLETINS_FULFILLED = "LOAD_BULLETINS_FULFILLED"
export const LOAD_BULLETINS_REJECTED = "LOAD_BULLETINS_REJECTED"

export const LOAD_NEW_BULLETINS = "LOAD_NEW_BULLETINS"
export const LOAD_NEW_BULLETINS_PENDING = "LOAD_NEW_BULLETINS_PENDING"
export const LOAD_NEW_BULLETINS_FULFILLED = "LOAD_NEW_BULLETINS_FULFILLED"
export const LOAD_NEW_BULLETINS_REJECTED = "LOAD_NEW_BULLETINS_REJECTED"

export const LOAD_BULLETINS_FILTERS = "LOAD_BULLETINS_FILTERS"
export const LOAD_BULLETINS_FILTERS_PENDING = "LOAD_BULLETINS_FILTERS_PENDING"
export const LOAD_BULLETINS_FILTERS_FULFILLED = "LOAD_BULLETINS_FILTERS_FULFILLED"
export const LOAD_BULLETINS_FILTERS_REJECTED = "LOAD_BULLETINS_FILTERS_REJECTED"

export const CLEAR_BULLETINS_MESSAGES = "CLEAR_BULLETINS_MESSAGES"

export function loadBulletins(queryParams, refetch = false, limit) {
  return {
    type: refetch ? LOAD_NEW_BULLETINS : LOAD_BULLETINS,
    payload: getDealerBulletins(queryParams, limit)
  }
}

export function loadBulletinsFilters() {
  return {
    type: LOAD_BULLETINS_FILTERS,
    payload: getDealerBulletinsFilterOptions()
  }
}

export function clearBulletinsMessages() {
  return {
    type: CLEAR_BULLETINS_MESSAGES
  }
}

