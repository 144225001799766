import { Wrapper, Status } from "@googlemaps/react-wrapper"
import LoadingSpinner from "../LoadingSpinner"

const GoogleMapWrapper = ({props, children}) => {
  
  const render = (status) => {
    switch (status) {
      case (status === Status.LOADING):
        return <LoadingSpinner />
      case (status === Status.FAILURE):
        return <div> {Status} </div>
      case (status === Status.SUCCESS):
        return <div>{children}</div>
      default:
        return <div>{children}</div>
    }
  }

  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render} />
  )
}

export default GoogleMapWrapper

// const map = () => {
//   return (
//     <GoogleMapWrapper>
//       <iframe
//         width="1166"
//         height="362"
//         frameborder="0" style="border:0"
//         src={`${config.googleMapPlaceURL}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q="659 pleasant ridge avenue, thornhill, ontario, canada"&zoom=14`}
//         allowfullscreen >
//       </iframe>
//     </GoogleMapWrapper>
//   )
// }