import {
  deleteBusinessRule,
  getBusinessRuleById,
  getBusinessRules,
  getBusinessRuleTypes,
  patchBusinessRule,
  postBusinessRule,
  putActivateBusinessRule,
  putDeactivateBusinessRule,
} from "../utils/requests/services&businessRulesAPI"

export const LOAD_BUSINESS_RULES = "LOAD_BUSINESS_RULES"
export const LOAD_BUSINESS_RULES_PENDING = "LOAD_BUSINESS_RULES_PENDING"
export const LOAD_BUSINESS_RULES_FULFILLED = "LOAD_BUSINESS_RULES_FULFILLED"
export const LOAD_BUSINESS_RULES_REJECTED = "LOAD_BUSINESS_RULES_REJECTED"

export const LOAD_NEW_BUSINESS_RULES = "LOAD_NEW_BUSINESS_RULES"
export const LOAD_NEW_BUSINESS_RULES_PENDING = "LOAD_NEW_BUSINESS_RULES_PENDING"
export const LOAD_NEW_BUSINESS_RULES_FULFILLED = "LOAD_NEW_BUSINESS_RULES_FULFILLED"
export const LOAD_NEW_BUSINESS_RULES_REJECTED = "LOAD_NEW_BUSINESS_RULES_REJECTED"

export const LOAD_BUSINESS_RULE_BY_ID = "LOAD_BUSINESS_RULE_BY_ID"
export const LOAD_BUSINESS_RULE_BY_ID_PENDING = "LOAD_BUSINESS_RULE_BY_ID_PENDING"
export const LOAD_BUSINESS_RULE_BY_ID_FULFILLED = "LOAD_BUSINESS_RULE_BY_ID_FULFILLED"
export const LOAD_BUSINESS_RULE_BY_ID_REJECTED = "LOAD_BUSINESS_RULE_BY_ID_REJECTED"

export const LOAD_BUSINESS_RULES_TYPES = "LOAD_BUSINESS_RULES_TYPES"
export const LOAD_BUSINESS_RULES_TYPES_PENDING = "LOAD_BUSINESS_RULES_TYPES_PENDING"
export const LOAD_BUSINESS_RULES_TYPES_FULFILLED = "LOAD_BUSINESS_RULES_TYPES_FULFILLED"
export const LOAD_BUSINESS_RULES_TYPES_REJECTED = "LOAD_BUSINESS_RULES_TYPES_REJECTED"

export const LOAD_DISCOUNTS = "LOAD_DISCOUNTS"
export const LOAD_DISCOUNTS_PENDING = "LOAD_DISCOUNTS_PENDING"
export const LOAD_DISCOUNTS_FULFILLED = "LOAD_DISCOUNTS_FULFILLED"
export const LOAD_DISCOUNTS_REJECTED = "LOAD_DISCOUNTS_REJECTED"

export const LOAD_TEST_DRIVES = "LOAD_TEST_DRIVES"
export const LOAD_TEST_DRIVES_PENDING = "LOAD_TEST_DRIVES_PENDING"
export const LOAD_TEST_DRIVES_FULFILLED = "LOAD_TEST_DRIVES_FULFILLED"
export const LOAD_TEST_DRIVES_REJECTED = "LOAD_TEST_DRIVES_REJECTED"

export const LOAD_TRIALS = "LOAD_TRIALS"
export const LOAD_TRIALS_PENDING = "LOAD_TRIALS_PENDING"
export const LOAD_TRIALS_FULFILLED = "LOAD_TRIALS_FULFILLED"
export const LOAD_TRIALS_REJECTED = "LOAD_TRIALS_REJECTED"

export const POST_BUSINESS_RULE = "POST_BUSINESS_RULE"
export const POST_BUSINESS_RULE_PENDING = "POST_BUSINESS_RULE_PENDING"
export const POST_BUSINESS_RULE_FULFILLED = "POST_BUSINESS_RULE_FULFILLED"
export const POST_BUSINESS_RULE_REJECTED = "POST_BUSINESS_RULE_REJECTED"

export const PUT_BUSINESS_RULE = "PUT_BUSINESS_RULE"
export const PUT_BUSINESS_RULE_PENDING = "PUT_BUSINESS_RULE_PENDING"
export const PUT_BUSINESS_RULE_FULFILLED = "PUT_BUSINESS_RULE_FULFILLED"
export const PUT_BUSINESS_RULE_REJECTED = "PUT_BUSINESS_RULE_REJECTED"

export const PUT_BUSINESS_RULE_STATUS = "PUT_BUSINESS_RULE_STATUS"
export const PUT_BUSINESS_RULE_STATUS_PENDING = "PUT_BUSINESS_RULE_STATUS_PENDING"
export const PUT_BUSINESS_RULE_STATUS_FULFILLED = "PUT_BUSINESS_RULE_STATUS_FULFILLED"
export const PUT_BUSINESS_RULE_STATUS_REJECTED = "PUT_BUSINESS_RULE_STATUS_REJECTED"

export const DELETE_BUSINESS_RULE = "DELETE_BUSINESS_RULE"
export const DELETE_BUSINESS_RULE_PENDING = "DELETE_BUSINESS_RULE_PENDING"
export const DELETE_BUSINESS_RULE_FULFILLED = "DELETE_BUSINESS_RULE_FULFILLED"
export const DELETE_BUSINESS_RULE_REJECTED = "DELETE_BUSINESS_RULE_REJECTED"

export const CLEAR_BUSINESS_RULES_MESSAGES = "CLEAR_BUSINESS_RULES_MESSAGES"
export const CLEAR_BUSINESS_RULES = "CLEAR_BUSINESS_RULES"

export function loadBusinessRules(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_BUSINESS_RULES : LOAD_BUSINESS_RULES,
    payload: getBusinessRules(queryParams),
  }
}

export function loadBusinessRuleById(id) {
  return {
    type: LOAD_BUSINESS_RULE_BY_ID,
    payload: getBusinessRuleById(id),
  }
}

export function loadBusinessRuleTypes() {
  return {
    type: LOAD_BUSINESS_RULES_TYPES,
    payload: getBusinessRuleTypes(),
  }
}

export function loadDiscounts(queryParams, withoutLimit = false) {
  return {
    type: LOAD_DISCOUNTS,
    payload: getBusinessRules({ ...queryParams, type: "discount" }, withoutLimit),
  }
}

export function loadTestDrives(queryParams, withoutLimit = false) {
  return {
    type: LOAD_TEST_DRIVES,
    payload: getBusinessRules({ ...queryParams, type: "test_drive" }, withoutLimit),
  }
}

export function loadTrials(queryParams, withoutLimit = false) {
  return {
    type: LOAD_TRIALS,
    payload: getBusinessRules({ ...queryParams, type: "trial" }, withoutLimit),
  }
}

export function createBusinessRule(bodyParams) {
  return {
    type: POST_BUSINESS_RULE,
    payload: postBusinessRule(bodyParams),
  }
}

export function updateBusinessRule(id, bodyParams) {
  return {
    type: PUT_BUSINESS_RULE,
    payload: patchBusinessRule(id, bodyParams),
  }
}

export function changeBusinessRuleStatus(id, operation) {
  return {
    type: PUT_BUSINESS_RULE_STATUS,
    payload: operation === "enable" ? putActivateBusinessRule(id) : putDeactivateBusinessRule(id),
  }
}

export function delBusinessRule(id) {
  return {
    type: DELETE_BUSINESS_RULE,
    payload: deleteBusinessRule(id),
    meta: { id: id },
  }
}

export function clearBusinessRulesMessages() {
  return {
    type: CLEAR_BUSINESS_RULES_MESSAGES,
  }
}
