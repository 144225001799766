export const SET_ONLINE_ACCESS = 'SET_ONLINE_ACCESS'

export function setOnlineAccess(querystring) {
  return {
    type: SET_ONLINE_ACCESS,
    querystring
  }
}

export function handleSetOnlineAccess(params) {
  return (dispatch) => {
    return dispatch(setOnlineAccess(params))
  }
}
