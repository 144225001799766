import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import CheckboxInput from "../common/CheckboxInput"
import ExpandableText from "../common/ExpandableText"
import { toast } from "../common/Toast"
import { CaretDownIcon } from "../../icons"
import { clearPriceListsMessages, loadDiscontinuedPriceLists, loadPriceListFilters } from "../../actions/priceLists"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import { downloadPricelists } from "../../utils/requests/dealersAPI"
import { convertToSimpleDateWithFullTime } from "../../utils/filters/date"
import { downloadFile } from "../../utils/functions"

const orderingFields = [
  {
    field: "partNo",
    order: "asc",
    key: "partNo_asc",
  },
  {
    field: "partNo",
    order: "desc",
    key: "partNo_desc",
  },
  {
    field: "description",
    order: "asc",
    key: "description_asc",
  },
  {
    field: "description",
    order: "desc",
    key: "description_desc",
  },
  {
    field: "segment",
    order: "asc",
    key: "segment_asc",
  },
  {
    field: "segment",
    order: "desc",
    key: "segment_desc",
  },
  {
    field: "productGroup",
    order: "asc",
    key: "productGroup_asc",
  },
  {
    field: "productGroup",
    order: "desc",
    key: "productGroup_desc",
  },
  {
    field: "productLine",
    order: "asc",
    key: "productLine_asc",
  },
  {
    field: "productLine",
    order: "desc",
    key: "productLine_desc",
  },
  {
    field: "brand",
    order: "asc",
    key: "brand_asc",
  },
  {
    field: "brand",
    order: "desc",
    key: "brand_desc",
  },
  {
    field: "trademark",
    order: "asc",
    key: "trademark_asc",
  },
  {
    field: "trademark",
    order: "desc",
    key: "trademark_desc",
  },
  {
    field: "manufacturer",
    order: "asc",
    key: "manufacturer_asc",
  },
  {
    field: "manufacturer",
    order: "desc",
    key: "manufacturer_desc",
  },
  {
    field: "shipperQuantity",
    order: "asc",
    key: "shipperQuantity_asc",
  },
  {
    field: "shipperQuantity",
    order: "desc",
    key: "shipperQuantity_desc",
  },
  {
    field: "rrp",
    order: "asc",
    key: "rrp_asc",
  },
  {
    field: "rrp",
    order: "desc",
    key: "rrp_desc",
  },
  {
    field: "myPrice",
    order: "asc",
    key: "myPrice_asc",
  },
  {
    field: "myPrice",
    order: "desc",
    key: "myPrice_desc",
  },
  {
    field: "rrpCurrency",
    order: "asc",
    key: "rrpCurrency_asc",
  },
  {
    field: "rrpCurrency",
    order: "desc",
    key: "rrpCurrency_desc",
  },
  {
    field: "priceListType",
    order: "asc",
    key: "priceListType_asc",
  },
  {
    field: "priceListType",
    order: "desc",
    key: "priceListType_desc",
  },
  {
    field: "market",
    order: "asc",
    key: "market_asc",
  },
  {
    field: "market",
    order: "desc",
    key: "market_desc",
  },
  {
    field: "updatedAt",
    order: "asc",
    key: "updatedAt_asc",
  },
  {
    field: "updatedAt",
    order: "desc",
    key: "updatedAt_desc",
  },
]

const Discontinued = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [itemsTableData, setItemsTableData] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [segmentFilters, setSegmentFilters] = useState([])
  const [productGroupFilters, setProductGroupFilters] = useState([])
  const [productLineFilters, setProductLineFilters] = useState([])
  const [trademarkFilters, setTrademarkFilters] = useState([])
  // const [sellingEntityFilters, setSellingEntityFilters] = useState([])
  const [searchParam, setSearchParam] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [selectedSegmentFilter, setSelectedSegmentFilter] = useState(null)
  const [selectedProductGroupFilter, setSelectedProductGroupFilter] = useState(null)
  const [selectedProductLineFilter, setSelectedProductLineFilter] = useState(null)
  const [selectedTrademarkFilter, setSelectedTrademarkFilter] = useState(null)
  // const [selectedSellingEntityFilter, setSelectedSellingEntityFilter] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedItem, setExpandedItem] = useState(0)

  useEffect(() => {
    if (Object.keys(props.priceListFilters).length === 0) {
      props.actions.loadPriceListFilters()
    }    
  }, [props.actions])

  useEffect(() => {
    if (Object.keys(props.priceListFilters).length > 0) {
      setSegmentFilters(props.priceListFilters.segment || [])
      setProductGroupFilters(props.priceListFilters.productGroup || [])
      setProductLineFilters(props.priceListFilters.productLine || [])
      setTrademarkFilters(props.priceListFilters.trademark || [])
      // setSellingEntityFilters(props.priceListFilters.sellingEntity || [])
    }
  }, [props.priceListFilters])

  useEffect(() => {
    props.setSelectedReviewItems(selectedItems)
  }, [selectedItems])

  useEffect(() => {
    if (props.clearSelectedItems) {
      setSelectedItems([])
    }
  }, [props.clearSelectedItems])

  useEffect(() => {
    const tableData = props.priceLists.map((item) => {
      return {
        identifier: item.id,
        partNo: item.partNo,
        description: item.description,
        segment: item.segment,
        productGroup: item.productGroup,
        productLine: item.productLine,
        brand: item.brand,
        trademark: item.trademark,
        // sellingEntity: item.manufacturer,
        // shipper: item.shipperQuantity,
        rrp: item.rrp,
        myPrice: item.myPrice,
        rrpCurrency: item.rrpCurrency,
        // priceListType: item.priceListType,
        market: item.market,
        date: convertToSimpleDateWithFullTime(item.updatedAt),
      }
    })

    setItemsTableData(tableData)
  }, [props.priceLists])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearPriceListsMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearPriceListsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadPriceLists(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadPriceLists(true)
        }

        setShouldApplyFilters(true)
      },
      searchParam ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [searchParam, selectedSegmentFilter, selectedProductGroupFilter, selectedProductLineFilter, selectedTrademarkFilter, orderBy])

  const handleLoadPriceLists = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    props.actions.loadDiscontinuedPriceLists(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParam(value)
    } else if (value?.length === 0 && searchParam?.length > 0) {
      setShowSpinner(true)
      setSearchParam(value)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedSegmentFilter(null)
    setSelectedProductGroupFilter(null)
    setSelectedProductLineFilter(null)
    setSelectedTrademarkFilter(null)
    // setSelectedSellingEntityFilter(null)
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParam) {
      queryParams = { ...queryParams, query: searchParam }
    }

    if (selectedSegmentFilter) {
      queryParams = { ...queryParams, segment: selectedSegmentFilter }
    }

    if (selectedProductGroupFilter) {
      queryParams = { ...queryParams, productGroup: selectedProductGroupFilter }
    }

    if (selectedProductLineFilter) {
      queryParams = { ...queryParams, productLine: selectedProductLineFilter }
    }

    if (selectedTrademarkFilter) {
      queryParams = { ...queryParams, trademark: selectedTrademarkFilter }
    }

    // if (selectedSellingEntityFilter) {
    //   queryParams = { ...queryParams, sellingEntity: selectedSellingEntityFilter }
    // }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`dealer-portal.${orderBy.key}`) : t("events.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`dealer-portal.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const exportToCsv = () => {
    let queryParams = createQueryParams()

    queryParams = { ...queryParams, status: "Discontinued" }

    if (selectedItems.length > 0) {
      queryParams = { ...queryParams, id: selectedItems.map(item => item.identifier) }
    }

    setShowSpinner(true)

    downloadPricelists(queryParams)
      .then((res) => {
        const date = new Date().toISOString().split('T')
        const dateNow = date[0].split('-').reverse().join('_')
        const timeNow = date[1].split('.')[0].split(':').join('_')

        downloadFile({
          data: res,
          fileName: `price-lists_${dateNow}_${timeNow}.csv`,
          fileType: 'text/csv',
        })

        setShowSpinner(false)

        toast.success(t("dealer-portal.download_success"))

        setSelectedItems([])
      })
      .catch(() => {
        setShowSpinner(false)

        toast.error(t("error.failure_msg"))
      })
  }

  const handleSelectItem = (item) => {
    setSelectedItems((prevValue) => { 
      if (prevValue.includes(item)) {
        return prevValue.filter((value) => value !== item)
      }

      return [...prevValue, item]
    })
  }

  const handleSelectAllItems = () => {
    if (selectedItems.length === itemsTableData.length) {
      setSelectedItems([])
      return
    } 

    setSelectedItems(itemsTableData)
  }

  return (
    <Fragment>
      <div className="discontinued-items-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={selectedSegmentFilter || selectedProductGroupFilter || selectedProductLineFilter || selectedTrademarkFilter }
            counterBadge={(selectedSegmentFilter && 1) + (selectedProductGroupFilter && 1) + (selectedProductLineFilter && 1) + (selectedTrademarkFilter && 1)}
          />
          <SimpleButton 
            className="action-button" 
            onClick={() => exportToCsv()}
            disabled={itemsTableData.length === 0 || showSpinner}
          >
            {selectedItems.length === 0 ? t("download.download") : t("download.download_selected")}
          </SimpleButton>
        </div>
        {isMobile && itemsTableData.length > 0 &&
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        }
        {!isMobile && itemsTableData.length > 0 && 
          <GenericTable
            data={itemsTableData}
            headers={[
              {
                title: "",
                isMultiSelect: true,
              },
              {
                title: t("dealer-portal.partNo"),
                orderKey: "partNo",
              },
              {
                title: t("dealer-portal.description"),
                orderKey: "description",
              },
              {
                title: t("dealer-portal.segment"),
                orderKey: "segment",
              },
              {
                title: t("dealer-portal.productGroup"),
                orderKey: "productGroup",
              },
              {
                title: t("dealer-portal.productLine"),
                orderKey: "productLine",
              },
              {
                title: t("dealer-portal.brand"),
                orderKey: "brand",
              },
              {
                title: t("dealer-portal.trademark"),
                orderKey: "trademark",
              },
              // {
              //   title: t("dealer-portal.sellingEntity"),
              //   orderKey: "manufacturer",
              // },
              // {
              //   title: t("dealer-portal.shipper"),
              //   orderKey: "shipperQuantity",
              // },
              {
                title: t("dealer-portal.rrp"),
                orderKey: "rrp",
              },
              {
                title: t("dealer-portal.myPrice"),
                orderKey: "myPrice",
              },
              {
                title: t("dealer-portal.rrpCurrency"),
                orderKey: "rrpCurrency",
              },
              // {
              //   title: t("dealer-portal.priceListType"),
              //   orderKey: "priceListType",
              // },
              {
                title: t("dealer-portal.market"),
                orderKey: "market",
              },
              {
                title: t("dealer-portal.date"),
                orderKey: "updatedAt",
              },
            ]}
            keys={[
              "selectable",
              "partNo",
              "description",
              "segment",
              "productGroup",
              "productLine",
              "brand",
              "trademark",
              // "sellingEntity",
              // "shipper",
              "rrp",
              "myPrice",
              "rrpCurrency",
              // "priceListType",
              "market",
              "date",
            ]}
            keyRenderer={{
              selectable: (item) => {
                return (
                  <div className="selectable-wrapper">
                    <CheckboxInput 
                      checked={selectedItems.includes(item)}
                      onChange={(e) => { e.stopPropagation(); handleSelectItem(item) }}
                    />
                  </div>
                )
              },
              description: (item) => {
                return (
                  <div className="description-wrapper">
                    <ExpandableText text={item.description} />
                  </div>
                )
              },
              date: (item) => {
                return (
                  <ExpandableText text={item.date} />
                )
              },
            }}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            hasSelectedAll={selectedItems.length === itemsTableData.length}
            onSelectAll={() => handleSelectAllItems()}
            isLoading={showSpinner}
          />
        }
        {isMobile && itemsTableData.map((item, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedItem(index)}
          >
            <div className={"card-item" + (expandedItem !== index ? " align-center" : "")}>
              <div className="card-item-title">{t("dealer-portal.partNo")}</div>
              <div className={"card-item-body" + (expandedItem !== index ? " align-center" : "")}>
                <div>{item.partNo}</div>
                <div className="card-actions">
                  <div className="selectable-wrapper">
                    <CheckboxInput 
                      checked={selectedItems.includes(item)}
                      onChange={(e) => { e.stopPropagation(); handleSelectItem(item) }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {expandedItem === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.description")}</div>
                  <div className="card-item-body">{item.description}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.segment")}</div>
                  <div className="card-item-body">{item.segment}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.productGroup")}</div>
                  <div className="card-item-body">{item.productGroup}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.productLine")}</div>
                  <div className="card-item-body">{item.productLine}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.brand")}</div>
                  <div className="card-item-body">{item.productLine}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.trademark")}</div>
                  <div className="card-item-body">{item.trademark}</div>
                </div>
                {/* <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.sellingEntity")}</div>
                  <div className="card-item-body">{item.sellingEntity}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.shipper")}</div>
                  <div className="card-item-body">{item.shipper}</div>
                </div> */}
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.rrp")}</div>
                  <div className="card-item-body">{item.rrp}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.myPrice")}</div>
                  <div className="card-item-body">{item.myPrice}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.rrpCurrency")}</div>
                  <div className="card-item-body">{item.rrpCurrency}</div>
                </div>
                {/* <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.priceListType")}</div>
                  <div className="card-item-body">{item.priceListType}</div>
                </div> */}
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.market")}</div>
                  <div className="card-item-body">{item.market}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.date")}</div>
                  <div className="card-item-body">{item.date}</div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && itemsTableData.length === 0 && (
          <EmptyPageResults
            title={t("dealer-portal.no-pricelists-found")}
            subtitle={t("dealer-portal.no-pricelists-found-extra")}
          />
        )}
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t("buttons.load_more")}
            </SimpleButton>
          </div>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("dealer-portal.segment"),
              type: FilterType.singleSelect,
              dataset: segmentFilters,
              input: selectedSegmentFilter,
              output: (selectedSegmentFilter) => {
                setSelectedSegmentFilter(selectedSegmentFilter)
              },
            },
            {
              name: t("dealer-portal.productGroup"),
              type: FilterType.singleSelect,
              dataset: productGroupFilters,
              input: selectedProductGroupFilter,
              output: (selectedProductGroupFilter) => {
                setSelectedProductGroupFilter(selectedProductGroupFilter)
              },
            },
            {
              name: t("dealer-portal.productLine"),
              type: FilterType.singleSelect,
              dataset: productLineFilters,
              input: selectedProductLineFilter,
              output: (selectedProductLineFilter) => {
                setSelectedProductLineFilter(selectedProductLineFilter)
              },
            },
            {
              name: t("dealer-portal.trademark"),
              type: FilterType.singleSelect,
              dataset: trademarkFilters,
              input: selectedTrademarkFilter,
              output: (selectedTrademarkFilter) => {
                setSelectedTrademarkFilter(selectedTrademarkFilter)
              },
            },
            // {
            //   name: t("dealer-portal.sellingEntity"),
            //   type: FilterType.singleSelect,
            //   dataset: sellingEntityFilters,
            //   input: selectedSellingEntityFilter,
            //   output: (selectSellingEntityFilter) => {
            //     setSelectedSellingEntityFilter(selectSellingEntityFilter)
            //   },
            // },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </Fragment>
  )
}

function stateToProps({ priceLists }) {
  return {
    priceLists: priceLists?.discontinuedPriceLists || [],
    priceListFilters: priceLists?.priceListFilters || {},
    isLoading: priceLists?.isLoading,
    canLoadMore: priceLists?.canLoadMoreDiscontinued,
    successMessage: priceLists?.successMessage,
    errorMessage: priceLists?.errorMessage,
    clearSelectedItems: priceLists?.clearSelectedItems,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadDiscontinuedPriceLists,
        loadPriceListFilters,
        clearPriceListsMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Discontinued)
