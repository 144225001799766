import { getProblemType } from "../utils/requests/dealersAPI"

export const LOAD_PROBLEM_TYPES = "LOAD_PROBLEM_TYPES"
export const LOAD_PROBLEM_TYPES_PENDING = "LOAD_PROBLEM_TYPES_PENDING"
export const LOAD_PROBLEM_TYPES_FULFILLED = "LOAD_PROBLEM_TYPES_FULFILLED"
export const LOAD_PROBLEM_TYPES_REJECTED = "LOAD_PROBLEM_TYPES_REJECTED"

export function loadProblemTypes() {
  return {
    type: LOAD_PROBLEM_TYPES,
    payload: getProblemType(),
  }
}
