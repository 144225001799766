import React, { useState, useEffect, useMemo } from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { Auth } from "aws-amplify"
import LoadingSpinner from "../../common/LoadingSpinner"
import { hasDealerSiteAccess, hasNoSiteAccess } from "../../../utils/permissionValidation"
import { handleClearBackNavigationState } from "../../../actions/backNavigationState"

const PrivateRoute = ({ component: Component, authedUser, user, ...rest }) => {
  const [accessAllowed, setAccessAllowed] = useState(false)
  const [isLoadingWhiteListAccess, setIsLoadingWhiteListAccess] = useState(true)
  const [isWhiteList, setIsWhiteList] = useState(false)
  const permissionsLoaded = rest.userPermissions?.length

  useMemo(() => {
    let accessAllowed = false

    const permissionArr = rest.userPermissions ? (Array.isArray(rest.userPermissions) ? rest.userPermissions : Object.values(rest.userPermissions)) : []
    const permission = rest.permission && rest.permission?.toLowerCase()

    if (permission) {
      accessAllowed = permissionArr.includes(permission)
    }

    setAccessAllowed(accessAllowed)
  }, [rest.userPermissions, rest.permission, rest.roles])

  useEffect(() => {
    let intervalId
    let done = false
    if (hasDealerSiteAccess(rest.userPermissions)) {
      function validateWhiteList() {
        if (!done) {
          Auth.currentUserInfo()
            .then((res) => {
              if (res) {
                if (user.email) {
                  const noAccess = hasNoSiteAccess(rest.userPermissions)
                  if (noAccess) {
                    setIsWhiteList(false)
                  } else {
                    done = true
                    setIsWhiteList(true)
                  }
                  setIsLoadingWhiteListAccess(false)
                }
              }
            })
            .catch(() => setIsLoadingWhiteListAccess(false))
        }
      }
      validateWhiteList()

      intervalId = setInterval(() => {
        validateWhiteList()
      }, 30000)
    } else {
      setIsLoadingWhiteListAccess(false)
    }

    return () => clearInterval(intervalId)
  }, [user.email, user.cognitoSub, rest.userPermissions])

  const RoutesComponent = (props) => {
    const shouldClearBackNavigationState = rest.backNavigationState && 
      !props.location?.state?.withBackNavigation && 
      rest.backNavigationState.backNavPage !== props.location.pathname

    if (!authedUser || authedUser === null) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    } else if (authedUser && !accessAllowed && permissionsLoaded !== 0) {
      return rest.path === "/page-not-found" ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/page-not-found",
            state: { from: props.location },
          }}
        />
      )
    } else if (!hasDealerSiteAccess(rest.userPermissions)) {      
      if (shouldClearBackNavigationState) {
        const { dispatch } = rest
        dispatch(handleClearBackNavigationState())
      }

      return <Component {...props} {...rest} />
    } else if (isWhiteList) {
      if (shouldClearBackNavigationState) {
        const { dispatch } = rest
        dispatch(handleClearBackNavigationState())
      }

      return <Component {...props} {...rest} />
    } else {
      if (rest.path !== "/") {
        return (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
      return <Component {...props} {...rest} />
    }
  }

  if (isLoadingWhiteListAccess) {
    return <LoadingSpinner />
  }

  return <Route {...rest} render={RoutesComponent} />
}

function mapStateToProps({ backNavigationState }) {
  return {
    backNavigationState
  }
}

export default connect(mapStateToProps)(PrivateRoute)
