import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import "./click-outside.scss"
import { keyboardEvents } from "../../../utils/keyboardEvents"

const ClickOutside = (props) => {
  const { itemRef } = props
  const [isComponentVisible, setIsComponentVisible] = useState(false)
  const ref = useRef(itemRef)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleVisibility(false)
    }
  }

  const toggleVisibility = (value) => {
    setIsComponentVisible(value)
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return (
    <div
      ref={ref}
      className={`click-outside-wrapper ${props.className || ""}`}
      tabIndex={0}
      role="button"
      onKeyDown={(event) => event.key === keyboardEvents.ENTER && toggleVisibility(!isComponentVisible)}
    >
      {isComponentVisible && (
        <div className="input-dropdown-container">
          {props.eventItem({ toggleVisibility, isComponentVisible })}
          {props.toDisplayItem({ toggleVisibility })}
        </div>
      )}
      {!isComponentVisible && <div>{props.eventItem({ toggleVisibility })}</div>}
    </div>
  )
}

ClickOutside.propTypes = {
  itemRef: PropTypes.string.isRequired,
  eventItem: PropTypes.func.isRequired,
  toDisplayItem: PropTypes.func.isRequired,
}

export default ClickOutside
