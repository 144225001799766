import React from "react"
import { useTranslation } from "react-i18next"
import { ColtenePlaceholderProduct } from "../../../icons"
import { formatStoreProductPrice } from "../../../utils/filters/filters"

const OrderDetailsItem = (props) => {
  const { t } = useTranslation()
  const { item } = props

  return (
    <div className="order-details-item-wrapper d-flex flex-row">
      {item && (
        <div className="item-details d-flex">
          <div className="details-l">
            {item.product?.images[0]?.url ? <img src={item.product?.images[0].url} alt="product" /> : <ColtenePlaceholderProduct />}
          </div>
          <div className="details-r">
            <div className="item-name">{item.product?.title}</div>
            <div className="item-subtitle">{item.product?.subtitle.toUpperCase()}</div>
            {!item.priceRecurrenceInterval && <div className="item-unit-price">{formatStoreProductPrice(item.product?.price || 0, item.paidCurrency)}</div>}
            {item.priceRecurrenceInterval && (
              <>
                <div className="item-subscription-interval item-unit-price">
                  {formatStoreProductPrice(item.product?.price, item.paidCurrency)}/{item.priceRecurrenceInterval.slice(0, 2).toUpperCase()}
                </div>
                <div className="item-subscription-interval">
                  {t([`coltene_store.create_product.${item.priceRecurrenceInterval}_plan`, item.priceRecurrenceInterval])}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default OrderDetailsItem
