import React, { useState, useEffect, Fragment } from "react"
import { useTranslation } from "react-i18next"
import SimpleButton from '../common/SimpleButton'
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import { toast } from "../common/Toast"
import ConfirmationModal from "../common/ConfirmationModal"
import { CloseSimpleIcon, ExpandLessIcon, ExpandMoreIcon, SuccessTick } from "../../icons"
import { deleteOnlineAccessAssociation, deleteProduct, getOnlineAccessAssociations, putOnlineAccessAssociation } from "../../utils/requests/productsAPI"
import { productsWritePermission, productsDeletePermission } from "../../utils/permissionValidation"
import "./online-access.scss"

const OnlineAccess = (props) => {
  const { t } = useTranslation()
  const { serialNumber, email } = props
  const [associations, setAssociations] = useState([])
  const [expandedAssociation, setExpandedAssociation] = useState(0)
  const [selectedUserUuid, setSelectedUserUuid] = useState(null)
  const [showRemoveProductConfirmationModal, setShowRemoveProductConfirmationModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (serialNumber) {
      setShowSpinner(true)

      getAssociations(true)

      return
    }

    setIsFirstLoad(false)
  },[serialNumber])

  const getAssociations = (isFirstLoad) => {
    getOnlineAccessAssociations({ sn: serialNumber })
      .then((data) => {
        data.sort((a, b) => (a.association_active < b.association_active) ? 1 : -1)

        if (isFirstLoad && data.length > 0) {
          setExpandedAssociation(data[0].customer_email)
        }
        
        setAssociations(data)

        setShowSpinner(false)
        setIsFirstLoad(false)
      })
      .catch(() => {
        toast.error(t("error.failure_msg"))

        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }

  const handleChangeAssociation = (targetUserEmail, associate) => {
    setShowSpinner(true)

    const changeAssociationRequestBody = {
      serial_number: serialNumber,
      email: targetUserEmail,
      action_by: email,
    }
    
    let changeAssociationPromise

    if (associate) {
      changeAssociationPromise = putOnlineAccessAssociation(changeAssociationRequestBody)
    } else {
      changeAssociationPromise = deleteOnlineAccessAssociation(changeAssociationRequestBody)
    }

    changeAssociationPromise
      .then(() => {
        getAssociations()
      })
      .catch(() => {
        toast.error(t("error.failure_msg"))

        setShowSpinner(false)
      })
  }

  const handleDeleteProduct = () => {
    setShowSpinner(true)

    deleteProduct(selectedUserUuid, serialNumber)
      .then(() => {
        const filteredAssociations = associations.filter((association) => association.user_uuid !== selectedUserUuid)
        setAssociations(filteredAssociations)

        toast.success(t("product.unit.remove_success"))
      })
      .catch(() => {
        toast.error(t("error.failure_msg"))
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }

  const toggleExpandAssociation = (customerEmail) => {
    setExpandedAssociation(customerEmail === expandedAssociation ? null : customerEmail)
  }

  return (
    <div className="online-access-wrapper">
      {associations.map((association, index) =>
        <Fragment key={`association-${index}`}>
          <div
            className={`association-expand-header d-flex flex-justify-between flex-align-center ${association.customer_email === expandedAssociation ? "highlight" : "border-bottom"}`}
            onClick={() => toggleExpandAssociation(association.customer_email)}
          >
            <div className="title">
              <div>{association.customer_email}</div>
            </div>
            <div className="icon">{expandedAssociation === association.customer_email ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
          </div>
          {expandedAssociation === association.customer_email &&
            <div className="association-expand-body">
              <div className="data">
                <div className="label">{t("product.unit.warranty_registered")}: </div>
                <div className="label-data">{association.warranty_registered ? <SuccessTick /> : <CloseSimpleIcon className="close-icon"/>}</div>
              </div>
              <div className="data">
                <div className="label">{t("product.unit.email_sent_by_unit")}: </div>
                <div className="label-data">{association.email_sent_by_unit ? <SuccessTick /> : <CloseSimpleIcon className="close-icon"/>}</div>
              </div>
              <div className="data">
                <div className="label">{t("product.unit.log_in_succesfull")}: </div>
                <div className="label-data">{association.email_link_validation  ? <SuccessTick /> : <CloseSimpleIcon className="close-icon"/>}</div>
              </div>
              <div className="data">
                <div className="label">{t("product.unit.allow_button_clicked_on_unit")}: </div>
                <div className="label-data">{association.association_active ? <SuccessTick /> : <CloseSimpleIcon className="close-icon"/>}</div>
              </div>
              <div className="data">
                <div className="label">{t("product.unit.association_status")}: </div>
                <div className="label-data">{association.association_active ? <SuccessTick /> : <CloseSimpleIcon className="close-icon"/>}</div>
              </div>
              <div className="data">
                <div className="label">{t("product.unit.associated_to")}: </div>
                <div className="label-data">{association.current_associated_to || t("labels.n_a")}</div>
              </div>
              <div className="data">
                <div className="label">{association.association_active ? t("product.unit.associated_by") : t("product.unit.dissociated_by")}: </div>
                <div className="label-data">{association.association_active ? (association.associated_by || t("labels.n_a")) : (association.disassociated_by || t("labels.n_a"))}</div>
              </div>
              <div className="data">
                <div className="label button-label"/>
                <div className="label-data button-label-data">
                  <SimpleButton 
                    className={(association.association_active ? "cancel-button" : "action-button" ) + " association-button"}
                    onClick={() => handleChangeAssociation(association.customer_email, !association.association_active)}
                    disabled={showSpinner || (!association.association_active && associations.find((association) => association.association_active))}
                    requiredPermission={productsWritePermission}
                  >
                    {association.association_active ? t("product.unit.dissociate") : t("product.unit.associate")}
                  </SimpleButton>
                  {/*A.S: Button hidden until further indiciations */}
                  {false && !association.association_active && (
                    <SimpleButton 
                      className={"submit-button remove-button"}
                      onClick={() => { setSelectedUserUuid(association.user_uuid); setShowRemoveProductConfirmationModal(true) }}
                      disabled={showSpinner}
                      requiredPermission={productsDeletePermission}
                    >
                      {t("product.unit.remove")}
                    </SimpleButton>)
                  }
                </div>
              </div>
            </div>
          }
        </Fragment>)
      }
      {!isFirstLoad && associations.length === 0 &&
        <EmptyPageResults className="empty-list" title={t("no_data_available")}/>
      }
      {showRemoveProductConfirmationModal && (
        <ConfirmationModal
          onToggle={() => setShowRemoveProductConfirmationModal(false)}
          message={t("product.unit.remove_product_confirmation")}
          onCancel={() => setShowRemoveProductConfirmationModal(false)}
          onAccept={() => {
            handleDeleteProduct()
            setShowRemoveProductConfirmationModal(false)
          }}
          requiredPermission={productsDeletePermission}
          isWarning
        />
      )}
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default OnlineAccess