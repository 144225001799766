import React from "react"
import { useTranslation } from "react-i18next"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import { BoxTabs, Tab } from "../common/Tabs"
import Policies from "./policies";
import PolicyTypes from "./policyTypes";
import "./policies.scss"

function PoliciesPage () {
  const { t } = useTranslation()

  return (
    <div className="policy-page-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.policies")} />
      <BoxTabs className="underlined-box-tab">
        <Tab index={0} label={t("nav.policies")}>
          <Policies />
        </Tab>
        <Tab index={1} label={t("policies.policy-type")}>
          <PolicyTypes />
        </Tab>
      </BoxTabs>
    </div>
  );
}

export default PoliciesPage
