import {
  LOAD_POLICIES_FULFILLED,
  LOAD_POLICIES_PENDING,
  LOAD_POLICIES_REJECTED,
  LOAD_NEW_POLICIES_FULFILLED,
  LOAD_NEW_POLICIES_PENDING,
  LOAD_NEW_POLICIES_REJECTED,
  LOAD_ALL_POLICIES_OPTIONS_FULFILLED,
  LOAD_ALL_POLICIES_OPTIONS_PENDING,
  LOAD_ALL_POLICIES_OPTIONS_REJECTED,
  LOAD_POLICY_TYPES_PENDING,
  LOAD_POLICY_TYPES_FULFILLED,
  LOAD_POLICY_TYPES_REJECTED,
  LOAD_POLICY_PERMISSIONS_PENDING,
  LOAD_POLICY_PERMISSIONS_FULFILLED,
  LOAD_POLICY_PERMISSIONS_REJECTED,
  LOAD_PERMISSIONS_PENDING,
  LOAD_PERMISSIONS_FULFILLED,
  LOAD_PERMISSIONS_REJECTED,
  POST_POLICY_PENDING,
  POST_POLICY_FULFILLED,
  POST_POLICY_REJECTED,
  PUT_POLICY_PENDING,
  PUT_POLICY_FULFILLED,
  PUT_POLICY_REJECTED,
  DELETE_POLICY_PENDING,
  DELETE_POLICY_FULFILLED,
  DELETE_POLICY_REJECTED,
  PUT_POLICY_TYPE_PENDING,
  PUT_POLICY_TYPE_FULFILLED,
  PUT_POLICY_TYPE_REJECTED,
  POST_POLICY_TYPE_PENDING,
  POST_POLICY_TYPE_FULFILLED,
  POST_POLICY_TYPE_REJECTED,
  DELETE_POLICY_TYPE_PENDING,
  DELETE_POLICY_TYPE_FULFILLED,
  DELETE_POLICY_TYPE_REJECTED,
  PUT_POLICY_PERMISSIONS_PENDING,
  PUT_POLICY_PERMISSIONS_FULFILLED,
  PUT_POLICY_PERMISSIONS_REJECTED,
  CLEAR_POLICIES_MESSAGES,
  CLEAR_POLICY_TYPE_MESSAGES,
} from "../actions/policies"
import i18n from 'i18next'

export default function policies(state = {}, action) {
  switch (action.type) {
    case LOAD_POLICIES_PENDING:
    case LOAD_NEW_POLICIES_PENDING:
    case LOAD_ALL_POLICIES_OPTIONS_PENDING:
    case POST_POLICY_PENDING:
    case POST_POLICY_TYPE_PENDING:
    case PUT_POLICY_PENDING:
    case PUT_POLICY_TYPE_PENDING:
    case PUT_POLICY_PERMISSIONS_PENDING:
    case DELETE_POLICY_PENDING:
    case DELETE_POLICY_TYPE_PENDING:
      return {
        ...state,
        policiesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_POLICY_TYPES_PENDING:
      return {
        ...state,
        isLoadingPolicyTypes: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_PERMISSIONS_PENDING:
      return {
        ...state,
        isLoadingPermissions: true,
        errorMessage: null,
        successMessage: null
      }
    case LOAD_POLICY_PERMISSIONS_PENDING:
      return {
        ...state,
        isLoadingPolicyPermissions: true,
        errorMessage: null,
        successMessage: null
      }
    case LOAD_POLICIES_REJECTED:
    case LOAD_NEW_POLICIES_REJECTED:
    case LOAD_ALL_POLICIES_OPTIONS_REJECTED:
    case POST_POLICY_REJECTED:
    case POST_POLICY_TYPE_REJECTED:
    case PUT_POLICY_REJECTED:
    case PUT_POLICY_TYPE_REJECTED:
    case PUT_POLICY_PERMISSIONS_REJECTED:
    case DELETE_POLICY_REJECTED:
    case DELETE_POLICY_TYPE_REJECTED:
      return {
        ...state,
        policiesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_POLICY_TYPES_REJECTED:
      return {
        ...state,
        isLoadingPolicyTypes: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_PERMISSIONS_REJECTED:
      return {
        ...state,
        isLoadingPermissions: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_POLICY_PERMISSIONS_REJECTED:
      return {
        ...state,
        isLoadingPolicyPermissions: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_POLICIES_FULFILLED:
      const policies = state?.policies || []
      const newPoliciesIds = action.payload.data?.map(item => item.id)
      const difference = policies
        .filter(x => !newPoliciesIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        policies: difference,
        canLoadMore: action.payload.currentPage !== action.payload.totalPages,
        policiesLoading: false,
      }
    case LOAD_NEW_POLICIES_FULFILLED:
      return {
        ...state,
        policies: action.payload.data,
        canLoadMore: action.payload.currentPage !== action.payload.totalPages,
        policiesLoading: false,
      }
    case LOAD_ALL_POLICIES_OPTIONS_FULFILLED:
      return {
        ...state,
        allPoliciesOptions: action.payload.data,
        policiesLoading: false
      }
    case LOAD_POLICY_TYPES_FULFILLED:
      let filteredPolicyTypes = action.payload

      if (action?.meta?.queryParams) {
        const orderBy = action.meta.queryParams.orderBy
        const order = action.meta.queryParams.order

        if (order && orderBy) {
          filteredPolicyTypes = filteredPolicyTypes.sort((a, b) => {
            if (orderBy === "name") {
              return order === "asc"
                ? a.name?.localeCompare(b.name)
                : b.name?.localeCompare(a.name)
            }

            return 0
          })
        }
      }


      return {
        ...state,
        policyTypes: filteredPolicyTypes,
        isLoadingPolicyTypes: false,
      }
    case LOAD_POLICY_PERMISSIONS_FULFILLED:
      return {
        ...state,
        policyPermissions: action.payload,
        isLoadingPolicyPermissions: false,
      }
    case LOAD_PERMISSIONS_FULFILLED:
      return {
        ...state,
        permissions: action.payload,
        isLoadingPermissions: false,
      }
    case PUT_POLICY_FULFILLED:
      const updatedPolicies = state?.policies.map((item) => item.id === action.payload.id ? {...action.payload} : item)

      return {
        ...state,
        policies: updatedPolicies,
        policiesLoading: false,
        successMessage: i18n.t("policies.success-edit-policy")
      }
    case POST_POLICY_FULFILLED:
      const initialPolicies = state?.policies || [];

      return {
        ...state,
        policies: [...initialPolicies, action.payload],
        policiesLoading: false,
        successMessage: i18n.t("policies.success-add-policy")
      }
    case POST_POLICY_TYPE_FULFILLED:
      const initialPolicyTypes = state?.policyTypes || []

      return {
        ...state,
        policyTypes: [...initialPolicyTypes, action.payload],
        policiesLoading: false,
        successMessage: i18n.t("policies.success-add-policy-type")
      }
    case PUT_POLICY_TYPE_FULFILLED:
      const updatedPolicyTypes = state?.policyTypes.map((item) => item.id === action.payload.id ? {...action.payload} : item)

      return {
        ...state,
        policyTypes: updatedPolicyTypes,
        policiesLoading: false,
        successMessage: i18n.t("policies.success-edit-policy-type")
      }
    case PUT_POLICY_PERMISSIONS_FULFILLED:
      return {
        ...state,
        policiesLoading: false
      }
    case DELETE_POLICY_FULFILLED:
      const remainingPolicies = state?.policies.filter((item) => item.id !== action.meta.id)

      return {
        ...state,
        policies: remainingPolicies,
        policiesLoading: false,
        successMessage: i18n.t("policies.success-delete-policy")
      }
    case DELETE_POLICY_TYPE_FULFILLED:
      const remainingPolicyTypes = state?.policyTypes.filter((item) => item.id !== action.meta.id)

      return {
        ...state,
        policyTypes: remainingPolicyTypes,
        policiesLoading: false,
        successMessage: i18n.t("policies.success-delete-policy-type")
      }
    case CLEAR_POLICIES_MESSAGES:
      return {
        ...state,
        errorMessage: null,
        successMessage: null
      }
    case CLEAR_POLICY_TYPE_MESSAGES:
      return {
        ...state,
        errorMessage: null,
        successMessage: null
      }
    default:
      return state
  }
}
