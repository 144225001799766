import React, { useState } from "react"
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useTranslation } from "react-i18next"
import SimpleButton from '../../common/SimpleButton'
import LoadingSpinner from "../../common/LoadingSpinner"
import { toast } from "../../common/Toast"
import { deletePaymentMethod } from "../../../utils/requests/paymentMethodsAPI"

const UpdateForm = (props) => {
  const { t } = useTranslation()
  const { paymenthMethodIdToDelete, closeModal } = props
  const stripe = useStripe()
  const elements = useElements()
  const [showSpinner, setShowSpinner] = useState(false)

  const handleUpdate = async () => {
    setShowSpinner(true)

    try {
      if (paymenthMethodIdToDelete) {
        await deletePaymentMethod(paymenthMethodIdToDelete)
      }
  
      const {error} = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      })
  
      setShowSpinner(false)

      if (error) {
        console.log({error})
        toast.error(t("error.fill_fields_correctly"))
      }
    } catch (error) {
      console.log("Payment method update error", error)
      setShowSpinner(false)

      toast.error(t("error.something_wrong"))
    }
  }

  return (
    <div className="update-form">
      <PaymentElement />
      <div className="update-form-footer">           
        <SimpleButton className="cancel-button" onClick={() => closeModal()}>
          {t('buttons.cancel')}
        </SimpleButton>
        <SimpleButton className="submit-button" onClick={() => handleUpdate()}>
          {t('labels.confirm')}
        </SimpleButton>
      </div>
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default UpdateForm