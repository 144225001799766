import config from '../config'
import {_basePostFetch, _baseGetFetch} from './baseFetch'

const env = config.stage
const apiName = env === 'prod' ? 'https://updates.scican.com' : `https://updates.${env}.scican.com`;
const baseUrl = 'https://80285050-2e49-43db-872f-e83fac3f0310.mock.pstmn.io'
const xApiKey = 'PMAK-607c5fc46c0c5a0063428897-38023b2d4d9c640b8fb7cd0b1701a15c1a'

export async function startRemoteSession(username,queryParams) {
  // TODO switch to amplify
  return _baseGetFetch(`/users/${username}/remote-access`, queryParams)
  // return fetch(fetchUrl(`${baseUrl}/remote-access`, queryParams),
  //   {
  //     method: 'GET',
  //     headers: {
  //       'x-api-key': xApiKey,
  //       'Content-Type': 'application/vnd.api+json'
  //     }
  //   }
  // )
  //   .then(response => getResponse(response))
  //   .catch(error => {
  //     return Promise.reject(error)
  //   })
}

export async function endRemoteSession(body) {
  // return _basePostFetch('https://updates.scican.com/close-remote-session/', body)
  return fetch(fetchUrl(`${apiName}/close-remote-session/`),
    {
      method: 'POST',
      body: JSON.stringify(body)
    }
  )
    .then(response => getResponse(response))
    .catch(error => {
      return Promise.reject(error)
    })
}

export async function trackRemoteSessionLogin(body) {
  return _basePostFetch(`/remote-access/tracking`, body)
  // return fetch(fetchUrl(`${apiName}/remote-access/tracking`),
  //   {
  //     method: 'POST',
  //     body: JSON.stringify(body)
  //   }
  // )
  //   .then(response => getResponse(response))
  //   .catch(error => {
  //     return Promise.reject(error)
  //   })
}

export async function auditRemoteSessionLogin(body) {
  return _basePostFetch(`/remote-access/audits`, body)
  // return fetch(fetchUrl(`${apiName}/remote-access/audits`),
  //   {
  //     method: 'POST',
  //     body: JSON.stringify(body)
  //   }
  // )
  //   .then(response => getResponse(response))
  //   .catch(error => {
  //     return Promise.reject(error)
  //   })
}

async function getResponse(response) {
  const isJson = response.headers.get('content-type')?.includes('application/json')
  const data = isJson && await response.json()
  // check for error response
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (data && data.message) || response.status
    return Promise.reject(error)
  }

  if (data && data.data) {
    return Promise.resolve(data.data)
  } else if(data) {
    return Promise.resolve(data)
  }
}

function fetchUrl(baseUrl, baseParams) {
  const url = new URL(baseUrl)
  if (baseParams) {
    Object.keys(baseParams).forEach(key => {
      if (baseParams[key]) {
        url.searchParams.append(key, baseParams[key])
      }
    })
  }
  return url.href
}
