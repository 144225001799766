export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS"

export function setCurrentUserPermissions(permissions) {
  return {
    type: SET_USER_PERMISSIONS,
    permissions,
  }
}

export function handleSetCurrentUserPermissions(permissions) {
  return (dispatch) => {
    return dispatch(setCurrentUserPermissions(permissions))
  }
}
