import { ApiEnum, _baseDeleteFetch, _baseGetFetch, _basePatchFetch, _basePostFetch, _basePutFetch } from "./baseFetch"

export const getBusinessRules = async (queryParams, withoutLimit) => {
  return _baseGetFetch(`/store/businessrules`, { ...queryParams, ...(!withoutLimit ? {maxResults: process.env.REACT_APP_PAGINATION_SIZE} : {}) }, ApiEnum.store)
}

export const getBusinessRuleById = async (id) => {
  return _baseGetFetch(`/store/businessrules/${id}`, undefined, ApiEnum.store)
}

export const postBusinessRule = async (body) => {
  return _basePostFetch(`/store/businessrules`, body, ApiEnum.store)
}

export const patchBusinessRule = async (id, body) => {
  return _basePatchFetch(`/store/businessrules/${id}`, body, ApiEnum.store)
}

export const deleteBusinessRule = async (id) => {
  return _baseDeleteFetch(`/store/businessrules/${id}`, undefined, ApiEnum.store)
}

export const putActivateBusinessRule = async (id) => {
  return _basePutFetch(`/store/businessrules/${id}/enable`, {}, ApiEnum.store)
}

export const putDeactivateBusinessRule = async (id) => {
  return _basePutFetch(`/store/businessrules/${id}/disable`, {}, ApiEnum.store)
}

export const getBusinessRuleTypes = async (id) => {
  return _baseGetFetch(`/store/businessrules/types`, undefined, ApiEnum.store)
}

export const getServices = async (queryParams, withoutLimit) => {
  return _baseGetFetch(`/store/services`, { ...queryParams, ...(!withoutLimit ? {maxResults: process.env.REACT_APP_PAGINATION_SIZE} : {}) }, ApiEnum.store)
}

export const getServiceById = async (id) => {
  return _baseGetFetch(`/store/services/${id}`, undefined, ApiEnum.store)
}

export const postService = async (body) => {
  return _basePostFetch(`/store/services`, body, ApiEnum.store)
}

export const putService = async (id, body) => {
  return _basePutFetch(`/store/services/${id}`, body, ApiEnum.store)
}

export const deleteService = async (id) => {
  return _baseDeleteFetch(`/store/services/${id}`, undefined, ApiEnum.store)
}

export const putActivateService = async (id) => {
  return _basePutFetch(`/store/services/${id}/enable`, undefined, ApiEnum.store)
}

export const putDeactivateService = async (id) => {
  return _basePutFetch(`/store/services/${id}/disable`, undefined, ApiEnum.store)
}
