import config from "../config"
import { _baseDeleteFetch, _baseGetFetch, _basePostFetch, _basePatchFetch, _basePutFetch, ApiEnum } from "./baseFetch"

const path = "/users"

export const getUserProducts = ({ cognitoSub }) => {
  return _baseGetFetch(`${path}/${cognitoSub}/products`)
    .then((res) => res)
    .catch((e) => {
      console.log(e.message)
      return {}
    })
}

export const getSterilizationReport = async (email, queryParams) => {
  return _baseGetFetch(`${path}/${email}/reports/instrument-reprocessing`, { ...queryParams }, undefined)
}

export const getSterilizationReportsCsv = async (cognitoSub, queryParams) => {
  return _baseGetFetch(`${path}/${cognitoSub}/reports/instrument-reprocessing/download`, { ...queryParams }, undefined)
}

export function getAllUserAssociations(cognitoSub, email) {
  return _baseGetFetch(`${path}/${cognitoSub}/associations/`)
}

export const approveRequests = (id, cognitoSub, email) => {
  return _basePutFetch(`${path}/${cognitoSub}/associations/${id}`, {
    email: email,
  })
}

export function getPendingRequests(id, cognitoSub, email) {
  return _baseGetFetch(`${path}/${cognitoSub}/associations/${id}`, {
    email: email,
  })
}

export async function emailSubmission(cognitoSub, email) {
  return _basePostFetch(`${path}/${cognitoSub}/associations/`, {
    email: email,
  })
}

export const declineRequests = (id, cognitoSub) => {
  return _baseDeleteFetch(`${path}/${cognitoSub}/associations/${id}`)
}

export async function createUser(body) {
  return _basePostFetch(`${path}`, body)
}
export async function updateUser(cognitoSub, body) {
  return _basePutFetch(`${path}/${cognitoSub}`, body)
}

export const updateCustomFields = (cognitoSub, serialNumber, body) => {
  return _basePutFetch(`${path}/${cognitoSub}/products/${serialNumber}/custom-names`, body)
}

export const updateNewsLetter = (cognitoSub, body) => {
  return _basePutFetch(`${path}/${cognitoSub}/newsletter`, body)
}

export const updateLastLogin = (cognitoSub, body) => {
  return _basePostFetch(`${path}/${cognitoSub}/last-login`, body)
}

export function getRegisteredStatus(cognitoSub, sn) {
  return _baseGetFetch(`${path}/${cognitoSub}/products/${sn}/register`)
}

export const registerProduct = (cognitoSub, sn, body) => {
  return _basePostFetch(`${path}/${cognitoSub}/products/${sn}/register`, body)
}

export const getCurrentUserPermissions = () => {
  return _baseGetFetch(`${path}/current/permissions`, {}, ApiEnum.admin)
    .then((res) => res)
    .catch((e) => {
      console.log("permission error - ", e.message)
      return []
    })
}

export const getUsersList = async (queryParams, limit) => {
  return _baseGetFetch(`${path}`, { ...queryParams, limit: limit || process.env.REACT_APP_PAGINATION_SIZE }, ApiEnum.admin)
}

export const patchUser = async (id, bodyParams) => {
  return _basePatchFetch(`${path}/${id}`, { ...bodyParams }, ApiEnum.admin);
}

export const confirmCognitoUser = async (id) => {
  return _basePatchFetch(`${path}/${id}/confirm-cognito-status`, undefined, ApiEnum.admin);
}

export const deleteUser = async (id) => {
  return _baseDeleteFetch(`${path}/${id}`, undefined, ApiEnum.admin);
}

export const getCurrentUserNotificationPreferences = async (cognitoSub) => {
  return _baseGetFetch(`${path}/${cognitoSub}/notifications/preferences`, undefined, undefined)
}

export const putCurrentUserEnabledNotificationsPreferences = async (cognitoSub, bodyParams) => {
  return _basePutFetch(`${path}/${cognitoSub}/notifications/preferences`, { ...bodyParams }, undefined)
}

export const putCurrentUserNotificationPreference = async (cognitoSub, notificationId, bodyParams) => {
  return _basePutFetch(`${path}/${cognitoSub}/notifications/${notificationId}/preferences`, { ...bodyParams }, undefined)
}

export const getCurrentUserNotifications = async (cognitoSub, isUnread) => {
  const params ={
    isUnread: isUnread? 1 : 0
  }
  return _baseGetFetch(`${path}/${cognitoSub}/notifications`, params, undefined)
}

export const postCurrentUserNotificationsRead = async (cognitoSub) => {
  return _basePostFetch(`${path}/${cognitoSub}/notifications/read`, undefined, undefined)
}

export const postCurrentUserNotificationRead = async (cognitoSub, notificationId, type) => {
  const param = {isFromDevice: type === 'device'}
  return _basePostFetch(`${path}/${cognitoSub}/notifications/${notificationId}/read`, param, undefined)
}

export const getUserDevices = (id) => {
  return _baseGetFetch(`${path}/${id}/devices`, undefined, ApiEnum.admin)
}

export const getUserSubscriptions = (id) => {
  return _baseGetFetch(`${path}/${id}/subscriptions`, undefined, ApiEnum.admin)
}

export const patchUserSubscription = async (subscriptionId, userUuid) => {
  return _basePatchFetch(`${path}/${userUuid}/subscriptions/${subscriptionId}`, undefined, ApiEnum.admin);
}

export const deleteUserSubscription = async (subscriptionId, userUuid) => {
  return _baseDeleteFetch(`${path}/${userUuid}/subscriptions/${subscriptionId}`, undefined, ApiEnum.admin);
}

export const getUserPermissions = (id) => {
  return _baseGetFetch(`${path}/${id}/permissions`, undefined, ApiEnum.admin)
}

export const postSendResetPasswordEmail = async (id) => {
  return _basePostFetch(`${path}/${id}/sendResetPasswordEmail`, undefined, ApiEnum.admin)
}

export const postVerifyUser = async (userUuid, email) => {
  return _basePostFetch(`${path}/${userUuid}/verify-email`, { data: { email } }, ApiEnum.admin)
}

export const getProducts = (cognitoSub) => {
  return _baseGetFetch(`${path}/${cognitoSub}/products`)
    .then(res => res)
    .catch( e => {
      console.log('error - ', e.message)
      return {}
    })
}

export const getApertures = (cognitoSub) => {
  return _baseGetFetch(`${path}/${cognitoSub}/apertures`)
  .then(res => res)
  .catch( e => { 
    console.log('error - ', e.message)
    return []
  })
}

export const putApertureNickname = (cognitoSub, apertureId, deviceUid, body) => {
  return _basePutFetch(`${path}/${cognitoSub}/apertures/${apertureId}/devices/${deviceUid}`, body)
  .then(res => res)
  .catch( e => { 
    console.log('error - ', e.message)
    return []
  })
}

export const getUserAccountTypes = async () => {
   try {
    const response = await fetch(`${config.apiGateway.URL}/users/registration/accounts`)
    return await response.json()
  } catch (e) {
    console.log('error - ', e)
    return []
  }
}

export const postInstrumentReprocessingNote = async (userUuid, bodyParams) => {
  return _basePostFetch(`${path}/${userUuid}/reports/instrument-reprocessing/notes`, { ...bodyParams })
}

export const validateCompanyName = async (queryParams) => {
  try {
    const queryString = new URLSearchParams(queryParams).toString()
    const response = await fetch(`${config.apiGateway.URL}/users/registration/company?${queryString}`)
    return await response.json()
  } catch (e) {
    console.log('error - ', e)
    return []
  }
}

export const getRegistrationInfo = async (id) => {
  try {
    const response = await fetch(`${config.apiGateway.URL}/users/registration/accounts/invitations/${id}`)
    return await response.json()
  } catch (e) {
    console.log('error - ', e)
    return []
  }
}