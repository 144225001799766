import React from 'react'
import {useTranslation} from "react-i18next";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import PropTypes from "prop-types";

const CycleDetailsGraph = (props) => {
  const {data, temperatureUnit, pressureUnit} = props
  const {t} = useTranslation()

  const celsiusFormatter = (value) => {
    return (value !== null && value !== 'undefined') ? `${value}°${temperatureUnit}` : 'N/A'
    // return `${Math.ceil(value)}°${temperatureUnit}`
  }

  const pressureFormatter = (value) => {
    return (value !== null && value !== 'undefined') ? `${value} ${pressureUnit}s` : "N/A"
    // return `${value} ${pressureUnit}s`
  }

  const renderTooltip = (props) => {
    if (!props.payload || !props.payload.length) {
      return false
    }

    return (
      <div className="report-graph-tooltip">
        <div className="tooltip-temperature">
          <p>{t('report-graph.legend.temperature')}</p>
          <p className="temperature">{celsiusFormatter(props.payload[0].payload.temp)}</p>
        </div>
        { props.payload[0].payload.temp2  !== null &&
        <div className="tooltip-temperature2">
          <p>{t('report-graph.legend.temperature2')}</p>
          <p className="temperature2">{celsiusFormatter(props.payload[0].payload.temp2)}</p>
        </div>}
        {props.payload[0].payload.pressure !== null &&
        <div className="tooltip-pressure">
          <p>{t('report-graph.legend.pressure')}</p>
          <p className="pressure">{pressureFormatter(props.payload[0].payload.pressure)}</p>
        </div>}
      </div>
    )
  }

  const renderLegend = (props) => {
    return (
      <div className="report-graph-legend-wrapper">
        {
          props.payload.map((item) => {
            switch (item.dataKey) {
              case "temp":
                return (data[0] && data[0].temp !== null) ?
                  <div key={item.dataKey}>
                    <div className="legend-dot" style={{backgroundColor: item.color}}/>
                      <p>{t('report-graph.legend.temperature')}</p>
                    </div>
                  : ""
              case "temp2":
                return (data[0] && data[0].temp2 !== null) ?
                <div key={item.dataKey}>
                  <div className="legend-dot" style={{backgroundColor: item.color}}/>
                    <p>{t('report-graph.legend.temperature2')}</p>
                  </div>
                : ""
              case "pressure":
                return (data[0] && data[0].pressure !== null) ?
                    <div key={item.dataKey}>
                      <div className="legend-dot" style={{backgroundColor: item.color}}/>
                        <p>{t('report-graph.legend.pressure')}</p>
                      </div>
                    : ""
              default:
                return ""
            }
          }
          )
        }
      </div>
    )
  }

  const getMaxVal = (arr, axis) => {
    const maxAdd = {
      pressure: 1,
      temp: 5,
      temp2: 5
    }
    const mArr = arr.reduce(function(prev, current) {
      return (prev[axis] > current[axis]) ? prev : current
    })

    return Math.ceil(Number(mArr[axis])) + maxAdd[axis]
  }

  const getMinVal = (arr, axis) => {
    const mArr = data.reduce(function(prev, current) {
      return (prev[axis] > current[axis]) ? current : prev
    })
  
    const minAdd = {
      pressure:  mArr[axis] > 0 ? mArr[axis] : 0.1,
      temp: mArr[axis] > 0 ? mArr[axis] : 0.5,
      temp2: mArr[axis] > 0 ? mArr[axis] : 0.5
    }

    return Math.floor(Number(mArr[axis]- minAdd[axis]))
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false}/>
        <XAxis dataKey="name" axisLine={false} tickLine={false}/>
        <YAxis
          yAxisId="pressure"
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          // tickCount={25}
          // domain={[getMinVal(data, 'pressure'), getMaxVal(data, 'pressure')]}
          orientation="right"
          tickFormatter={pressureFormatter}
        />
        <YAxis
          yAxisId="temp"
          axisLine={false}
          tickLine={false}
          allowDecimals={true}
          // tickCount={25}
          // domain={[getMinVal(data, 'temp'), getMaxVal(data, 'temp')]}
          tickFormatter={celsiusFormatter}
        />
        
        <Line yAxisId="temp" strokeWidth={3} type="monotone" stroke="#00A0E9" dataKey="temp" dot={{r: 0}}
              activeDot={{r: 5}}/>
        <Line yAxisId="temp" strokeWidth={3} type="monotone" stroke="#5357e6" dataKey="temp2" dot={{r: 0}} 
              activeDot={{r: 5}}/>    
        <Line yAxisId="pressure" strokeWidth={3} type="monotone" stroke="#27cb6b" dataKey="pressure" dot={{r: 0}}
              activeDot={{r: 5}}/>
        <Tooltip content={renderTooltip}/>
        <Legend content={renderLegend}/>
      </LineChart>
    </ResponsiveContainer>
  )
}

CycleDetailsGraph.propTypes = {
  data: PropTypes.array.isRequired,
  temperatureUnit: PropTypes.string.isRequired,
  pressureUnit: PropTypes.string.isRequired
}

export default CycleDetailsGraph
