import { _baseGetFetch, _basePostFetch, _baseDeleteFetch, ApiEnum } from "./baseFetch"

const path = "/users/current/store/paymentmethods"

export const getPaymentMethods = async () => {
  return _baseGetFetch(path, undefined, ApiEnum.store)
}

export const createSetupIntent = async () => {
  return _basePostFetch(path, undefined, ApiEnum.store)
}

export const postDefaultPaymentMethod = async (id) => {
  return _basePostFetch(`${path}/${id}/default`, undefined, ApiEnum.store)
}

export const deletePaymentMethod = async (id) => {
  return _baseDeleteFetch(`${path}/${id}`, undefined, ApiEnum.store)
}