import { LOAD_PROBLEM_TYPES_FULFILLED, LOAD_PROBLEM_TYPES_PENDING, LOAD_PROBLEM_TYPES_REJECTED } from "../actions/requestHelp"
import i18n from "i18next"

const initialState = {
  problemTypes: [],
  isLoading: true,
  errorMessage: null,
}
export default function requestHelp(state = initialState, action) {
  switch (action.type) {
    case LOAD_PROBLEM_TYPES_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }
    case LOAD_PROBLEM_TYPES_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_PROBLEM_TYPES_FULFILLED:
      return {
        ...state,
        problemTypes: action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
