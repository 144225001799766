import {
  LOAD_VIDEO_MODELS_PENDING,
  LOAD_VIDEO_MODELS_FULFILLED,
  LOAD_VIDEO_MODELS_REJECTED,
  LOAD_VIDEO_CATEGORIES_PENDING,
  LOAD_VIDEO_CATEGORIES_FULFILLED,
  LOAD_VIDEO_CATEGORIES_REJECTED,
  CLEAR_VIDEOS_MESSAGES,
} from "../actions/videos"
import i18n from 'i18next'

export default function videos(state = {}, action) {
  switch (action.type) {
    case LOAD_VIDEO_MODELS_PENDING:
    case LOAD_VIDEO_CATEGORIES_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }
    case LOAD_VIDEO_MODELS_REJECTED:
    case LOAD_VIDEO_CATEGORIES_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_VIDEO_MODELS_FULFILLED:
      return {
        ...state,
        models: action.payload.data,
        isLoading: false,
      }
    case LOAD_VIDEO_CATEGORIES_FULFILLED:
      return {
        ...state,
        categories: action.payload.data,
        isLoading: false,
      }
    case CLEAR_VIDEOS_MESSAGES:
      return {
        ...state,
        errorMessage: null
      }
    default:
      return state
  }
}
