import { 
  getVideosModels, 
  getVideosCategories,
} from "../utils/requests/videosAPI"

export const LOAD_VIDEO_MODELS = "LOAD_VIDEO_MODELS"
export const LOAD_VIDEO_MODELS_PENDING = "LOAD_VIDEO_MODELS_PENDING"
export const LOAD_VIDEO_MODELS_FULFILLED = "LOAD_VIDEO_MODELS_FULFILLED"
export const LOAD_VIDEO_MODELS_REJECTED = "LOAD_VIDEO_MODELS_REJECTED"

export const LOAD_VIDEO_CATEGORIES = "LOAD_VIDEO_CATEGORIES"
export const LOAD_VIDEO_CATEGORIES_PENDING = "LOAD_VIDEO_CATEGORIES_PENDING"
export const LOAD_VIDEO_CATEGORIES_FULFILLED = "LOAD_VIDEO_CATEGORIES_FULFILLED"
export const LOAD_VIDEO_CATEGORIES_REJECTED = "LOAD_VIDEO_CATEGORIES_REJECTED"

export const CLEAR_VIDEOS_MESSAGES = "CLEAR_VIDEOS_MESSAGES"

export function loadVideoModels() {
  return {
    type: LOAD_VIDEO_MODELS,
    payload: getVideosModels()
  }
}

export function loadVideoCategories() {
  return {
    type: LOAD_VIDEO_CATEGORIES,
    payload: getVideosCategories()
  }
}

export function clearVideosMessages() {
  return {
    type: CLEAR_VIDEOS_MESSAGES
  }
}