import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from 'react-router-dom'
import ViewCreateReportModal from "./ViewCreateReportModal"
import Breadcrumb from "../common/Breadcrumb"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import SearchWithFilter from "../common/SearchWithFilter"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import ActionsMenu from '../common/ActionsMenu'
import GenericTable from "../common/GenericTable"
import ExpandableText from "../common/ExpandableText"
import ConfirmationModal from "../common/ConfirmationModal"
import CheckboxInput from "../common/CheckboxInput"
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { handleReportTicket } from "../../actions/reportTicket"
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect'
import { MenuKebabVerticalBackgroundIcon, MenuKebabVerticalIcon, EnabledIcon, DisabledIcon } from '../../icons'
import { convertToSimpleDateWithTime } from "../../utils/filters/date"
import { deleteReport, getReports, getReportsCsv } from "../../utils/requests/reportsAPI"
import { downloadFile } from '../../utils/functions'
import { reportProblemDeletePermission } from "../../utils/permissionValidation"
import './report-problem-modern.scss'

const ReportProblemModern = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const isMobile = useWindowSize()[0] <= 768
  const canGoBack = location.state && location.state.withBackNavigation
  const prefilledSerialNumber = location.state && location.state.serial_number
  const prefilledModel = location.state && location.state.model
  const statuses = ["open", "closed"]
  const [reports, setReports] = useState([])
  const [reportsTableData, setReportsTableData] = useState([])
  const [selectedReport, setSelectedReport] = useState(null)
  const [selectedReportIds, setSelectedReportIds] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedFilterStatuses, setSelectedFilterStatuses] = useState([])
  const [selectedFilterModels, setSelectedFilterModels] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [orderBy, setOrderBy] = useState({ field: "date", order: "desc" })
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedReport, setExpandedReport] = useState(0)
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false)
  const [reportIdToDelete, setReportIdToDelete] = useState(null)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [modelOptions, setModelOptions] = useState([])
  const [showViewCreateReportModal, setShowViewCreateReportModal] = useState({isOpen: false, isView: false})
  
  useEffect(() => {
    if (prefilledSerialNumber && prefilledModel) {
      setShowViewCreateReportModal({isOpen: true, isView: false})
    }
  }, [])

  useEffect(() => {
    if (reports.length > 0) {
      const queryParams = new URLSearchParams(window.location.search)
      const ticketIdParam = queryParams.get("ticket")
      history.replace({ search: "" })
    
      const report = reports.find((r) => r.ticketId === Number(props.ticket) || r.ticketId === Number(ticketIdParam))

      if (!report) {
        cleanStoredData()
        return
      }

      setSelectedReport(report)
      setShowViewCreateReportModal({isOpen: true, isView: true})
    }
  }, [reports])

  useEffect(() => {
    if (props.productTypes && Object.values(props.productTypes).length > 0) {
      const models = Object.values(props.productTypes).map(productType => productType.models).flat().map((model) => model.name)

      setModelOptions(models.sort())
    }
  }, [props.productTypes])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadReports(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadReports(true)
        }

        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, selectedFilterStatuses, selectedFilterModels, orderBy])

  useEffect(() => {
    mapTableData(reports)
  }, [reports])

  const handleLoadReports = (withReset) => {
    let queryParams = {
      page: withReset ? 1 : selectedPage,
      limit: process.env.REACT_APP_PAGINATION_SIZE
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterModels.length > 0) {
      queryParams = { ...queryParams, model: selectedFilterModels}
    }

    if (selectedFilterStatuses.length === 1) {
      queryParams = { ...queryParams, status: selectedFilterStatuses}
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    setShowSpinner(true)

    getReports(queryParams)
      .then((data) => {
        let tickets = []
        
        if (data?.data) {
          tickets = withReset ? data.data : [...reports, ...data.data]

          setCanLoadMore(data.data.length === Number(process.env.REACT_APP_PAGINATION_SIZE))
        }

        setReports(tickets)
      })
      .catch(() => {
        toast.error(t('report-problem.error-loading-reports'))

        setCanLoadMore(false)
      })
      .finally(()=> {
        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }

  const mapTableData = (data) => {
    const tableData = data?.map((report) => {
      return {
        identifier: report.ticketId,
        date: convertToSimpleDateWithTime(report.date),
        ticketNr: report.ticketId,
        serialNumber: report.sn,
        model: report.model,
        cf: report.cf,
        subject: report.issueDescription,
        email: report.reportByEmail,
        status: report.status,
        actions: [
          {
            label: "report-problem.report_details",
            onActionClick: (e) => { e.stopPropagation(); openReportDetails(report.ticketId, data) },
          },
          {
            label: "report-problem.download_report",
            onActionClick: (e) => { e.stopPropagation(); exportToCsv(report.ticketId) },
          },
          {
            label: "labels.delete",
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(report.ticketId) },
            requiredPermission: reportProblemDeletePermission
          },
        ]
      }
    })

    setReportsTableData(tableData)
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  } 

  const handleResetFilters = () => {
    setSelectedFilterStatuses([])
    setSelectedFilterModels([])
  }

  const openReportDetails = (identifier, data) => {
    const selectedReport = data?.find((report) => report.ticketId === identifier)

    if (selectedReport) {
      setSelectedReport(selectedReport)
      setShowViewCreateReportModal({isOpen: true, isView: true})
    }    
  }

  const renderReportStatus = (status) => {
    switch (status) {
      case "open":
        return (
          <div className="status-open">
            <EnabledIcon />
            <p>{t("filters.open")}</p>
          </div>
        )
      case "closed":
        return (
          <div className="status-close">
            <DisabledIcon />
            <p>{t("filters.closed")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  const exportToCsv = (ticketId) => {
    let queryParams = {}

    if (selectedReportIds.length > 0 || ticketId) {
      let ticketsToDownload = selectedReportIds

      if (ticketId) {
        ticketsToDownload = [ticketId]
      }

      queryParams = { ...queryParams, ticketId: ticketsToDownload}
    }

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }

    if (selectedFilterModels.length > 0) {
      queryParams = { ...queryParams, model: selectedFilterModels}
    }

    if (selectedFilterStatuses.length === 1) {
      queryParams = { ...queryParams, status: selectedFilterStatuses}
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    setShowSpinner(true)

    getReportsCsv(queryParams)
      .then((res) => {
        const date = new Date().toISOString().split('T')
        const dateNow = date[0].split('-').reverse().join('_')
        const timeNow = date[1].split('.')[0].split(':').join('_')

        downloadFile({
          data: res,
          fileName: `report_tickets_${dateNow}_${timeNow}.csv`,
          fileType: 'text/csv',
        })

        setShowSpinner(false)

        toast.success(t("report-problem.download_success"))
      })
      .catch(() => {
        setShowSpinner(false)

        toast.error(t("error.failure_msg"))
      })
  }

  const showConfirmationModal = (id) => {
    setShowActionsMenu(null)
    setReportIdToDelete(id)
    setShowDeleteReportModal(true)
  }

  const handleDeleteReport = () => {
    setShowDeleteReportModal(false)

    setShowSpinner(true)

    deleteReport(reportIdToDelete)
      .then(() => {
        setShowSpinner(false)
        
        const filteredReports = reports.filter((report) => report.ticketId !== reportIdToDelete)
        setReports(filteredReports)

        toast.success(t("report-problem.delete_success"))
      })
      .catch(() => {
        setShowSpinner(false)

        toast.error(t("error.failure_msg"))
      })
  }

  const handleSelectReport = (id) => {
    setSelectedReportIds((prevValue) => { 
      if (prevValue.includes(id)) {
        return prevValue.filter((value) => value !== id)
      }

      return [...prevValue, id]
    })
  }

  const handleSelectAllReports = () => {
    setSelectedReportIds((prevValue) => { 
      if (prevValue.length === reports.length) {
        return []
      }

      return reports.map((report) => report.ticketId)
    })
  }

  const cleanStoredData = () => {
    const { dispatch } = props
    dispatch(handleReportTicket({ ticket: null }))
  }

  const cleanStateData = () => {
    const state = location.state
    delete state?.model
    delete state?.serial_number
    history.replace(`${history.location.pathname}`, { ...state })
  }
 
  return (
    <div className="report-problem-modern-wrapper">
      <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack} />
      <DashboardHeader
        headerText={t("nav.report_problem")}
      >
        <SimpleButton 
          className="submit-button" 
          onClick={() => setShowViewCreateReportModal({isOpen: true, isView: false})}
        >
          {t('report-problem.report_new_problem')}
        </SimpleButton>
      </DashboardHeader>
      <div className="separator" />
      <div className="actions-wrapper d-flex flex-align-center">
        <SearchWithFilter
          placeholder={t("placeholders.select-serial-number")}
          onSearch={(e) => handleSearch(e)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedFilterStatuses.length > 0 || selectedFilterModels.length > 0}
          counterBadge={selectedFilterStatuses.length + selectedFilterModels.length}
        />
        <SimpleButton
          className="action-button"
          onClick={() => exportToCsv()}
          disabled={showSpinner || !reports.length > 0}
        >
          {selectedReportIds.length === 0 ? t("download.download") : t("download.download_selected")}
        </SimpleButton>
      </div>
      {!isMobile && reports.length > 0 && 
        <GenericTable
          data={reportsTableData}
          headers={[
            {
              title: '',
              isMultiSelect: true,
            },
            {
              title: t('report-problem.date'),
              orderKey: 'date'
            },
            {
              title: t('report-problem.ticket_nr'),
              orderKey: 'ticketId'
            },
            {
              title: t('report-problem.serial_number'),
              orderKey: 'sn'
            },
            {
              title: t('report-problem.product'),
              orderKey: 'model'
            },
            {
              title: t('report-problem.cf'),
              orderKey: 'cf'
            },
            {
              title: t('report-problem.subject')
            },
            {
              title: t('report-problem.reported_by_email'),
              orderKey: 'reportByEmail'
            },
            {
              title: t('report-problem.status'),
              orderKey: 'status'
            },
            {
              title: ''
            }
          ]}
          keys={[
            'selectable',
            'date',
            'ticketNr',
            'serialNumber',
            'model',
            'cf',
            'subject',
            'email',
            'status',
            'action',
          ]}
          keyRenderer={{
            selectable: (item) => {
              return (
                <div className="selectable-wrapper">
                  <CheckboxInput 
                    checked={selectedReportIds.includes(item.ticketNr)}
                    onChange={(e) => { e.stopPropagation(); handleSelectReport(item.ticketNr) }}
                  />
                </div>
              )
            },
            date: (item) => {
              return (
                <div className="date-wrapper">
                  <ExpandableText text={item.date} />
                </div>
              )
            },
            serialNumber: (item) => {
              return (
                <div className="serial-number-wrapper">
                  <ExpandableText text={item.serialNumber} />
                </div>
              )
            },
            subject: (item) => {
              return (
                <div className="subject-wrapper">
                  <ExpandableText text={item.subject} />
                </div>
              )
            },
            status: (item) => {
              return (
                <div className="status-wrapper">
                  {renderReportStatus(item.status)}
                </div>
              )
            },
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(report) => openReportDetails(report.identifier, reports)}
          activeSort={orderBy}
          onSort={(orderingField) => handleSort(orderingField)}
          hasSelectedAll={selectedReportIds.length === reports.length}
          onSelectAll={() => handleSelectAllReports()}
          isLoading={showSpinner}
        />
      }
      {isMobile && reportsTableData.map((report, index) =>
        <div 
          key={index}
          className="card" 
          onClick={() => setExpandedReport(index)}
        >
          <div className={"card-item" + (expandedReport !== index ? " align-center" : "")}>
            <div className="card-item-title">{t("report-problem.ticket_nr")}</div>
            <div className={"card-item-body" + (expandedReport !== index ? " align-center" : "")}>
              <div className="card-description">
                {report.ticketNr}
              </div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={report.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedReport === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t("report-problem.date")}</div>
                <div className="card-item-body">{report.date}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("report-problem.serial_number")}</div>
                <div className="card-item-body">{report.serialNumber}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("report-problem.product")}</div>
                <div className="card-item-body">{report.product}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("report-problem.cf")}</div>
                <div className="card-item-body">{report.cf}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("report-problem.subject")}</div>
                <div className="card-item-body">{report.subject}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("report-problem.reported_by_email")}</div>
                <div className="card-item-body">{report.reportByEmail}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("report-problem.status")}</div>
                <div className="card-item-body">
                  <div className="card-item-body-status">{renderReportStatus(report.status)}</div>
                </div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && reports.length === 0 && (
        <EmptyPageResults 
          title={t("report-problem.no-tickets-found")}
          subtitle={t("report-problem.no-tickets-found-extra")}
        />)
      }
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('buttons.load_more')}
          </SimpleButton>
        </div>)
      }
      {showViewCreateReportModal.isOpen && (
        <ViewCreateReportModal
          isViewMode={showViewCreateReportModal.isView}
          report={selectedReport}
          prefilledSerialNumber={prefilledSerialNumber}
          prefilledModel={prefilledModel}
          onSuccesfulCreate={() => handleLoadReports(true)}
          closeModal={() => {
            setShowViewCreateReportModal({isOpen: false, isView: false})
            cleanStoredData()
            cleanStateData()
          }}
        />
      )}
      {showFiltersModal &&
        <FiltersModal
          filters={[
            {
              name: t("report-problem.status"),
              type: FilterType.multiSelect,
              dataset: statuses,
              input: selectedFilterStatuses,
              output: (filterStatuses) => {
                setSelectedFilterStatuses(filterStatuses)
              },
            },
            {
              name: t("report-problem.product_model"),
              type: FilterType.multiSelect,
              dataset: modelOptions,
              input: selectedFilterModels,
              output: (filterModels) => {
                setSelectedFilterModels(filterModels)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      }
      {showDeleteReportModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteReportModal(false)}
          onAccept={() => handleDeleteReport()}
          onCancel={() => setShowDeleteReportModal(false)}
          message={t("report-problem.confirm_delete_report")}
          acceptButtonText={t("labels.delete")}
          isWarning
          requiredPermission={reportProblemDeletePermission}
        />)
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function mapStateToProps({ productTypes, ticket }) {
  return {
    productTypes,
    ticket: ticket?.ticket,
  }
}

export default connect(mapStateToProps)(ReportProblemModern)
