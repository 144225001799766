import React, { useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import FormDigitalProductPrice from "./FormDigitalProductPrice"
import FormPhysicalProductPrice from "./FormPhysicalProductPrice"

const FormProductPrices = (props) => {
  const { t } = useTranslation()
  const { isEditMode, pricesFormData, productPricesErrors, isDigital, resetPricesErrors, updatePrices } = props
  const [countryOptions, setCountryOptions] = useState([])

  useLayoutEffect(() => {
    if (!isEditMode) {
      updatePrices([])
    }
  }, [isDigital])

  useEffect(() => {
    if (props.countries.length > 0) {
      const countriesMapped = props.countries.map((country) => ({
        countryName: country.countryName,
        countryCode: country.countryCode,
        currencyCode: country.currencies[0]?.currencyCode,
      }))

      setCountryOptions(countriesMapped)
    }
  }, [props.countries])

  return (
    <div className="product-prices-wrapper">
      <div className="section-title with-margin-bottom">
        {t("coltene_store.create_product.locations-and-prices")}
      </div>
      {isDigital && (
        <FormDigitalProductPrice
          isEditMode={isEditMode}
          pricesFormData={pricesFormData}
          productPricesErrors={productPricesErrors}
          resetPricesErrors={resetPricesErrors}
          countries={countryOptions}
          updatePrices={updatePrices}
        />
      )}
      {!isDigital && (
        <FormPhysicalProductPrice
          isEditMode={isEditMode}
          pricesFormData={pricesFormData}
          productPricesErrors={productPricesErrors}
          resetPricesErrors={resetPricesErrors}
          countries={countryOptions}
          updatePrices={updatePrices}
        />
      )}
    </div>
  )
}

function stateToProps({ adminStore }) {
  return {
    countries: adminStore?.countries || [],
  }
}

export default connect(stateToProps)(FormProductPrices)
