import {
  getAdminSubscriptionHistory,
  getAdminOrderHistory,
  getAdminOrderStatuses, 
  acceptAdminRefund,
  declineAdminRefund,
  completeAdminRefund 
} from "../utils/requests/productsAPI"
import {
  patchUserSubscription,
  deleteUserSubscription,
} from "../utils/requests/usersAPI"

export const LOAD_SUBSCRIPTIONS = "LOAD_SUBSCRIPTIONS"
export const LOAD_SUBSCRIPTIONS_PENDING = "LOAD_SUBSCRIPTIONS_PENDING"
export const LOAD_SUBSCRIPTIONS_FULFILLED = "LOAD_SUBSCRIPTIONS_FULFILLED"
export const LOAD_SUBSCRIPTIONS_REJECTED = "LOAD_SUBSCRIPTIONS_REJECTED"

export const LOAD_NEW_SUBSCRIPTIONS = "LOAD_NEW_SUBSCRIPTIONS"
export const LOAD_NEW_SUBSCRIPTIONS_PENDING = "LOAD_NEW_SUBSCRIPTIONS_PENDING"
export const LOAD_NEW_SUBSCRIPTIONS_FULFILLED = "LOAD_NEW_SUBSCRIPTIONS_FULFILLED"
export const LOAD_NEW_SUBSCRIPTIONS_REJECTED = "LOAD_NEW_SUBSCRIPTIONS_REJECTED"

export const LOAD_ORDERS = "LOAD_ORDERS"
export const LOAD_ORDERS_PENDING = "LOAD_ORDERS_PENDING"
export const LOAD_ORDERS_FULFILLED = "LOAD_ORDERS_FULFILLED"
export const LOAD_ORDERS_REJECTED = "LOAD_ORDERS_REJECTED"

export const LOAD_NEW_ORDERS = "LOAD_NEW_ORDERS"
export const LOAD_NEW_ORDERS_PENDING = "LOAD_NEW_ORDERS_PENDING"
export const LOAD_NEW_ORDERS_FULFILLED = "LOAD_NEW_ORDERS_FULFILLED"
export const LOAD_NEW_ORDERS_REJECTED = "LOAD_NEW_ORDERS_REJECTED"

export const LOAD_ORDER_STATUSES = "LOAD_ORDER_STATUSES"
export const LOAD_ORDER_STATUSES_PENDING = "LOAD_ORDER_STATUSES_PENDING"
export const LOAD_ORDER_STATUSES_FULFILLED = "LOAD_ORDER_STATUSES_FULFILLED"
export const LOAD_ORDER_STATUSES_REJECTED = "LOAD_ORDER_STATUSES_REJECTED"

export const LOAD_LAST_ORDER = "LOAD_LAST_ORDER"
export const LOAD_LAST_ORDER_PENDING = "LOAD_LAST_ORDER_PENDING"
export const LOAD_LAST_ORDER_FULFILLED = "LOAD_LAST_ORDER_FULFILLED"
export const LOAD_LAST_ORDER_REJECTED = "LOAD_LAST_ORDER_REJECTED"

export const PUT_ACCEPT_ADMIN_REFUND = "PUT_ACCEPT_ADMIN_REFUND"
export const PUT_ACCEPT_ADMIN_REFUND_PENDING = "PUT_ACCEPT_ADMIN_REFUND_PENDING"
export const PUT_ACCEPT_ADMIN_REFUND_FULFILLED = "PUT_ACCEPT_ADMIN_REFUND_FULFILLED"
export const PUT_ACCEPT_ADMIN_REFUND_REJECTED = "PUT_ACCEPT_ADMIN_REFUND_REJECTED"

export const PUT_DECLINE_ADMIN_REFUND = "PUT_DECLINE_ADMIN_REFUND"
export const PUT_DECLINE_ADMIN_REFUND_PENDING = "PUT_DECLINE_ADMIN_REFUND_PENDING"
export const PUT_DECLINE_ADMIN_REFUND_FULFILLED = "PUT_DECLINE_ADMIN_REFUND_FULFILLED"
export const PUT_DECLINE_ADMIN_REFUND_REJECTED = "PUT_DECLINE_ADMIN_REFUND_REJECTED"

export const PUT_COMPLETE_ADMIN_REFUND = "PUT_COMPLETE_ADMIN_REFUND"
export const PUT_COMPLETE_ADMIN_REFUND_PENDING = "PUT_COMPLETE_ADMIN_REFUND_PENDING"
export const PUT_COMPLETE_ADMIN_REFUND_FULFILLED = "PUT_COMPLETE_ADMIN_REFUND_FULFILLED"
export const PUT_COMPLETE_ADMIN_REFUND_REJECTED = "PUT_COMPLETE_ADMIN_REFUND_REJECTED"

export const PATCH_REACTIVATE_SUBSCRIPTION = "PUT_REACTIVATE_SUBSCRIPTION"
export const PATCH_REACTIVATE_SUBSCRIPTION_PENDING = "PUT_REACTIVATE_SUBSCRIPTION_PENDING"
export const PATCH_REACTIVATE_SUBSCRIPTION_FULFILLED = "PUT_REACTIVATE_SUBSCRIPTION_FULFILLED"
export const PATCH_REACTIVATE_SUBSCRIPTION_REJECTED = "PUT_REACTIVATE_SUBSCRIPTION_REJECTED"

export const DELETE_CANCEL_SUBSCRIPTION = "DELETE_CANCEL_SUBSCRIPTION"
export const DELETE_CANCEL_SUBSCRIPTION_PENDING = "DELETE_CANCEL_SUBSCRIPTION_PENDING"
export const DELETE_CANCEL_SUBSCRIPTION_FULFILLED = "DELETE_CANCEL_SUBSCRIPTION_FULFILLED"
export const DELETE_CANCEL_SUBSCRIPTION_REJECTED = "DELETE_CANCEL_SUBSCRIPTION_REJECTED"

export const CLEAR_ADMIN_ORDERS_MESSAGES = "CLEAR_ADMIN_ORDERS_MESSAGES"

export function loadSubscriptionHistory(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_SUBSCRIPTIONS : LOAD_SUBSCRIPTIONS,
    payload: getAdminSubscriptionHistory(queryParams),
  }
}

export function loadOrderHistory(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_ORDERS : LOAD_ORDERS,
    payload: getAdminOrderHistory(queryParams),
  }
}

export function loadOrderStatuses() {
  return {
    type: LOAD_ORDER_STATUSES,
    payload: getAdminOrderStatuses(),
  }
}

export function loadLastOrder(userEmail) {
  return {
    type: LOAD_LAST_ORDER,
    payload: getAdminOrderHistory({ query: userEmail }, 1),
  }
}

export function acceptRefund(orderId) {
  return {
    type: PUT_ACCEPT_ADMIN_REFUND,
    payload: acceptAdminRefund(orderId),
    meta: { orderId }
  }
}

export function declineRefund(orderId, isReactivate) {
  return {
    type: PUT_DECLINE_ADMIN_REFUND,
    payload: declineAdminRefund(orderId),
    meta: { orderId, isReactivate }
  }
}

export function completeRefund(orderId) {
  return {
    type: PUT_COMPLETE_ADMIN_REFUND,
    payload: completeAdminRefund(orderId),
    meta: { orderId }
  }
}

export function reactivateSubscription(subscriptionId, userUuid) {
  return {
    type: PATCH_REACTIVATE_SUBSCRIPTION,
    payload: patchUserSubscription(subscriptionId, userUuid),
    meta: { subscriptionId }
  }
}

export function cancelSubscription(subscriptionId, userUuid) {
  return {
    type: DELETE_CANCEL_SUBSCRIPTION,
    payload: deleteUserSubscription(subscriptionId, userUuid),
    meta: { subscriptionId }
  }
}

export function clearAdminOrdersMessages() {
  return {
    type: CLEAR_ADMIN_ORDERS_MESSAGES
  }
}