import React, { Fragment, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "../Toast"
import SimpleButton from '../SimpleButton'
import CheckboxInput from '../CheckboxInput'
import RadioInput from "../RadioInput";
import Search from "../Search"
import ClickOutside from "../ClickOutside"
import { CalendarIcon, ArrowBackThickIcon, ExpandLessIcon, ExpandMoreIcon, CaretDownIcon, TickIcon, MinusSimpleIcon, CloseCircleIcon } from "../../../icons"
import "./filters-modal.scss"
import { keyboardEvents } from "../../../utils/keyboardEvents";

export const FilterType = {
  dateRange: "dateRange",
  dateFixed: "dateFixed",
  multiSelect: "multiSelect",
  radioSelect: "radioSelect",
  singleSelect: "singleSelect",
  multiSelectWithChildren: "multiSelectWithChildren",
}

const FiltersModal = (props) => {
  const { t } = useTranslation()
  const { shouldFilter, resetFilters, closeFilters, filters } = props
  const [openedFilters, setOpenedFilters] = useState([])
  const [multiSelectValues, setMultiSelectValues] = useState([])
  const [singleSelectValues, setSingleSelectValues] = useState([])
  const [singleSelectValuesFilteredOptions, setSingleSelectValuesFilteredOptions] = useState([])
  const [dateRangeValues, setDateRangeValues] = useState([])
  const [dateFixedValues, setDateFixedValues] = useState([])
  const [multiSelectWithChildrenValues, setMultiSelectWithChildrenValues] = useState([])
  const [openedParents, setOpenedParents] = useState([])
  const [showAllParents, setShowAllParents] = useState([])
  const [hadPreviousFilters, setHadPreviousFilters] = useState(false)
  const showAllLimit = 3
  const backgroundRef = useRef(null)

  useEffect(() => {
    backgroundRef?.current?.focus()
  }, [])

  useEffect(() => {
    if (filters?.length > 0) {
      let hasPreviousFilters = false
      const currentOpenedFilters = []
      const currentDateRangeValues = []
      const currentDateFixedValues = []
      const currentMultiSelectValues = []
      const currentSingleSelectValues = []
      const currentSingleSelectValuesFilteredOptions = []
      const currentMultiSelectWithChildren = []
      const currentOpenedParents = []

      filters.forEach((filter, idx) => {
        currentOpenedFilters.push(idx)

        if (filter.type === FilterType.dateRange && filter.input?.startDate && filter.input?.endDate) {
          const startDate = new Date(filter.input.startDate.replace('Z', ''))
          const endDate = new Date(filter.input.endDate.replace('Z', ''))

          currentDateRangeValues.push({ 
            id: idx,
            dates: {
              startDate: startDate,
              endDate: endDate
            }
          })
          
          hasPreviousFilters = true
        }
        if (filter.type === FilterType.dateFixed && filter.input) {
          const date = new Date(filter.input.replace('Z', ''))

          currentDateFixedValues.push({ 
            id: idx,
            value: date
          })
          
          hasPreviousFilters = true
        }
        if (filter.type === FilterType.multiSelect && filter.input?.length > 0) {
          currentMultiSelectValues.push({
            id: idx,
            values: JSON.parse(JSON.stringify(filter.input))
          })

          hasPreviousFilters = true
        }
        if (filter.type === FilterType.radioSelect && filter.input?.length > 0) {
          currentMultiSelectValues.push({
            id: idx,
            values: JSON.parse(JSON.stringify(filter.input))
          })

          hasPreviousFilters = true
        }
        if (filter.type === FilterType.singleSelect) {
          if (filter.input) {
            currentSingleSelectValues.push({
              id: idx,
              value: filter.input
            })

            hasPreviousFilters = true
          }

          currentSingleSelectValuesFilteredOptions.push({ 
            id: idx,
            options: JSON.parse(JSON.stringify(filter.dataset))
          })
        }
        if (filter.type === FilterType.multiSelectWithChildren && filter.input?.length > 0) {
          currentMultiSelectWithChildren.push({
            id: idx,
            values: JSON.parse(JSON.stringify(filter.input))
          })

          filter.input.forEach((option) => {
            if (option.children?.length > 0) {
              currentOpenedParents.push(`${idx}-${option.id}`)
            }
          })

          hasPreviousFilters = true
        }
      })

      setHadPreviousFilters(hasPreviousFilters)
      setOpenedFilters(currentOpenedFilters)
      setDateRangeValues(currentDateRangeValues)
      setDateFixedValues(currentDateFixedValues)
      setMultiSelectValues(currentMultiSelectValues)
      setSingleSelectValues(currentSingleSelectValues)
      setSingleSelectValuesFilteredOptions(currentSingleSelectValuesFilteredOptions)
      setMultiSelectWithChildrenValues(currentMultiSelectWithChildren)
      setOpenedParents(currentOpenedParents)
      setShowAllParents(currentOpenedParents)
    }
  }, [filters])

  const buildFilterHeader = (id, title, isFilterOpen) => {
    const toggleFilter = () => {
      if (isFilterOpen) {
        setOpenedFilters(openedFilters.filter((item) => item !== id))
      } else {
        setOpenedFilters([...openedFilters, id])
      }
    }

    return (
      <div 
        className="filter-header" 
        onClick={() => toggleFilter()}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => e.key === keyboardEvents.ENTER && toggleFilter()}
      >
        <p className="title">{title}</p>
        <div className="icon">{isFilterOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
      </div>
    )
  }

  const buildDateRangeFilter = (id, title, withStartDateOnly, hasMinDate) => {
    const isFilterOpen = openedFilters.includes(id)

    const CustomStartDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <div className="custom-date-input" ref={ref} onClick={onClick}>
        <div className="date-text">{value ? value : t("events.select-date")}</div>
        <CalendarIcon className="calendar-icon" />
      </div>
    ))

    const CustomEndDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <div className="custom-date-input" ref={ref} onClick={onClick}>
        <div className="date-text">{value ? value : t("events.select-date")}</div>
        <CalendarIcon className="calendar-icon" />
      </div>
    ))

    const currentSelectedDates = dateRangeValues.find((item) => item.id === id)?.dates

    const selectStartDateValue = (startDate) => {
      if (currentSelectedDates) {
        setDateRangeValues(
          dateRangeValues.map((item) => (item.id === id ? { id: id, dates: { startDate: startDate, endDate: currentSelectedDates.endDate }, withStartDateOnly } : item))
        )
      } else {
        setDateRangeValues([...dateRangeValues, { id: id, dates: { startDate: startDate, endDate: null }, withStartDateOnly }])
      }
    }

    const selectEndDateValue = (endDate) => {
      if (currentSelectedDates) {
        setDateRangeValues(
          dateRangeValues.map((item) => (item.id === id ? { id: id, dates: { startDate: currentSelectedDates.startDate, endDate: endDate }, withStartDateOnly } : item))
        )
      } else {
        setDateRangeValues([...dateRangeValues, { id: id, dates: { startDate: null, endDate: endDate }, withStartDateOnly }])
      }
    }

    return (
      <div className="filter-wrapper">
        {buildFilterHeader(id, title, isFilterOpen)}
        {isFilterOpen && (
          <div className="filter-date-content">
            <p className="input-option">{t("events.start-date")}</p>
            <DatePicker
              selected={currentSelectedDates && currentSelectedDates.startDate ? currentSelectedDates.startDate : null}
              onChange={(date) => selectStartDateValue(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<CustomStartDateInput />}
              showYearDropdown
              showMonthDropdown
              minDate={hasMinDate && new Date().setFullYear(new Date().getFullYear() - 5)}
              maxDate={new Date()}
            />
            <p className="input-option">{t("events.end-date")}</p>
            <DatePicker
              selected={currentSelectedDates && currentSelectedDates.endDate ? currentSelectedDates.endDate : null}
              onChange={(date) => selectEndDateValue(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<CustomEndDateInput />}
              showYearDropdown
              showMonthDropdown
              minDate={hasMinDate && new Date().setFullYear(new Date().getFullYear() - 5)}
              maxDate={new Date()}
            />
          </div>
        )}
      </div>
    )
  }

  const buildDateFixedFilter = (id, title, isYearPicker, hasMinDate) => {
    const isFilterOpen = openedFilters.includes(id)

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
      <div className="custom-date-input" ref={ref} onClick={onClick}>
        <div className="date-text">{value ? value : t("events.select-date")}</div>
        <CalendarIcon className="calendar-icon" />
      </div>
    ))

    const currentSelectedDate = dateFixedValues.find((item) => item.id === id)?.value

    const selectDateValue = (date) => {
      if (currentSelectedDate) {
        setDateFixedValues(
          dateFixedValues.map((item) => (item.id === id ? { id: id, value: date } : item))
        )
      } else {
        setDateFixedValues([...dateFixedValues, { id: id, value: date }])
      }
    }

    return (
      <div className="filter-wrapper">
        {buildFilterHeader(id, title, isFilterOpen)}
        {isFilterOpen && (
          <div className="filter-date-content">
            <DatePicker
              selected={currentSelectedDate}
              onChange={(date) => selectDateValue(date)}
              showYearPicker={isYearPicker}
              dateFormat={isYearPicker ? "yyyy": "yyyy-MM-dd"}
              yearItemNumber={9}
              customInput={<CustomDateInput />}
              showYearDropdown={!isYearPicker}
              showMonthDropdown
              minDate={hasMinDate && new Date().setFullYear(new Date().getFullYear() - 5)}
              maxDate={new Date()}
            />
          </div>
        )}
      </div>
    )
  }

  const buildMultiSelectFilter = (id, title, options) => {
    const isFilterOpen = openedFilters.includes(id)
    let currentSelectedValues = multiSelectValues.find((item) => item.id === id)?.values

    const selectMultiSelectValue = (option) => {
      if (currentSelectedValues) {
        if (currentSelectedValues.includes(option)) {
          const newValues = currentSelectedValues.filter((item) => item !== option)
          setMultiSelectValues(multiSelectValues.map((item) => (item.id === id ? { id: id, values: newValues } : item)))
        } else {
          currentSelectedValues = [...currentSelectedValues, option]
          setMultiSelectValues(multiSelectValues.map((item) => (item.id === id ? { id: id, values: currentSelectedValues } : item)))
        }
      } else {
        setMultiSelectValues([...multiSelectValues, { id: id, values: [option] }])
      }
    }

    return (
      <div className="filter-wrapper">
        {buildFilterHeader(id, title, isFilterOpen)}
        {isFilterOpen && (
          <div className="filter-multiselect-content">
            {options?.slice(0, showAllParents.includes(`${id}`) ? options?.length : showAllLimit)?.map((option, index) => (
              <Fragment key={index}>
                <div className="filter-multiselect-item" onClick={() => selectMultiSelectValue(option)}>
                  <CheckboxInput 
                    checked={currentSelectedValues?.includes(option) ?? false}
                    text={t([`filters.${option}`, option])}
                  />
                </div>
              </Fragment>
            ))}
            {!showAllParents.includes(`${id}`) && options?.length > showAllLimit && (
              <div className="show-all" onClick={() => setShowAllParents([...showAllParents, `${id}`])}>
                {t("labels.show_all", {amount: options.length})}
              </div>)
            }
          </div>
        )}
      </div>
    )
  }

  const buildRadioSelectFilter = (id, title, options) => {
    const isFilterOpen = openedFilters.includes(id)
    let currentSelectedValues = multiSelectValues.find((item) => item.id === id)?.values

    const selectMultiSelectValue = (option) => {
      if (currentSelectedValues) {
        if (currentSelectedValues.includes(option)) {
          const newValues = currentSelectedValues.filter((item) => item !== option)
          setMultiSelectValues(multiSelectValues.map((item) => (item.id === id ? { id: id, values: newValues } : item)))
        } else {
          currentSelectedValues = [...currentSelectedValues, option]
          setMultiSelectValues(multiSelectValues.map((item) => (item.id === id ? { id: id, values: currentSelectedValues } : item)))
        }
      } else {
        setMultiSelectValues([...multiSelectValues, { id: id, values: [option] }])
      }
    }

    return (
      <div className="filter-wrapper">
        {buildFilterHeader(id, title, isFilterOpen)}
        {isFilterOpen && (
          <div className="filter-multiselect-content">
            {options?.slice(0, showAllParents.includes(`${id}`) ? options?.length : showAllLimit)?.map((option, index) => (
              <Fragment key={index}>
                <div className="filter-multiselect-item" onClick={() => selectMultiSelectValue(option)}>
                  <RadioInput 
                    checked={currentSelectedValues?.includes(option) ?? false}
                    text={t([`filters.${option}`, option])}
                  />
                </div>
              </Fragment>
            ))}
            {!showAllParents.includes(`${id}`) && options?.length > showAllLimit && (
              <div className="show-all" onClick={() => setShowAllParents([...showAllParents, `${id}`])}>
                {t("labels.show_all", {amount: options.length})}
              </div>)
            }
          </div>
        )}
      </div>
    )
  }

  const buildSingleSelectFilter = (id, title, options) => {
    const isFilterOpen = openedFilters.includes(id)
    const currentSelectedValue = singleSelectValues.find((item) => item.id === id)?.value
    const filteredOptions = singleSelectValuesFilteredOptions.find((item) => item.id === id)?.options

    const selectSingleSelectOption = (option, toggleVisibility) => {
      setSingleSelectValuesFilteredOptions(singleSelectValuesFilteredOptions.map((item) => (item.id === id ? { id: id, options: JSON.parse(JSON.stringify(options)) } : item)))

      toggleVisibility(false)

      if (currentSelectedValue) {
        if (currentSelectedValue === option) {
          return
        }

        setSingleSelectValues(singleSelectValues.map((item) => (item.id === id ? { id: id, value: option } : item)))
      } else {
        setSingleSelectValues([...singleSelectValues, { id: id, value: option }])
      }
    }

    const resetSingleSelectOption = () => {
      setSingleSelectValues(singleSelectValues.filter((item) => item.id !== id))
    }

    const searchSingleSelectOption = (value) => {
      if (value) {
        const filtered = options.filter(option => t([`filters.${option}`, option])?.toLowerCase()?.includes(value.toLowerCase()))
        setSingleSelectValuesFilteredOptions(singleSelectValuesFilteredOptions.map((item) => (item.id === id ? { id: id, options: filtered } : item)))
      } else {
        setSingleSelectValuesFilteredOptions(singleSelectValuesFilteredOptions.map((item) => (item.id === id ? { id: id, options: JSON.parse(JSON.stringify(options)) } : item)))
      }
    }
  
    const onSelectSingleSelectInput = (isComponentVisible, toggleVisibility) => {
      if (isComponentVisible) {
        toggleVisibility(false)
        setSingleSelectValuesFilteredOptions(singleSelectValuesFilteredOptions.map((item) => (item.id === id ? { id: id, options: JSON.parse(JSON.stringify(options)) } : item)))
      } else {
        setSingleSelectValuesFilteredOptions(singleSelectValuesFilteredOptions.map((item) => (item.id === id ? { id: id, options: JSON.parse(JSON.stringify(options)) } : item)))
        toggleVisibility(true)
      }
    }
  
    const selectSingleSelectInput = ({toggleVisibility, isComponentVisible}) => (
      <div className={"select-input" + (isComponentVisible ? " is-focused" : "")}>
        <div 
          className="height d-flex flex-align-center flex-justify-between" 
          onClick={() => onSelectSingleSelectInput(isComponentVisible, toggleVisibility)}
        >
          <div className={"text-wrapper" + (!currentSelectedValue ? " placeholder": "")}>
            {currentSelectedValue ? t([`filters.${currentSelectedValue}`, currentSelectedValue]) : t('placeholders.please_select')}
          </div>
          {currentSelectedValue && (
            <button className="reset-filter-button" onClick={(e) => {e.stopPropagation(); resetSingleSelectOption()}}>
              <CloseCircleIcon/>
            </button>)
          }
          <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
        </div>
      </div>
    )
  
    const selectSingleSelectOptions = ({toggleVisibility}) => (
      <div className="options-wrapper d-flex flex-align-center flex-column">
        <div className="options-filter">
          <Search
            className="search"
            onSearch={(e) => searchSingleSelectOption(e)}
            placeholder={t('placeholders.search')}
            onCancel={() => searchSingleSelectOption(null)}
          />
        </div>
        {filteredOptions?.map((option, index) => (
          <div 
            className={"option cursor-pointer no-wrap" + (option === currentSelectedValue ? " selected-option" : "")} 
            key={index}
            onClick={() => selectSingleSelectOption(option, toggleVisibility)}
          >
            {t([`filters.${option}`, option])}
          </div>
        ))
        }
      </div>
    )

    return (
      <div className="filter-wrapper">
        {buildFilterHeader(id, title, isFilterOpen)}
        {isFilterOpen && (
          <div className="filter-singleselect-content">
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectSingleSelectInput}
              toDisplayItem={selectSingleSelectOptions}/>
          </div>
        )}
      </div>
    )
  }

  const buildMultiSelectWithChildrenFilter = (id, title, options) => {
    const isFilterOpen = openedFilters.includes(id)
    let currentSelectedValues = multiSelectWithChildrenValues.find((item) => item.id === id)?.values

    const toggleParent = (key, isOpened) => {
      if (isOpened) {
        setOpenedParents(openedParents.filter((item) => item !== key))
      } else {
        setOpenedParents([...openedParents, key])
      }
    }

    const selectParentForMultiSelectWithChildrenValue = (filterId, parentId, children) => {
      if (currentSelectedValues) {
        let currentSelectedValuesParent = currentSelectedValues.find((item) => item.id === parentId)

        if (currentSelectedValuesParent) {
          currentSelectedValues = currentSelectedValues.filter((item) => item.id !== parentId)
        } else {
          currentSelectedValues = [...currentSelectedValues, { id: parentId, children: children.map((child) => child.id) }]
          toggleParent(`${filterId}-${parentId}`, false)
          setShowAllParents([...showAllParents, `${filterId}-${parentId}`])
        }

        setMultiSelectWithChildrenValues(multiSelectWithChildrenValues.map((item) => (item.id === id ? { id: id, values: currentSelectedValues } : item)))
      } else {
        setMultiSelectWithChildrenValues([...multiSelectWithChildrenValues, { id: id, values: [{ id: parentId, children: children.map((child) => child.id) }] }])
        toggleParent(`${filterId}-${parentId}`, false)
        setShowAllParents([...showAllParents, `${filterId}-${parentId}`])
      }
    }

    const selectChildForMultiSelectWithChildrenValue = (parentId, childId) => {
      if (currentSelectedValues) {
        let currentSelectedValuesParent = currentSelectedValues.find((item) => item.id === parentId)

        if (currentSelectedValuesParent) {
          let currentSelectedValuesParentChildren = currentSelectedValuesParent.children?.includes(childId)

          if (currentSelectedValuesParentChildren) {
            currentSelectedValuesParent.children = currentSelectedValuesParent.children.filter((item) => item !== childId)
          } else {
            currentSelectedValuesParent.children = [...currentSelectedValuesParent.children, childId]
          }

          if (currentSelectedValuesParent.children.length === 0) {
            currentSelectedValues = currentSelectedValues.filter((item) => item.id !== parentId)
          } else {
            currentSelectedValues = currentSelectedValues.map((item) => (item.id === parentId ? currentSelectedValuesParent : item))
          }
        } else {
          currentSelectedValues = [...currentSelectedValues, { id: parentId, children: [childId] }]
        }

        setMultiSelectWithChildrenValues(multiSelectWithChildrenValues.map((item) => (item.id === id ? { id: id, values: currentSelectedValues } : item)))
      } else {
        setMultiSelectWithChildrenValues([...multiSelectWithChildrenValues, { id: id, values: [{ id: parentId, children: [childId] }] }])
      }
    }

    return (
      <div className="filter-wrapper">
        {buildFilterHeader(id, title, isFilterOpen)}
        {isFilterOpen && (
          <div className="filter-multiselect-with-children-content">
            {options?.map((parent, index) => (
              <Fragment key={index}>
                <div className="filter-multiselect-with-children-item">
                  <div className="parent-option">
                    <div className="parent-checkbox d-flex flex-align-center" onClick={() => selectParentForMultiSelectWithChildrenValue(id, parent.id, parent.children)}>
                      <CheckboxInput
                        checked={currentSelectedValues?.find((item) => item.id === parent.id) ?? false}
                        text={parent.name}
                        icon={currentSelectedValues?.find((item) => item.id === parent.id)?.children?.length === parent.children?.length ? <TickIcon/> : <MinusSimpleIcon className="minus-icon"/>}
                      />
                    </div>
                    {openedParents.includes(`${id}-${parent.id}`) 
                      ? (<div className="multiselect-icon" onClick={() => toggleParent(`${id}-${parent.id}`, true)}>
                          <div className="icon-wrapper">
                            <CaretDownIcon className="up-icon"/>
                          </div>
                        </div>) 
                      : (<div className="multiselect-icon" onClick={() => toggleParent(`${id}-${parent.id}`, false)}>
                          <div className="icon-wrapper">
                            <CaretDownIcon/>
                          </div>
                        </div>)
                    }
                  </div>
                  {openedParents.includes(`${id}-${parent.id}`) &&
                    parent.children?.slice(0, showAllParents.includes(`${id}-${parent.id}`) ? parent.children?.length : showAllLimit)?.map((child, idx) => (
                      <Fragment key={idx}>
                        <div className="child-option" onClick={() => selectChildForMultiSelectWithChildrenValue(parent.id, child.id)}>
                          <CheckboxInput
                            checked={currentSelectedValues?.find((item) => item.id === parent.id)?.children?.includes(child.id) ?? false}
                            text={child.name}
                          />
                        </div>
                      </Fragment>
                    ))}
                  {openedParents.includes(`${id}-${parent.id}`) && !showAllParents.includes(`${id}-${parent.id}`) && parent.children?.length > showAllLimit && (
                    <div className="show-all" onClick={() => setShowAllParents([...showAllParents, `${id}-${parent.id}`])}>
                      {t("labels.show_all", {amount: parent.children.length})}
                    </div>)
                  }
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    )
  }

  const areDateRangesValid = () => {
    for (let i = 0; i < dateRangeValues.length; i++) {
      const item = dateRangeValues[i]

      if (item.withStartDateOnly && !item.dates.endDate) {
        if (!item.dates.startDate) {
          toast.error(t("error.start_date_required"))
          return false
        }

        return true
      }

      if (!item.dates.startDate || !item.dates.endDate) {
        toast.error(t("error.dates_required"))
        return false
      }

      if (item.dates.startDate > item.dates.endDate) {
        toast.error(t("error.start_date_late"))
        return false
      }
    }

    return true
  }

  const determineShouldDoFiltering = () => {
    let hasDateRangeChanges = false

    filters.forEach((filter, index) => {
      if (filter.type === FilterType.dateRange) {
        const currentDates = dateRangeValues.find((item) => item.id === index)?.dates

        if (currentDates && (currentDates.startDate?.toISOString() !== filter.input?.startDate || currentDates.endDate?.toISOString() !== filter.input?.endDate)) {
          hasDateRangeChanges = true
        }
      }
    })

    if (hasDateRangeChanges) {
      return
    }

    let hasFixedDateChanges = false

    filters.forEach((filter, index) => {
      if (filter.type === FilterType.dateFixed) {
        const currentDateFixed = dateFixedValues.find((item) => item.id === index)?.value

        if (currentDateFixed && (currentDateFixed.toISOString() !== filter.input)) {
          hasFixedDateChanges = true
        }
      }
    })

    if (hasFixedDateChanges) {
      return
    }

    let hasSingleSelectChanges = false

    filters.forEach((filter, index) => {
      if (filter.type === FilterType.singleSelect) {
        const currentSingleSelect = singleSelectValues.find((item) => item.id === index)?.value

        if (currentSingleSelect && (currentSingleSelect !== filter.input)) {
          hasSingleSelectChanges = true
        }
      }
    })

    if (hasSingleSelectChanges) {
      return
    }

    let hasMultiSelectChanges = false

    filters.forEach((filter, index) => {
      if (filter.type === FilterType.multiSelect) {
        const currentMultiSelect = multiSelectValues.find((item) => item.id === index)?.values

        if (currentMultiSelect) {
          if (currentMultiSelect.length !== filter.input.length) {
            if (filter.input.length === 0 && (currentMultiSelect.length === filter.dataset.length && filter.dataset.length !== 1)) {
              return
            }
            if (filter.input.length === filter.dataset.length && currentMultiSelect.length === 0) {
              return
            }

            hasMultiSelectChanges = true
          } else {
            for (let i = 0; i < currentMultiSelect.length; i++) {
              if (currentMultiSelect[i] !== filter.input[i]) {
                hasMultiSelectChanges = true
                break
              }
            }
          }
        }
      }
    })

    if (hasMultiSelectChanges) {
      return
    }

    let hasMultiSelectWithChildrenChanges = false

    filters.forEach((filter, index) => {
      if (filter.type === FilterType.multiSelectWithChildren) {
        const currentMultiSelectWithChildren = multiSelectWithChildrenValues.find((item) => item.id === index)?.values

        if (currentMultiSelectWithChildren) {
          if (currentMultiSelectWithChildren.length !== filter.input.length) {
            if (filter.input.length === 0 && currentMultiSelectWithChildren.length === filter.dataset.length) {
              return
            }

            if (filter.input.length === filter.dataset.length && currentMultiSelectWithChildren.length === 0) {
              return
            }

            hasMultiSelectWithChildrenChanges = true
          } else {
            for (let i = 0; i < currentMultiSelectWithChildren.length; i++) {
              if (currentMultiSelectWithChildren[i].id !== filter.input[i].id) {
                hasMultiSelectWithChildrenChanges = true
                break
              }

              if (currentMultiSelectWithChildren[i].children.length !== filter.input[i].children.length) {
                hasMultiSelectWithChildrenChanges = true
                break
              }

              for (let j = 0; j < currentMultiSelectWithChildren[i].children.length; j++) {
                if (currentMultiSelectWithChildren[i].children[j] !== filter.input[i].children[j]) {
                  hasMultiSelectWithChildrenChanges = true
                  break
                }
              }
            }
          }
        }
      }
    })

    if (hasMultiSelectWithChildrenChanges) {
      return
    }

    if (hadPreviousFilters) {
      handleResetFilters()
      return
    }

    if (shouldFilter) {
      shouldFilter(false)
    }
  }

  const applyDateRangeFilters = (index, output, withStartDateOnly) => {
    const dates = dateRangeValues.find((item) => item.id === index)?.dates

    if (output && dates) {
      const startDate = new Date(Date.UTC(dates.startDate?.getFullYear(), dates.startDate?.getMonth(), dates.startDate?.getDate())).toISOString()
      const endDate = new Date(Date.UTC(dates.endDate?.getFullYear(), dates.endDate?.getMonth(), dates.endDate?.getDate(), 23, 59, 59)).toISOString()
      output(startDate, endDate ? endDate : withStartDateOnly ? new Date().toISOString() : null)
    }
  }

  const applyDateFixedFilters = (index, output) => {
    const dateFixed = dateFixedValues.find((item) => item.id === index)?.value

    if (output && dateFixed) {
      const date = new Date(Date.UTC(dateFixed.getFullYear(), dateFixed.getMonth(), dateFixed.getDate())).toISOString()
      output(date)
    }
  }

  const applyMultiSelectFilters = (index, output) => {
    const multiSelect = multiSelectValues.find((item) => item.id === index)?.values || []

    if (output && multiSelect) {
      output(multiSelect)
    }
  }

  const applySingleSelectFilters = (index, output) => {
    const singleSelect = singleSelectValues.find((item) => item.id === index)?.value || null

    if (output) {
      output(singleSelect)
    }
  }

  const applyMultiSelectWithChildrenFilters = (index, output) => {
    const multiSelectWithChildren = multiSelectWithChildrenValues.find((item) => item.id === index)?.values

    if (output && multiSelectWithChildren) {
      output(multiSelectWithChildren)
    }
  }

  const applyFilters = () => {
    if (!areDateRangesValid()) {
      return
    }

    determineShouldDoFiltering()

    filters.forEach((filter, index) => {
      switch (filter.type) {
        case FilterType.dateRange:
          applyDateRangeFilters(index, filter.output, filter.withStartDateOnly)
          break
        case FilterType.dateFixed:
          applyDateFixedFilters(index, filter.output)
          break
        case FilterType.multiSelect:
          applyMultiSelectFilters(index, filter.output)
          break
        case FilterType.radioSelect:
          applyMultiSelectFilters(index, filter.output)
          break
        case FilterType.singleSelect:
          applySingleSelectFilters(index, filter.output)
          break
        case FilterType.multiSelectWithChildren:
          applyMultiSelectWithChildrenFilters(index, filter.output)
          break
        default:
          break
      }
    })

    closeFilters()
  }

  const handleResetFilters = () => {
    if (resetFilters) {
      setDateRangeValues([])
      setDateFixedValues([])
      setMultiSelectValues([])
      setSingleSelectValues([])
      setMultiSelectWithChildrenValues([])

      if (hadPreviousFilters) {
        resetFilters()
      }
    }
  }

  const hasFilters = () => {
    const dateRangeHasValues = dateRangeValues.find((item) => item.dates.startDate || item.dates.endDate)
    const dateFixedHasValues = dateFixedValues.find((item) => item.value)
    const multiSelectHasValues = multiSelectValues.find((item) => item.values.length > 0)
    const singleSelectHasValues = singleSelectValues.find((item) => item.value)
    const multiSelectWithChildrenHasValues = multiSelectWithChildrenValues.find((item) => item.values.length > 0)
  
    return dateRangeHasValues || dateFixedHasValues || multiSelectHasValues || singleSelectHasValues || multiSelectWithChildrenHasValues || hadPreviousFilters
  }

  return (
    <div className="filters-modal-page">
      <div
        ref={backgroundRef}
        className="filters-overflow"
        onClick={()=> closeFilters()}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => e.key === keyboardEvents.ESC && closeFilters()}
      />
      <div className="filters-modal-wrapper">
        <div className="filters-header">
          <div 
            className="filters-back-button" 
            onClick={() => closeFilters()}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => e.key === keyboardEvents.ENTER && closeFilters()}
          >
            <div className="back-icon">
              <ArrowBackThickIcon />
            </div>
            <p className="title">{t("buttons.filters")}</p>
          </div>
          {hasFilters() && (
            <div 
              className="clear-all-button" 
              onClick={() => handleResetFilters()}
              tabIndex={0}
              role="button"
              onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleResetFilters()}
            >
              {t("labels.clear_all")}
            </div>)
          }
        </div>
        <div className="filters-body">
          {filters?.map((filter, index) => {
            switch (filter.type) {
              case FilterType.dateRange:
                return <Fragment key={index}>{buildDateRangeFilter(index, filter.name, filter.withStartDateOnly, filter.hasMinDate)}</Fragment>
              case FilterType.dateFixed:
                return <Fragment key={index}>{buildDateFixedFilter(index, filter.name, filter.yearPicker, filter.hasMinDate)}</Fragment>
              case FilterType.multiSelect:
                return <Fragment key={index}>{buildMultiSelectFilter(index, filter.name, filter.dataset)}</Fragment>
              case FilterType.radioSelect:
                return <Fragment key={index}>{buildRadioSelectFilter(index, filter.name, filter.dataset)}</Fragment>
              case FilterType.singleSelect:
                return <Fragment key={index}>{buildSingleSelectFilter(index, filter.name, filter.dataset)}</Fragment>
              case FilterType.multiSelectWithChildren:
                return <Fragment key={index}>{buildMultiSelectWithChildrenFilter(index, filter.name, filter.dataset)}</Fragment>
              default:
                return null
            }
          })}
        </div>
        <div className="filters-footer">
          <SimpleButton className="cancel-button" onClick={() => closeFilters()}>
            {t("buttons.cancel")}
          </SimpleButton>
          <SimpleButton className="submit-button" onClick={() => applyFilters()} disabled={!hasFilters()}>
            {t("buttons.apply")}
          </SimpleButton>
        </div>
      </div>
    </div>
  )
}

export default FiltersModal
