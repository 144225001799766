import {
  LOAD_FIRMWARE_DOWNLOAD_PENDING,
  LOAD_FIRMWARE_DOWNLOAD_FULFILLED,
  LOAD_FIRMWARE_DOWNLOAD_REJECTED,
  CLEAR_FIRMWARE_DOWNLOAD_MESSAGES,
  CLEAR_FIRMWARE_DOWNLOAD,
} from "../actions/firmware"
import i18n from 'i18next'

export default function firmwareDownload(state = {}, action) {
  switch (action.type) {
    case LOAD_FIRMWARE_DOWNLOAD_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }
    case LOAD_FIRMWARE_DOWNLOAD_REJECTED:
      const errorMessageCode = action.payload.response?.data?.code
      
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${errorMessageCode}`,"error.something_wrong"])
      }
    case LOAD_FIRMWARE_DOWNLOAD_FULFILLED:
      return {
        ...state,
        firmwares: action.payload.data,
        isLoading: false,
      }
    case CLEAR_FIRMWARE_DOWNLOAD_MESSAGES: {
      return {
        ...state,
        errorMessage: null
      }
    }
    case CLEAR_FIRMWARE_DOWNLOAD:
      return {}
    default:
      return state
  }
}
