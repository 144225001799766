import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import PageModal from "../common/PageModal"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from '../common/SimpleButton'
import LoadingSpinner from '../common/LoadingSpinner'
import { toast } from "../common/Toast"
import { CaretDownIcon } from '../../icons'
import { getModels } from "../../utils/requests/productsAPI"
import { sendMail } from "../../utils/requests/mqttPublishAPI"
import { registerProduct } from "../../utils/requests/usersAPI"
import { getModelBySerial } from "../../utils/requests/productsAPI"
import { putG4OnlineAccess } from "../../utils/requests/onlineActivation"
import { handleSetOnlineAccess } from "../../actions/onlineAccess"
import { handleSetWarranty } from "../../actions/warranty"
import { handleSetUserProducts } from "../../actions/userProducts"
import { formatPhone } from "../../utils/functions"
import { useAppContext } from "../../libs/contextLib"
import { registerProductWritePermission } from "../../utils/permissionValidation"
import "./register-product-modern.scss"

const RegisterProductModern = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { closeModal, dispatch } = props
  const {isAuthenticated} = useAppContext()
  const [serialNumber, setSerialNumber] = useState("")
  const [modelsData, setModelsData] = useState([])
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [dealerInfoName, setDealerInfoName] = useState("")
  const [dealerInfoEmail, setDealerInfoEmail] = useState("")
  const [dealerInfoPhone, setDealerInfoPhone] = useState("")
  const [salesRepName, setSalesRepName] = useState("")
  const [salesRepEmail, setSalesRepEmail] = useState("")
  const [salesRepPhone, setSalesRepPhone] = useState("")
  const [officeName, setOfficeName] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [hasValidSerialNumber, setHasValidSerialNumber] = useState(true)
  const [hasValidProduct, setHasValidProduct] = useState(true)
  const [hasValidCompany, setHasValidCompany] = useState(true)
  const [hasValidDealerEmail, setHasValidDealerEmail] = useState(true)
  const [hasValidSalesRepEmail, setHasValidSalesRepEmail] = useState(true)
  const [hasValidDealerPhone, setHasValidDealerPhone] = useState(true)
  const [hasValidSalesRepPhone, setHasValidSalesRepPhone] = useState(true)
  const locale = localStorage.getItem("i18nextLng").replace(/-/g, "_");
  const email = `${props.authedUser?.email}`
  const templateName = `ocp_dentist_warranty_registration_${locale}`
  const emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  const phonePattern = new RegExp(/^\d*$/)

  useEffect(() => {
    const state = history.location.state
    delete state?.registrationSuccess
    delete state?.phaseSuccess
    history.replace(`${history.location.pathname}`, { ...history.location, state })
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(handleSetWarranty({
        action: null
      }))
    }
  },[dispatch])

  useEffect(() => {
    if (props.onlineAccess?.type?.toLowerCase() === "g4") {
      setSerialNumber(props.onlineAccess?.sn)
    }
  }, [props.onlineAccess?.sn, props.onlineAccess?.type])

  useEffect(() => {
    setShowSpinner(true)

    getModels()
      .then((response) => {
        const groups = Object.values(response.data).reduce(
          (groups, item) => ({
            ...groups,
            [item.model]: [...(groups[item.model] || []), item],
          }),
          {}
        )
        
        setModelsData(Object.keys(groups).map(group => groups[group][0]))
        setProducts(Object.keys(groups).sort());
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }, [])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (serialNumber.length > 3 && (!selectedProduct || selectedProduct.length ===0)) {
          setShowSpinner(true)
          setLoadingProduct(true)

          getModelBySerial({SN: serialNumber})
            .then((snModel) => {
              if (snModel.status === 1 && snModel.data[0].model.length > 0 && snModel.data[0].model !== "SCICAN LT") {
                selectProduct(snModel.data[0].model)
              }

              setShowSpinner(false)
              setLoadingProduct(false)
            })
            .catch(() => {
              setShowSpinner(false)
              setLoadingProduct(false)
            })
        }
      },
      300
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  },[serialNumber])

  useEffect(() => {
    if (serialNumber.length === 0) {
      selectProduct(null)
    }
  }, [serialNumber])

  const registerProductFooterContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => handleOnClose()}
        >
          {t('buttons.cancel')}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={() => handleRegisterProduct()}
          disabled={showSpinner}
          requiredPermission={registerProductWritePermission}
        >
          {t('register.register')}
        </SimpleButton>
      </>
    )
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectProduct = (product, toggleVisibility) => {
    setSelectedProduct(product)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectProductInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedProduct ? selectedProduct : t("product.no_product_selected")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectProductOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {products.map((product, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (product === selectedProduct ? " selected-option" : "")} 
          key={index}
          onClick={() => selectProduct(product, toggleVisibility)}
        >
          {product}
        </div>)
      }
    </div>
  )

  const handleChangeSerialNumber = (value) => {
    const upperTrimmedValue = value?.toUpperCase()?.trim()
    setSerialNumber(upperTrimmedValue || "")
  }

  const isValidSerialNumber = () => {
    const serialNumberLength = serialNumber.trim().length
    if (serialNumberLength === 0 || serialNumberLength > 12) {
      setHasValidSerialNumber(false)
      toast.error(t("error.invalid_serial_number"))

      return false
    }

    setHasValidSerialNumber(true)
    return true
  }

  const isValidProduct = () => {
    if (!selectedProduct) {
      setHasValidProduct(false)
      toast.error(t("error.invalid_product"))

      return false
    }

    setHasValidProduct(true)
    return true
  }

  const areValidAdditionalFields = () => {
    if (officeName.trim().length === 0) {
      setHasValidCompany(false)
      toast.error(t("error.fill_all_fields"))

      return false
    }

    setHasValidCompany(true)

    if (dealerInfoEmail.trim().length > 0 && !emailPattern.test(dealerInfoEmail.toLowerCase())) {
      setHasValidDealerEmail(false)
      toast.error(t("error.email"))

      return false
    }

    setHasValidDealerEmail(true)

    if (salesRepEmail.trim().length > 0 && !emailPattern.test(salesRepEmail.toLowerCase())) {
      setHasValidSalesRepEmail(false)
      toast.error(t("error.email"))

      return false
    }

    setHasValidSalesRepEmail(true)
    
    if (dealerInfoPhone.trim().length > 0 && !phonePattern.test((dealerInfoPhone).replaceAll("-",""))) {
      setHasValidDealerPhone(false)
      toast.error(t("error.phone"))

      return false
    }

    setHasValidDealerPhone(true)
    if (salesRepPhone.trim().length > 0 && !phonePattern.test((salesRepPhone).replaceAll("-",""))) {
      setHasValidSalesRepPhone(false)
      toast.error(t("error.phone"))

      return false
    }

    setHasValidSalesRepPhone(true)

    return true
  }

  const handleRegisterProduct = async () => {
    if (!isValidSerialNumber()) {
      return
    }

    setShowSpinner(true)

    const sn = serialNumber.trim().toUpperCase()

    const snModel = await getModelBySerial({SN: sn})
    if(snModel.status === 1 && snModel.data[0]?.model?.length > 0 && snModel.data[0]?.model !== "SCICAN LT") {
      setSelectedProduct(snModel.data[0].model)
    }
    
    if (!isValidProduct() || !areValidAdditionalFields()) {
      setShowSpinner(false)
      return
    }

    const model = modelsData?.find(modelOption => modelOption.model === selectedProduct)
    const modelId = model?.model_id || ""

    try {
      const shouldRegister = model.is_g4 === 1
      if(shouldRegister) {
        const registrationBody = {
          model_id: modelId,
          model: selectedProduct,
          association_active: 0,
          office_name: officeName,
          dealer_name: dealerInfoName,
          dealer_phone_1: dealerInfoPhone,
          dealer_email: dealerInfoEmail,
          sales_rep_name: salesRepName,
          sales_rep_phone_1: salesRepPhone,
          sales_rep_email: salesRepEmail
        }

        const registeredProduct = await registerProduct(
          props.authedUser?.cognitoSub,
          sn,
          registrationBody
        )

        if (registeredProduct?.status === 0) {
          toast.error(t("error.recordExists"))
          return
        }
      }

      const emailTemplateVars = {
        name: `${props.authedUser.firstname} ${props.authedUser.lastname}`,
        serialNumber: sn,
        product_name: selectedProduct,
        sign_in_url: process.env.REACT_APP_HOST
      }
  
      sendMail(email, templateName, emailTemplateVars)

      if (props.onlineAccess?.type?.toLowerCase() === "g4") {
        try {
          const activateG4OnlineAccess = await putG4OnlineAccess({
            state: "pending",
            email: props.onlineAccess?.email,
            sn: props.onlineAccess?.sn,
            ref_id: props.onlineAccess?.refId,
            etk: props.onlineAccess?.etk,
            model_id: props.onlineAccess?.model_id ?? "",
          })

          if (activateG4OnlineAccess.status === 1) {
            history.push(`/`, {
              registrationSuccess: true,
              phaseSuccess: true,
            })
          } else {
            toast.error(t("error.failure_msg"))

            history.push(`/`, {
              registrationSuccess: true,
              phaseSuccess: false,
            })
          }

          dispatch(handleSetOnlineAccess(null))
        } catch (error) {
          let errorMessage = t("error.failure_msg")

          if (error?.response?.data?.error?.code) {
            errorMessage = t(`error.${error.response.data["error"]["code"]}`)
          }

          toast.error(errorMessage)
        }
      } else {
        history.push(`/`, {
          registrationSuccess: true,
          phaseSuccess: false
        })
      }

      dispatch(handleSetUserProducts({ cognitoSub: props.authedUser.cognitoSub}))
      handleOnClose()
    } catch (e) {
      toast.error([t(`error.${e.response?.data?.code}`), t("error.something_wrong")])
    } finally {
      setShowSpinner(false)
    }
  }

  const handleOnClose = () => {
    if (closeModal) {
      dispatch(handleSetOnlineAccess(null))
      closeModal()
    }
  }

  return (
    <div className="register-product-modern-wrapper">
      {isAuthenticated && (
        <PageModal toggle onToggle={() => handleOnClose()} title={t("product.register_product")} footerContent={registerProductFooterContent()}>
          <div className="register-product-modal-content">
            <div className="section-title">
              {t("product.add_serial_number").toUpperCase()}
            </div>
            <div className="section-disclaimer">
              <p>
                {t("product.add_serial_number_disclaimer")}
              </p>
            </div>
            <div className="section-wrapper with-margin-top">
              <div className="input-wrapper">
                <div className="input-label">
                  {`${t("product.serial_number_complete")}*`}
                </div>
                <input
                  type="text" 
                  className={"input-item" + (!hasValidSerialNumber ? " has-error" : "") }
                  value={serialNumber ?? ""}
                  disabled={props.onlineAccess?.sn}
                  onChange={(e) => handleChangeSerialNumber(e.target.value)}>
                </input>
              </div>
            </div>
            <div className="section-wrapper with-margin-top">
              <div className="input-wrapper">
                <div className="input-label">
                  {`${t("product.select_product")}*`}
                </div>
                <ClickOutside 
                  itemRef="click-outside-wrapper"
                  className={`${!hasValidProduct ? "has-error" : ""} ${loadingProduct ? "loading-start" : ""}`}
                  eventItem={selectProductInput}
                  toDisplayItem={selectProductOptions}
                />
              </div>
            </div>
            <div className="separator"/>
            <div className="section-title">
              {t("product.dealer_information").toUpperCase()}
            </div>
            <div className="section-wrapper">
              <div className="input-wrapper">
                <div className="input-label">
                  {`${t("labels.company")}*`}
                </div>
                <input
                  type="text" 
                  className={"input-item" + (!hasValidCompany ? " has-error" : "") }
                  value={officeName ?? ""}
                  onChange={(e) => setOfficeName(e.target.value)}
                ></input>
              </div>
              <div className="input-wrapper">
                <div className="input-label">
                  {t("labels.full_name")}
                </div>
                <input
                  type="text" 
                  className="input-item"
                  value={dealerInfoName ?? ""}
                  onChange={(e) => setDealerInfoName(e.target.value)}>
                </input>
              </div>
            </div>
            <div className="section-wrapper with-margin-top">
              <div className="input-wrapper">
                <div className="input-label">
                  {t("labels.email_address")}
                </div>
                <input
                  type="text" 
                  className={"input-item" + (!hasValidDealerEmail ? " has-error" : "") }
                  value={dealerInfoEmail ?? ""}
                  onChange={(e) => setDealerInfoEmail(e.target.value)}>
                </input>
              </div>
              <div className="input-wrapper">
                <div className="input-label">
                  {t("labels.phone_number")}
                </div>
                <input
                  type="text" 
                  className={"input-item" + (!hasValidDealerPhone ? " has-error" : "") }
                  value={dealerInfoPhone ? formatPhone(dealerInfoPhone) : ""}
                  onChange={(e) => setDealerInfoPhone(formatPhone(e.target.value))}>
                </input>
              </div>
            </div>
            <div className="separator"/>
            <div className="section-title">
              {t("product.sales_rep_information").toUpperCase()}
            </div>
            <div className="section-wrapper">
              <div className="input-wrapper">
                <div className="input-label">
                  {t("labels.full_name")}
                </div>
                <input
                  type="text" 
                  className="input-item"
                  value={salesRepName ?? ""}
                  onChange={(e) => setSalesRepName(e.target.value)}>
                </input>
              </div>
              <div className="input-wrapper">
              </div>
            </div>
            <div className="section-wrapper with-margin-top with-margin-bottom">
              <div className="input-wrapper">
                <div className="input-label">
                  {t("labels.email_address")}
                </div>
                <input
                  type="text" 
                  className={"input-item" + (!hasValidSalesRepEmail ? " has-error" : "") }
                  value={salesRepEmail ?? ""}
                  onChange={(e) => setSalesRepEmail(e.target.value)}>
                </input>
              </div>
              <div className="input-wrapper">
                <div className="input-label">
                  {t("labels.phone_number")}
                </div>
                <input
                  type="text" 
                  className={"input-item" + (!hasValidSalesRepPhone ? " has-error" : "") }
                  value={salesRepPhone ? formatPhone(salesRepPhone) : ""}
                  onChange={(e) => setSalesRepPhone(formatPhone(e.target.value))}>
                </input>
              </div>
            </div>
          </div>
        </PageModal>
      )}
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ authedUser, onlineAccess }) {
  return {
    authedUser,
    onlineAccess
  }
}
export default connect(mapStateToProps)(RegisterProductModern)
