import React, { useLayoutEffect, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import RichTextInput from "../../common/RichTextInput"
import ClickOutside from "../../common/ClickOutside"
import { CaretDownIcon, CloseCircleIcon, TrashCanFull } from "../../../icons"
import { useWindowSize } from "../../../libs/hooks"

const FormProductDescription = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { productId, descriptionFormData, productDescriptionErrors, resetFeaturesErrors, updateDescription } = props
  const [relatedProductOptions, setRelatedProductOptions] = useState([])

  useLayoutEffect(() => {
    if (descriptionFormData.features.length === 0) {
      updateDescription({ ...descriptionFormData, features: [{ title: "", details: "" }] })
    }
  }, [descriptionFormData.features])

  useEffect(() => {
    if (props.products.length > 0) {
      const mappedProducts = props.products.filter((p) => p.id !== productId).map((p) => ({ id: p.id, name: p.title }))
      setRelatedProductOptions(mappedProducts)
    }
  }, [props.products])

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectRelatedItem = (relatedItemId, idx, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }
    
    if (descriptionFormData.relatedProductIds.includes(relatedItemId)) {
      return
    }

    const newRelatedProductIds = [...descriptionFormData.relatedProductIds]
    newRelatedProductIds[idx] = relatedItemId

    updateDescription({ ...descriptionFormData, relatedProductIds: newRelatedProductIds })    
  }

  const removeRelatedItem = (idx) => {
    const newRelatedProductIds = [...descriptionFormData.relatedProductIds]
    newRelatedProductIds[idx] = null

    updateDescription({ ...descriptionFormData, relatedProductIds: newRelatedProductIds })
  }

  const selectRelatedItemInput = ({toggleVisibility, isComponentVisible}, idx) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {descriptionFormData.relatedProductIds[idx] ? relatedProductOptions.find((c) => c.id === descriptionFormData.relatedProductIds[idx])?.name : ""}
        </div>
        {descriptionFormData.relatedProductIds[idx] && (
          <button 
            className="reset-filter-button" 
            onClick={(e) => {e.stopPropagation(); removeRelatedItem(idx)}}
          >
            <CloseCircleIcon/>
          </button>)
        }
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectRelatedItemOptions = ({toggleVisibility}, idx) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {relatedProductOptions.filter((p) => !descriptionFormData.relatedProductIds.includes(p.id)).map((relatedProductOption, index) =>
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (descriptionFormData.relatedProductIds.includes(relatedProductOption.id) ? " selected-option" : "")} 
          onClick={() => selectRelatedItem(relatedProductOption.id, idx, toggleVisibility)}
        >
          {relatedProductOption.name}
        </div>)
      }
    </div>
  )

  const handleUpdateFeatureTitle = (value, index) => {
    const newFeatures = descriptionFormData.features.map((feature, i) => {
      if (i === index) {
        return { ...feature, title: value }
      }
      return feature
    })

    updateDescription({ ...descriptionFormData, features: newFeatures })

  }

  const handleUpdateFeatureDetails = (value, index) => {
    const newFeatures = descriptionFormData.features.map((feature, i) => {
      if (i === index) {
        return { ...feature, details: value }
      }
      return feature
    })

    updateDescription({ ...descriptionFormData, features: newFeatures })
  }

  const handleDeleteFeature = (index) => {
    const newFeatures = descriptionFormData.features.filter((feature, i) => i !== index)

    resetFeaturesErrors()
    updateDescription({ ...descriptionFormData, features: newFeatures })
  }

  const handleAddFeature = () => {
    const newFeatures = [...descriptionFormData.features, { title: "", details: "" }]

    updateDescription({ ...descriptionFormData, features: newFeatures })
  }

  return (
    <div className="product-description-wrapper">
      <div className="fixed-input-wrapper without-fixed-height">
        <div className="section-subtitle with-margin-bottom">
          {t("coltene_store.create_product.description")}
        </div>
        <textarea
          className={"text-area-input" + (productDescriptionErrors?.description ? " input-error" : "") }
          value={descriptionFormData.productDescription}
          onChange={(e) => updateDescription({...descriptionFormData, productDescription: e.target.value })}
        />
      </div>
      <div className="internal-section-delimiter" />
      <div className="section-subtitle">
        {t("coltene_store.create_product.features")}
      </div>
      {descriptionFormData.features.map((feature, index) =>
        <div
          key={index}
          className={`product-options-wrapper flex-column`}
        >
          <div className="fixed-input-wrapper without-fixed-height">
            <div className="input-label">
              {t("coltene_store.create_product.title")}
            </div>
            <div className="title-with-action">
              <input
                type="text"
                className={"input-item" + (productDescriptionErrors?.features?.includes(`${index}-title`) ? " input-error" : "") }
                value={feature.title} 
                onChange={(e) => handleUpdateFeatureTitle(e.target.value, index)}
              />
              <div className="delete-option-wrapper">
                <TrashCanFull
                  onClick={() => {
                    handleDeleteFeature(index)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="fixed-input-wrapper without-fixed-height">
            <div className="input-label">
              {t("coltene_store.create_product.details")}
            </div>
            <RichTextInput
              value={feature.details}
              onChangeValue={(value) => handleUpdateFeatureDetails(value, index)}
              hasError={productDescriptionErrors?.features?.includes(`${index}-details`)}
            />
          </div>
        </div>)
      }
      <div className="add-option-wrapper" onClick={() => handleAddFeature()}>
        {`+  ${t("coltene_store.create_product.add-new-feature")}`}
      </div>
      <div className="internal-section-delimiter" />
      <div className="fixed-input-wrapper without-fixed-height">
        <div className="section-subtitle with-margin-bottom">
          {t("coltene_store.create_product.specifications")}
        </div>
        <RichTextInput
          value={descriptionFormData.specification}
          onChangeValue={(value) => updateDescription({ ...descriptionFormData, specification: value })}
          hasError={productDescriptionErrors?.specification}
        />
      </div>
      <div className="internal-section-delimiter" />
      <div className="section-subtitle with-margin-bottom">
        {t("coltene_store.create_product.accessories")}
      </div>
      <div className={`product-options-wrapper ${isMobile && "flex-column"}`}>
        <div className="fixed-input-wrapper one-third-wrapper">
          <div className="input-label">
            {`${t("coltene_store.create_product.item")} 1`}
          </div>
          <ClickOutside
            itemRef="click-outside-wrapper"
            className={productDescriptionErrors?.relatedProductIds?.includes(0) ? "input-error" : ""}
            eventItem={(e) => selectRelatedItemInput(e, 0)}
            toDisplayItem={(e) => selectRelatedItemOptions(e, 0)}
          />
        </div>
        <div className="fixed-input-wrapper one-third-wrapper">
          <div className="input-label">
            {`${t("coltene_store.create_product.item")} 2`}
          </div>
          <ClickOutside
            itemRef="click-outside-wrapper"
            className={productDescriptionErrors?.relatedProductIds?.includes(1) ? "input-error" : ""}
            eventItem={(e) => selectRelatedItemInput(e, 1)}
            toDisplayItem={(e) => selectRelatedItemOptions(e, 1)}
          />
        </div>
        <div className="fixed-input-wrapper one-third-wrapper">
          <div className="input-label">
            {`${t("coltene_store.create_product.item")} 3`}
          </div>
          <ClickOutside
            itemRef="click-outside-wrapper"
            className={productDescriptionErrors?.relatedProductIds?.includes(2) ? "input-error" : ""}
            eventItem={(e) => selectRelatedItemInput(e, 2)}
            toDisplayItem={(e) => selectRelatedItemOptions(e, 2)}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ adminStore }) {
  return {
    products: adminStore?.products || [], //A.S: Possible issue on not having retrieved all products
  }
}

export default connect(mapStateToProps)(FormProductDescription)
