import {
  getUsersCurrentDetails,
  getCompanyLogo,
  deleteCompanyLogo,
  getUserInviteRequest,
  userAcceptRejectCompanyInvitation,
  usersLeaveCompany,
  updateCurrentBranch,
  updateCurrentCompany,
} from "../utils/requests/companyAPI"

export const LOAD_USER_COMPANY = "LOAD_USER_COMPANY"
export const LOAD_USER_COMPANY_PENDING = "LOAD_USER_COMPANY_PENDING"
export const LOAD_USER_COMPANY_FULFILLED = "LOAD_USER_COMPANY_FULFILLED"
export const LOAD_USER_COMPANY_REJECTED = "LOAD_USER_COMPANY_REJECTED"

export const LOAD_COMPANY_LOGO = "LOAD_COMPANY_LOGO"
export const LOAD_COMPANY_LOGO_PENDING = "LOAD_COMPANY_LOGO_PENDING"
export const LOAD_COMPANY_LOGO_FULFILLED = "LOAD_COMPANY_LOGO_FULFILLED"
export const LOAD_COMPANY_LOGO_REJECTED = "LOAD_COMPANY_LOGO_REJECTED"

export const LOAD_COMPANY_REQUESTS = "LOAD_COMPANY_REQUESTS"
export const LOAD_COMPANY_REQUESTS_PENDING = "LOAD_COMPANY_REQUESTS_PENDING"
export const LOAD_COMPANY_REQUESTS_FULFILLED = "LOAD_COMPANY_REQUESTS_FULFILLED"
export const LOAD_COMPANY_REQUESTS_REJECTED = "LOAD_COMPANY_REQUESTS_REJECTED"

export const DELETE_COMPANY_LOGO = "DELETE_COMPANY_LOGO"
export const DELETE_COMPANY_LOGO_PENDING = "DELETE_COMPANY_LOGO_PENDING"
export const DELETE_COMPANY_LOGO_FULFILLED = "DELETE_COMPANY_LOGO_FULFILLED"
export const DELETE_COMPANY_LOGO_REJECTED = "DELETE_COMPANY_LOGO_REJECTED"

export const LEAVE_USER_COMPANY = "LEAVE_USER_COMPANY"
export const LEAVE_USER_COMPANY_PENDING = "LEAVE_USER_COMPANY_PENDING"
export const LEAVE_USER_COMPANY_FULFILLED = "LEAVE_USER_COMPANY_FULFILLED"
export const LEAVE_USER_COMPANY_REJECTED = "LEAVE_USER_COMPANY_REJECTED"

export const ACCEPT_COMPANY_REQUEST = "ACCEPT_COMPANY_REQUEST"
export const ACCEPT_COMPANY_REQUEST_PENDING = "ACCEPT_COMPANY_REQUEST_PENDING"
export const ACCEPT_COMPANY_REQUEST_FULFILLED = "ACCEPT_COMPANY_REQUEST_FULFILLED"
export const ACCEPT_COMPANY_REQUEST_REJECTED = "ACCEPT_COMPANY_REQUEST_REJECTED"

export const REJECT_COMPANY_REQUEST = "REJECT_COMPANY_REQUEST"
export const REJECT_COMPANY_REQUEST_PENDING = "REJECT_COMPANY_REQUEST_PENDING"
export const REJECT_COMPANY_REQUEST_FULFILLED = "REJECT_COMPANY_REQUEST_FULFILLED"
export const REJECT_COMPANY_REQUEST_REJECTED = "REJECT_COMPANY_REQUEST_REJECTED"

export const CANCEL_COMPANY_REQUEST = "CANCEL_COMPANY_REQUEST"
export const CANCEL_COMPANY_REQUEST_PENDING = "CANCEL_COMPANY_REQUEST_PENDING"
export const CANCEL_COMPANY_REQUEST_FULFILLED = "CANCEL_COMPANY_REQUEST_FULFILLED"
export const CANCEL_COMPANY_REQUEST_REJECTED = "CANCEL_COMPANY_REQUEST_REJECTED"

export const RESEND_COMPANY_REQUEST = "RESEND_COMPANY_REQUEST"
export const RESEND_COMPANY_REQUEST_PENDING = "RESEND_COMPANY_REQUEST_PENDING"
export const RESEND_COMPANY_REQUEST_FULFILLED = "RESEND_COMPANY_REQUEST_FULFILLED"
export const RESEND_COMPANY_REQUEST_REJECTED = "RESEND_COMPANY_REQUEST_REJECTED"

export const PUT_COMPANY_INFORMATION = "PUT_COMPANY_INFORMATION"
export const PUT_COMPANY_INFORMATION_PENDING = "PUT_COMPANY_INFORMATION_PENDING"
export const PUT_COMPANY_INFORMATION_FULFILLED = "PUT_COMPANY_INFORMATION_FULFILLED"
export const PUT_COMPANY_INFORMATION_REJECTED = "PUT_COMPANY_INFORMATION_REJECTED"

export const PUT_BRANCH_INFORMATION = "PUT_BRANCH_INFORMATION"
export const PUT_BRANCH_INFORMATION_PENDING = "PUT_BRANCH_INFORMATION_PENDING"
export const PUT_BRANCH_INFORMATION_FULFILLED = "PUT_BRANCH_INFORMATION_FULFILLED"
export const PUT_BRANCH_INFORMATION_REJECTED = "PUT_BRANCH_INFORMATION_REJECTED"

export const CLEAR_USER_COMPANY_MESSAGES = "CLEAR_USER_COMPANY_MESSAGES"

export function loadUserCompany() {
  return {
    type: LOAD_USER_COMPANY,
    payload: getUsersCurrentDetails()
  }
}

export function loadCompanyLogo(companyId, branchId) {
  return {
    type: LOAD_COMPANY_LOGO,
    payload: getCompanyLogo(companyId, branchId)
  }
}

export function loadCompanyRequests(queryParams) {
  return {
    type: LOAD_COMPANY_REQUESTS,
    payload: getUserInviteRequest(queryParams)
  }
}

export function removeCompanyLogo(companyId, branchId) {
  return {
    type: DELETE_COMPANY_LOGO,
    payload: deleteCompanyLogo(companyId, branchId)
  }
}

export function leaveCompany(bodyParams) {
  return {
    type: LEAVE_USER_COMPANY,
    payload: usersLeaveCompany(bodyParams)
  }
}

export function acceptCompanyRequest(requestId, showSuccessMessage = true) {
  return {
    type: ACCEPT_COMPANY_REQUEST,
    payload: userAcceptRejectCompanyInvitation(requestId, { action: "accept" }),
    meta: { requestId, showSuccessMessage }
  }
}

export function rejectCompanyRequest(requestId) {
  return {
    type: REJECT_COMPANY_REQUEST,
    payload: userAcceptRejectCompanyInvitation(requestId, { action: "reject" }),
    meta: { requestId }
  }
}

export function cancelCompanyRequest(requestId) {
  return {
    type: CANCEL_COMPANY_REQUEST,
    payload: userAcceptRejectCompanyInvitation(requestId, { action: "cancel" }),
    meta: { requestId }
  }
}

export function resendCompanyRequest(requestId) {
  return {
    type: RESEND_COMPANY_REQUEST,
    payload: userAcceptRejectCompanyInvitation(requestId, { action: "resend" }),
    meta: { requestId }
  }
}

export function updateCompanyInformation(bodyParams) {
  return {
    type: PUT_COMPANY_INFORMATION,
    payload: updateCurrentCompany(bodyParams)
  }
}

export function updateBranchInformation(bodyParams) {
  return {
    type: PUT_BRANCH_INFORMATION,
    payload: updateCurrentBranch(bodyParams)
  }
}

export function clearUserCompanyMessages() {
  return {
    type: CLEAR_USER_COMPANY_MESSAGES
  }
}

export function handleLoadUserCompany() {
  return (dispatch) => {
    return dispatch(loadUserCompany())
  }
}

export function handleLoadCompanyRequests() {
  return (dispatch) => {
    return dispatch(loadCompanyRequests())
  }
}