import { ApiEnum, _baseGetFetch, _basePostFetch, _basePutFetch, _basePatchFetch, _baseDeleteFetch } from "./baseFetch";

const path = "/notifications";

export const getNotificationsSettings = async (queryParams) => {
  //Use following call when pagination is implemented for device notifications
  //return _baseGetFetch(`${path}`, { ...queryParams, maxResults: process.env.REACT_APP_PAGINATION_SIZE}, ApiEnum.admin)
  
  return _baseGetFetch(`${path}/settings`, { ...queryParams }, ApiEnum.admin)
}

export const getEventGroups = async () => {
  return _baseGetFetch(`${path}/eventgroups`, undefined, ApiEnum.admin);
}

export const getNotificationDeliveryMethods = async () => {
  return _baseGetFetch('/notification/delivery-methods', undefined, ApiEnum.admin);
}

export const getEmailTemplates = async () => {
  return _baseGetFetch(`${path}/emailtemplates`, undefined, ApiEnum.admin);
}

export const getEmailTemplateContent = async (emailTemplateName) => {
  return _baseGetFetch(`${path}/emailtemplates/${emailTemplateName}_en_CA`, undefined, ApiEnum.admin);
}

export const postNotificationSetting = async (bodyParams) => {
  return _basePostFetch(`${path}/settings`, { ...bodyParams }, ApiEnum.admin);
}

export const putNotificationSetting = async (id, bodyParams) => {
  return _basePutFetch(`${path}/settings/${id}`, { ...bodyParams }, ApiEnum.admin);
}

export const patchNotificationSetting = async (id, bodyParams) => {
  return _basePatchFetch(`${path}/settings/${id}`, { ...bodyParams }, ApiEnum.admin);
}

export const deleteNotificationSetting = async (id) => {
  return _baseDeleteFetch(`${path}/settings/${id}`, undefined, ApiEnum.admin);
}

export const getNotificationMsgs = async (queryParams) => {
  return _baseGetFetch(`${path}`, { ...queryParams, maxResults: process.env.REACT_APP_PAGINATION_SIZE }, ApiEnum.admin);
}

export const postNotificationMsg = async (queryParams) => {
  return _basePostFetch(`${path}`, queryParams, ApiEnum.admin);
}

export const getNotificationMsgById = async (id) => {
  return _baseGetFetch(`${path}/${id}`, undefined, ApiEnum.admin);
}

export const putNotificationMsg = async (id, bodyParams) => {
  return _basePutFetch(`${path}/${id}`, { ...bodyParams }, ApiEnum.admin);
}

export const patchNotificationMsg = async (id, bodyParams) => {
  return _basePatchFetch(`${path}/${id}`, { ...bodyParams }, ApiEnum.admin);
}

export const deleteNotificationMsg = async (id) => {
  return _baseDeleteFetch(`${path}/${id}`, undefined, ApiEnum.admin);
}

export const getNotificationRegions = async () => {
  return _baseGetFetch(`/notification/regions`, undefined, ApiEnum.admin);
}