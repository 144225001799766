export const SET_WARRANTY = 'SET_WARRANTY'

export function setWarranty(querystring) {
  return {
    type: SET_WARRANTY,
    querystring
  }
}

export function handleSetWarranty(params) {
  return (dispatch) => {
    return dispatch(setWarranty(params))
  }
}