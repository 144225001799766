import React from "react"
import "./empty-page-results.scss"

const EmptyPageResults = (props) => {
  const { className, title, subtitle } = props

  return (
    <div className={`empty-page-wrapper ${className}`}>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  )
}

export default EmptyPageResults
