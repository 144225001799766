import { SET_PRODUCT_TYPES } from '../actions/productTypes'

export default function produtTypes (state=null, action) {
    switch (action.type) {
        case SET_PRODUCT_TYPES:
            return {
                ...state,
                ...action.types
            }
        default:
            return state
    }
}