import { useTranslation } from "react-i18next"

export default function TabularView(props) {
  const { t } = useTranslation()

  const { data } = props
  return (
    <div className={"tabular-view"}>
      <div className={"tabular-view-section-half"}>
        <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.valve_control")}</div>
        <div className={"tabular-view-section-container"}>
          <div className={"section-container-line-top-center"}>{t("advance_troubleshooting.valve_fill_boiler_1")}</div>
          <div className={"section-container-line-bottom"}>
            {data.VFB1} | {t("advance_troubleshooting.current")} {data.currentVFB1}
          </div>

          <div className={"section-container-line-top-center"}>{t("advance_troubleshooting.valve_fill_boiler_2")}</div>
          <div className={"section-container-line-bottom"}>
            {data.VFB2} | {t("advance_troubleshooting.current")} {data.currentVFB2}
          </div>

          <div className={"section-container-line-top-center"}>{t("advance_troubleshooting.valve_exhaust_chamber_short")}</div>
          <div className={"section-container-line-bottom"}>
            {data.VEC} | {t("advance_troubleshooting.current")} {data.currentVEC}
          </div>

          <div className={"section-container-line-top-center"}>{t("advance_troubleshooting.valve_drain_heat_exchanger_short")}</div>
          <div className={"section-container-line-bottom"}>
            {data.VDHE} | {t("advance_troubleshooting.current")} {data.currentVDHE}
          </div>

          <div className={"section-container-line-top-center"}>{t("advance_troubleshooting.valve_vent_chamber_short")}</div>
          <div className={"section-container-line-bottom"}>
            {data.VVC} | {t("advance_troubleshooting.current")} {data.currentVVC}
          </div>

          <div className={"section-container-line-top-center"}>{t("advance_troubleshooting.valve_fill_water_short")}</div>
          <div className={"section-container-line-bottom"}>
            {data.VFW} | {t("advance_troubleshooting.current")} {data.currentVFW}
          </div>

          <div className={"section-container-line-top-center"}>{t("advance_troubleshooting.valve_drain_reservoir_short")} </div>
          <div className={"section-container-line-bottom"}>
            {data.VDR} | {t("advance_troubleshooting.current")} {data.currentVDR}
          </div>
        </div>
      </div>

      <div className={"tabular-view-section"}>
        <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.steam_generator_1_short")}</div>
        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.power_target_overall")}</div>
        <div className={"tabular-view-section-long-text"}>{data.steamGenerator1PowerTarget}</div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.temperature")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.target_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator1TempTarget}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator1}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_1_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator1PowerTrack1Target}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator1PowerTrack1Curr}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_2_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator1PowerTrack2Target}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator1PowerTrack2Curr}</div>
          </div>
        </div>
      </div>

      <div className={"tabular-view-section"}>
        <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.steam_generator_2_short")}</div>
        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.power_target_overall")}</div>
        <div className={"tabular-view-section-long-text"}>{data.steamGenerator2PowerTarget}</div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.temperature")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.target_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator2TempTarget}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator2}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_1_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator2PowerTrack1Target}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator2PowerTrack1Curr}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_2_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator2PowerTrack2Target}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.steamGenerator2PowerTrack2Curr}</div>
          </div>
        </div>
      </div>

      <div className={"tabular-view-section-one-third"}>
        <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.fan_control")}</div>
        <div className={"section-container-line-top-left-normal-small"}>{t("advance_troubleshooting.he_fan_state")}</div>
        <div className={"tabular-view-section-medium-text"}>{data.fanHeaterExchanger}</div>
        <div className={"section-container-line-top-left-normal-small"}>{t("advance_troubleshooting.he_fan_current_value")}</div>
        <div className={"tabular-view-section-medium-text"}>{data.currentFHE}</div>
        <div className={"section-container-line-top-left-normal-small"}>{t("advance_troubleshooting.he_temperature_current_value")}</div>
        <div className={"tabular-view-section-medium-text"}>{data.heatExchanger}</div>
        <div className={"section-container-line-top-left-normal-small"}>{t("advance_troubleshooting.lcd_fan_state")}</div>
        <div className={"tabular-view-section-medium-text"}>{data.fanLCD}</div>
        <div className={"section-container-line-top-left-normal-small"}>{t("advance_troubleshooting.lcd_fan_current_value")}</div>
        <div className={"tabular-view-section-medium-text"}>{data.currentLCDF}</div>
      </div>

      <div className={"tabular-view-section-two-thirds"}>
        <div className={"tabular-view-section-two-thirds-top"}>
          <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.water_pump")}</div>
          <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.water_pump_1_short")}</div>
          <div className={"section-container-two-col-narrow"}>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.waterPumpFillSG1}</div>
            </div>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.currentPW1}</div>
            </div>
          </div>

          <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.water_pump_2_short")}</div>
          <div className={"section-container-two-col-narrow"}>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.waterPumpFillSG2}</div>
            </div>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.currentPW2}</div>
            </div>
          </div>

          <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.water_pump_fill_reservoir")}</div>
          <div className={"section-container-two-col-narrow"}>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.waterPumpFillReservoir}</div>
            </div>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.currentPWF}</div>
            </div>
          </div>
        </div>
        <div className={"tabular-view-section-two-thirds-bottom"}>
          <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.vacuum_pump")}</div>
          <div className={"section-container-two-col-narrow"}>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.vacuumPump}</div>
            </div>
            <div className={"section-container-two-col-narrow-1"}>
              <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
              <div className={"tabular-view-section-short-text-narrow"}>{data.currentPV}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={"tabular-view-section"}>
        <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.band_heater_top")}</div>
        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.power_target_overall")}</div>
        <div className={"tabular-view-section-long-text"}>{data.bandHeaterTopPowerTarget}</div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.temperature")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.target_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeaterTopTempTarget}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeater1}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_1_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeaterTopPowerTrack1}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.currentBandHeaterTopTrack1}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_2_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeaterTopPowerTrack2}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.currentBandHeaterTopTrack2}</div>
          </div>
        </div>
      </div>

      <div className={"tabular-view-section"}>
        <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.band_heater_bottom")}</div>
        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.power_target_overall")}</div>
        <div className={"tabular-view-section-long-text"}>{data.bandHeaterBottomPowerTarget}</div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.temperature")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.target_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeaterBottomTempTarget}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeater2}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_1_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeaterBottomPowerTrack1}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.currentBandHeaterBottomTrack1}</div>
          </div>
        </div>

        <div className={"section-container-line-top-left"}>{t("advance_troubleshooting.track_2_power")}</div>
        <div className={"section-container-two-col"}>
          <div className={"section-container-two-col-1"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.state")}</div>
            <div className={"tabular-view-section-short-text"}>{data.bandHeaterBottomPowerTrack2}</div>
          </div>
          <div className={"section-container-two-col-2"}>
            <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.current_value")}</div>
            <div className={"tabular-view-section-short-text"}>{data.currentBandHeaterBottomTrack2}</div>
          </div>
        </div>
      </div>

      <div className={"tabular-view-section-half"}>
        <div className={"tabular-view-section-header"}>{t("advance_troubleshooting.drawer")}</div>

        <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.closed")}</div>
        <div className={"tabular-view-section-short-text"}>{data.doorInputClosed}</div>

        <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.latched")}</div>
        <div className={"tabular-view-section-short-text"}>{data.doorLatched}</div>

        <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.locked")}</div>
        <div className={"tabular-view-section-short-text"}>{data.doorLocked}</div>

        <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.lock_motor_state")}</div>
        <div className={"tabular-view-section-short-text"}>{data.doorOutputLocked}</div>

        <div className={"section-container-line-top-left-normal"}>{t("advance_troubleshooting.lock_motor_current_value")}</div>
        <div className={"tabular-view-section-short-text"}>{data.currentDL}</div>
      </div>
    </div>
  )
}
