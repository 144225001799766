import config from "../config"
import {_baseGetFetchNoLang} from "./baseFetch"

const path = "/search/place"

export const getGooglePlace = async (queryParams) => {
  const selectedLang = localStorage.getItem("i18nextLng")
  return _baseGetFetchNoLang(`${path}/autocomplete`, {...queryParams, locale: selectedLang}, undefined)
}

export const getGooglePlaceDetails = async (queryParams) => {
  const selectedLang = localStorage.getItem("i18nextLng")
  return _baseGetFetchNoLang(`${path}/detail`, {...queryParams, locale: selectedLang}, undefined)
}

export const getGooglePlaceWithoutAuth = async (queryParams) => {
  try {
    const selectedLang = localStorage.getItem("i18nextLng")
    const params = new URLSearchParams({...queryParams, locale: selectedLang})

    const response = await fetch(`${config.apiGateway.URL}/${path}/autocomplete?${params.toString()}`)

    return await response.json()
  } catch (e) {
    console.log('error - ', e)
    return null
  }
}

export const getGooglePlaceDetailsWithoutAuth = async (queryParams) => {
  try {
    const selectedLang = localStorage.getItem("i18nextLng")
    const params = new URLSearchParams({...queryParams, locale: selectedLang})

    const response = await fetch(`${config.apiGateway.URL}/${path}/detail?${params.toString()}`)

    return await response.json()
  } catch (e) {
    console.log('error - ', e)
    return null
  }
}
