import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { SelectMenu } from "../common/SelectMenu"
import { inviteMember } from "../../actions/members"
import { organizationRequestsWritePermission } from "../../utils/permissionValidation"
import { InformationIcon } from "../../icons"
import "./invite-member-modal.scss"

const InviteMemberModal = (props) => {
  const { t } = useTranslation()
  const { closeModal, showRoleDetails, branches, companyRoles, companyId, isLoading } = props
  const [email, setEmail] = useState("")
  const [selectedBranchId, setSelectedBranchId] = useState(branches[0]?.id)
  const [selectedRoleId, setSelectedRoleId] = useState(null)
  const [roleOptions, setRoleOptions] = useState([])

  useEffect(() => {
    if (companyRoles.length > 0) {
      const mappedRoles = companyRoles.map((role) => ({
        key: role.id,
        value: role.name.replace(/Dealer |Dentist /g, "")
      }))

      setSelectedRoleId(mappedRoles[0].key)
      setRoleOptions(mappedRoles)
    }
  }, [companyRoles])

  const handleInvite = () => {
    props.actions.inviteMember(companyId, {
      email,
      branch: selectedBranchId,
      roleId: selectedRoleId,
    })
      .then(() => {
        closeModal()
      })
  }

  const getFooter = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={closeModal}
        >
          {t("labels.cancel")}
        </SimpleButton>
        <SimpleButton
          className="submit-button"
          onClick={handleInvite}
          disabled={!selectedBranchId || !email || email.toLocaleLowerCase() === props.companyAdminEmail?.toLocaleLowerCase() || isLoading}
          requiredPermission={organizationRequestsWritePermission}
        >
          {t("labels.add")}
        </SimpleButton>
      </>
    )
  }

  return (
    <PageModal
      toggle
      className="invite-member-modal"
      onToggle={closeModal}
      title={t("dealer-portal.invite_member")}
      footerContent={getFooter()}
    >
      <div className="invite-member-modal-content">
        <div className="row-wrapper">
          <div className="input-wrapper">
            <p className="input-option">{t('dealer-portal.user_email')}*</p>
            <input 
              type="text" 
              className="email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="row-wrapper">
          <div className="input-wrapper">
            <p className="input-option">{t('dealer-portal.branch')}*</p>
            <SelectMenu
              options={branches?.map((b) => ({ key: b.id, value: b.branchName })) || []}
              initialSelectedKey={selectedBranchId}
              onChange={(option) => setSelectedBranchId(option.key)}
            />
          </div>
          <div className="input-wrapper">
            <p className="input-option">
              {t('dealer-portal.role')}*
              <InformationIcon onClick={() => showRoleDetails()}/>
            </p>
            <SelectMenu
              options={roleOptions}
              initialSelectedKey={selectedRoleId}
              onChange={(option) => setSelectedRoleId(option.key)}
            />
          </div>
        </div>
      </div>
    </PageModal>
  )
}

function stateToProps({ authedUser, members, companies }) {
  return {
    companyAdminEmail: authedUser?.email,
    companyId: members?.companyId,
    branches: members?.branches || [],
    isLoading: members?.isLoading || companies?.isLoading,
    companyRoles: companies?.roles || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        inviteMember,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(InviteMemberModal)