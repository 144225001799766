export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS'
export const QUERY_PARAMS_KEY = 'QUERY_PARAMS'

export function setQueryParams(querystring) {
  return {
    type: SET_QUERY_PARAMS,
    querystring
  }
}

export function handleSetQueryParams(params) {
  return (dispatch) => {
    localStorage.setItem(QUERY_PARAMS_KEY, JSON.stringify(params))
    return dispatch(setQueryParams(params))
  }
}
