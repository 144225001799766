import {
  getSystemEvents,
  getSystemEventsTypes
} from "../utils/requests/eventsAPI"

export const LOAD_SYSTEM_EVENTS = "LOAD_SYSTEM_EVENTS"
export const LOAD_SYSTEM_EVENTS_PENDING = "LOAD_SYSTEM_EVENTS_PENDING"
export const LOAD_SYSTEM_EVENTS_FULFILLED = "LOAD_SYSTEM_EVENTS_FULFILLED"
export const LOAD_SYSTEM_EVENTS_REJECTED = "LOAD_SYSTEM_EVENTS_REJECTED"

export const LOAD_NEW_SYSTEM_EVENTS = "LOAD_NEW_SYSTEM_EVENTS"
export const LOAD_NEW_SYSTEM_EVENTS_PENDING = "LOAD_NEW_SYSTEM_EVENTS_PENDING"
export const LOAD_NEW_SYSTEM_EVENTS_FULFILLED = "LOAD_NEW_SYSTEM_EVENTS_FULFILLED"
export const LOAD_NEW_SYSTEM_EVENTS_REJECTED = "LOAD_NEW_SYSTEM_EVENTS_REJECTED"

export const LOAD_SYSTEM_EVENTS_TYPES = "LOAD_SYSTEM_EVENTS_TYPES"
export const LOAD_SYSTEM_EVENTS_TYPES_PENDING = "LOAD_SYSTEM_EVENTS_TYPES_PENDING"
export const LOAD_SYSTEM_EVENTS_TYPES_FULFILLED = "LOAD_SYSTEM_EVENTS_TYPES_FULFILLED"
export const LOAD_SYSTEM_EVENTS_TYPES_REJECTED = "LOAD_SYSTEM_EVENTS_TYPES_REJECTED"

export const CLEAR_SYSTEM_EVENTS_MESSAGES = "CLEAR_SYSTEM_EVENTS_MESSAGES"

export function loadSystemEvents(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_SYSTEM_EVENTS : LOAD_SYSTEM_EVENTS,
    payload: getSystemEvents(queryParams)
  }
}

export function loadSystemEventsTypes() {
  return {
    type: LOAD_SYSTEM_EVENTS_TYPES,
    payload: getSystemEventsTypes()
  }
}

export function clearSystemEventsMessages() {
  return {
    type: CLEAR_SYSTEM_EVENTS_MESSAGES
  }
}

