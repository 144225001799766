import { _baseGetFetch, _basePostFetch, ApiEnum } from './baseFetch'

export const getEventLog = (queryParams, serNum) => {
  const path = `/devices/${serNum}/events`

  return _baseGetFetch(path, queryParams, ApiEnum.records)
}

export const getPrintout = (uuid) => {
  const path = `/cycle/${uuid}/printout`

  return _basePostFetch(path, {}, ApiEnum.records)
}
