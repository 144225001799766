export const SET_AUTH_USER = 'SET_AUTH_USER'
export const GET_AUTH_USER = 'GET_AUTH_USER'
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'

export function setAuthedUser (authedUser) {
  return {
    type: SET_AUTH_USER,
    authedUser
  }
}

export function updateAuthedUser (authedUser) {
  return {
    type: UPDATE_AUTH_USER,
    authedUser
  }
}

export function handleSetAuthedUser (params) {
  return (dispatch) => {
    return dispatch(setAuthedUser(params))
  }
}

export function handleUpdateAuthedUser (params) {
  return (dispatch) => {
    return dispatch(updateAuthedUser(params))
  }
}
