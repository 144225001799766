import { Auth } from "aws-amplify"
import { CLEAR_BASKET, SET_IN_BASKET } from "../actions/colteneStore"
import { on } from "../middleware/local-store-middleware"

export const COLTENE_STORE = "COLTENE_STORE"

function saveBasket(action, state) {
  Auth.currentUserInfo().then((userInfo) => {
    const { attributes = {} } = userInfo

    const imageUrl = action.payload?.imageUrl || action.payload.images[0]?.url || ''
    localStorage.setItem(
      COLTENE_STORE,
      JSON.stringify({ basket: { ...state.colteneStore.basket, [action.payload.id]: { ...action.payload, imageUrl} }, authedUser: attributes?.sub })
    )
  })
}

function clearBasket() {
  localStorage.removeItem(COLTENE_STORE)
}

const resolvers = [on(SET_IN_BASKET, saveBasket), on(CLEAR_BASKET, clearBasket)]
export default resolvers
