import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from "react-redux"
import { Auth } from "aws-amplify"
import { isEmpty } from 'lodash'
import ConfirmationCode from './ConfirmationCode'
import Button from '../common/Button'
import PublicPageHeading from "../common/PublicPageHeading"
import TextInputField from '../common/TextInputField'
import SelectField from '../common/SelectField'
import { toast } from '../common/Toast'
import TooltipedIcon from '../common/TooltipedIcon'
import { processError } from "../../libs/processError"
import { useFormFields } from "../../libs/hooks"
import { QUERY_PARAMS_KEY } from '../../actions/queryParams'
import languages from '../../utils/languages'
import countries from "../../utils/countries.json"
import config from '../../utils/config'
import { sendMail } from '../../utils/requests/mqttPublishAPI'
import { getUserValidationShema, validateForm } from '../../utils/formValidation'
import { formatPhone } from '../../utils/functions'
import { getRegistrationInfo, getUserAccountTypes, validateCompanyName } from '../../utils/requests/usersAPI'
import { getGooglePlaceWithoutAuth, getGooglePlaceDetailsWithoutAuth } from '../../utils/requests/searchAPI'
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import {
  PostBoxIcon,
  EmailIcon,
  BuildingIcon,
  PersonIcon,
  PhoneIcon,
  WorldIcon,
  LockIcon,
  CityIcon,
  ProvinceIcon,
  FlagIcon,
  EyeIcon,
  EyeOffIcon,
  Address,
  CheckCircleFilledIcon,
  InformationIcon
} from '../../icons'
import './signup.scss'

const CreateAccount = ( props ) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { userGroup } = props.match.params || ''
  const fromInvitation = props.querystring
  const [error, setError] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [newUser, setNewUser] = useState(null)
  const [googlePlace, setGooglePlace] = useState({})
  const [googlePlaceDetails, setGooglePlaceDetails] = useState({})
  const [googlePlaces, setGooglePlaces] = useState([])
  const [searchAddress, setSearchAddress] = useState('')
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true)
  const [companyExists, setCompanyExists] = useState(false)
  const [isOrganization, setIsOrganization] = useState(false)
  const [inviteInformation, setInviteInformation] = useState(null)
  const [subGroups, setSubGroups] = useState([])
  const [fields, handleFieldChange] = useFormFields({
    email: fromInvitation?.email || "",
    password: "",
    confirmpassword: "",
    firstname: "",
    lastname: "",
    language: "",
    company: "",
    phone: "",
    address: "",
    city: "",
    stateprovince: "",
    postalcode: "",
    usergroup: "",
    isUserGroup: false,
    companyType: fromInvitation ? "individual" : "",
    hasCompanyType: false,
    fromInvitation: false
  })
  const addressRef = useRef(null)
  let timeoutAddress = useRef(null)
  const isColteneEmployee = userGroup?.toLowerCase()?.includes("employee")
  const typeKey = userGroup.replace('-', '_')
  const savedSearchParams = JSON.parse(localStorage.getItem(QUERY_PARAMS_KEY) || "{}")
  const userErrorMsg = {
    firstname: t("error.firstname"),
    lastname: t("error.lastname"),
    language: t("error.language"),
    country: t("error.country"),
    company: t("error.company"),
    companyExists: t("error.company_name_exists"),
    phone: t("error.phone"),
    email: t("error.email"),
    password: t("error.password"),
    confirmpassword: t("error.confirm_password"),
    privacy: t("error.privacy"),
    usergroup: t("error.user_group"),
    address: t("error.address"),
    companyType: t("error.company_type"),
  }
  
  useEffect(() => {
    if (fromInvitation?.inviteId) {
      getRegistrationInfo(fromInvitation.inviteId)
        .then((res) => {
          if (res.code) {
            localStorage.removeItem(QUERY_PARAMS_KEY)
            toast.error(t([`error.${res.code}`, `error.something_wrong`]))
            return 
          }

          setInviteInformation(res)
          handleFieldChange({ target: { name: "company", value: res.company_name } })
          handleFieldChange({ target: { name: "country", value: res.country } })
          handleFieldChange({ target: { name: "city", value: res.city } })
          handleFieldChange({ target: { name: "postalcode", value: res.zip_postal_code } })
          handleFieldChange({ target: { name: "stateprovince", value: res.state_province_region } })
          handleFieldChange({ target: { name: "address", value: res.office_address_one } })
        })
        .catch((error) => {
          toast.error(t([`error.${error.code}`, `error.something_wrong`]))
        })
    }
  }, [fromInvitation?.inviteId])
  
  useEffect(() => {
    const updatedUserGroup = isOrganization
      ? (userGroup === 'dealers' ? 'dealer_admin' : userGroup === 'dentist' ? 'dentist_admin' : '')
      : ''

    handleFieldChange({ target: { name: 'usergroup', value: updatedUserGroup } })
  }, [isOrganization])

  useEffect(() => {
    if (history.location.state) {
      setSubGroups(history.location.state?.userGroups)
      return
    }

    const parts = history.location.pathname.split("/")
    const afterCreateAccount = parts[parts.indexOf("create-account") + 1].replaceAll("-", "_")
    
    getUserAccountTypes()
      .then((res) => {
        let nextSubGroups = Object.values(res).filter(value => {
          if (afterCreateAccount === "dentist") {
            return value.name === "End User"
          } else if (afterCreateAccount === "dealers") {
            return value.name === "Dealer"
          } else {
            return value.name === "Coltene"
          }
        })
        .flatMap(value => value.userGroups)
        
        setSubGroups(nextSubGroups)
      })
  }, [])

  useEffect(() => {
    const dispatchClickEvent = (elementId) => {
      const element = document.getElementById(elementId)
      if (element) {
        const event = new Event('click', { bubbles: true })
        element.dispatchEvent(event)
      } else {
        console.warn(`Element with ID ${elementId} not found`)
      }
    }
    
    if (inviteInformation) {
      dispatchClickEvent('fromInvitation')
    }
  
    if (!userGroup.includes('employee')) {
      dispatchClickEvent('hasCompanyType')
    }
    
    dispatchClickEvent('isUserGroup')
  }, [fromInvitation, userGroup])

  useEffect(() => {
    if (!isFirstLoad && Object.keys(googlePlace).length > 0){
      setGooglePlaces(googlePlace.predictions || [])
    }
  }, [googlePlace])

  useEffect(() => {
    timeoutAddress = window.setTimeout(
      () => {
        if (fields.country?.length > 0) {
          getGooglePlace(fields.address, fields.country)
        }
      },
       250
    )

    return () => {
      clearTimeout(timeoutAddress)
    }
  }, [searchAddress])

  useEffect(() => {
    let placeData = {}

    if (!googlePlaceDetails.result?.address_components) {
      return
    }

    googlePlaceDetails.result.address_components.map((placeDetails) => {
      if (placeDetails.types.includes('postal_code')) {
        placeData['zipPostalCode'] = placeDetails.long_name || ''
      }

      if (placeDetails.types.includes('locality')) {
        placeData['city'] = placeDetails.long_name || ''
      }

      if (placeDetails.types.includes('administrative_area_level_1')) {
        placeData['stateProvinceRegion'] = placeDetails.short_name || ''
      }

      if (placeDetails.types.includes('street_number')) {
        placeData['street_number'] = placeDetails.long_name || ''
      }

      if (placeDetails.types.includes('route')) {
        placeData['route'] = placeDetails.long_name || ''
      }
    })

    let streetName = ''
    if (placeData.street_number) {
      streetName = placeData.street_number
    }

    if (placeData.route) {
      streetName = streetName.length > 0 ? streetName + ' ' + placeData.route : placeData.route
    }

    const changePostalCodeEvent = new CustomEvent('event', {
      bubbles: true,
      detail: {
        name: "postalcode",
        value: placeData.zipPostalCode
      }
    })

    const changeCityEvent = new CustomEvent('event', {
      bubbles: true,
      detail: {
        name: "city",
        value: placeData.city
      }
    })
    
    const changeStateProvinceEvent = new CustomEvent('event', {
      bubbles: true,
      detail: {
        name: "stateprovince",
        value: placeData.stateProvinceRegion
      }
    })
    
    const changeAddressEvent = new CustomEvent('event', {
      bubbles: true,
      detail: {
        name: "address",
        value: streetName
      }
    })

    handleFieldChange(changePostalCodeEvent)
    handleFieldChange(changeCityEvent)
    handleFieldChange(changeStateProvinceEvent)
    handleFieldChange(changeAddressEvent)
  }, [googlePlaceDetails])

  useOnClickOutside(addressRef, () => {
    setGooglePlaces([])
  })

  const getGooglePlace = (address, country) => {
    if (address) {
      const queryParams = {
        query: address,
        country: country,
        types: "address",
      }
      getGooglePlaceWithoutAuth(queryParams)
        .then((res) => {
          setGooglePlace(res?.data || {})
        })

      return
    }

    setGooglePlaces([])
  }

  const getGooglePlaceDetails = (placeId) => {
    clearInterval(timeoutAddress)

    if (placeId) {
      getGooglePlaceDetailsWithoutAuth({ place_id: placeId })
        .then((res) => {
          setGooglePlaceDetails(res?.data || {})
        })
    }

    setGooglePlaces([])
  }

  const handleInputChange = (e) => {
    setIsFirstLoad(false)

    let elementValue = e.target.value?.toString() || ''
    const maxInputLength = 40

    elementValue = elementValue.slice(0, maxInputLength)

    if (elementValue) {
      setSearchAddress(elementValue)
    } else {
      setSearchAddress('')
      setGooglePlaces([])
    }

    const event = new CustomEvent('event', {
      bubbles: true,
      detail: {
        name: "address",
        value: elementValue
      }
    })

    return handleFieldChange(event)
  }
  
  const handleCompanyBlur = async () => {
    if (fields?.company && !isColteneEmployee) {
      const queryParams = { name: fields.company }
      const companyValidationResponse = await validateCompanyName(queryParams)

      setCompanyExists(companyValidationResponse.isCompanyExists)

      if (companyValidationResponse.isCompanyExists) {
        setSubmitting(true)
        setError(prevError => ({
          ...prevError,
          company: t("error.company_name_exists")
        }))

        return
      }

      setError(prevError => ({
          ...prevError,
          company: null
      }))
    }
  }
  
  const getUserSubGroups = () => {
    const groups = {}
    const originalGroups = {}
    Object.keys(subGroups).forEach((index) => {
      return (groups[subGroups[index]["id"]] = t(`register.${subGroups[index]["name"].toLowerCase().replaceAll(" ", "_")}`))
    })
    Object.keys(subGroups).forEach((index) => {
      return (originalGroups[subGroups[index]["id"]] = subGroups[index]["name"].toLowerCase().replaceAll(" ", "_"))
    })

    let initialGroupName = fields.usergroup
    let initialGroupId = ""
    const paramsUserGroupId = savedSearchParams.role
    if (paramsUserGroupId) {
      // If role exists in search params load the corresponding group
      const initialGroup = subGroups.find((g) => Number(paramsUserGroupId) === g.id)
      initialGroupId = initialGroup?.id
      initialGroupName = initialGroup?.name || ""
    } else if (Object.keys(groups).length === 1) {
      // If there is only one group select it by default
      initialGroupId = Object.keys(groups)[0]
      initialGroupName = Object.values(groups)[0]
    }
    fields.usergroup = initialGroupName?.toLowerCase()?.replaceAll(' ', '_')
    
    const companyTypes = { individual: t("account.individual"), organization: t("account.organization"), organization_member: t("account.organization_member") }
    const filteredCompanyTypes = inviteInformation ? companyTypes : Object.fromEntries(Object.entries(companyTypes).filter(([key]) => key !== "organization_member"))
    
    const transformedGroups = groups ? Object.fromEntries(Object.entries(groups).map(([key, value]) => [key, value.replace("Dealer ", "")])) : {}
    let selectedType = fromInvitation ? "individual" : ""
    
    if (inviteInformation) {
      selectedType = "organization_member"
    }
    
    return (
      <div className={!isColteneEmployee ? "account-type-input" : "input-fields"}>
        {!isColteneEmployee && (
          <div className="account-type-wrapper">
            <SelectField
              name="companyType"
              value={selectedType}
              disabled={inviteInformation}
              options={inviteInformation ? companyTypes : filteredCompanyTypes}
              selectLbl={t("select_option_label.select_company_type")}
              onChange={(e) => {
                setIsOrganization(e.detail.value === 'organization')
                handleFieldChange(e)
              }}
              error={error.companyType ? error.companyType : null}
              isRequired={true}
              isSubmitting={isSubmitting}
            />
          </div>
        )}
        {!isEmpty(transformedGroups) && (
          <SelectField
            name="usergroup"
            value={String(initialGroupId)}
            disabled={inviteInformation || isOrganization}
            resetOnDisable={isOrganization}
            options={transformedGroups}
            selectLbl={t("select_option_label.select_role")}
            onChange={(e) => {
              e.detail.value = originalGroups[e.detail.value].toLowerCase().replaceAll(" ", "_")
              handleFieldChange(e)
            }}
            error={error.usergroup ? error.usergroup : null}
            isRequired={!inviteInformation}
            isSubmitting={isSubmitting}
            resetOnDisable={true}
          />
        )}
      </div>
    )
  }

  const getLanguageOptions = () => {
    const langs = {}
    Object.keys(languages).forEach( (k) => langs[k] = t(`language.${languages[k]}`) )

    return (
      <SelectField
        name="language"
        value={fields.language}
        options={langs}
        selectLbl={t("select_option_label.select_language")}
        onChange={handleFieldChange}
        error= {error.language ? error.language : null}
        isRequired={true}
        isSubmitting={isSubmitting}
        selectIcon={<WorldIcon className="globe-icon" />}
      />
    )
  }

  const getCountryOptions = () => {
    const countryData = {}
    Object.keys(countries).forEach( (index) => {
      return countryData[countries[index]["code"]] = countries[index]["name"]
    })
    
    const selectedValue = fromInvitation ? inviteInformation?.country : fields.country
    
    return (
      <SelectField
        name="country"
        value={selectedValue}
        options={countryData}
        disabled={inviteInformation}
        selectLbl={t("select_option_label.select_country")}
        sublabel={t("profile-labels.select_country_to_autofill")}
        onChange={handleFieldChange}
        error= {error.country ? error.country : null}
        isRequired={!inviteInformation}
        isSubmitting={isSubmitting}
        selectIcon={<FlagIcon className="flag-icon" />}
      />
    )
  }
  
  useEffect(() => { 
    if (isOrganization) {
      handleCompanyBlur()
      return
    }

    setCompanyExists(false)
  }, [isOrganization])

  const handleSignup = async (e) => {
    e.preventDefault()
    setError({})
    setSubmitting(true)

    const userSchema = getUserValidationShema(userErrorMsg, companyExists)
    const validateData = await validateForm(userSchema, fields)
    setError(validateData)

    if(Object.keys(validateData).length === 0 && !companyExists) {
      setLoading(true)

      try {
        const user = await Auth.signUp({
          'username': fields.email,
          'password': fields.password,
          'attributes': {
            'email': fields.email,
            'given_name': fields.firstname || '',
            'family_name': fields.lastname || '',
            'locale': fields.language || '',
            'custom:phone_number': fields.phone || '',
            'custom:company': fields.company || '',
            'custom:address': fields.address || '',
            'custom:postal_code': fields.postalcode || '',
            'custom:city': fields.city || '',
            'custom:state': fields.stateprovince || '',
            'custom:country': fields.country || '',
            'custom:user_group': fields.usergroup ? fields.usergroup : (userGroup ? userGroup.replace('-','_') : 'dentist'),
            'custom:privacy': fields.privacy ? fields.privacy.toString() : 'false',
            'custom:newsletter': fields.subscribe ? fields.subscribe.toString() : 'false',
            'custom:company_account_type': fields.companyType ? fields.companyType.toLowerCase() === "organization" ? "organization_admin" : fields.companyType.toLowerCase() : 'individual'
          }
        })

        setNewUser(user)
        setLoading(false)
        setSubmitting(false)
      } catch (e) {
        processError(e)
        setLoading(false)
        setSubmitting(false)
      }
    }
  }

  const getCreateAccountSubHeading = () => (
    <div>
      { t("register.already_have_account") + " " }
      <Link to="/" className="login-link">
        {t("register.login_account")}
      </Link>
    </div>
  )

  const toggleShowHidePassword = (e) => {
    document.getElementById('password').type = hidePassword ? "text" : "password"
    setHidePassword(!hidePassword)
  }

  const toggleShowHideConfirmPassword = (e) => {
    document.getElementById('confirmpassword').type = hideConfirmPassword ? "text" : "password"
    setHideConfirmPassword(!hideConfirmPassword)
  }

  const handleErrorCompanyName = () => {
    toast.error(t("error.company_name_exists_support"))
  }

  return (
    newUser !== null
    ?  <ConfirmationCode
        username={fields.email}
        user={fields}
      />
    :
    <div className="center signup-container public-page">
      <div className="center form-container">
        <div>
          <PublicPageHeading heading={t(`register.${typeKey}_registration`)} subheading={getCreateAccountSubHeading()} />
          <div className="signup-form">
            <form onSubmit={ handleSignup } autoComplete="off" noValidate>
              <div>
                {getUserSubGroups()}
                <input type="hidden" id="isUserGroup" onClick={handleFieldChange} name="isUserGroup" value={true} />
                <input type="hidden" id="hasCompanyType" onClick={handleFieldChange} name="hasCompanyType" value={true} />
                <input type="hidden" id="fromInvitation" onClick={handleFieldChange} name="fromInvitation" value={true} />
              </div>
              <div className="input-fields">
                <TextInputField
                  type="text"
                  name="firstname"
                  value={fields.firstname}
                  autoFocus
                  placeholder={t("profile-labels.first_name")}
                  onChange = {handleFieldChange}
                  error= {error.firstname ? error.firstname : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<PersonIcon className="user-icon" />}
                />
                <TextInputField
                  type="text"
                  name="lastname"
                  value={fields.lastname}
                  placeholder={t("profile-labels.last_name")}
                  onChange = {handleFieldChange}
                  error= {error.lastname ? error.lastname : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<PersonIcon className="user-icon" />}
                />
              </div>
              <div className="input-fields">
                <TextInputField
                  type="text"
                  name="phone"
                  value={fields.phone}
                  placeholder={t("profile-labels.phone")}
                  onChange={(e) => {
                    e.target.value = formatPhone(e.target.value)
                    handleFieldChange(e)
                  }}
                  error= {error.phone ? error.phone : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<PhoneIcon className="phone-icon" />}
                />
                {
                  getLanguageOptions()
                }
              </div>
              <div className="input-fields full-row">
                <TextInputField
                  type="email"
                  name="email"
                  value={fields.email}
                  disabled={inviteInformation}
                  placeholder={t("profile-labels.email")}
                  onChange = {handleFieldChange}
                  error= {error.email ? error.email : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<EmailIcon className="at-icon" />}
                />
              </div>
              <div className="input-fields">
                <TextInputField
                  type="text"
                  name="company"
                  value={inviteInformation ? inviteInformation?.company_name : fields.company}
                  disabled={inviteInformation}
                  onBlur={isOrganization ? handleCompanyBlur : undefined}
                  placeholder={t("profile-labels.company_name")}
                  onChange = {handleFieldChange}
                  error= {error.company ? error.company : null}
                  showError={isSubmitting}
                  isRequired={!inviteInformation}
                  isSubmitting={isSubmitting}
                  inputIcon={<BuildingIcon className="building-icon" />}
                  customError={
                    <div className="company-error-message">
                      {error.company}
                      {!isColteneEmployee && (
                        <TooltipedIcon
                          icon={<InformationIcon onClick={() => handleErrorCompanyName()} />}
                        />
                      )}
                    </div>
                  }
                />
                {
                  getCountryOptions()
                }
              </div>
              <div
                className={"input-fields full-row address-row" + (fields.country?.length > 0 ? "" : " disabled-row")}
              >
                <TextInputField
                  ref={addressRef}
                  type="text"
                  name="address"
                  id="address"
                  value={fields.address}
                  disabled={inviteInformation}
                  placeholder={t("profile-labels.address")}
                  onChange={handleInputChange}
                  error= {error.address ? error.address : null}
                  autoComplete="do-not-autofill"
                  isRequired={!inviteInformation}
                  isSubmitting={isSubmitting}
                  inputIcon={<Address className="address-svg" />}
                  rightIcon={<CheckCircleFilledIcon className={"checkmark-icon" + (fields.address.length > 0 ? " active" : "")} />}
                />
                {googlePlaces.length > 0 && (
                  <div className="google-places-wrapper">
                    {googlePlaces.map((placeItem) =>
                      <div 
                        key={placeItem.place_id} 
                        className="google-place" 
                        onClick={(e) => {
                          e.stopPropagation();
                          getGooglePlaceDetails(placeItem.place_id)
                        }}
                      >
                        {placeItem.description}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="input-fields one-third-row">
                <TextInputField
                  type="text"
                  name="city"
                  value={fields.city}
                  disabled={inviteInformation}
                  placeholder={t("profile-labels.city")}
                  onChange = {handleFieldChange}
                  isSubmitting={isSubmitting}
                  inputIcon={<CityIcon className="city-icon" />}
                />
                <TextInputField
                  type="text"
                  name="stateprovince"
                  value={fields.stateprovince}
                  disabled={inviteInformation}
                  placeholder={t("profile-labels.state_province")}
                  onChange = {handleFieldChange}
                  isSubmitting={isSubmitting}
                  inputIcon={<ProvinceIcon className="at-icon" />}
                />
                <TextInputField
                  type="text"
                  name="postalcode"
                  value={fields.postalcode}
                  disabled={inviteInformation}
                  placeholder={t("profile-labels.postal_code")}
                  onChange = {handleFieldChange}
                  isSubmitting={isSubmitting}
                  inputIcon={<PostBoxIcon className="icon" />}
                />
              </div>
              <div className="input-fields row">
                <TextInputField
                  type="password"
                  name="password"
                  id="password"
                  value={fields.password}
                  placeholder={t("profile-labels.password")}
                  autoComplete="new-password"
                  onChange = {handleFieldChange}
                  error= {error.password ? error.password : null}
                  sublabel={t("profile-labels.password_message")}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<LockIcon className="lock-icon" />}
                  rightIcon={ hidePassword
                    ? <EyeIcon className="eye-icon" onClick={toggleShowHidePassword} />
                    : <EyeOffIcon className="eye-icon" onClick={toggleShowHidePassword} />
                  }
                />
                <TextInputField
                  type="password"
                  name="confirmpassword"
                  id="confirmpassword"
                  value={fields.confirmpassword}
                  placeholder={t("profile-labels.confirm_password")}
                  onChange = {handleFieldChange}
                  error= {error.confirmpassword ? error.confirmpassword : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<LockIcon className="lock-icon" />}
                  rightIcon={ hideConfirmPassword
                    ? <EyeIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                    : <EyeOffIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                  }
                />
              </div>

              <div className="privacy-wrapper">
                <div className="privacy">
                  <input
                    type="checkbox"
                    id="privacy-term"
                    name="privacy"
                    className="privacy"
                    onChange = {handleFieldChange}
                  />
                  <label htmlFor="privacy-term">
                    {t("register.i_accept_scican")} <span><a href="https://www.scican.com/privacy/" target="_blank" rel="noreferrer" className="external-link">{t("register.privacy_statement")}</a></span> &
                    <span><a href="https://www.scican.com/terms/" target="_blank" rel="noreferrer" className="external-link">{t("register.terms_of_use")}</a></span>.
                  </label>
                  <span className="required">*</span>
                </div>
                {error.privacy && <div className="error-message">{error.privacy}</div>}
              </div>

              <div className="subscribe-wrapper">
                <div className="subscribe">
                  <input
                    type="checkbox"
                    name="subscribe"
                    id="subscribe"
                    className="subscribe"
                    onChange = {handleFieldChange}
                  />
                  <label htmlFor="subscribe">
                    {t("newsletter_agreement")} <span><a href={t("privacy_link")} target="_blank" rel="noreferrer" className="external-link">{t("privacy_policy")}</a>, <a href={t("contact_link")} target="_blank" rel="noreferrer" className="external-link">{t("contact_us")}</a></span>.
                  </label>
                </div>
              </div>
              <Button
                type="submit"
                isLoading={isLoading}
                className='large'
              >
                {t("register.create_account")}
              </Button>
            </form>

          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps({ onlineAccess, queryParams }) {
  return {
    querystring: onlineAccess || queryParams || null,
  }
}

export default connect(mapStateToProps)(CreateAccount)
