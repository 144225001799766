import React, { useEffect, useState } from "react"
import { TabsArrowRight } from "../../../icons"
import "./wrappedtabs.scss"
import { keyboardEvents } from "../../../utils/keyboardEvents"

const WrappedTabs = (props) => {
  const { activeTab, changeTab } = props
  const [tabs, setTabs] = useState([])
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)
  
  useEffect(() => {
    if (props.tabs?.length > 0) {
      setTabs(props.tabs)
    }
  }, [props.tabs])

  useEffect(() => {
    if (tabs?.length > 0) {
      updateArrowVisibility()
    }
  }, [tabs])

  useEffect(() => {
    window.addEventListener('resize', updateArrowVisibility)

    return () => {
      window.removeEventListener('resize', updateArrowVisibility)
    }
  }, [])

  const updateArrowVisibility = () => {
    const tabsContainer = document.querySelector(".wrapped-tabs-wrapper .tabs-container")

    const hasLeftOverflow = tabsContainer.scrollLeft > 0
    setShowLeftArrow(hasLeftOverflow)

    const hasRightOverflow = tabsContainer.scrollLeft + tabsContainer.clientWidth < tabsContainer.scrollWidth
    setShowRightArrow(hasRightOverflow)
  }

  const scrollTabs = (direction) => {
    const tabsContainer = document.querySelector(".wrapped-tabs-wrapper .tabs-container")
    const scrollAmount = 200
    const scrollDurationMs = 200

    let targetScrollLeft = tabsContainer.scrollLeft

    if (direction === "left") {
      targetScrollLeft -= scrollAmount
    } else if (direction === "right") {
      targetScrollLeft += scrollAmount
    }

    smoothScroll(tabsContainer, targetScrollLeft, scrollDurationMs)

    updateArrowVisibility()
  }

  const smoothScroll = (element, targetScrollLeft, duration) => {
    const startScrollLeft = element.scrollLeft
    const distance = targetScrollLeft - startScrollLeft
    const startTime = performance.now()
  
    const scrollStep = (timestamp) => {
      const elapsedTime = timestamp - startTime
      const scrollFraction = Math.min(elapsedTime / duration, 1)
      const scrollLeft = startScrollLeft + distance * scrollFraction
      element.scrollLeft = scrollLeft
  
      if (scrollFraction < 1) {
        requestAnimationFrame(scrollStep)
      }
    }
  
    requestAnimationFrame(scrollStep)
  }

  return (
    <div className="wrapped-tabs-wrapper d-flex">
      {showLeftArrow && (
        <div 
          className="arrow arrow-left" 
          onClick={() => scrollTabs("left")}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === keyboardEvents.ENTER && scrollTabs("left")}
        >
          <TabsArrowRight />
        </div>
      )}
      <div className={"tabs-container d-flex scrollable"} onScroll={updateArrowVisibility}>
        {tabs.map((tab, index) =>
          <div
            key={`tab-${index}`}
            className={`tab d-flex flex-align-center ${activeTab === tab.id ? "selected" : ""}`}
            onClick={() => changeTab(tab.id)}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => e.key === keyboardEvents.ENTER && changeTab(tab.id)}
          >
            <span>{tab.name}</span>
          </div>)
        }
      </div>
      {showRightArrow && (
        <div 
          className="arrow arrow-right" 
          onClick={() => scrollTabs("right")}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === keyboardEvents.ENTER && scrollTabs("right")}
        >
          <TabsArrowRight />
        </div>
      )}
    </div>
  )
}

export default WrappedTabs
