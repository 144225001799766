import { getCycleDetails, getTrackingDta } from "../utils/requests/trackingAPI"
import { getProductCyclePrintout } from "../utils/requests/productsAPI"

export const LOAD_TRACKING_DATA = "LOAD_TRACKING_DATA"
export const LOAD_TRACKING_DATA_PENDING = "LOAD_TRACKING_DATA_PENDING"
export const LOAD_TRACKING_DATA_FULFILLED = "LOAD_TRACKING_DATA_FULFILLED"
export const LOAD_TRACKING_DATA_REJECTED = "LOAD_TRACKING_DATA_REJECTED"

export const LOAD_CYCLE_DETAILS = "LOAD_CYCLE_DETAILS"
export const LOAD_CYCLE_DETAILS_PENDING = "LOAD_CYCLE_DETAILS_PENDING"
export const LOAD_CYCLE_DETAILS_FULFILLED = "LOAD_CYCLE_DETAILS_FULFILLED"
export const LOAD_CYCLE_DETAILS_REJECTED = "LOAD_CYCLE_DETAILS_REJECTED"

export const LOAD_LEGACY_CYCLE_DETAILS = "LOAD_LEGACY_CYCLE_DETAILS"
export const LOAD_LEGACY_CYCLE_DETAILS_PENDING = "LOAD_LEGACY_CYCLE_DETAILS_PENDING"
export const LOAD_LEGACY_CYCLE_DETAILS_FULFILLED = "LOAD_LEGACY_CYCLE_DETAILS_FULFILLED"
export const LOAD_LEGACY_CYCLE_DETAILS_REJECTED = "LOAD_LEGACY_CYCLE_DETAILS_REJECTED"

export const CLEAR_TRACKING_MESSAGES = "CLEAR_TRACKING_MESSAGES"
export const CLEAR_CYCLE_DETAILS = "CLEAR_CYCLE_DETAILS"

export function loadTrackingData(queryParams) {
  return {
    type: LOAD_TRACKING_DATA,
    payload: getTrackingDta(queryParams)
  }
}

export function loadCycleDetails(recordsUuid, serialNumber) {
  return {
    type: LOAD_CYCLE_DETAILS,
    payload: getCycleDetails(recordsUuid, serialNumber)
  }
}

export function loadLegacyCycleDetails(fileName) {
  return {
    type: LOAD_LEGACY_CYCLE_DETAILS,
    payload: getProductCyclePrintout(fileName)
  }
}

export function clearTrackingMessages() {
  return {
    type: CLEAR_TRACKING_MESSAGES
  }
}

export function clearCycleDetails() {
  return {
    type: CLEAR_CYCLE_DETAILS
  }
}
