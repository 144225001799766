import {_baseGetFetch, _basePutFetch} from './baseFetch'

export const getCycleFaultProducts = (baseParams) => {
  return _baseGetFetch('/cycles/faults', baseParams)
}

export const getCyclesChart = (queryParams) => {
  return _baseGetFetch('/cycles/chart', queryParams)
}

export const getCyclesSummary = (queryParams) => {
  return _baseGetFetch('/cycles/summary', queryParams)
}

export const getCyclesFaultsNotes = (serialNumber, queryParams) => {
  return _baseGetFetch(`/cycles/faults/${serialNumber}`, queryParams)
}

export const putCyclesFaultsStatus = (id, payload) => {
  return _basePutFetch(`/cycles/faults/${id}`, payload)
}

export const getAnalyticsFaultsOverview = (queryParams) => {
  return _baseGetFetch(`/analytics/faults/overview`, queryParams)
}

export const getAnalyticsFaultsCountries = (queryParams) => {
  return _baseGetFetch('/cycles/faults/countries', queryParams)
}

export const getAnalyticsFaultsCodes = (queryParams) => {
  return _baseGetFetch('/cycles/faults/codes', queryParams)
}

export const getAnalyticsFaultsSummary = async (queryParams) => {
  return _baseGetFetch('/analytics/faults/summary', queryParams)
}

export const getAnalyticsFaultsList = (queryParams) => {
  return _baseGetFetch('/analytics/faults/list', queryParams)
}

export const getAnalyticsFaultsListCsv = (queryParams) => {
    return _baseGetFetch('/analytics/faults/download', queryParams)
  }

export const getAnalyticsFaultsCycleDetails = async (queryParams) => {
  return _baseGetFetch('/analytics/faults/trend/monthly', queryParams)
}
