const Pages = {
  dashbord: {
    route: "/",
    permission: "page:dashboard",
  },
  documents: {
    route: "/documents",
    permission: "page:documents",
  },
  priceLists: {
    route: "/dealers/price-list",
    permission: "page:price-list",
  },
  members: {
    route: "/members",
    permission: "page:members",
  },
  requestHelp: {
    route: "/request-help",
    permission: "site:dealer-site-access",
  },
  // digitalAssets: {
  //   route: "/dealers/digital-assets",
  //   permission: "module:troubleshoot",
  // },
  dealerBulletins: {
    route: "/dealers/bulletins",
    permission: "page:bulletins",
  },
  videos: {
    route: "/videos",
    permission: "page:videos",
  },
  reports: {
    route: "/reports",
    permission: "page:reports",
  },
  instrumentTracking: {
    route: "/instrument-tracking",
    permission: "page:instrument-tracking",
  },
  remoteAccess: {
    route: "/remote-access",
    permission: "page:remote_access",
  },
  productNotes: {
    route: "/products/:id/notes",
    permission: "page:product_notes",
  },
  product: {
    route: "/products/:id",
    permission: "page:product",
  },
  monitoring: {
    route: "/monitoring",
    permission: "page:monitoring",
  },
  myProducts: {
    route: "/my-products",
    permission: "page:user-products",
  },
  reportProblem: {
    route: "/report-problem",
    permission: "page:report_problem",
  },
  reportProblemForm: {
    route: "/report-problem/report-form",
    permission: "page:report_problem_form",
  },
  spareParts: {
    route: "/spareparts",
    permission: "page:spare_parts",
  },
  firmware: {
    route: "/firmware",
    permission: "page:firmware",
  },
  troubleshoot: {
    route: "/troubleshoot",
    permission: "page:troubleshoot",
  },
  remoteAccessSession: {
    route: "/remote-access-session",
    permission: "page:remote_access_session",
  },
  warranty: {
    route: "/warranty",
    permission: "page:warranty",
  },
  accountsManagement: {
    route: "/account-management",
    permission: "page:accounts",
  },
  policies: {
    route: "/policies",
    permission: "page:policy",
  },
  colteneStore: {
    route: "/coltene-store",
    permission: "page:store",
  },
  adminStore: {
    route: "/admin-store",
    permission: "page:store-admin",
  },
  adminOrderHistory: {
    route: "/admin-order-history",
    permission: "page:store-admin",
    // permission: "page:admin-order-history",
  },
  adminOrderDetails: {
    route: "/admin-order-history/:id",
    permission: "page:store-admin",
    // permission: "page:admin-order-details"
  },
  productDetails: {
    route: "/coltene-store/:id",
    permission: "page:store",
  },
  previewProductDetails: {
    route: "/preview/:id",
    permission: "page:store-admin",
  },
  events: {
    route: "/events",
    permission: "page:system-events",
  },
  services: {
    route: "/services",
    permission: "page:services",
  },
  users: {
    route: "/users-management",
    permission: "page:users",
  },
  companies: {
    route: "/organizations-management",
    permission: "page:companies",
  },
  accountDetails: {
    route: "/my-account/profile",
    permission: "page:profile_settings",
  },
  notifications: {
    route: "/my-account/notifications",
    permission: "page:notifications",
  },
  paymentMethods: {
    route: "/my-account/payment-methods",
    permission: "page:store",
  },
  userSubscriptions: {
    route: "/my-account/my-subscriptions",
    permission: "page:store",
  },
  userOrders: {
    route: "/my-account/my-orders",
    permission: "page:store",
  },
  userCompany: {
    route: "/my-account/my-organization",
    permission: "page:my-organization",
  },
  pageNotFound: {
    route: "/page-not-found",
    permission: "page:page_not_found",
  },
  businessRules: {
    route: "/business-rules",
    permission: "page:business-rules",
  },
  recycleBin: {
    route: "/recycle-bin",
    permission: "page:recycle-bin",
  }
}

const Modules = {
  troubleshoot: {
    module: "Troubleshoot",
    permission: "module:troubleshoot",
  },
  tsb: {
    module: "TechnicalServiceBulletin",
    permission: "module:tsb",
  },
  remoteAccess: {
    module: "RemoteAccess",
    permission: "module:remote_access",
  },
  administration: {
    module: "Administration",
    permission: "module:administration",
  },
  organizationAdmin: {
    module: "OrganizationAdmin",
    permission: "module:organization-admin",
  }
}

export { Pages, Modules }
