import React, {useState} from "react"
import { useTranslation } from "react-i18next"
import NotificationSettings from "./NotificationSettings"
import NotificationMessages from "./NotificationMessages"
import SystemEvents from "./SystemEvents"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import WrappedTabs from "../../components/common/WrappedTabs"
import "./events.scss"

function Events() {
  const [activeTab, setActiveTab] = useState(1)

  const { t } = useTranslation()

  const generateTabs = () => {
    const tabs = [
      {
        id: 1,
        label: t('events.system-events'),
        component: <SystemEvents />
      }
    ]
    tabs.push({
      id: 2,
      label: t('events.notification-settings'),
      component: <NotificationSettings />
    })
    tabs.push({
      id: 3,
      label: t('events.notification-messages'),
      component: <NotificationMessages />
    })

    return tabs
  }

  const handleChangeTab = (accountId) => {
    setActiveTab(accountId)
  }

  return (
    <div className="events-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.events")} />
      <WrappedTabs
        tabs={generateTabs().map((e) => ({ id: e.id, name: e.label }))}
        activeTab={activeTab}
        changeTab={handleChangeTab}
      />
      { generateTabs().find(e => e.id === activeTab)?.component }
    </div>
  );
}

export default Events