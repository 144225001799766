import {
  LOAD_BUSINESS_RULES_FULFILLED,
  LOAD_BUSINESS_RULES_PENDING,
  LOAD_BUSINESS_RULES_REJECTED,
  LOAD_BUSINESS_RULES_TYPES_FULFILLED,
  LOAD_BUSINESS_RULES_TYPES_PENDING,
  LOAD_BUSINESS_RULES_TYPES_REJECTED,
  LOAD_BUSINESS_RULE_BY_ID_FULFILLED,
  LOAD_BUSINESS_RULE_BY_ID_PENDING,
  LOAD_BUSINESS_RULE_BY_ID_REJECTED,
  LOAD_NEW_BUSINESS_RULES_FULFILLED,
  LOAD_NEW_BUSINESS_RULES_PENDING,
  LOAD_NEW_BUSINESS_RULES_REJECTED,
  LOAD_DISCOUNTS_FULFILLED,
  LOAD_DISCOUNTS_PENDING,
  LOAD_DISCOUNTS_REJECTED,
  LOAD_TEST_DRIVES_FULFILLED,
  LOAD_TEST_DRIVES_PENDING,
  LOAD_TEST_DRIVES_REJECTED,
  LOAD_TRIALS_FULFILLED,
  LOAD_TRIALS_PENDING,
  LOAD_TRIALS_REJECTED,
  POST_BUSINESS_RULE_PENDING,
  PUT_BUSINESS_RULE_PENDING,
  DELETE_BUSINESS_RULE_PENDING,
  PUT_BUSINESS_RULE_STATUS_PENDING,
  POST_BUSINESS_RULE_FULFILLED,
  PUT_BUSINESS_RULE_FULFILLED,
  PUT_BUSINESS_RULE_STATUS_FULFILLED,
  DELETE_BUSINESS_RULE_FULFILLED,
  CLEAR_BUSINESS_RULES_MESSAGES,
  POST_BUSINESS_RULE_REJECTED,
  PUT_BUSINESS_RULE_REJECTED,
  DELETE_BUSINESS_RULE_REJECTED,
  PUT_BUSINESS_RULE_STATUS_REJECTED,
} from "../actions/businessRules"
import i18n from "i18next"

export default function businessRules(state = {}, action) {
  switch (action.type) {
    case LOAD_BUSINESS_RULES_PENDING:
    case LOAD_NEW_BUSINESS_RULES_PENDING:
      return {
        ...state,
        busRulesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_BUSINESS_RULE_BY_ID_PENDING:
      return {
        ...state,
        busRuleLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_BUSINESS_RULES_TYPES_PENDING:
      return {
        ...state,
        busRuleTypeLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_DISCOUNTS_PENDING:
      return {
        ...state,
        discountsLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_TRIALS_PENDING:
      return {
        ...state,
        trialsLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_TEST_DRIVES_PENDING:
      return {
        ...state,
        testDrivesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case POST_BUSINESS_RULE_PENDING:
      return {
        ...state,
        busRuleLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case PUT_BUSINESS_RULE_PENDING:
      return {
        ...state,
        busRuleLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case DELETE_BUSINESS_RULE_PENDING:
      return {
        ...state,
        busRulesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case PUT_BUSINESS_RULE_STATUS_PENDING:
      return {
        ...state,
        busRuleLoading: true,
        busRulesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_BUSINESS_RULES_FULFILLED:
      const businessRules = state?.busRules || []
      return {
        ...state,
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE) ? true : false,
        busRules: [...businessRules, ...action.payload],
        busRulesLoading: false,
      }
    case LOAD_NEW_BUSINESS_RULES_FULFILLED:
      return {
        ...state,
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE) ? true : false,
        busRules: action.payload,
        busRulesLoading: false,
      }
    case LOAD_BUSINESS_RULE_BY_ID_FULFILLED:
      return {
        ...state,
        busRule: action.payload,
        busRuleLoading: false,
      }
    case LOAD_BUSINESS_RULES_TYPES_FULFILLED:
      return {
        ...state,
        busRuleTypes: action.payload,
        busRuleTypeLoading: false,
      }
    case LOAD_DISCOUNTS_FULFILLED:
      return {
        ...state,
        discounts: action.payload,
        discountsLoading: false,
      }
    case LOAD_TRIALS_FULFILLED:
      return {
        ...state,
        trials: action.payload,
        trialsLoading: false,
      }
    case LOAD_TEST_DRIVES_FULFILLED:
      return {
        ...state,
        testDrives: action.payload,
        testDrivesLoading: false,
      }
    case POST_BUSINESS_RULE_FULFILLED:
      const initialBusinessRules = state?.busRules || []
      return {
        ...state,
        busRules: [action.payload, ...initialBusinessRules ],
        busRuleLoading: false,
        successMessage: i18n.t("business-rules.business_rule_created")
      }
    case PUT_BUSINESS_RULE_FULFILLED:
      return {
        ...state,
        busRules: state?.busRules.map((item) => (item.id === action.payload.id ? { ...action.payload } : item)),
        busRuleLoading: false,
        successMessage: i18n.t("business-rules.business_rule_updated")
      }
    case PUT_BUSINESS_RULE_STATUS_FULFILLED:
      return {
        ...state,
        busRules: state?.busRules.map((item) => (item.id === action.payload.id ? { ...action.payload } : item)),
        busRulesLoading: false,
        busRuleLoading: false,
        successMessage: i18n.t("business-rules.business_rule_updated")
      }
    case DELETE_BUSINESS_RULE_FULFILLED:
      return {
        ...state,
        busRules: state?.busRules.filter((busRule) => busRule.id !== action.meta.id),
        busRulesLoading: false,
        successMessage: i18n.t("business-rules.business_rule_deleted"),
      }
    case LOAD_BUSINESS_RULES_REJECTED:
    case LOAD_NEW_BUSINESS_RULES_REJECTED:
      return {
        ...state,
        busRulesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_BUSINESS_RULE_BY_ID_REJECTED:
      return {
        ...state,
        busRuleLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_BUSINESS_RULES_TYPES_REJECTED:
      return {
        ...state,
        busRuleTypeLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_TEST_DRIVES_REJECTED:
      return {
        ...state,
        testDrivesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_DISCOUNTS_REJECTED:
      return {
        ...state,
        discountsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_TRIALS_REJECTED:
      return {
        ...state,
        trialsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case POST_BUSINESS_RULE_REJECTED:
      return {
        ...state,
        busRuleLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PUT_BUSINESS_RULE_REJECTED:
      return {
        ...state,
        busRuleLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case DELETE_BUSINESS_RULE_REJECTED:
      return {
        ...state,
        busRulesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PUT_BUSINESS_RULE_STATUS_REJECTED:
      return {
        ...state,
        busRulesLoading: false,
        busRuleLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case CLEAR_BUSINESS_RULES_MESSAGES:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    default:
      return state
  }
}
