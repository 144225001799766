export function compareVersions(versionA, versionB) {
  if (!versionA || !versionB) {
    return 0
  }

  const sanitizeVersion = (version) => version.replace(/[^0-9.]/g, '')

  const sanitizedVersionA = sanitizeVersion(versionA)
  const sanitizedVersionB = sanitizeVersion(versionB)

  const versionsA = sanitizedVersionA.split(/\./g)
  const versionsB = sanitizedVersionB.split(/\./g)

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())
    const b = Number(versionsB.shift())

    if (a === b) {
      continue
    }

    if (a > b || isNaN(b)) {
      return 1
    }

    return -1
  }

  return 0
}