import {
  LOAD_BULLETINS_PENDING,
  LOAD_BULLETINS_FULFILLED,
  LOAD_BULLETINS_REJECTED,
  LOAD_NEW_BULLETINS_PENDING,
  LOAD_NEW_BULLETINS_FULFILLED,
  LOAD_NEW_BULLETINS_REJECTED,
  LOAD_BULLETINS_FILTERS_PENDING,
  LOAD_BULLETINS_FILTERS_FULFILLED,
  LOAD_BULLETINS_FILTERS_REJECTED,
  CLEAR_BULLETINS_MESSAGES,
} from "../actions/bulletins"
import i18n from 'i18next'

export default function bulletins(state = {}, action) {
  switch (action.type) {
    case LOAD_BULLETINS_PENDING:
    case LOAD_NEW_BULLETINS_PENDING:
    case LOAD_BULLETINS_FILTERS_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }
    case LOAD_BULLETINS_REJECTED:
    case LOAD_NEW_BULLETINS_REJECTED:
    case LOAD_BULLETINS_FILTERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_BULLETINS_FULFILLED:
      const bulletins = state?.bulletins || []
      const newBulletinsIds = action.payload.data?.map(item => item.id)
      const difference = bulletins
        .filter(x => !newBulletinsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        bulletins: difference,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_BULLETINS_FULFILLED:
      return {
        ...state,
        bulletins: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_BULLETINS_FILTERS_FULFILLED:
      return {
        ...state,
        bulletinsFilters: action.payload.data,
        isLoading: false,
      }
    case CLEAR_BULLETINS_MESSAGES: {
      return {
        ...state,
        errorMessage: null
      }
    }
    default:
      return state
  }
}
