import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import LoadingSpinner from '../../common/LoadingSpinner'
import { loadGooglePlace, loadGooglePlaceDetails } from "../../../actions/colteneStore";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { Address, CityIcon, PostBoxIcon, ProvinceIcon } from "../../../icons"
import "./google-address-input.scss"

const GoogleAddressInput = (props) => {
  const { t } = useTranslation()
  const {
    country,
    address,
    handleSetAddress,
    city,
    handleSetCity,
    stateProvince,
    handleSetStateProvince,
    zipCode,
    handleSetZipCode,
    withoutLabels,
    withIcons,
    disabled,
    readOnly,
    errors = {},
    withErrorLabels,
    withRequiredPlaceholder,
  } = props
  const [searchAddress, setSearchAddress] = useState('')
  const [googlePlaces, setGooglePlaces] = useState([])
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const addressRef = useRef(null)
  let timeoutAddress = useRef(null)

  useEffect(() => {
    if (!isFirstLoad && Object.keys(props.googlePlace).length > 0) {
      setGooglePlaces(props.googlePlace.predictions || [])
    }
  }, [props.googlePlace])

  useEffect(() => {
    timeoutAddress = window.setTimeout(
      () => {
        getGooglePlace(address, country)
      },
       250
    )

    return () => {
      clearTimeout(timeoutAddress)
    }
  }, [searchAddress])

  useEffect(() => {
    let placeData = {}

    if (!props.googlePlaceDetails.result?.address_components) {
      return
    }

    props.googlePlaceDetails.result.address_components.map((placeDetails) => {
      if (placeDetails.types.includes('postal_code')) {
        placeData['zipPostalCode'] = placeDetails.long_name
      }

      if (placeDetails.types.includes('locality')) {
        placeData['city'] = placeDetails.long_name
      }

      if (placeDetails.types.includes('administrative_area_level_1')) {
        placeData['stateProvinceRegion'] = placeDetails.short_name
      }

      if (placeDetails.types.includes('street_number')) {
        placeData['street_number'] = placeDetails.long_name || ''
      }

      if (placeDetails.types.includes('route')) {
        placeData['route'] = placeDetails.long_name || ''
      }
    })

    let streetName = ''
    if (placeData.street_number) {
      streetName = placeData.street_number
    }

    if (placeData.route) {
      streetName = streetName.length > 0 ? streetName + ' ' + placeData.route : placeData.route
    }

    if (placeData.zipPostalCode) {
      handleSetZipCode(placeData.zipPostalCode)
    }
    
    if (placeData.city) {
      handleSetCity(placeData.city)
    }

    if (placeData.stateProvinceRegion) {
      handleSetStateProvince(placeData.stateProvinceRegion)
    }
    
    if (streetName) {
      handleSetAddress(streetName)
    }
  }, [props.googlePlaceDetails])

  useEffect(() => {
    setShowSpinner(props.addressLoading)
  }, [props.addressLoading])

  useOnClickOutside(addressRef, () => {
    setGooglePlaces([])
  })

  const handleAddressChange = (e) => {
    setIsFirstLoad(false)

    let elementValue = e.target.value?.toString() || ''
    const maxInputLength = 40

    elementValue = elementValue.slice(0, maxInputLength)

    if (elementValue) {
      setSearchAddress(elementValue)
    } else {
      setSearchAddress('')
      setGooglePlaces([])
    }

    handleSetAddress(elementValue)
  }

  const getGooglePlace = (elementValue, country) => {
    if (elementValue) {
      const queryData = {
        query: elementValue,
        country: country,
        types: "address",
      }
      props.actions.loadGooglePlace(queryData)
      return
    }

    setGooglePlaces([])
  }

  const getGooglePlaceDetails = (placeId) => {
    clearInterval(timeoutAddress)

    if (placeId) {
      props.actions.loadGooglePlaceDetails({ place_id: placeId })
    }

    setGooglePlaces([])
  }

  return (
    <>
      <div className="google-address-input-wrapper">
        <div className="row-wrapper" ref={addressRef}>
          <div className="input-wrapper-google-address">
            {!withoutLabels && (
              <p className="input-option">{t("account.address")}*</p>
            )}
            <div className={"input-item-wrapper" + (errors["address"] ? " has-error" : "")}>
              {withIcons && (
                <div className="input-item-icon">
                  <Address />
                </div>
              )}
              <input
                type="text" 
                className={"input-item"}
                value={address || ""}
                autoComplete="do-not-autofill"
                onChange={handleAddressChange}
                placeholder={`${t("account.address")}${withRequiredPlaceholder ? "*" : ""}`}
                disabled={!country || disabled}
                readOnly={readOnly}
              />
              {withErrorLabels && errors["address"] && <p className="error-message">{t("error.address")}</p>}
            </div>
            {googlePlaces.length > 0 && (
              <div className="google-places-wrapper">
                {googlePlaces.map((placeItem) =>
                  <div 
                    key={placeItem.place_id} 
                    className="google-place" 
                    onClick={(e) => {
                      e.stopPropagation();
                      getGooglePlaceDetails(placeItem.place_id)
                    }}
                  >
                    {placeItem.description}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row-wrapper">
          <div className="input-wrapper-google-address">
            {!withoutLabels && (
              <p className="input-option">{t("account.city")}*</p>
            )}
            <div className={"input-item-wrapper" + (errors["city"] ? " has-error" : "")}>
              {withIcons && (
                <div className="input-item-icon">
                  <CityIcon />
                </div>
              )}
              <input
                type="text" 
                className={"input-item"}
                value={city || ""}
                onChange={(e) => handleSetCity(e.target.value)}
                placeholder={`${t("account.city")}${withRequiredPlaceholder ? "*" : ""}`}
                disabled={disabled}
                readOnly={readOnly}
              />
              {withErrorLabels && errors["city"] && <p className="error-message">{t("error.city")}</p>}
            </div>
          </div>
          <div className="input-wrapper-google-address">
            {!withoutLabels && (
              <p className="input-option">{t("account.state_province")}*</p>
            )}
            <div className={"input-item-wrapper" + (errors["stateProvince"] ? " has-error" : "")}>
              {withIcons && (
                <div className="input-item-icon">
                  <ProvinceIcon />
                </div>
              )}
              <input
                type="text" 
                className={"input-item"}
                value={stateProvince || ""}
                onChange={(e) => handleSetStateProvince(e.target.value)}
                placeholder={`${t("account.state_province")}${withRequiredPlaceholder ? "*" : ""}`}
                disabled={disabled}
                readOnly={readOnly}
              />
              {withErrorLabels && errors["stateProvince"] && <p className="error-message">{t("error.state")}</p>}
            </div>
          </div>
          <div className="input-wrapper-google-address">
            {!withoutLabels && (
              <p className="input-option">{t("account.zip_postal_code")}*</p>
            )}
            <div className={"input-item-wrapper" + (errors["zipCode"] ? " has-error" : "")}>
              {withIcons && (
                <div className="input-item-icon">
                  <PostBoxIcon />
                </div>
              )}
              <input
                type="text" 
                className={"input-item"}
                value={zipCode || ""}
                onChange={(e) => handleSetZipCode(e.target.value)}
                placeholder={`${t("account.zip_postal_code")}${withRequiredPlaceholder ? "*" : ""}`}
                disabled={disabled}
                readOnly={readOnly}
              />
              {withErrorLabels && errors["zipCode"] && <p className="error-message">{t("error.zipCode")}</p>}
            </div>
          </div>
        </div>
      </div>
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}

function mapStateToProps({ authedUser, colteneStore }) {
  return {
    authedUser,
    googlePlace: colteneStore?.googlePlace?.data || {},
    googlePlaceDetails: colteneStore?.googlePlaceDetails?.data || {},
    addressLoading: colteneStore?.googlePlaceLoading || false,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadGooglePlace,
        loadGooglePlaceDetails,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(GoogleAddressInput)
