import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import GoogleMapWrapper from "../common/GoogleMapWrapper"
import config from "../../utils/config"
import { useWindowSize } from "../../libs/hooks"
import { convertToSimpleDateWithTime } from '../../utils/filters/date'
import "./main-details.scss"

const MainDetails = (props) => {
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const [dataRegistrationContact, setDataRegistrationContact] = useState({})
  const [dataIOTContact, setDataIOTContact] = useState({})
  const [contactData, setContactData] = useState({})
  const [contactDataSource, setContactDataSource] = useState("")
  const [dataGeo, setDataGeo] = useState({})
  const [dataSoftwareVersion, setDataSoftwareVersion] = useState({})

  useEffect(() => {
    if (props.generalInformation) {
      setDataSoftwareVersion(props.generalInformation.software_version || {})
      setDataRegistrationContact(props.generalInformation.registration_contact || {})
      setDataIOTContact(props.generalInformation.iot_contact || {})
      setDataGeo(props.generalInformation.geo_data || {})
    }
  }, [props.generalInformation])
  
  function areAllValuesNullOrEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== null && obj[key] !== "") {
          return false;
        }
      }
    }
    return true;
  }
  
  useEffect(() => {
    if(areAllValuesNullOrEmpty(dataRegistrationContact) && !areAllValuesNullOrEmpty(dataIOTContact)) {
      setContactData(dataIOTContact)
      setContactDataSource(t("monitoring.from_device"))
      return
    }
      
    setContactData(dataRegistrationContact)
    setContactDataSource(t("monitoring.from_website_registration"))
  }, [dataIOTContact, dataRegistrationContact])
  
  return (
    <div className="main-details-wrapper">
      <div className="section-wrapper">
        <div className="title">{t("monitoring.unit_informations").toUpperCase()}</div>
        <div className="data">
          <span className="label">{t("product.unit.software_versions.last_ping_date")}:</span>
          <span className="label-data">
            {props.generalInformation?.last_ping_date ? convertToSimpleDateWithTime(props.generalInformation.last_ping_date) : t("labels.n_a")}
          </span>
        </div>
        <div className="data">
          <span className="label">{t("monitoring.software_versions")}:</span>
          <span className="label-data">
            {`${t("monitoring.screensaver")}: ${dataSoftwareVersion.screen_saver || t("labels.n_a")}`}
          </span>
        </div>
        <div className="data">
          <span className="label"/>
          <span className="label-data">
            {`${t("monitoring.instruction")}: ${dataSoftwareVersion.instruction || t("labels.n_a")}`}
          </span>
        </div>
        <div className="data">
          <span className="label"/>
          <span className="label-data">
            {`${t("monitoring.interface_t")}: ${dataSoftwareVersion.interface_t || t("labels.n_a")}`}
          </span>
        </div>
        <div className="data">
          <span className="label"/>
          <span className="label-data">
            {`${t("monitoring.interface_m")}: ${dataSoftwareVersion.interface_m || t("labels.n_a")}`}
          </span>
        </div>
        <div className="data">
          <span className="label"/>
          <span className="label-data">
            {`${t("monitoring.interface_d")}: ${dataSoftwareVersion.interface_d || t("labels.n_a")}`}
          </span>
        </div>
        <div className="data">
          <span className="label"/>
          <span className="label-data">
            {`${t("monitoring.interface_w")}: ${dataSoftwareVersion.interface_w || t("labels.n_a")}`}
          </span>
        </div>
        <div className="data">
          <span className="label"/>
          <span className="label-data">
            {`${t("monitoring.interface_s")}: ${dataSoftwareVersion.interface_s || t("labels.n_a")}`}
          </span>
        </div>
      </div>

      <div className="section-wrapper">
        <div className="title">{t("monitoring.contact_informations").toUpperCase()} <span className="data-source">({contactDataSource})</span></div>
        <div className="data">
          <span className="label">{t("product.contact.customer_title")}:</span>
          <span className="label-data">{contactData.title || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.first_name")}: </span>
          <span className="label-data">{contactData.first_name || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.middle_name")}: </span>
          <span className="label-data">{contactData.middle_name || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.last_name")}: </span>
          <span className="label-data">{contactData.last_name || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.email")}: </span>
          <span className="label-data">{contactData.email || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.phone")}: </span>
          <span className="label-data">{contactData.phone || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.fax")}: </span>
          <span className="label-data">{contactData.fax || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.country")}: </span>
          <span className="label-data">{contactData.country || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.region")}: </span>
          <span className="label-data">{contactData.region || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.city")}: </span>
          <span className="label-data">{contactData.city || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.address_1")}: </span>
          <span className="label-data">{contactData.address1 || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.address_2")}: </span>
          <span className="label-data">{contactData.address2 || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.postal_code")}: </span>
          <span className="label-data">{contactData.postal_code || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.contact.language")}: </span>
          <span className="label-data">{contactData.language || t("labels.n_a")}</span>
        </div>
      </div>

      <div className="section-wrapper">
        <div className="title">{t("monitoring.unit_geo_data").toUpperCase()}</div>
        <div className="data">
          <span className="label">{t("product.geolocation.product_ip")}: </span>
          <span className="label-data">{dataGeo.ip || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.geolocation.country_group")}: </span>
          <span className="label-data">{dataGeo.country || t("labels.n_a")}</span>
        </div>
        <div className="data">
          <span className="label">{t("product.geolocation.region")}: </span>
          <span className="label-data">{dataGeo.region || t("labels.n_a")}</span>
        </div>
        <div className="data with-margin-bottom">
          <span className="label">{t("product.geolocation.city")}: </span>
          <span className="label-data">{dataGeo.city || t("labels.n_a")}</span>
        </div> 
        {dataGeo.lat && (
          <GoogleMapWrapper className="map-wrapper">
            <a href={`${config.maps.searchURL}?q=${dataGeo.lat},${dataGeo.lng}`} target="_blank">
              <img
                className="lazy-load"
                border="0"
                alt="maps"
                src={`${config.maps.staticURL}?center=${dataGeo.lat},${dataGeo.lng}&maptype=static&zoom=14&size=${
                  width > 480 ? "640x320" : "320x160"
                }&scale=1&markers=color:red|${dataGeo.lat},${dataGeo.lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
              />
            </a>
            <div className="larger-map">
              <a href={`${config.maps.searchURL}?q=${dataGeo.lat},${dataGeo.lng}`} target="_blank">
                {t("product.geolocation.view_larger_map")}
              </a>
            </div>
          </GoogleMapWrapper>
        )}
      </div>
    </div>
  )
}

export default MainDetails
