import { getApertures } from "../utils/requests/usersAPI"

export const SET_APERTURES = 'SET_APERTURES'
export const SET_APERTURES_LOADING = 'SET_APERTURES_LOADING'
export const SET_APERTURES_LOADING_OFF = 'SET_APERTURES_LOADING_OFF'

export function setApertures(apertures) {
  return {
    type: SET_APERTURES,
    payload: apertures
  }
}

export function setAperturesLoading() {
  return {
    type: SET_APERTURES_LOADING
  }
}

export function setAperturesLoadingOff() {
  return {
    type: SET_APERTURES_LOADING_OFF
  }
}

export function handleSetApertures(cognitoSub) {
  return (dispatch) => {
    dispatch(setAperturesLoading())

    return getApertures(cognitoSub)
      .then ( ({data: apertures}) => {
        dispatch(setApertures(apertures))
      })
  }
}
