import React, { useEffect, useRef, useState } from "react"
import { CloseCircleBlackIcon } from "../../../icons"
import { keyboardEvents } from "../../../utils/keyboardEvents"
import "./page-modal.scss"

const PageModal = (props) => {
  const { toggle, onToggle } = props
  const [showHeaderBorder, setShowHeaderBorder] = useState(false)
  const pageContentRef = useRef(null)
  const modalRef = useRef(null)

  useEffect(() => {
    modalRef?.current?.focus()
  }, [])

  const closeModal = () => {
    onToggle()
  }

  const onScroll = () => {
    if (pageContentRef?.current) {
      const { scrollTop } = pageContentRef.current

      setShowHeaderBorder(scrollTop > 0)
    }
  }

  return (
    toggle && (
      <div className={`page-modal-wrapper ${props.className}`}>
        <div
          ref={modalRef}
          className="page-modal-overflow" 
          onClick={closeModal}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === keyboardEvents.ESC && closeModal()}
        />
        <div className="page-modal">
          <div className={`page-header ${showHeaderBorder ? "header-border" : ""}`}>
            <div>
              <div className="page-title">{props.title}</div>
              {props.subtitle && 
                <div className="page-subtitle">{props.subtitle}</div>
              }
            </div>
            <div className="page-header-content">
              {props.headerContent}
              <div 
                className="page-close"
                tabIndex={0}
                role="button"
                onKeyDown={(e) => e.key === keyboardEvents.ENTER && closeModal()}
              >
                <CloseCircleBlackIcon onClick={closeModal} />
              </div>
            </div>
          </div>
          <div className="page-content" onScroll={() => onScroll()} ref={pageContentRef}>
            {props.children}
          </div>
          <div className="page-footer">{props.footerContent}</div>
        </div>
      </div>
    )
  )
}

export default PageModal
