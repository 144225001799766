import React from 'react'
import { SortingArrow } from '../../../icons'
import CheckboxInput from '../CheckboxInput'
import "./generic-table.scss"
import { keyboardEvents } from '../../../utils/keyboardEvents'

const GenericTable = (props) => {
  const { data, headers, keys, keyRenderer, onRowClick, activeSort, onSort, hasSelectedAll, onSelectAll, className, isLoading } = props

  const getRow = (row, rowIndex) => {
    let startTime = 0
    let endTime = 0

    return (
      <tr
        key={rowIndex}
        className={(onRowClick ? "row-effects": "")}
        onClick={(e) => {
          if (onRowClick && endTime - startTime < 300) {
            handleRowClick(e, row);
          }
        }}
        onMouseDown={() => {
          startTime = new Date().getTime();
        }}
        onMouseUp={() => {
          endTime = new Date().getTime();
        }}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if (e.key === keyboardEvents.ENTER && onRowClick && endTime - startTime < 300) {
            handleRowClick(e, row);
          }
        }}
      >
        {keys.map((key, index) => {
          return (
            <td
              key={index}
            >
              {keyRenderer && keyRenderer[key]
                ? keyRenderer[key](row, rowIndex)
                : row[key]}
            </td>
          )
        })}
      </tr>
    )
  }

  const handleRowClick = (e, item) => {
    e.stopPropagation()

    onRowClick(item)
  }

  const handleSort = (e, orderKey, order) => {
    e.stopPropagation()

    if (isLoading) {
      return
    }
    
    onSort({
      field: orderKey,
      order: order,
      key: `${orderKey}_${order}`
    })
  }

  const handleSelectAll = () => {
    onSelectAll()
  }

  return (
    <div className={`generic-table-wrapper ${className}`}>
      <table className="table">
        <thead>
          <tr className="header">
            {headers.map((item, index) => {
              return (
                <th key={index}>
                  <div className="header-item">
                    {item.isMultiSelect && (
                      <div className="selectable-wrapper">
                        <CheckboxInput 
                          checked={hasSelectedAll}
                          onChange={() => handleSelectAll()}
                        />
                      </div>)
                    }
                    {item.title}
                    {item.orderKey && (
                      <div className="header-sort">
                        <SortingArrow
                          className={activeSort?.field === item.orderKey && activeSort.order === "asc" ? "active-sort" : "inactive-sort"}
                          onClick={(e) => handleSort(e, item.orderKey, "asc") }
                          tabIndex={0}
                          role="button"
                          onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleSort(e, item.orderKey, "asc")}
                        />
                        <SortingArrow
                          className={activeSort?.field === item.orderKey && activeSort.order === "desc" ? "active-sort" : "inactive-sort"}
                          onClick={(e) => handleSort(e, item.orderKey, "desc")}
                          tabIndex={0}
                          role="button"
                          onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleSort(e, item.orderKey, "desc")}
                        />
                      </div>)
                    }
                  </div>
                </th>)
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return getRow(row, index)
          })}
        </tbody>
      </table>
    </div>
  )
}

export default GenericTable
