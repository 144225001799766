import React, { useEffect, useState } from "react"
import "./radio-input.scss"

const RadioInput = (props) => {
  const { checked, onChange, text, disabled } = props
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const triggerRadio = () => {
    if (onChange) {
      setIsChecked(!isChecked)
      onChange()
    }
  }

  return (
    <div className="radio-wrapper">
      <div
        className={"radio-switch" + (isChecked ? " active-radio-switch" : "") + (disabled ? " disabled-radio-switch" : "")}
        onClick={triggerRadio}
      >
        {isChecked && <div className="inner-radio-circle"></div>}
      </div>
      <div className="radio-text">{text}</div>
    </div>
  )
}

export default RadioInput
