import { SET_QUERY_PARAMS } from '../actions/queryParams'

export default function queryParams (state = null, action) {
  switch (action.type) {
    case SET_QUERY_PARAMS:
      return {
        ...action.querystring
      }
    default:
      return state
  }
}
