import {
  LOAD_ACTIVE_PRICE_LISTS_FULFILLED,
  LOAD_ACTIVE_PRICE_LISTS_PENDING,
  LOAD_ACTIVE_PRICE_LISTS_REJECTED,
  LOAD_NEW_ACTIVE_PRICE_LISTS_FULFILLED,
  LOAD_NEW_ACTIVE_PRICE_LISTS_PENDING,
  LOAD_NEW_ACTIVE_PRICE_LISTS_REJECTED,
  LOAD_NEW_PRICE_LISTS_FULFILLED,
  LOAD_NEW_PRICE_LISTS_PENDING,
  LOAD_NEW_PRICE_LISTS_REJECTED,
  LOAD_NEW_NEW_PRICE_LISTS_FULFILLED,
  LOAD_NEW_NEW_PRICE_LISTS_PENDING,
  LOAD_NEW_NEW_PRICE_LISTS_REJECTED,
  LOAD_DISCONTINUED_PRICE_LISTS_FULFILLED,
  LOAD_DISCONTINUED_PRICE_LISTS_PENDING,
  LOAD_DISCONTINUED_PRICE_LISTS_REJECTED,
  LOAD_NEW_DISCONTINUED_PRICE_LISTS_FULFILLED,
  LOAD_NEW_DISCONTINUED_PRICE_LISTS_PENDING,
  LOAD_NEW_DISCONTINUED_PRICE_LISTS_REJECTED,
  LOAD_UPDATED_PRICE_LISTS_FULFILLED,
  LOAD_UPDATED_PRICE_LISTS_PENDING,
  LOAD_UPDATED_PRICE_LISTS_REJECTED,
  LOAD_NEW_UPDATED_PRICE_LISTS_FULFILLED,
  LOAD_NEW_UPDATED_PRICE_LISTS_PENDING,
  LOAD_NEW_UPDATED_PRICE_LISTS_REJECTED,
  LOAD_PRICE_LIST_REQUESTS_FULFILLED,
  LOAD_PRICE_LIST_REQUESTS_PENDING,
  LOAD_PRICE_LIST_REQUESTS_REJECTED,
  LOAD_NEW_PRICE_LIST_REQUESTS_FULFILLED,
  LOAD_NEW_PRICE_LIST_REQUESTS_PENDING,
  LOAD_NEW_PRICE_LIST_REQUESTS_REJECTED,
  LOAD_PRICE_LIST_FILTERS_FULFILLED,
  LOAD_PRICE_LIST_FILTERS_PENDING,
  LOAD_PRICE_LIST_FILTERS_REJECTED,
  LOAD_REVIEW_OPTIONS_FULFILLED,
  LOAD_REVIEW_OPTIONS_PENDING,
  LOAD_REVIEW_OPTIONS_REJECTED,
  POST_REVIEW_ITEMS_FULFILLED,
  POST_REVIEW_ITEMS_PENDING,
  POST_REVIEW_ITEMS_REJECTED,
  POST_MISSING_ITEMS_FULFILLED,
  POST_MISSING_ITEMS_PENDING,
  POST_MISSING_ITEMS_REJECTED,
  CLEAR_PRICE_LISTS_MESSAGES,
  CLEAR_PRICE_LISTS,
} from "../actions/priceLists"
import i18n from 'i18next'

function priceLists(state = {}, action) {
  switch (action.type) {
    case LOAD_ACTIVE_PRICE_LISTS_PENDING:
    case LOAD_NEW_ACTIVE_PRICE_LISTS_PENDING:
    case LOAD_NEW_PRICE_LISTS_PENDING:
    case LOAD_NEW_NEW_PRICE_LISTS_PENDING:
    case LOAD_DISCONTINUED_PRICE_LISTS_PENDING:
    case LOAD_NEW_DISCONTINUED_PRICE_LISTS_PENDING:
    case LOAD_UPDATED_PRICE_LISTS_PENDING:
    case LOAD_NEW_UPDATED_PRICE_LISTS_PENDING:
    case LOAD_PRICE_LIST_REQUESTS_PENDING:
    case LOAD_NEW_PRICE_LIST_REQUESTS_PENDING:
    case LOAD_PRICE_LIST_FILTERS_PENDING:
    case LOAD_REVIEW_OPTIONS_PENDING:
    case POST_MISSING_ITEMS_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case POST_REVIEW_ITEMS_PENDING:
      return {
        ...state,
        isLoading: true,
        clearSelectedItems: false,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ACTIVE_PRICE_LISTS_REJECTED:
    case LOAD_NEW_ACTIVE_PRICE_LISTS_REJECTED:
    case LOAD_NEW_PRICE_LISTS_REJECTED:
    case LOAD_NEW_NEW_PRICE_LISTS_REJECTED:
    case LOAD_DISCONTINUED_PRICE_LISTS_REJECTED:
    case LOAD_NEW_DISCONTINUED_PRICE_LISTS_REJECTED:
    case LOAD_UPDATED_PRICE_LISTS_REJECTED:
    case LOAD_NEW_UPDATED_PRICE_LISTS_REJECTED:
    case LOAD_PRICE_LIST_REQUESTS_REJECTED:
    case LOAD_NEW_PRICE_LIST_REQUESTS_REJECTED:
    case LOAD_PRICE_LIST_FILTERS_REJECTED:
    case LOAD_REVIEW_OPTIONS_REJECTED:
    case POST_REVIEW_ITEMS_REJECTED:
    case POST_MISSING_ITEMS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_ACTIVE_PRICE_LISTS_FULFILLED:
      const activePriceLists = state?.activePriceLists || []
      const newActivePriceListsIds = action.payload.data?.map(item => item.id)
      const activeDifference = activePriceLists
        .filter(x => !newActivePriceListsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        activePriceLists: activeDifference,
        canLoadMoreActive: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_ACTIVE_PRICE_LISTS_FULFILLED:
      return {
        ...state,
        activePriceLists: action.payload.data,
        canLoadMoreActive: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_PRICE_LISTS_FULFILLED:
      const newPriceLists = state?.newPriceLists || []
      const newNewPriceListsIds = action.payload.data?.map(item => item.id)
      const newDifference = newPriceLists
        .filter(x => !newNewPriceListsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        newPriceLists: newDifference,
        canLoadMoreNew: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_NEW_PRICE_LISTS_FULFILLED:
      return {
        ...state,
        newPriceLists: action.payload.data,
        canLoadMoreNew: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_DISCONTINUED_PRICE_LISTS_FULFILLED:
      const discontinuedPriceLists = state?.discontinuedPriceLists || []
      const newDiscontinuedPriceListsIds = action.payload.data?.map(item => item.id)
      const discontinuedDifference = discontinuedPriceLists
        .filter(x => !newDiscontinuedPriceListsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        discontinuedPriceLists: discontinuedDifference,
        canLoadMoreDiscontinued: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_DISCONTINUED_PRICE_LISTS_FULFILLED:
      return {
        ...state,
        discontinuedPriceLists: action.payload.data,
        canLoadMoreDiscontinued: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_UPDATED_PRICE_LISTS_FULFILLED:
      const updatedPriceLists = state?.updatedPriceLists || []
      const newUpdatedPriceListsIds = action.payload.data?.map(item => item.id)
      const updatedDifference = updatedPriceLists
        .filter(x => !newUpdatedPriceListsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        updatedPriceLists: updatedDifference,
        canLoadMoreUpdated: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_UPDATED_PRICE_LISTS_FULFILLED:
      return {
        ...state,
        updatedPriceLists: action.payload.data,
        canLoadMoreUpdated: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_PRICE_LIST_REQUESTS_FULFILLED:
      const requests = state?.priceListRequests || []
      const newRequestIds = action.payload.data?.map(item => item.id)
      const updatedRequests = requests
        .filter(x => !newRequestIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        priceListRequests: updatedRequests,
        canLoadMoreRequests: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_PRICE_LIST_REQUESTS_FULFILLED:
      return {
        ...state,
        priceListRequests: action.payload.data,
        canLoadMoreRequests: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_PRICE_LIST_FILTERS_FULFILLED:
      return {
        ...state,
        priceListFilters: action.payload.data,
        isLoading: false,
      }
    case LOAD_REVIEW_OPTIONS_FULFILLED:
      return {
        ...state,
        reviewOptions: action.payload.data,
        isLoading: false,
      }
    case POST_REVIEW_ITEMS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        clearSelectedItems: true,
        successMessage: i18n.t("dealer-portal.review_success"),
      }
    case POST_MISSING_ITEMS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        successMessage: i18n.t("dealer-portal.missing_item_success"),
      }
    case CLEAR_PRICE_LISTS_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    }
    case CLEAR_PRICE_LISTS:
      return {}
    default:
      return state
  }
}

export default priceLists
