
import { useEffect, useState, Fragment } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import PageModal from "../common/PageModal"
import LoadingSpinner from "../common/LoadingSpinner"
import { ExpandLessIcon, ExpandMoreIcon } from "../../icons"
import { getPermissionDescriptionKey, getPermissionTitleKey } from "../../utils/permissionsDescription"
import { loadRolePermissions } from "../../actions/accountsManagement"
import "./view-role-details-modal.scss"

const ViewRoleDetailsModal = (props) => {
  const { t } = useTranslation()
  const { closeModal, companyRoles, companyId, rolePermissions, isLoading } = props
  const [expandedRoleId, setExpandedRoleId] = useState(null)

  useEffect(() => {
    if (!companyId) {
      return
    }

    if (companyRoles.length === 0) {
      props.actions.loadCompanyRoles(companyId)
    }
  }, [companyId])

  useEffect(() => {
    if (companyRoles.length === 0) {
      return
    }

    const roleIds = companyRoles.map((role) => role.id)
    const currentRolePermissions = rolePermissions.map((role) => role.roleId)
  
    const missingRolePermissions = roleIds.filter((role) => !currentRolePermissions.includes(role))

    for (const roleId of missingRolePermissions) {
      props.actions.loadRolePermissions(roleId)
    }
  }, [companyRoles])

  useEffect(() => {
    if (rolePermissions.length === 0) {
      return
    }

    setExpandedRoleId(rolePermissions[0].roleId)
  }, [rolePermissions])

  const toggleExpandRole = (roleKey) => {
    setExpandedRoleId(roleKey === expandedRoleId ? null : roleKey)
  }

  return (
    <PageModal 
      toggle 
      onToggle={closeModal}
      className="view-role-details-modal"
      title={t("dealer-portal.role_page")}
    >
      <div className="view-role-details-modal-content">
        <div className="disclaimer">
          <p>
            {t("dealer-portal.role_page_disclaimer")}
          </p>
        </div>
        {rolePermissions.map((role) =>
          <Fragment key={`role-${role.roleId}`}>
            <div
              className={`role-expand-header ${role.roleId === expandedRoleId ? "highlight" : "border-bottom"}`}
              onClick={() => toggleExpandRole(role.roleId)}
            >
              <div className="title">
                <div>{companyRoles.find(r => r.id === role.roleId)?.name || ""}</div>
              </div>
              <div className="icon">{expandedRoleId === role.roleId ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
            </div>
            {expandedRoleId === role.roleId && role.permissions.map((permission) =>
              <div
                key={`${role.roleId} - ${permission}`}
                className="role-expand-body"
              > 
                <div className="title">{t(getPermissionTitleKey(permission))}</div>
                <div className="description">{t(getPermissionDescriptionKey(permission))}</div>
              </div>)
            }
          </Fragment>)
        }
      </div>
      {isLoading && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </PageModal>
  )
}

function stateToProps({ members, companies, accounts }) {
  return {
    companyId: members?.companyId,
    isLoading: members?.isLoading || companies?.isLoading || accounts?.rolePermissionsLoading,
    companyRoles: companies?.roles || [],
    rolePermissions: accounts?.rolePermissions || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadRolePermissions,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(ViewRoleDetailsModal)
