import React, { useEffect, useState } from "react"
import LoadingSpinner from "../common/LoadingSpinner"
import WrappedTabs from '../common/WrappedTabs'
import { SuccessTick } from "../../icons"
import { getAccounts, getRoles } from "../../utils/requests/accountManagementAPI"
import "./notified-group.scss"

const NotifiedGroup = ({ initialRoles, onChange, isEditMode=true, msgId }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [accounts, setAccounts] = useState([])
  const [roles, setRoles] = useState([])
  const [accountId, setAccountId] = useState(null)
  const [selectedRoles, setSelectedRoles] = useState([])
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    const getAccountsData = async () => {
      const accounts = await getAccounts()
      setAccounts(accounts)
      setAccountId(accounts[0].id)
      setActiveTab(accounts[0].id)
    }
    const getRolesData = async () => {
      const roles = await getRoles()
      setRoles(roles)
      const initialSelectedRoles = roles.filter(
        e => initialRoles.map(e => +e).includes(e.id)
      ).map(e => ({roleId: e.id, accountId: e.accountId}))
      setSelectedRoles(initialSelectedRoles)
    }
    getAccountsData()
    getRolesData()
  }, [])

  useEffect(() => {
    if(accounts.length > 0){
      setShowSpinner(false)
    }
  }, [accounts])

  const handleChangeTab = (accountId) => {
    setActiveTab(accountId)
    setAccountId(accountId)
  }

  const handleSelectedRoles = (roleId,accountId) => {
    let newSelectedRoles = [...selectedRoles]
    if(roleId !== 0) {
      const selectedIndex = selectedRoles.findIndex(e => e.roleId === roleId && e.accountId === accountId);
      newSelectedRoles = [...selectedRoles];
      if (selectedIndex === -1) {
        newSelectedRoles.push({roleId, accountId});
      } else {
        newSelectedRoles.splice(selectedIndex, 1);
      }
    } else {
      const selectedRolesAccount = selectedRoles.filter(e => e.accountId === accountId)
      const allRolesAccount = roles.filter(
        e => e.accountId === accountId
      ).map(e => ({roleId: e.id, accountId: e.accountId}))
      const otherRoles = selectedRoles.filter(
        e => allRolesAccount.findIndex(
          ele => ele.roleId === e.roleId && ele.accountId === e.accountId
        ) === -1
      )
      if(selectedRolesAccount.length !== allRolesAccount.length){
        newSelectedRoles = [...allRolesAccount, ...otherRoles]
      } else {
        newSelectedRoles = [...otherRoles]
      }
    }
  
    setSelectedRoles(newSelectedRoles);
    onChange(newSelectedRoles.map(e => e.roleId));
  }

  const isChecked = (roleId, accountId) => {
    let isChecked
    if(roleId !== 0){
      isChecked = selectedRoles.filter(
        e => e.accountId === accountId
      ).findIndex(e => e.roleId === roleId) !== -1
    } else {
      isChecked = roles.filter(
        e => e.accountId === accountId
      ).length === selectedRoles.filter(e => e.accountId === accountId).length
    }
    return isChecked
  }
  
  return (
    <div className="notified-group-wrapper">
      {accounts && (
        <>
          <WrappedTabs
            tabs={accounts.map((account) => ({ id: account.id, name: account.name }))}
            activeTab={activeTab}
            changeTab={handleChangeTab} 
          />
          {roles.length > 0 && (
            <div className="permissions-wrapper d-flex flex-row">
              {[{id: 0, name: 'Select All'}, ...roles.filter(e => e.accountId === accountId)].map((role, index) => (
                <div
                    className="permission-wrapper"
                    key={index}
                >
                  <div className="checkbox-wrapper">
                  <React.Fragment>
                    {isEditMode ?
                      <input
                        id={'permission-' + role.id}
                        type="checkbox"
                        name={role.id}
                        onChange={() => handleSelectedRoles(role.id, accountId)}
                        checked={isChecked(role.id, accountId)}
                      />
                      : 
                      <SuccessTick />
                    }
                    <label 
                      htmlFor={'permission-' + role.id}
                    >
                      {role.name}
                    </label>
                  </React.Fragment>
                  </div>
                </div>
                ))
              }
            </div>
          )}
        </>
      )}
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default NotifiedGroup