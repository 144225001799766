import React, { useMemo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import InviteUserModal from "./InviteMemberModal"
import AllMembers from "./AllMembers"
import Requests from "./Requests"
import ViewRoleDetailsModal from "./ViewRoleDetailsModal"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import WrappedTabs from "../common/WrappedTabs"
import SimpleButton from "../common/SimpleButton"
import "./members.scss"

const getTabs = (t) => [
  {
    key: 0,
    title: t("dealer-portal.active_members"),
    content: <AllMembers />,
  },
  {
    key: 1,
    title: t("dealer-portal.requests"),
    content: <Requests />,
  },
]

const Members = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigationTab = location.state?.activeTab
  const [activeTab, setActiveTab] = useState(null)
  const [showInviteUserModal, setShowInviteUserModal] = useState(false)
  const [showRoleDetails, setShowRoleDetails] = useState(false)
  const tabs = [
    {
      key: 0,
      title: t("dealer-portal.active_members"),
      content: <AllMembers showRoleDetails={() => setShowRoleDetails(true)} />,
    },
    {
      key: 1,
      title: t("dealer-portal.requests"),
      content: <Requests showRoleDetails={() => setShowRoleDetails(true)} />,
    },
  ]

  useEffect(() => {
    setActiveTab(tabs[navigationTab || 0])
  }, [])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.key === tabKey)
    setActiveTab(tab)
  }

  return (
    <div className="members-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader 
        headerText={t("nav.members")}
      >
        <SimpleButton
          className="submit-button"
          onClick={() => setShowInviteUserModal(true)}
        >
          {t("dealer-portal.invite_member")}
        </SimpleButton>
      </DashboardHeader>
      <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))} activeTab={activeTab?.key} changeTab={handleChangeTab} />
      {activeTab?.content && activeTab.content}

      {showInviteUserModal && (
        <InviteUserModal
          closeModal={() => setShowInviteUserModal(false)}
          showRoleDetails={() => setShowRoleDetails(true)}
        />
      )}
      {showRoleDetails && (
        <ViewRoleDetailsModal 
          closeModal={() => setShowRoleDetails(false)}
        />
      )}
    </div>
  )
}

export default Members
