import React from 'react'
import { FaSpinner } from 'react-icons/fa'

import "./button.scss"

 const Button = ({className='', disabled=false, isLoading, ...props}) => {

    return (
        <button 
            className={`btn ${className}`}
            type='submit'
            disabled={disabled || isLoading}
            {...props}
        >
            {isLoading && <FaSpinner className="loading-icon"/>} &nbsp;
            {props.children}
        </button>
    )
}

export default Button