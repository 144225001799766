import React, { useState, useLayoutEffect, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Active from "./Active"
import New from "./New"
import Discontinued from "./Discontinued"
import Updated from "./Updated"
import Requests from "./Requests"
import RequestReviewItemModal from "./RequestReviewItemModal"
import RequestMissingItemModal from "./RequestMissingItemModal"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import WrappedTabs from "../common/WrappedTabs"
import SimpleButton from "../common/SimpleButton"
import { priceListWritePermission } from "../../utils/permissionValidation"
import "./price-list.scss"

const PriceList = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(null)
  const [selectedReviewItems, setSelectedReviewItems] = useState([])
  const [selectedMissingItem, setSelectedMissingItem] = useState(null)
  const [showRequestReviewItemModal, setShowRequestReviewItemModal] = useState(false)
  const [showRequestMissingItemModal, setShowRequestMissingItemModal] = useState(false)
  const [isEditReviewItem, setIsEditReviewItem] = useState(true)
  const tabs = [
    {
      key: 0,
      title: t("dealer-portal.active-list"),
      content: <Active setSelectedReviewItems={setSelectedReviewItems} />,
    },
    {
      key: 1,
      title: t("dealer-portal.new-items"),
      content: <New setSelectedReviewItems={setSelectedReviewItems} />,
    },
    {
      key: 2,
      title: t("dealer-portal.discontinued-items"),
      content: <Discontinued setSelectedReviewItems={setSelectedReviewItems} />,
    },
    {
      key: 3,
      title: t("dealer-portal.updated-items"),
      content: <Updated setSelectedReviewItems={setSelectedReviewItems} />,
    },
    {
      key: 4,
      title: t("dealer-portal.requests"),
      content: <Requests setSelectedReviewItem={(item) => handleReviewItem(item)} setSelectedMissingItem={setSelectedMissingItem} />,
    },
  ]

  useLayoutEffect(() => {
    setSelectedReviewItems([])

    setIsEditReviewItem(activeTab?.key !== 4)
  }, [activeTab])

  useEffect(() => {
    setActiveTab(tabs[0])
  }, [])

  useEffect(() => {
    if (selectedMissingItem) {
      setShowRequestMissingItemModal(true)
    }
  },[selectedMissingItem])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.key === tabKey)
    setActiveTab(tab)
  }

  const handleReviewItem = (item) => {
    setSelectedReviewItems([{ ...item, options: item.options?.map((option) => Number(option)) || [] }])
    setShowRequestReviewItemModal(true)
  }

  return (
    <div className="price-list-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.price-list")}>
        <div className="request-buttons-wrapper">
          {activeTab?.key !== 4 && (
            <>
              <SimpleButton
                className="submit-button"
                disabled={selectedReviewItems.length === 0 || selectedReviewItems.length > 4}
                onClick={() => setShowRequestReviewItemModal(true)}
                requiredPermission={priceListWritePermission}
              >
                {t("dealer-portal.request-review")}
              </SimpleButton>
              <SimpleButton
                className="submit-button"
                onClick={() => setShowRequestMissingItemModal(true)}
                requiredPermission={priceListWritePermission}
              >
                {t("dealer-portal.request-missing")}
              </SimpleButton>
            </>
          )}
        </div>
      </DashboardHeader>
      <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))} activeTab={activeTab?.key} changeTab={handleChangeTab} />
      {activeTab?.content && activeTab.content}
      {showRequestReviewItemModal && (
        <RequestReviewItemModal
          selectedParts={selectedReviewItems}
          handleClose={() => setShowRequestReviewItemModal(false)}
          isEdit={isEditReviewItem}
        />
      )}
      {showRequestMissingItemModal && (
        <RequestMissingItemModal
          selectedMissingItem={selectedMissingItem}
          handleClose={() => {
            setSelectedMissingItem(null)
            setShowRequestMissingItemModal(false)
          }}
        />
      )}
    </div>
  )
}

export default PriceList
