const PermissionsDescription = [
  {
    permission: "page:dashboard",
    translationKey: "permission.page.dashboard"
  },
  {
    permission: "page:documents",
    translationKey: "permission.page.documents"
  },
  {
    permission: "page:enhanced_event_log",
    translationKey: "permission.page.enhanced_event_log"
  },
  {
    permission: "page:firmware",
    translationKey: "permission.page.firmware"
  },
  {
    permission: "page:monitoring",
    translationKey: "permission.page.monitoring"
  },
  {
    permission: "page:page_not_found",
    translationKey: "permission.page.page_not_found"
  },
  {
    permission: "page:product",
    translationKey: "permission.page.product"
  },
  {
    permission: "page:product_firmware",
    translationKey: "permission.page.product_firmware"
  },
  {
    permission: "page:product_notes",
    translationKey: "permission.page.product_notes"
  },
  {
    permission: "page:products",
    translationKey: "permission.page.products"
  },
  {
    permission: "page:register_product",
    translationKey: "permission.page.register_product"
  },
  {
    permission: "page:remote_access",
    translationKey: "permission.page.remote_access"
  },
  {
    permission: "page:remote_access_session",
    translationKey: "permission.page.remote_access_session"
  },
  {
    permission: "page:report_problem",
    translationKey: "permission.page.report_problem"
  },
  {
    permission: "page:report_problem_form",
    translationKey: "permission.page.report_problem_form"
  },
  {
    permission: "page:reports",
    translationKey: "permission.page.reports"
  },
  {
    permission: "page:spare_parts",
    translationKey: "permission.page.spare_parts"
  },
  {
    permission: "page:troubleshoot",
    translationKey: "permission.page.troubleshoot"
  },
  {
    permission: "page:videos",
    translationKey: "permission.page.videos"
  },
  {
    permission: "page:warranty",
    translationKey: "permission.page.warranty"
  },
  {
    permission: "page:store",
    translationKey: "permission.page.store"
  },
  {
    permission: "page:accounts",
    translationKey: "permission.page.accounts"
  },
  {
    permission: "page:store-admin",
    translationKey: "permission.page.store_admin"
  },
  {
    permission: "page:system-events",
    translationKey: "permission.page.system_events"
  },
  {
    permission: "page:notifications",
    translationKey: "permission.page.notifications"
  },
  {
    permission: "page:business-rules",
    translationKey: "permission.page.business_rules"
  },
  {
    permission: "page:services",
    translationKey: "permission.page.services"
  },
  {
    permission: "page:profile_settings",
    translationKey: "permission.page.profile_settings"
  },
  {
    permission: "page:policy",
    translationKey: "permission.page.policy"
  },
  {
    permission: "page:recycle-bin",
    translationKey: "permission.page.recycle_bin"
  },
  {
    permission: "page:time-frame",
    translationKey: "permission.page.time_frame"
  },
  {
    permission: "page:product-notification",
    translationKey: "permission.page.product_notification"
  },
  {
    permission: "page:user-product",
    translationKey: "permission.page.user_product"
  },
  {
    permission: "page:user-product-notification",
    translationKey: "permission.page.user_product_notification"
  },
  {
    permission: "page:user-products",
    translationKey: "permission.page.user_products"
  },
  {
    permission: "page:profile_settings",
    translationKey: "permission.page.profile_settings"
  },
  {
    permission: "page:user-warranty",
    translationKey: "permission.page.user_warranty"
  },
  {
    permission: "page:instrument-tracking",
    translationKey: "permission.page.instrument_tracking"
  },
  {
    permission: "page:users",
    translationKey: "permission.page.users"
  },
  {
    permission: "page:roles",
    translationKey: "permission.page.roles"
  },
  {
    permission: "page:admin-device-notification",
    translationKey: "permission.page.admin_device_notification"
  },
  {
    permission: "page:price-list",
    translationKey: "permission.page.price_list"
  },
  {
    permission: "page:bulletins",
    translationKey: "permission.page.bulletins"
  },
  {
    permission: "page:agreements",
    translationKey: "permission.page.agreements"
  },
  {
    permission: "page:advanced-troubleshooting",
    translationKey: "permission.page.advanced_troubleshooting"
  },
  {
    permission: "page:companies",
    translationKey: "permission.page.companies"
  },
  {
    permission: "page:members",
    translationKey: "permission.page.members"
  },
  {
    permission: "page:my-organization",
    translationKey: "permission.page.my_organization"
  }
]

export const getPermissionTitleKey = (permission) => {
  const permissionDescription = PermissionsDescription.find(p => p.permission === permission)
  return permissionDescription ? `${permissionDescription.translationKey}_title` : ''
}

export const getPermissionDescriptionKey = (permission) => {
  const permissionDescription = PermissionsDescription.find(p => p.permission === permission)
  return permissionDescription ? `${permissionDescription.translationKey}_description` : ''
}