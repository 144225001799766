import {
  SET_USER_PERMISSIONS,
} from "../actions/userPermissions"

export default function userPermissions(state = {}, action) {
  switch (action.type) {
    case SET_USER_PERMISSIONS:
      return action.permissions
    default:
      return state
  }
}
