import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../common/LoadingSpinner"
import ResponsiveTable from "../common/ResponsiveTable"
import EmptyPageResults from "../common/EmptyPageResults"
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { convertToSimpleDate, convertToSimpleDateWithTime } from "../../utils/filters/date"
import { getProductCyclesFaultsNotesBySn } from "../../utils/requests/productsAPI"
import "./notes-history.scss"

const NotesHistory = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { serialNumber } = props
  const [items, setItems] = useState([])
  const [notesTableData, setNotesTableData] = useState([])
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const tableHeaders = [
    {
      field: "cycle",
      translation: t("products.notes.cycle"),
      hasOrdering: false,
    },
    {
      field: "cycle_fault",
      translation: t("products.notes.cycle_fault"),
      hasOrdering: false,
    },
    {
      field: "cycle_date",
      translation: t("products.notes.cycle-date"),
      hasOrdering: false,
    },
    {
      field: "comment",
      translation: t("products.notes.comment"),
      hasOrdering: false,
    },
    {
      field: "user",
      translation: t("products.notes.user"),
      hasOrdering: false,
    },
    {
      field: "comment_date",
      translation: t("products.notes.comment-date"),
      hasOrdering: false,
    },
  ]

  useEffect(() => {
    if (serialNumber) {
      setShowSpinner(true)

      getProductCyclesFaultsNotesBySn(serialNumber)
        .then((data) => {
          if (data?.data) {
            setItems(data.data)

            const tableData = data.data.map((item) => {
              return {
                cycle: item.cycle_number,
                cycle_fault: `${t("products.notes.cycle_fault_abrev")}: ${item.cycle_fault}`,
                cycle_date: convertToSimpleDate(item.cycle_date),
                comment: item.note,
                user: item.posted_by,
                comment_date: convertToSimpleDateWithTime(item.note_date),
              }
            })

            setNotesTableData(tableData)
          }
        })
        .catch(() => {
          toast.error(t("error.failure_msg"))
        })
        .finally(() => {
          setShowSpinner(false)
          setIsFirstLoad(false)
        })

      return
    }

    setIsFirstLoad(false)
  }, [serialNumber])

  return (
    <div className="notes-history-wrapper">
      {!isMobile ?
        <>
          {notesTableData.length > 0 && 
            <ResponsiveTable 
              className="notes-history-table" 
              headers={tableHeaders} 
              data={notesTableData} 
              expandingColumn={5} 
            />
          }
        </>
      :
        <div className="mobile-content">
          {items.map((item, index) =>
            <div key={index} className="card">
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.cycle")}</div>
                <div className="card-item-body">{item.cycle_number}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.cycle_fault")}</div>
                <div className="card-item-body cycle-fault">{`${t("products.notes.cycle_fault_abrev")}: ${item.cycle_fault}`}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.cycle-date")}</div>
                <div className="card-item-body">{convertToSimpleDate(item.cycle_date)}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.comment")}</div>
                <div className="card-item-body">{item.note}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.user")}</div>
                <div className="card-item-body">{item.posted_by}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.comment-date")}</div>
                <div className="card-item-body">{convertToSimpleDateWithTime(item.note_date)}</div>
              </div>
            </div>)
          }
        </div>
      }
      {!isFirstLoad && items.length === 0 &&
        <EmptyPageResults className="empty-list" title={t("no_data_available")}/>
      }
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default NotesHistory
