import {
  LOAD_TRACKING_DATA_PENDING,
  LOAD_TRACKING_DATA_FULFILLED,
  LOAD_TRACKING_DATA_REJECTED,
  LOAD_CYCLE_DETAILS_PENDING,
  LOAD_CYCLE_DETAILS_FULFILLED,
  LOAD_CYCLE_DETAILS_REJECTED,
  LOAD_LEGACY_CYCLE_DETAILS_PENDING,
  LOAD_LEGACY_CYCLE_DETAILS_FULFILLED,
  LOAD_LEGACY_CYCLE_DETAILS_REJECTED,
  CLEAR_TRACKING_MESSAGES,
  CLEAR_CYCLE_DETAILS
} from "../actions/instrumentTracking"
import i18n from 'i18next'

export default function instrumentTracking (state={}, action) {
  switch (action.type) {
    case LOAD_TRACKING_DATA_PENDING:
      return {
        ...state,
        hasError: false,
        errorMessage: null,
        instrumentTracking: [],
        isLoading: true,
      }
    case LOAD_CYCLE_DETAILS_PENDING:
    case LOAD_LEGACY_CYCLE_DETAILS_PENDING:
      return {
        ...state,
        cycleLegacyDetails: null,
        hasError: false,
        cycleDetails: null,
        isLoadingDetails: true,
      }
    case LOAD_TRACKING_DATA_REJECTED:
      return {
        ...state,
        instrumentTracking: null,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_LEGACY_CYCLE_DETAILS_REJECTED:
      return {
        ...state,
        hasError: true,
        cycleLegacyDetails: null,
        isLoadingDetails: false,
      }
    case LOAD_CYCLE_DETAILS_REJECTED:
      return {
        ...state,
        hasError: true,
        cycleDetails: null,
        isLoadingDetails: false,
      }
    case LOAD_TRACKING_DATA_FULFILLED:
      return {
        ...state,
        instrumentTracking: action.payload,
        isLoading: false,
      }
    case LOAD_CYCLE_DETAILS_FULFILLED:
      return {
        ...state,
        cycleDetails: action.payload,
        isLoadingDetails: false,
      }
    case LOAD_LEGACY_CYCLE_DETAILS_FULFILLED:
      return {
        ...state,
        cycleLegacyDetails: action.payload,
        isLoadingDetails: false,
      }
    case CLEAR_TRACKING_MESSAGES:
      return {
        ...state,
        errorMessage: null,
      }
    case CLEAR_CYCLE_DETAILS:
      return {
        ...state,
        cycleDetails: null,
        cycleLegacyDetails: null,
        hasError: false,
        isLoadingDetails: false
      }
    default:
      return state
  }
}
