import { 
  FileDownloadIcon,
  FwDownloadIcon,
  RemoteAccessIcon,
  SparepartsIcon,
  TroubleshootIcon,
  VideoIcon,
  MonitoringIcon,
  SettingsWheelFullIcon,
  ReportProblemModernIcon,
  ProductsModernIcon,
  ReportsIcon,
  WebStoreIcon,
  PriceListIcon,
  // DigitalAssetsIcon,
  DealerBulletinsIcon,
  RequestHelpIcon,
} from "../icons"
import { manageAdminRoutes } from "./manageAdminRoutes"
import { Modules, Pages } from './pages'

const LinkCards = [
  {
    key: 'admin_settings',
    titleKey: "nav.admin_settings",
    descriptionKey: "dashboard.admin_settings_description",
    image: <SettingsWheelFullIcon />,
    permission: Modules.administration.permission,
    modulePages: manageAdminRoutes
  },
  {
    key: 'monitoring',
    titleKey: "nav.monitoring",
    descriptionKey: "dashboard.monitoring_description",
    image: <MonitoringIcon />,
    path: Pages.monitoring.route,
    permission: Pages.monitoring.permission,
  },
  {
    key: 'remote_access',
    titleKey: "nav.remote_access",
    descriptionKey: "dashboard.remote_access_description",
    image: <RemoteAccessIcon />,
    path: Pages.remoteAccess.route,
    permission: Pages.remoteAccess.permission,
  },
  {
    key: 'troubleshoot',
    titleKey: "nav.troubleshoot",
    descriptionKey: "dashboard.troubleshoot_description",
    image: <TroubleshootIcon />,
    path: Pages.troubleshoot.route,
    permission: Pages.troubleshoot.permission,
  },
  {
    key: 'fw_download',
    titleKey: "nav.firmware",
    descriptionKey: "dashboard.fw_download_description",
    image: <FwDownloadIcon />,
    path: Pages.firmware.route,
    permission: Pages.firmware.permission,
  },
  {
    key: 'spareparts',
    titleKey: "nav.spareparts",
    descriptionKey: "dashboard.spareparts_description",
    image: <SparepartsIcon />,
    path: Pages.spareParts.route,
    permission: Pages.spareParts.permission,
  },
  {
    key: 'documents',
    titleKey: "nav.documents",
    descriptionKey: "dashboard.documents_description",
    image: <FileDownloadIcon />,
    path: Pages.documents.route,
    permission: Pages.documents.permission,
  },
  {
    key: 'report_problem',
    titleKey: "nav.report_problem",
    descriptionKey: "dashboard.report_problem_description",
    image: <ReportProblemModernIcon />,
    path: Pages.reportProblem.route,
    permission: Pages.reportProblem.permission,
  },
  {
    key: 'videos',
    titleKey: "nav.videos",
    descriptionKey: "dashboard.videos_description",
    image: <VideoIcon />,
    path: Pages.videos.route,
    permission: Pages.videos.permission,
  },
  {
    key: "my_products",
    titleKey: "nav.my-products",
    descriptionKey: "dashboard.my_products_description",
    image: <ProductsModernIcon />,
    path: Pages.myProducts.route,
    permission: Pages.myProducts.permission
  },
  {
    key: "reports",
    titleKey: "nav.reports",
    descriptionKey: "dashboard.reports_description",
    image: <ReportsIcon />,
    path: Pages.reports.route,
    permission: Pages.reports.permission
  },
  {
    key: "coltene_store",
    titleKey: "nav.web_store",
    descriptionKey: "dashboard.web_store_description",
    image: <WebStoreIcon />,
    path: Pages.colteneStore.route,
    permission: Pages.colteneStore.permission
  },
  // {
  //   key: "instrument_tracking",
  //   titleKey: "nav.instrument-tracking",
  //   descriptionKey: "dashboard.instrument_tracking_description",
  //   image: <FontAwesomeIcon icon={faTh} className="icon" />,
  //   path: Pages.instrumentTracking.route,
  //   permission: Pages.instrumentTracking.permission
  // },
  {
    key: "price_lists",
    titleKey: "nav.price-list",
    descriptionKey: "dashboard.price_lists_description",
    image: <PriceListIcon />,
    path: Pages.priceLists.route,
    permission: Pages.priceLists.permission
  },
  {
    key: "request_help",
    titleKey: "nav.request_help",
    descriptionKey: "dashboard.request_help_description",
    image: <RequestHelpIcon />,
    path: Pages.requestHelp.route,
    permission: Pages.requestHelp.permission
  },
  // {
  //   key: "digital_assets",
  //   titleKey: "nav.digital-assets",
  //   descriptionKey: "dashboard.digital_assets_description",
  //   image: <DigitalAssetsIcon />,
  //   path: Pages.digitalAssets.route,
  //   permission: Pages.digitalAssets.permission
  // },
  {
    key: "dealer_bulletins",
    titleKey: "nav.dealer-bulletins",
    descriptionKey: "dashboard.dealer_bulletins_description",
    image: <DealerBulletinsIcon />,
    path: Pages.dealerBulletins.route,
    permission: Pages.dealerBulletins.permission
  },
]

export { LinkCards }