import {
  getAdminStoreProducts,
  getAdminStoreCountries,
  getAdminStorePricePlansForDigital,
  getAdminStoreProductCategories,
  getAdminStoreProductById,
  getAdminStoreServices,
  postAdminProduct,
  putAdminProduct,
  putPublishAdminProduct,
  putUnpublishAdminProduct,
  deleteAdminProduct,
} from "../utils/requests/productsAPI"

export const LOAD_ADMIN_STORE_PRODUCTS = "LOAD_ADMIN_STORE_PRODUCTS"
export const LOAD_ADMIN_STORE_PRODUCTS_PENDING = "LOAD_ADMIN_STORE_PRODUCTS_PENDING"
export const LOAD_ADMIN_STORE_PRODUCTS_FULFILLED = "LOAD_ADMIN_STORE_PRODUCTS_FULFILLED"
export const LOAD_ADMIN_STORE_PRODUCTS_REJECTED = "LOAD_ADMIN_STORE_PRODUCTS_REJECTED"

export const LOAD_ADMIN_STORE_NEW_PRODUCTS = "LOAD_ADMIN_STORE_NEW_PRODUCTS"
export const LOAD_ADMIN_STORE_NEW_PRODUCTS_PENDING = "LOAD_ADMIN_STORE_NEW_PRODUCTS_PENDING"
export const LOAD_ADMIN_STORE_NEW_PRODUCTS_FULFILLED = "LOAD_ADMIN_STORE_NEW_PRODUCTS_FULFILLED"
export const LOAD_ADMIN_STORE_NEW_PRODUCTS_REJECTED = "LOAD_ADMIN_STORE_NEW_PRODUCTS_REJECTED"

export const LOAD_ADMIN_STORE_PRODUCT_BY_ID = "LOAD_ADMIN_STORE_PRODUCT_BY_ID"
export const LOAD_ADMIN_STORE_PRODUCT_BY_ID_PENDING = "LOAD_ADMIN_STORE_PRODUCT_BY_ID_PENDING"
export const LOAD_ADMIN_STORE_PRODUCT_BY_ID_FULFILLED = "LOAD_ADMIN_STORE_PRODUCT_BY_ID_FULFILLED"
export const LOAD_ADMIN_STORE_PRODUCT_BY_ID_REJECTED = "LOAD_ADMIN_STORE_PRODUCT_BY_ID_REJECTED"

export const UPDATE_ADMIN_STORE_PRODUCT_BY_ID = "UPDATE_ADMIN_STORE_PRODUCT_BY_ID"
export const UPDATE_ADMIN_STORE_PRODUCT_BY_ID_PENDING = "UPDATE_ADMIN_STORE_PRODUCT_BY_ID_PENDING"
export const UPDATE_ADMIN_STORE_PRODUCT_BY_ID_FULFILLED = "UPDATE_ADMIN_STORE_PRODUCT_BY_ID_FULFILLED"
export const UPDATE_ADMIN_STORE_PRODUCT_BY_ID_REJECTED = "UPDATE_ADMIN_STORE_PRODUCT_BY_ID_REJECTED"

export const LOAD_ADMIN_STORE_COUNTRIES = "LOAD_STORE_COUNTRIES"
export const LOAD_ADMIN_STORE_COUNTRIES_PENDING = "LOAD_STORE_COUNTRIES_PENDING"
export const LOAD_ADMIN_STORE_COUNTRIES_FULFILLED = "LOAD_STORE_COUNTRIES_FULFILLED"
export const LOAD_ADMIN_STORE_COUNTRIES_REJECTED = "LOAD_STORE_COUNTRIES_REJECTED"

export const LOAD_ADMIN_STORE_PRICE_PLANS = "LOAD_STORE_PRICE_PLANS"
export const LOAD_ADMIN_STORE_PRICE_PLANS_PENDING = "LOAD_STORE_PRICE_PLANS_PENDING"
export const LOAD_ADMIN_STORE_PRICE_PLANS_FULFILLED = "LOAD_STORE_PRICE_PLANS_FULFILLED"
export const LOAD_ADMIN_STORE_PRICE_PLANS_REJECTED = "LOAD_STORE_PRICE_PLANS_REJECTED"

export const LOAD_ADMIN_STORE_SERVICES = "LOAD_ADMIN_STORE_SERVICES"
export const LOAD_ADMIN_STORE_SERVICES_PENDING = "LOAD_ADMIN_STORE_SERVICES_PENDING"
export const LOAD_ADMIN_STORE_SERVICES_FULFILLED = "LOAD_ADMIN_STORE_SERVICES_FULFILLED"
export const LOAD_ADMIN_STORE_SERVICES_REJECTED = "LOAD_ADMIN_STORE_SERVICES_REJECTED"

export const LOAD_ADMIN_STORE_PRODUCT_CATEGORIES = "LOAD_ADMIN_STORE_PRODUCT_CATEGORIES"
export const LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_PENDING = "LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_PENDING"
export const LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_FULFILLED = "LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_FULFILLED"
export const LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_REJECTED = "LOAD_ADMIN_STORE_PRODUCT_CATEGORIES_REJECTED"

export const POST_PRODUCT = "POST_PRODUCT"
export const POST_PRODUCT_PENDING = "POST_PRODUCT_PENDING"
export const POST_PRODUCT_FULFILLED = "POST_PRODUCT_FULFILLED"
export const POST_PRODUCT_REJECTED = "POST_PRODUCT_REJECTED"

export const PUT_PRODUCT = "PUT_PRODUCT"
export const PUT_PRODUCT_PENDING = "PUT_PRODUCT_PENDING"
export const PUT_PRODUCT_FULFILLED = "PUT_PRODUCT_FULFILLED"
export const PUT_PRODUCT_REJECTED = "PUT_PRODUCT_REJECTED"

export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_PENDING = "DELETE_PRODUCT_PENDING"
export const DELETE_PRODUCT_FULFILLED = "DELETE_PRODUCT_FULFILLED"
export const DELETE_PRODUCT_REJECTED = "DELETE_PRODUCT_REJECTED"

export const PUT_PRODUCT_STATUS = "PUT_PRODUCT_STATUS"
export const PUT_PRODUCT_STATUS_PENDING = "PUT_PRODUCT_STATUS_PENDING"
export const PUT_PRODUCT_STATUS_FULFILLED = "PUT_PRODUCT_STATUS_FULFILLED"
export const PUT_PRODUCT_STATUS_REJECTED = "PUT_PRODUCT_STATUS_REJECTED"

export const PUT_PRODUCT_PRICES = "PUT_PRODUCT_PRICES"
export const PUT_PRODUCT_PRICES_PENDING = "PUT_PRODUCT_PRICES_PENDING"
export const PUT_PRODUCT_PRICES_FULFILLED = "PUT_PRODUCT_PRICES_FULFILLED"
export const PUT_PRODUCT_PRICES_REJECTED = "PUT_PRODUCT_PRICES_REJECTED"

export const CLEAR_NEW_PRODUCT = "CLEAR_NEW_PRODUCT"
export const CLEAR_PRODUCT_MESSAGES = "CLEAR_PRODUCT_MESSAGES"

export function loadAdminStoreProducts(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_ADMIN_STORE_NEW_PRODUCTS : LOAD_ADMIN_STORE_PRODUCTS,
    payload: getAdminStoreProducts(queryParams),
  }
}

export function loadAdminStoreCountries() {
  return {
    type: LOAD_ADMIN_STORE_COUNTRIES,
    payload: getAdminStoreCountries(),
  }
}

export function loadAdminStorePricePlans() {
  return {
    type: LOAD_ADMIN_STORE_PRICE_PLANS,
    payload: getAdminStorePricePlansForDigital(),
  }
}

export function loadAdminStoreProductCategories() {
  return {
    type: LOAD_ADMIN_STORE_PRODUCT_CATEGORIES,
    payload: getAdminStoreProductCategories(),
  }
}

export function loadAdminStoreProductById(productId) {
  return {
    type: LOAD_ADMIN_STORE_PRODUCT_BY_ID,
    payload: getAdminStoreProductById(productId),
  }
}

export function loadAdminStoreServices() {
  return {
    type: LOAD_ADMIN_STORE_SERVICES,
    payload: getAdminStoreServices(),
  }
}

export function createProduct(bodyParams) {
  return {
    type: POST_PRODUCT,
    payload: postAdminProduct(bodyParams),
  }
}

export function updateProduct(id, bodyParams) {
  return {
    type: PUT_PRODUCT,
    payload: putAdminProduct(id, bodyParams),
  }
}

export function updateProductPrices(newProductPrices, productId) {
  return {
    type: PUT_PRODUCT_PRICES,
    payload: newProductPrices,
    meta: { productId: productId },
  }
}

export function changeProductStatus(id, shouldPublish) {
  return {
    type: PUT_PRODUCT_STATUS,
    payload: shouldPublish ? putPublishAdminProduct(id) : putUnpublishAdminProduct(id),
    meta: { id, shouldPublish },
  }
}

export function delProduct(id) {
  return {
    type: DELETE_PRODUCT,
    payload: deleteAdminProduct(id),
    meta: { id: id },
  }
}

export function clearNewProduct() {
  return {
    type: CLEAR_NEW_PRODUCT,
  }
}

export function clearProductsMessages() {
  return {
    type: CLEAR_PRODUCT_MESSAGES,
  }
}

