import React, {useState} from 'react'
import {connect} from "react-redux";
import {REMOTE_SESSION_DATA} from "../../actions/root";
import './remote-control.scss'

const RemoteAccessSession = (props) => {
  const data = localStorage.getItem(REMOTE_SESSION_DATA)
  const [remoteSessionData, setRemoteSessionData] = useState(data ? JSON.parse(data) : data)

  const closeSession = () => {
    props.disableRemoteSession()
  }

  if(!remoteSessionData) {
    closeSession()
  }
  
  return (
      <div className="remote-access-session-wrapper">
        {remoteSessionData && remoteSessionData.url &&
          <div className="d-flex h-80vh">
            <div className="iframe-wrapper">
              <iframe src={remoteSessionData.url} className="iframe"/>
            </div>
          </div>
        }
      </div>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}

export default connect(mapStateToProps)(RemoteAccessSession);