import {
  getPriceLists,
  getPriceListsFilterOptions,
  getPriceListsRequests,
  getRequestReviewOptions,
  postRequestReview,
  postRequestMissingItems,
} from "../utils/requests/dealersAPI"

export const LOAD_ACTIVE_PRICE_LISTS = "LOAD_ACTIVE_PRICE_LISTS"
export const LOAD_ACTIVE_PRICE_LISTS_PENDING = "LOAD_ACTIVE_PRICE_LISTS_PENDING"
export const LOAD_ACTIVE_PRICE_LISTS_FULFILLED = "LOAD_ACTIVE_PRICE_LISTS_FULFILLED"
export const LOAD_ACTIVE_PRICE_LISTS_REJECTED = "LOAD_ACTIVE_PRICE_LISTS_REJECTED"

export const LOAD_NEW_ACTIVE_PRICE_LISTS = "LOAD_NEW_ACTIVE_PRICE_LISTS"
export const LOAD_NEW_ACTIVE_PRICE_LISTS_PENDING = "LOAD_NEW_ACTIVE_PRICE_LISTS_PENDING"
export const LOAD_NEW_ACTIVE_PRICE_LISTS_FULFILLED = "LOAD_NEW_ACTIVE_PRICE_LISTS_FULFILLED"
export const LOAD_NEW_ACTIVE_PRICE_LISTS_REJECTED = "LOAD_NEW_ACTIVE_PRICE_LISTS_REJECTED"

export const LOAD_NEW_PRICE_LISTS = "LOAD_NEW_PRICE_LISTS"
export const LOAD_NEW_PRICE_LISTS_PENDING = "LOAD_NEW_PRICE_LISTS_PENDING"
export const LOAD_NEW_PRICE_LISTS_FULFILLED = "LOAD_NEW_PRICE_LISTS_FULFILLED"
export const LOAD_NEW_PRICE_LISTS_REJECTED = "LOAD_NEW_PRICE_LISTS_REJECTED"

export const LOAD_NEW_NEW_PRICE_LISTS = "LOAD_NEW_NEW_PRICE_LISTS"
export const LOAD_NEW_NEW_PRICE_LISTS_PENDING = "LOAD_NEW_NEW_PRICE_LISTS_PENDING"
export const LOAD_NEW_NEW_PRICE_LISTS_FULFILLED = "LOAD_NEW_NEW_PRICE_LISTS_FULFILLED"
export const LOAD_NEW_NEW_PRICE_LISTS_REJECTED = "LOAD_NEW_NEW_PRICE_LISTS_REJECTED"

export const LOAD_DISCONTINUED_PRICE_LISTS = "LOAD_DISCONTINUED_PRICE_LISTS"
export const LOAD_DISCONTINUED_PRICE_LISTS_PENDING = "LOAD_DISCONTINUED_PRICE_LISTS_PENDING"
export const LOAD_DISCONTINUED_PRICE_LISTS_FULFILLED = "LOAD_DISCONTINUED_PRICE_LISTS_FULFILLED"
export const LOAD_DISCONTINUED_PRICE_LISTS_REJECTED = "LOAD_DISCONTINUED_PRICE_LISTS_REJECTED"

export const LOAD_NEW_DISCONTINUED_PRICE_LISTS = "LOAD_NEW_DISCONTINUED_PRICE_LISTS"
export const LOAD_NEW_DISCONTINUED_PRICE_LISTS_PENDING = "LOAD_NEW_DISCONTINUED_PRICE_LISTS_PENDING"
export const LOAD_NEW_DISCONTINUED_PRICE_LISTS_FULFILLED = "LOAD_NEW_DISCONTINUED_PRICE_LISTS_FULFILLED"
export const LOAD_NEW_DISCONTINUED_PRICE_LISTS_REJECTED = "LOAD_NEW_DISCONTINUED_PRICE_LISTS_REJECTED"

export const LOAD_UPDATED_PRICE_LISTS = "LOAD_UPDATED_PRICE_LISTS"
export const LOAD_UPDATED_PRICE_LISTS_PENDING = "LOAD_UPDATED_PRICE_LISTS_PENDING"
export const LOAD_UPDATED_PRICE_LISTS_FULFILLED = "LOAD_UPDATEDW_PRICE_LISTS_FULFILLED"
export const LOAD_UPDATED_PRICE_LISTS_REJECTED = "LOAD_UPDATED_PRICE_LISTS_REJECTED"

export const LOAD_NEW_UPDATED_PRICE_LISTS = "LOAD_NEW_UPDATED_PRICE_LISTS"
export const LOAD_NEW_UPDATED_PRICE_LISTS_PENDING = "LOAD_NEW_UPDATED_PRICE_LISTS_PENDING"
export const LOAD_NEW_UPDATED_PRICE_LISTS_FULFILLED = "LOAD_NEW_UPDATED_PRICE_LISTS_FULFILLED"
export const LOAD_NEW_UPDATED_PRICE_LISTS_REJECTED = "LOAD_NEW_UPDATED_PRICE_LISTS_REJECTED"

export const LOAD_PRICE_LIST_REQUESTS = "LOAD_PRICE_LIST_REQUESTS"
export const LOAD_PRICE_LIST_REQUESTS_PENDING = "LOAD_PRICE_LIST_REQUESTS_PENDING"
export const LOAD_PRICE_LIST_REQUESTS_FULFILLED = "LOAD_PRICE_LIST_REQUESTS_FULFILLED"
export const LOAD_PRICE_LIST_REQUESTS_REJECTED = "LOAD_PRICE_LIST_REQUESTS_REJECTED"

export const LOAD_NEW_PRICE_LIST_REQUESTS = "LOAD_NEW_PRICE_LIST_REQUESTS"
export const LOAD_NEW_PRICE_LIST_REQUESTS_PENDING = "LOAD_NEW_PRICE_LIST_REQUESTS_PENDING"
export const LOAD_NEW_PRICE_LIST_REQUESTS_FULFILLED = "LOAD_NEW_PRICE_LIST_REQUESTS_FULFILLED"
export const LOAD_NEW_PRICE_LIST_REQUESTS_REJECTED = "LOAD_NEW_PRICE_LIST_REQUESTS_REJECTED"

export const LOAD_PRICE_LIST_FILTERS = "LOAD_PRICE_LIST_FILTERS"
export const LOAD_PRICE_LIST_FILTERS_PENDING = "LOAD_PRICE_LIST_FILTERS_PENDING"
export const LOAD_PRICE_LIST_FILTERS_FULFILLED = "LOAD_PRICE_LIST_FILTERS_FULFILLED"
export const LOAD_PRICE_LIST_FILTERS_REJECTED = "LOAD_PRICE_LIST_FILTERS_REJECTED"

export const LOAD_REVIEW_OPTIONS = "LOAD_REVIEW_OPTIONS"
export const LOAD_REVIEW_OPTIONS_PENDING = "LOAD_REVIEW_OPTIONS_PENDING"
export const LOAD_REVIEW_OPTIONS_FULFILLED = "LOAD_REVIEW_OPTIONS_FULFILLED"
export const LOAD_REVIEW_OPTIONS_REJECTED = "LOAD_REVIEW_OPTIONS_REJECTED"

export const POST_REVIEW_ITEMS = "POST_REVIEW_ITEMS"
export const POST_REVIEW_ITEMS_PENDING = "POST_REVIEW_ITEMS_PENDING"
export const POST_REVIEW_ITEMS_FULFILLED = "POST_REVIEW_ITEMS_FULFILLED"
export const POST_REVIEW_ITEMS_REJECTED = "POST_REVIEW_ITEMS_REJECTED"

export const POST_MISSING_ITEMS = "POST_MISSING_ITEMS"
export const POST_MISSING_ITEMS_PENDING = "POST_MISSING_ITEMS_PENDING"
export const POST_MISSING_ITEMS_FULFILLED = "POST_MISSING_ITEMS_FULFILLED"
export const POST_MISSING_ITEMS_REJECTED = "POST_MISSING_ITEMS_REJECTED"

export const CLEAR_PRICE_LISTS_MESSAGES = "CLEAR_PRICE_LISTS_MESSAGES"
export const CLEAR_PRICE_LISTS = "CLEAR_PRICE_LISTS"

export function loadActivePriceLists(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_ACTIVE_PRICE_LISTS : LOAD_ACTIVE_PRICE_LISTS,
    payload: getPriceLists({ ...queryParams, status: "Active" })
  }
}

export function loadNewPriceLists(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_NEW_PRICE_LISTS : LOAD_NEW_PRICE_LISTS,
    payload: getPriceLists({ ...queryParams, status: "New" })
  }
}

export function loadDiscontinuedPriceLists(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_DISCONTINUED_PRICE_LISTS : LOAD_DISCONTINUED_PRICE_LISTS,
    payload: getPriceLists({ ...queryParams, status: "Discontinued" })
  }
}

export function loadUpdatedPriceLists(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_UPDATED_PRICE_LISTS : LOAD_UPDATED_PRICE_LISTS,
    payload: getPriceLists({ ...queryParams, status: "Updated" })
  }
}

export function loadPriceListRequests(queryParams, refetch = false) {
  return {
    type: refetch ? LOAD_NEW_PRICE_LIST_REQUESTS : LOAD_PRICE_LIST_REQUESTS,
    payload: getPriceListsRequests(queryParams)
  }
}

export function loadPriceListFilters() {
  return {
    type: LOAD_PRICE_LIST_FILTERS,
    payload: getPriceListsFilterOptions()
  }
}

export function loadReviewOptions() {
  return {
    type: LOAD_REVIEW_OPTIONS,
    payload: getRequestReviewOptions()
  }
}

export function postReviewItems(bodyParams) {
  return {
    type: POST_REVIEW_ITEMS,
    payload: postRequestReview(bodyParams),
    meta: { reviewItems: bodyParams }
  }
}

export function postMissingItems(bodyParams) {
  return {
    type: POST_MISSING_ITEMS,
    payload: postRequestMissingItems(bodyParams),
    meta: { missingItems: bodyParams }
  }
}

export function clearPriceListsMessages() {
  return {
    type: CLEAR_PRICE_LISTS_MESSAGES
  }
}

export function clearPriceLists() {
  return {
    type: CLEAR_PRICE_LISTS
  }
}

export function handleClearPriceLists() {
  return (dispatch) => {
    return dispatch(clearPriceLists())
  }
}
