import { _baseGetFetch } from "./baseFetch"

const path = "/documents"

export const getDocuments = async (queryParams, limit) => {
  return _baseGetFetch(`${path}`, { ...queryParams, limit: limit || process.env.REACT_APP_PAGINATION_SIZE }, undefined)
}

export const getTSBDocuments = async (queryParams, limit) => {
  return _baseGetFetch(`${path}/technical-service-bulletins`, { ...queryParams, limit: limit || process.env.REACT_APP_PAGINATION_SIZE }, undefined)
}

export const getDocumentTypes = () => {
  return _baseGetFetch(`${path}/types`, undefined, undefined)
}

export const getDocumentLanguages = async (queryParams) => {
  return _baseGetFetch(`${path}/languages`, { ...queryParams }, undefined)
}
