import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {SearchIcon, CloseCircleIcon} from '../../../icons'
import "./search.scss"

const Search = (props) => {
  const [searchText, setSearchText] = useState("")
  const {onSearch, placeholder, icon, searchError, resetPage, onCancel, onKeyDown, className="", disabled = false } = props

  useEffect(() => {
    setSearchText(props.searchText || "")
  }, [props.searchText])

  const handleChange = (e) => {
    const value = (e.target.value)
    setSearchText(value)
    onSearch(value)
    if (props.resetPage) {
      resetPage()
    }
  }

  const handleOnCancel = () => {
    setSearchText("")
    onCancel()
  }

  return (
    <div className={`search-wrapper ${className}`}>
      {searchError &&
        <div className="error-message">{searchError}</div>
      }
      <div className="search">
        {icon ?
          icon
        :
          <SearchIcon className="search-icon" />
        }

        <input
            type="text"
            placeholder={placeholder}
            value={searchText}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            disabled={disabled}
        />
        {onCancel && searchText.length > 0 && (
          <button className="reset-search-button" onClick={(e) => {e.stopPropagation(); handleOnCancel()}}>
            <CloseCircleIcon/>
          </button>)
        }
      </div>
    </div>

  )
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  resetPage: PropTypes.func
}

export default Search
