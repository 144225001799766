import { 
  getUsersList,
  getUserDevices,
  getUserSubscriptions,
  getUserPermissions,
  patchUser,
  deleteUser,
  confirmCognitoUser
} from "../utils/requests/usersAPI"

export const LOAD_USERS = "LOAD_USERS"
export const LOAD_USERS_PENDING = "LOAD_USERS_PENDING"
export const LOAD_USERS_FULFILLED = "LOAD_USERS_FULFILLED"
export const LOAD_USERS_REJECTED = "LOAD_USERS_REJECTED"

export const LOAD_NEW_USERS = "LOAD_NEW_USERS"
export const LOAD_NEW_USERS_PENDING = "LOAD_NEW_USERS_PENDING"
export const LOAD_NEW_USERS_FULFILLED = "LOAD_NEW_USERS_FULFILLED"
export const LOAD_NEW_USERS_REJECTED = "LOAD_NEW_USERS_REJECTED"

export const PATCH_USER = "PATCH_USER"
export const PATCH_USER_PENDING = "PATCH_USER_PENDING"
export const PATCH_USER_FULFILLED = "PATCH_USER_FULFILLED"
export const PATCH_USER_REJECTED = "PATCH_USER_REJECTED"

export const CONFIRM_USER = "CONFIRM_USER"
export const CONFIRM_USER_PENDING = "CONFIRM_USER_PENDING"
export const CONFIRM_USER_FULFILLED = "CONFIRM_USER_FULFILLED"
export const CONFIRM_USER_REJECTED = "CONFIRM_USER_REJECTED"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_PENDING = "DELETE_USER_PENDING"
export const DELETE_USER_FULFILLED = "DELETE_USER_FULFILLED"
export const DELETE_USER_REJECTED = "DELETE_USER_REJECTED"

export const LOAD_USER_DEVICES = "LOAD_USER_DEVICES"
export const LOAD_USER_DEVICES_PENDING = "LOAD_USER_DEVICES_PENDING"
export const LOAD_USER_DEVICES_FULFILLED = "LOAD_USER_DEVICES_FULFILLED"
export const LOAD_USER_DEVICES_REJECTED = "LOAD_USER_DEVICES_REJECTED"

export const LOAD_USER_SUBSCRIPTIONS = "LOAD_USER_SUBSCRIPTIONS"
export const LOAD_USER_SUBSCRIPTIONS_PENDING = "LOAD_USER_SUBSCRIPTIONS_PENDING"
export const LOAD_USER_SUBSCRIPTIONS_FULFILLED = "LOAD_USER_SUBSCRIPTIONS_FULFILLED"
export const LOAD_USER_SUBSCRIPTIONS_REJECTED = "LOAD_USER_SUBSCRIPTIONS_REJECTED"

export const LOAD_END_USER_PERMISSIONS = "LOAD_END_USER_PERMISSIONS"
export const LOAD_END_USER_PERMISSIONS_PENDING = "LOAD_END_USER_PERMISSIONS_PENDING"
export const LOAD_END_USER_PERMISSIONS_FULFILLED = "LOAD_END_USER_PERMISSIONS_FULFILLED"
export const LOAD_END_USER_PERMISSIONS_REJECTED = "LOAD_END_USER_PERMISSIONS_REJECTED"

export const CLEAR_USERS_MESSAGES = "CLEAR_USERS_MESSAGES"
export const CLEAR_USER_DEVICES = "CLEAR_USER_DEVICES"
export const CLEAR_USER_SUBSCRIPTIONS = "CLEAR_USER_SUBSCRIPTIONS"
export const CLEAR_END_USER_PERMISSIONS = "CLEAR_END_USER_PERMISSIONS"

export function loadUsers(queryParams, refetch = false, limit) {
  return {
    type: refetch ? LOAD_NEW_USERS : LOAD_USERS,
    payload: getUsersList(queryParams, limit),
  }
}

export function partiallyUpdateUser(id, bodyParams) {
  return {
    type: PATCH_USER,
    payload: patchUser(id, bodyParams)
  }
}

export function confirmUser(id) {
  return {
    type: CONFIRM_USER,
    payload: confirmCognitoUser(id)
  }
}

export function removeUser(id) {
  return {
    type: DELETE_USER,
    payload: deleteUser(id),
    meta: { id }
  }
}

export function loadUserDevices(id) {
  return {
    type: LOAD_USER_DEVICES,
    payload: getUserDevices(id),
  }
}

export function loadUserSubscriptions(id) {
  return {
    type: LOAD_USER_SUBSCRIPTIONS,
    payload: getUserSubscriptions(id),
  }
}

export function loadUserPermissions(id) {
  return {
    type: LOAD_END_USER_PERMISSIONS,
    payload: getUserPermissions(id),
  }
}

export function clearUsersMessages() {
  return {
    type: CLEAR_USERS_MESSAGES
  }
}

export function clearUserDevices() {
  return {
    type: CLEAR_USER_DEVICES
  }
}

export function clearUserSubscriptions() {
  return {
    type: CLEAR_USER_SUBSCRIPTIONS
  }
}

export function clearUserPermissions() {
  return {
    type: CLEAR_END_USER_PERMISSIONS
  }
}
