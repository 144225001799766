import React from 'react'
import { useTranslation } from "react-i18next";
import './footer.scss'

const Footer = () => {
  const { t } = useTranslation() 
  const curYear = (new Date()).getFullYear()
  return (
    <footer>
      <div>
        {t("copyright", {year: curYear})}
      </div>
    </footer>
  )
}

export default Footer