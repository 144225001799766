import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ServiceModal from "./ServiceModal"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import LoadingSpinner from "../common/LoadingSpinner"
import Breadcrumb from "../common/Breadcrumb"
import ConfirmationModal from "../common/ConfirmationModal"
import EmptyPageResults from "../common/EmptyPageResults"
import SimpleButton from "../common/SimpleButton"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import { toast } from "../common/Toast"
import SearchWithFilter from "../common/SearchWithFilter"
import ClickOutside from "../common/ClickOutside"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import { loadAllPoliciesOptions, loadPolicyTypes } from "../../actions/policies"
import { delService, changeServiceStatus, loadServices, clearServicesMessages } from "../../actions/services"
import { CaretDownIcon, DisabledIcon, EnabledIcon, MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import { servicesDeletePermission, servicesWritePermission } from "../../utils/permissionValidation"
import "./services.scss"

const orderingFields = [
  {
    field: "name",
    order: "asc",
    key: "name_asc",
  },
  {
    field: "name",
    order: "desc",
    key: "name_desc",
  },
  {
    field: "policy",
    order: "asc",
    key: "policy_asc",
  },
  {
    field: "policy",
    order: "desc",
    key: "policy_desc",
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc",
  },
]

const Services = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [servicesTableData, setServicesTableData] = useState([])
  const [filteredPolicies, setFilteredPolicies] = useState([])
  const [filterQuery, setFilterQuery] = useState(null)
  const [selectedFilterPolicies, setSelectedFilterPolicies] = useState([])
  const [selectedFilterStatuses, setSelectedFilterStatuses] = useState([])
  const [orderBy, setOrderBy] = useState(null)
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [selectedPage, setSelectedPage] = useState(1)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedService, setExpandedService] = useState(0)
  const [editService, setEditService] = useState(null)
  const [serviceIdToDelete, setServiceIdToDelete] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showServiceModal, setShowServiceModal] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  
  useEffect(() => {
    if (props.policies.length === 0) {
      props.actions.loadAllPoliciesOptions()
    }
    
    if (props.policyTypes.length === 0) {
      props.actions.loadPolicyTypes()
    }    
  }, [props.actions])

  useEffect(() => {
    if (props.policies.length > 0 && props.policyTypes.length > 0) {
      const servicesPolicyTypeId = props.policyTypes.find((policyType) => policyType.name === "Services")?.id

      const servicesPolicies = []

      props.policies.forEach((policy) => {
        if (policy.policyTypeId === servicesPolicyTypeId) {
          servicesPolicies.push(policy)
        }
      })

      setFilteredPolicies(servicesPolicies)
    }
  }, [props.policies, props.policyTypes])

  useEffect(() => {
    if (props.services.length > 0 && filteredPolicies.length > 0) {
      const servicesWithPolicyName = []

      props.services.forEach((service) => {
        const policyName = filteredPolicies.find((policy) => policy.id === service.policyId)?.name || ""

        servicesWithPolicyName.push({
          ...service,
          policyName: policyName 
        })
      })

      const tableData = servicesWithPolicyName.map((service) => {
        return {
          identifier: service.id,
          name: service.name,
          description: service.description,
          policyName: service.policyName,
          status: service.isEnabled,
          actions: [
            {
              label: "labels.edit",
              onActionClick: (e) => { e.stopPropagation(); handleEditService(service.id) },
            },
            {
              label: service.isEnabled ? "labels.deactivate" : "labels.activate",
              onActionClick: (e) => { e.stopPropagation(); handleChangeStatusService(service.id, service.isEnabled) },
              requiredPermission: servicesWritePermission
            },
            {
              label: "labels.delete",
              onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(service.id) },
              isDisabled: service.isEnabled,
              requiredPermission: servicesDeletePermission
            },
          ]
        }
      })
  
      setServicesTableData(tableData)
    } else {
      setServicesTableData([])
    }
  }, [props.services, filteredPolicies])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearServicesMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearServicesMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadServices(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadServices(true)
        }
        
        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )

    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [filterQuery, selectedFilterPolicies, selectedFilterStatuses, orderBy])

  const handleLoadServices = (withReset) => {
    let queryParams = {}

    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery}
    }
    
    if (selectedFilterPolicies.length > 0) {
      const selectedPolicyIds = filteredPolicies
        .filter(policy => selectedFilterPolicies.includes(policy.name))
        .map(policy => policy.id);
      
      queryParams = { ...queryParams, policy: selectedPolicyIds }
    }

    if (selectedFilterStatuses.length === 1) {
      const isActiveStatus = true

      queryParams = { ...queryParams, isEnabled: selectedFilterStatuses.indexOf("Inactive") >= 0 ? false : isActiveStatus }
    }
    
    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    queryParams = {
      ...queryParams,
      startPage: withReset ? 1 : selectedPage
    }

    props.actions.loadServices(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleChangeStatusService = (id, isEnabled) => {
    props.actions.changeServiceStatus(id, isEnabled ? "disable" : "enable", true)
  }

  const showConfirmationModal = (id) => {
    setServiceIdToDelete(id)
    setShowDeleteConfirmationModal(true)
  }

  const handleDeleteService = (id) => {
    setShowDeleteConfirmationModal(false)
    props.actions.delService(id)
  }

  const handleEditService = (serviceId) => {
    let service = props.services.find((service) => service.id === serviceId)
    const policyName = filteredPolicies.find((policy) => policy.id === service.policyId)?.name || ""
    
    service = {
      ...service,
      policyName: policyName
    }

    if (service) {
      setEditService(service)
      setShowServiceModal(true)
    }    
  }

  const handleCloseModal = () => {
    setShowServiceModal((prevVal) => !prevVal)
    setEditService(null)
  }

  const handleClearFilters = () => {
    setSelectedFilterPolicies([])
    setSelectedFilterStatuses([])
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`services.${orderBy.key}`) : t("services.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`services.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <div className="services-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader
        headerText={t("services.services")}
      >
        <SimpleButton className="submit-button" onClick={() => { setEditService(null); setShowServiceModal((prevVal) => !prevVal)} }>
          {t("services.add_new_service")}
        </SimpleButton>
      </DashboardHeader>
      <div className="separator" />
      <div className="actions-wrapper">
        <SearchWithFilter
          onSearch={handleSearch}
          showFiltersModal={() => setShowFiltersModal((prevVal) => !prevVal)}
          onResetFilters={handleClearFilters}
          showCounterBadge={selectedFilterStatuses.length > 0 || selectedFilterPolicies.length > 0}
          counterBadge={selectedFilterStatuses.length + selectedFilterPolicies.length} 
        />
        {isMobile && 
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectOrderByOptionsInput}
              toDisplayItem={selectOrderByDropdownOptions}
            />
          </div>
        }
      </div>
      {!isMobile && servicesTableData.length > 0 &&
        <GenericTable
          className="services-table"
          data={servicesTableData}
          headers={[
            {
              title: t('services.name'),
              orderKey: 'name'
            },
            {
              title: t('services.description')
            },
            {
              title: t('services.policy'),
              orderKey: 'policy'
            },
            {
              title: t('services.status'),
              orderKey: 'status'
            },
            {
              title: ''
            },
          ]}
          keys={[
            'name',
            'description',
            'policyName',
            'status',
            'action',
          ]}
          keyRenderer={{
            status: (item) => {
              return (
                <div className="status-wrapper">
                  {item.status ?
                    <div className="status-active">
                      <EnabledIcon /> 
                      <p>{t("services.active")}</p>
                    </div>
                  : 
                    <div className="status-inactive">
                      <DisabledIcon /> 
                      <p>{t("services.inactive")}</p>
                    </div>
                  }
                </div>
              )
            },
            action: (item) => {
              return (
                <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                  {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === item["identifier"] &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={item.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              )
            }
          }}
          onRowClick={(service) => handleEditService(service.identifier)}
          activeSort={orderBy}
          onSort={(orderingField) => handleSort(orderingField)}
          isLoading={showSpinner}
        />
      }
      {isMobile && servicesTableData.map((service, index) =>
        <div 
          key={index}
          className={"card" + (index === 0 ? " with-margin-top" : "")} 
          onClick={() => setExpandedService(index)}
        >
          <div className={"card-item" + (expandedService !== index ? " align-center" : "")}>
            <div className="card-item-title">{t('services.name')}</div>
            <div className={"card-item-body" + (expandedService !== index ? " align-center" : "")}>
              <div>{service.name}</div>
              <div 
                className="card-actions"
                onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
              >
                {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                {showActionsMenu === index &&
                  <ActionsMenu
                    setHideMenu={() => setShowActionsMenu(null)}
                    actions={service.actions}
                    isLoading={showSpinner}
                  />
                }
              </div>
            </div>
          </div>
          {expandedService === index && (
            <>
              <div className="card-item">
                <div className="card-item-title">{t('services.description')}</div>
                <div className="card-item-body">{service.description}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t('services.policy')}</div>
                <div className="card-item-body">{service.policyName}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t('services.status')}</div>
                <div className="card-item-body">
                  <div className="card-item-body-status">
                    {service.status ? 
                      <div className="status-active">
                        <EnabledIcon /> 
                        <p>{t("services.active")}</p>
                      </div>
                    : 
                      <div className="status-inactive">
                        <DisabledIcon /> 
                        <p>{t("services.inactive")}</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>)
      }
      {!showSpinner && !isFirstLoad && servicesTableData.length === 0 &&  (
        <EmptyPageResults 
          title={t("services.no_services")} 
          subtitle={t("services.no_services_tips")} 
        />
      )}
      {canLoadMore && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('buttons.load_more')}
          </SimpleButton>
        </div>)
      }
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showServiceModal && 
        <ServiceModal 
          onToggle={handleCloseModal} 
          service={editService} 
          policies={filteredPolicies} 
        />
      }
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("services.policies"),
              type: FilterType.multiSelect,
              dataset: filteredPolicies.map((policy) => policy.name),
              input: selectedFilterPolicies,
              output: (filterBusinessRulesPolicies) => {
                setSelectedFilterPolicies(filterBusinessRulesPolicies)
              },
            },
            {
              name: t("services.status"),
              type: FilterType.multiSelect,
              dataset: ["Active", "Inactive"],
              input: selectedFilterStatuses,
              output: (filterBUsinessRulesStatuses) => {
                setSelectedFilterStatuses(filterBUsinessRulesStatuses)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          closeFilters={() => setShowFiltersModal(false)}
          resetFilters={() => {
            setSelectedFilterPolicies([])
            setSelectedFilterStatuses([])
          }}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteConfirmationModal(false)}
          message={t("services.delete_confirmation")}
          acceptButtonText={t("labels.delete")}
          onCancel={() => setShowDeleteConfirmationModal(false)}
          onAccept={() => handleDeleteService(serviceIdToDelete)}
          isWarning
          requiredPermission={servicesDeletePermission}
        />
      )}
    </div>
  )
}

function stateToProps({ services, policies }) {
  return {
    services: services?.services || [],
    policies: policies?.allPoliciesOptions || [],
    policyTypes: policies?.policyTypes || [],
    isLoading: services?.servicesLoading || services?.serviceLoading || policies?.policiesLoading,
    canLoadMore: services?.canLoadMore,
    errorMessage: services?.errorMessage,
    successMessage: services?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadServices,
        loadAllPoliciesOptions,
        loadPolicyTypes,
        delService,
        changeServiceStatus,
        clearServicesMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Services)