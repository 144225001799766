import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import PageModal from "../common/PageModal"
import ToggleSwitch from "../common/ToggleSwitch"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import ConditionalWrapper from "../common/ConditionalWrapper"
import { toast } from "../common/Toast"
import { CaretDownIcon, SuccessTick } from "../../icons"
import { getRoleValidationSchema, validateForm } from "../../utils/formValidation"
import {
  loadRolePolicies,
  createRole,
  updateRole,
  partiallyUpdateRole,
  updateRolePolicies,
} from "../../actions/accountsManagement"
import { loadPolicyTypes, loadAllPoliciesOptions } from "../../actions/policies"
import { useWindowSize } from "../../libs/hooks"
import { checkPermission, rolesDeletePermission, rolesWritePermission } from "../../utils/permissionValidation"
import "./role-modal.scss"

const RoleModal = (props) => {
  const { type, onToggle, toggle, openConfirmation, role, rolePoliciesOptions, policyTypes, rolePolicies, accounts, rolePoliciesLoading, isLoading } =
    props
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const location = useLocation()
  const [modalType, setModalType] = useState(type)
  const [initialFormData, setInitialFormData] = useState({ policies: [] })
  const [formData, updateFormData] = useState({ policies: [] })
  const [errors, setErrors] = useState({})
  const [filteredPolicies, setFilteredPolicies] = useState([])
  const roleAccessLevelOptions = ["public", "protected", "private", "admin"]

  const validateFormFields = async () => {
    setErrors({})
    const validateData = await validateForm(getRoleValidationSchema(), formData)

    return new Promise(function (resolve, reject) {
      if (Object.keys(validateData).length > 0) {
        reject({ type: "validationError", data: validateData })
      } else {
        resolve()
      }
    })
  }

  useEffect(() => {
    if (rolePoliciesOptions.length === 0) {
      props.actions.loadAllPoliciesOptions()
    }

    if (policyTypes.length === 0) {
      props.actions.loadPolicyTypes()
    }    
  }, [props.actions])

  useEffect(() => {
    if (role) {
      props.actions.loadRolePolicies(role.id)
    }
  }, [role])

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search)
    if (["view", "edit"].includes(modalType)) {
      if (accounts && rolePolicies) {
        switch (modalType) {
          case "view":
            updateFormData({
              name: role.name || "-",
              description: role?.description,
              account: accounts.find((account) => account.id === role.accountId).name,
              isEnabled: role?.isEnabled,
              userCount: role?.userCount,
              accessLevel: role?.accessLevel,
            })
            break
          case "edit":
            updateFormData({
              name: role?.name,
              description: role?.description,
              account: accounts.find((account) => account.id === role.accountId),
              isEnabled: role?.isEnabled,
              policies: rolePolicies.map((policy) => policy.id),
              userCount: role?.userCount,
              accessLevel: role?.accessLevel,
            })

            setInitialFormData({
              name: role?.name,
              description: role?.description,
              account: accounts.find((account) => account.id === role.accountId),
              isEnabled: role?.isEnabled,
              policies: rolePolicies.map((policy) => policy.id),
              userCount: role?.userCount,
              accessLevel: role?.accessLevel,
            })
            break
          default:
            break
        }
      }
    } else if (modalType === "create") {
      if (accounts) {
        updateFormData({
          name: "",
          description: "",
          isEnabled: role?.isEnabled,
          account: accounts.find((account) => account.id === Number(queryParams.get("accountId"))),
          accessLevel: roleAccessLevelOptions[2],
          policies: [],
        })
      }
    }
  }, [modalType, accounts, rolePolicies, role, location.search])

  useEffect(() => {
    if (rolePoliciesOptions.length > 0 && policyTypes.length > 0) {
      const accountPolicyTypeId = policyTypes.find((policyType) => policyType.name.toLowerCase() === "account")?.id

      if (!accountPolicyTypeId) {
        return
      }

      const enabledAccountPolicies = rolePoliciesOptions.filter((policy) => policy.isEnabled && policy.policyTypeId === accountPolicyTypeId)
      const sortedAccountPolicies = enabledAccountPolicies.sort((a,b) => { return a.name?.localeCompare(b.name) })
      setFilteredPolicies(sortedAccountPolicies)
    }
  }, [rolePoliciesOptions, policyTypes])

  const handleSelectPolicy = (policyId) => {
    if (policyId) {
      let policies = formData.policies
      if (policies && policies.length > 0) {
        if (policies.find((it) => it === policyId)) {
          policies = policies.filter((it) => it !== policyId)
        } else {
          policies = [...policies, policyId]
        }
      } else {
        policies = [policyId]
      }
      updateFormData({ ...formData, policies: policies })
    }
  }

  const handleInputChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleEditRole = async () => {
    const refetch = formData.account.id === role.accountId

    try {
      await validateFormFields()

      const updatedRole = await props.actions.updateRole(
        role.id,
        {
          name: formData.name.trim(),
          description: formData?.description.trim() || "",
          accountId: formData.account.id,
          isEnabled: formData.isEnabled,
          accessLevel: formData.accessLevel,
        },
        refetch,
        formData.userCount
      )
      await props.actions.updateRolePolicies(updatedRole.value.id, formData.policies)
      onToggle()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)
        toast.error(t("error.fill_all_fields"))
      } else {
        onToggle()
      }
    }
  }

  const handleCreateRole = async () => {
    let queryParams = new URLSearchParams(location.search)
    const refetch = Number(queryParams.get("accountId")) === formData.account.id
    try {
      await validateFormFields()
      const newRole = await props.actions.createRole(
        {
          name: formData.name.trim(),
          accountId: formData.account.id,
          description: formData.description.trim() || "",
          isEnabled: formData.isEnabled,
          accessLevel: formData.accessLevel,
        },
        refetch
      )
      await props.actions.updateRolePolicies(newRole.value.id, formData.policies)

      onToggle()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)
        toast.error(t("error.fill_all_fields"))
      } else {
        onToggle()
      }
    }
  }

  const handleEnableRole = async () => {
    try {
      if (modalType === "view") {
        await props.actions.partiallyUpdateRole(role.id, { isEnabled: true }, false)
      }
      
      updateFormData({ ...formData, isEnabled: true })
    } catch (error) {
      console.log(error)
    }
  }

  const handleDisableRole = async () => {
    try {
      if (modalType === "view") {
        await props.actions.partiallyUpdateRole(role.id, { isEnabled: false }, false)
      }
      
      updateFormData({ ...formData, isEnabled: false })
    } catch (error) {
      console.log(error)
    }
  }

  const roleFooter = () => {
    return (
      <div className={`bottom-actions-wrapper d-flex ${modalType === "view" ? "flex-justify-between" : "flex-justify-end"}`}>
        {modalType === "view" && (
          <SimpleButton 
            className="action-delete-button" 
            onClick={() => openConfirmation(role.id)} 
            disabled={formData?.isEnabled || formData?.userCount > 0}
            requiredPermission={rolesDeletePermission}
          >
            {t("labels.delete")}
          </SimpleButton>
        )}
        <ConditionalWrapper condition={modalType === "view" && !isMobile} wrapper={(children) => <div className="right-actions d-flex">{children}</div>}>
          {modalType === "edit" &&
            <SimpleButton className="cancel-button" onClick={() => onToggle()}>
              {t("labels.cancel")}
            </SimpleButton>
          }
          <SimpleButton
            className={`${["edit", "create"].indexOf(modalType) >= 0 ? "submit-button" : "action-button"}`}
            disabled={isDisabledSaveButton()}
            onClick={() => {
              if (modalType === "edit") {
                handleEditRole()
              } else if (modalType === "create") {
                handleCreateRole()
              } else if (modalType === "view") {
                setModalType("edit")
              }
            }}
            {...(modalType !== "view" ? { requiredPermission: rolesWritePermission } : {})}
          >
            {["edit", "create"].indexOf(modalType) >= 0 ? t("labels.save") : t("labels.edit")}
          </SimpleButton>
        </ConditionalWrapper>
      </div>
    )
  }

  const isDisabledSaveButton = () => {
    return (modalType === "edit" && JSON.stringify(initialFormData) === JSON.stringify(formData)) || isLoading
  }

  const selectAccount = (account, toggleVisibility) => {
    updateFormData({ ...formData, account: account })

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectAccessLevel = (accessLevel, toggleVisibility) => {
    updateFormData({ ...formData, accessLevel: accessLevel })

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectAccountInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input" + (isComponentVisible ? " highlighted" : "" + (errors.account ? " input-error" : ""))}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className={`${formData.account && "selected-option"} d-flex flex-align-center h-100`}>
          {formData?.account ? formData.account.name : t("account_management.account.select_account")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectAccountOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {accounts
        .filter((account) => account.isEnabled)
        .map((account, index) => (
          <div
            key={index}
            className={"option cursor-pointer no-wrap" + (formData?.account === account ? " selected-option" : "")}
            onClick={() => selectAccount(account, toggleVisibility)}
          >
            {account.name}
          </div>
        ))}
    </div>
  )

  const selectAccessLevelInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input" + (isComponentVisible ? " highlighted" : "")}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className={`${formData.accessLevel && "selected-option"} d-flex flex-align-center h-100`}>
          {formData?.accessLevel ? t([`account_management.account.${formData?.accessLevel}`, formData?.accessLevel || ""]) : ""}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectAccessLevelOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {roleAccessLevelOptions.map((accessLevel, index) => (
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (formData?.accessLevel === accessLevel ? " selected-option" : "")}
          onClick={() => selectAccessLevel(accessLevel, toggleVisibility)}
        >
          {t([`account_management.account.${accessLevel}`, accessLevel])}
        </div>
      ))}
    </div>
  )

  return (
    <React.Fragment>
      {modalType === "view" ? (
        <PageModal
          onToggle={onToggle}
          toggle={toggle}
          className="view-role-modal"
          footerContent={roleFooter()}
          headerContent={
            <div className="status-wrapper d-flex flex-align-center">
              <div className="title">{t("labels.status")}</div>
              <ConditionalWrapper
                condition={true}
                wrapper={
                  formData.userCount > 0
                    ? (children) => <div className="uneditable-toggle">{children}</div>
                    : (children) => (
                        <div
                          onClick={() => {
                            if (!isLoading && checkPermission(props.userPermissions, rolesWritePermission)) {
                              if (formData?.isEnabled) {
                                handleDisableRole()
                              } else {
                                handleEnableRole()
                              }
                            }
                          }}
                        >
                          {children}
                        </div>
                      )
                }
              >
                <ToggleSwitch checked={formData?.isEnabled} />
              </ConditionalWrapper>
              <div className="status">{formData.isEnabled ? t("account_management.account.enabled") : t("account_management.account.disabled")}</div>
            </div>
          }
          title={t("account_management.account.role_details")}
        >
          <React.Fragment>
            <div className="details-wrapper">
              <div className="details-first-row d-flex flex-row flex-justify-between">
                <div className="detail-wrapper">
                  <div className="title">{t("labels.name")}</div>
                  <div className="description">{formData?.name || ""}</div>
                </div>
                <div className="detail-wrapper">
                  <div className="title">{t("account_management.account.account_name")}</div>
                  <div className="description">{formData?.account || ""}</div>
                </div>
              </div>
              <div className="details-first-row d-flex flex-row flex-justify-between with-margin-top">
                <div className="detail-wrapper">
                  <div className="title">{t("account_management.account.access_level")}</div>
                  <div className="description wrappable-description readonly-description">{t([`account_management.account.${formData?.accessLevel}`, formData?.accessLevel || ""])}</div>
                </div>
                <div className="detail-wrapper">
                  <div className="title">{t("account_management.account.description")}</div>
                  <div className="description wrappable-description readonly-description">{formData?.description || ""}</div>
                </div>
              </div>
            </div>
            <div className="policies-header">
              <div className="title">{t("account_management.account.policies")}</div>
            </div>
            {!rolePoliciesLoading && (
              <div className="policies-wrapper d-flex flex-row">
                {rolePolicies &&
                  rolePolicies.length > 0 &&
                  rolePolicies.map((policy, index) => (
                    <div className="policy-wrapper d-flex" key={`policy-${index}`}>
                      <SuccessTick />
                      <div>{policy.name}</div>
                    </div>
                  ))}
                {rolePolicies && rolePolicies.length === 0 && <div>{t("account_management.account.no_policies")}</div>}
              </div>
            )}
          </React.Fragment>
        </PageModal>
      ) : (
        <PageModal
          onToggle={onToggle}
          toggle={toggle}
          className={`${modalType}-role-modal`}
          title={
            modalType === "create"
              ? t("account_management.account.add_role")
              : t("account_management.account.edit_role")
          }
          footerContent={roleFooter()}
        >
          <React.Fragment>
            <div className="details-wrapper">
              <div className="details-first-row d-flex flex-row flex-justify-between">
                <div className="detail-wrapper">
                  <div className="title">{t("labels.name")} *</div>
                  <input
                    className={`input-item ${errors.name ? "input-error" : ""}`}
                    onChange={handleInputChange}
                    value={formData?.name || ""}
                    name="name"
                    disabled={modalType === "edit"}
                  />
                </div>
                <div className="detail-wrapper">
                  <div className="title">{t("account_management.account.account_name")} *</div>
                  <ClickOutside itemRef="click-outside-wrapper" eventItem={selectAccountInput} toDisplayItem={selectAccountOptions} />
                </div>
              </div>
              {/*A.S: Will need to replace with Dropdown */}
              <div className="details-first-row d-flex flex-row flex-justify-between with-margin-top">
                <div className="detail-wrapper">
                  <div className="title">{t("account_management.account.access_level")} *</div>
                  <ClickOutside itemRef="click-outside-wrapper" eventItem={selectAccessLevelInput} toDisplayItem={selectAccessLevelOptions} />
                </div>
              </div>
              <div className="details-third-row">
                <div className="detail-wrapper">
                  <div className="title">{t("account_management.account.description")}</div>
                  <textarea 
                    className="description wrappable-description" 
                    onChange={handleInputChange} 
                    value={formData?.description || ""} 
                    name="description"
                  />
                </div>
              </div>
            </div>
            <div className="policies-header">
              <div className="title">{t("account_management.account.policies")}</div>
            </div>

            <div className="policies-wrapper">
              <div className="columns-wrapper">
                {!rolePoliciesLoading && filteredPolicies.map((policy, index) => 
                  <div className="policy-wrapper" key={index}>
                    <div className="checkbox-wrapper d-flex">
                      <input
                        type="checkbox"
                        name={policy.name}
                        value={policy.id}
                        onChange={() => handleSelectPolicy(policy.id)}
                        checked={formData.policies && formData.policies.indexOf(policy.id) > -1}
                      />
                      <div className="checkmark" />
                      <label htmlFor={policy.id} onClick={() => handleSelectPolicy(policy.id)}>
                        {policy.name}
                      </label>
                    </div>
                  </div>)
                }
              </div>
            </div>

            <div className="status-wrapper d-flex flex-align-center">
              <div className="title">{t("labels.status")}</div>
              <ConditionalWrapper
                condition={true}
                wrapper={
                  formData.userCount > 0
                    ? (children) => <div className="uneditable-toggle">{children}</div>
                    : (children) => (
                        <div
                          onClick={() => {
                            updateFormData({ ...formData, isEnabled: !formData.isEnabled })
                          }}
                        >
                          {children}
                        </div>
                      )
                }
              >
                <ToggleSwitch checked={formData?.isEnabled} />
              </ConditionalWrapper>
              <div className="status">{formData.isEnabled ? t("account_management.account.enabled") : t("account_management.account.disabled")}</div>
            </div>
          </React.Fragment>
        </PageModal>
      )}
    </React.Fragment>
  )
}

export function stateToProps({ accounts, policies, userPermissions }) {
  return {
    accounts: accounts?.accounts,
    rolePoliciesOptions: policies?.allPoliciesOptions || [],
    policyTypes: policies?.policyTypes || [],
    rolePolicies: accounts?.rolePolicies,
    rolePoliciesLoading: accounts?.rolePoliciesLoading,
    userPermissions
  }
}

export function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
      	loadAllPoliciesOptions,
        loadPolicyTypes,
        loadRolePolicies,
        createRole,
        partiallyUpdateRole,
        updateRole,
        updateRolePolicies
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(RoleModal)
