import {
  LOAD_USER_COMPANY_PENDING,
  LOAD_USER_COMPANY_FULFILLED,
  LOAD_USER_COMPANY_REJECTED,
  LOAD_COMPANY_LOGO_PENDING,
  LOAD_COMPANY_LOGO_FULFILLED,
  LOAD_COMPANY_LOGO_REJECTED,
  LOAD_COMPANY_REQUESTS_PENDING,
  LOAD_COMPANY_REQUESTS_FULFILLED,
  LOAD_COMPANY_REQUESTS_REJECTED,
  DELETE_COMPANY_LOGO_PENDING,
  DELETE_COMPANY_LOGO_FULFILLED,
  DELETE_COMPANY_LOGO_REJECTED,
  LEAVE_USER_COMPANY_PENDING,
  LEAVE_USER_COMPANY_FULFILLED,
  LEAVE_USER_COMPANY_REJECTED,
  ACCEPT_COMPANY_REQUEST_PENDING,
  ACCEPT_COMPANY_REQUEST_FULFILLED,
  ACCEPT_COMPANY_REQUEST_REJECTED,
  REJECT_COMPANY_REQUEST_PENDING,
  REJECT_COMPANY_REQUEST_FULFILLED,
  REJECT_COMPANY_REQUEST_REJECTED,
  CANCEL_COMPANY_REQUEST_PENDING,
  CANCEL_COMPANY_REQUEST_FULFILLED,
  CANCEL_COMPANY_REQUEST_REJECTED,
  RESEND_COMPANY_REQUEST_PENDING,
  RESEND_COMPANY_REQUEST_FULFILLED,
  RESEND_COMPANY_REQUEST_REJECTED,
  PUT_COMPANY_INFORMATION_PENDING,
  PUT_COMPANY_INFORMATION_FULFILLED,
  PUT_COMPANY_INFORMATION_REJECTED,
  PUT_BRANCH_INFORMATION_PENDING,
  PUT_BRANCH_INFORMATION_FULFILLED,
  PUT_BRANCH_INFORMATION_REJECTED,
  CLEAR_USER_COMPANY_MESSAGES,
} from "../actions/userCompany"
import i18n from 'i18next'

export default function userCompany(state = {}, action) {
  switch (action.type) {
    case LOAD_COMPANY_LOGO_PENDING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_USER_COMPANY_PENDING:
    case DELETE_COMPANY_LOGO_PENDING:
    case LEAVE_USER_COMPANY_PENDING:
    case LOAD_COMPANY_REQUESTS_PENDING:
    case ACCEPT_COMPANY_REQUEST_PENDING:
    case REJECT_COMPANY_REQUEST_PENDING:
    case CANCEL_COMPANY_REQUEST_PENDING:
    case RESEND_COMPANY_REQUEST_PENDING:
    case PUT_BRANCH_INFORMATION_PENDING:
    case PUT_COMPANY_INFORMATION_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_COMPANY_LOGO_REJECTED:
      return {
        ...state,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case PUT_BRANCH_INFORMATION_REJECTED:
      const errorCodeUpdateBranch = action.payload.response?.data?.code
      const branchExistsErrorCode = "recordExists"
      return {
        ...state,
        isLoading: false,
        errorMessage: errorCodeUpdateBranch === branchExistsErrorCode ? i18n.t("error.company_name_exists_support") : i18n.t([`error.${errorCodeUpdateBranch}`, "error.something_wrong"])
      }
    case LOAD_USER_COMPANY_REJECTED:
    case DELETE_COMPANY_LOGO_REJECTED:
    case LEAVE_USER_COMPANY_REJECTED:
    case LOAD_COMPANY_REQUESTS_REJECTED:
    case ACCEPT_COMPANY_REQUEST_REJECTED:
    case REJECT_COMPANY_REQUEST_REJECTED:
    case CANCEL_COMPANY_REQUEST_REJECTED:
    case RESEND_COMPANY_REQUEST_REJECTED:
    case PUT_COMPANY_INFORMATION_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_USER_COMPANY_FULFILLED:
      const companyId = action.payload.companiesId
      const companyName = action.payload.branch?.branchName
      const companyAddress = action.payload.branch?.officeAddressOne
      const companyCity = action.payload.branch?.city
      const companyState = action.payload.branch?.stateProvinceRegion
      const companyZip = action.payload.branch?.zipPostalCode
      const companyCountry = action.payload.branch?.country
      const companyAdmin = action.payload.branch?.email
      const companyStatus = action.payload.branch?.status
      const branchId = action.payload.branch?.branchesId
      const companyPhone = action.payload.branch?.telephone

      return {
        ...state,
        companyId,
        companyName,
        companyAddress,
        companyCity,
        companyState,
        companyZip,
        companyCountry,
        companyAdmin,
        companyStatus,
        companyPhone,
        branchId,
        isLoading: false,
      }
    case LOAD_COMPANY_LOGO_FULFILLED:
      return {
        ...state,
        companyLogo: action.payload
      }
    case LOAD_COMPANY_REQUESTS_FULFILLED:
      return {
        ...state,
        companyRequests: action.payload,
        isLoading: false,
      }
    case DELETE_COMPANY_LOGO_FULFILLED:
      return {
        ...state,
        companyLogo: null,
        isLoading: false,
      }
    case LEAVE_USER_COMPANY_FULFILLED:
      return {
        ...state,
        companyId: null,
        companyName: null,
        companyAddress: null,
        companyCity: null,
        companyState: null,
        companyZip: null,
        companyCountry: null,
        companyAdmin: null,
        companyStatus: null,
        branchId: null,
        isLoading: false,
        successMessage: i18n.t("account.leave_organization_message")
      }
    case ACCEPT_COMPANY_REQUEST_FULFILLED:
      const companyRequestsAfterAccept = state.companyRequests?.filter(request => request.id !== action.meta.requestId)

      return {
        ...state,
        companyRequests: companyRequestsAfterAccept,
        isLoading: false,
        successMessage: action.meta.showSuccessMessage ? i18n.t("account.invitation_accepted") : null
      } 
    case REJECT_COMPANY_REQUEST_FULFILLED:
      const companyRequestsAfterReject = state.companyRequests?.filter(request => request.id !== action.meta.requestId)

      return {
        ...state,
        companyRequests: companyRequestsAfterReject,
        isLoading: false,
        successMessage: i18n.t("account.invitation_rejected")
      }
    case CANCEL_COMPANY_REQUEST_FULFILLED:
      const companyRequestsAfterCancel = state.companyRequests?.filter(request => request.id !== action.meta.requestId)

      return {
        ...state,
        companyRequests: companyRequestsAfterCancel,
        isLoading: false,
        successMessage: i18n.t("account.request_cancelled")
      }
    case RESEND_COMPANY_REQUEST_FULFILLED:
      const companyRequestsAfterResend = state.companyRequests?.map(request => request.id === action.meta.requestId ? { ...request, status: "requested", lastModifyAt: new Date().toISOString() } : request)

      return {
        ...state,
        companyRequests: companyRequestsAfterResend,
        isLoading: false,
        successMessage: i18n.t("account.request_resent")
      }
    case PUT_COMPANY_INFORMATION_FULFILLED:
      return {
        ...state,
        isLoading: false,
        successMessage: i18n.t("account.organization_information_updated")
      }
    case PUT_BRANCH_INFORMATION_FULFILLED:
      const updatedCompanyName = action.payload.branchName
      const updatedCompanyAddress = action.payload.officeAddressOne
      const updatedCompanyCity = action.payload.city
      const updatedCompanyState = action.payload.stateProvinceRegion
      const updatedCompanyZip = action.payload.zipPostalCode
      const updatedCompanyPhone = action.payload.telephone

      return {
        ...state,
        companyName: updatedCompanyName,
        companyAddress: updatedCompanyAddress,
        companyCity: updatedCompanyCity,
        companyState: updatedCompanyState,
        companyZip: updatedCompanyZip,
        companyPhone: updatedCompanyPhone,
        isLoading: false,
        successMessage: i18n.t("account.organization_information_updated")
      }
    case CLEAR_USER_COMPANY_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    }
    default:
      return state
  }
}