import React from 'react'
import "./public-page-heading.scss";

const PublicPageHeading = (props) => {
  const {heading, subheading, intro} = props

  return (
    <div className="public-page-heading">
      <h1 >{heading}</h1>
      { subheading &&
        <div className="public-page-subheading">{subheading}</div>
      }
        
      <div className="divider" ></div>

      { intro &&
        <div className="public-page-intro">{intro}</div>
      }
    </div>
  )
}

export default PublicPageHeading