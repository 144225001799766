import React from 'react';
import { LineChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, Line } from "recharts";


const celsius = "\u2103"
const kPas = "kPas"

export default function DTChart1(props) {

  const { data } = props

  // Target Temp and Exhaust Air Temp don't exist in the API response
  return (
      <LineChart width={props.width} height={300} data={data}>
          <CartesianGrid/>
          <XAxis dataKey="time" style={{ fontSize: "0.8em" }}/>
          <YAxis yAxisId="left" unit={celsius} style={{ fontSize: "0.8em" }} type="number" domain={['auto', 'auto']}/>
          <YAxis yAxisId="right" type="number" domain={['auto', 'auto']} orientation="right" unit={kPas} style={{ fontSize: "0.8em" }}/>
          <Tooltip/>
          <Legend layout="vertical" verticalAlign="middle" align="right" wrapperStyle={{ padding: "0 0 0 30px" }}/>
          {/*<Line yAxisId="left" type="monotone" dataKey="Target Temp" stroke="#02c2c2" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>*/}
          <Line yAxisId="left" type="monotone" dataKey="Ambient Temp" stroke="#FF7F00" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
          <Line yAxisId="left" type="monotone" dataKey="Heat Exchanger Temp" stroke="#33A02C" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
          <Line yAxisId="left" type="monotone" dataKey="Band Heater (Bottom) Temp" stroke="#E31A1C" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
          <Line yAxisId="left" type="monotone" dataKey="Water Reservoir Temp" stroke="#6A3D9A" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
          <Line yAxisId="left" type="monotone" dataKey="Band Heater (Top) Temp" stroke="#E82582" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
          <Line yAxisId="left" type="monotone" dataKey="Steam Generator Temp 1" stroke="#3700de" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
          <Line yAxisId="left" type="monotone" dataKey="Steam Generator Temp 2" stroke="#007F5F" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>
          {/*<Line yAxisId="left" type="monotone" dataKey="Exhaust Air Temp" stroke="#42f700" strokeWidth="2" activeDot={{ r: 8 }} dot={false}/>*/}
      </LineChart>
  )
  
};

