import { _basePostFetch, _baseGetFetch, ApiEnum } from './baseFetch'

export const getCycleData = (uuid) => {
  const path = `/cycle/${uuid}`
  
  return _basePostFetch(path, {}, ApiEnum.telemetry)
}

// export const getTelemetrySnapshot = (baseParams, serNum) => {
//   // const path = `/telemetry/device/${serNum}/snapshot`
//   const path = `/device_state/${serNum}`

//   return _baseGetFetch(path, baseParams, ApiEnum.telemetry)
// }

// export const getHistoryInput = (serNum, baseParams) => {
//   // const path = `/telemetry/device/${serNum}/history/input`
//   const path = `/device_state/${serNum}`

//   return _baseGetFetch(path, baseParams, ApiEnum.telemetry)
// }

// export const getHistoryOutput = (serNum, baseParams) => {
//   const path = `/device_state/${serNum}`

//   return _baseGetFetch(path, baseParams, ApiEnum.telemetry)
// }

export const fetchPrevNextMinute = (serNum, baseParams) => {
  const path = `/device_state/${serNum}`
  return _baseGetFetch(path, baseParams, ApiEnum.telemetry)
}

export const fetchLatest = (serNum, baseParams) => {
  const path = `/device_state/${serNum}/latest`
  return _baseGetFetch(path, baseParams, ApiEnum.telemetry)
}

