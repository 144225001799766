import { useContext, createContext } from "react"

export const AppContext = createContext(null)
export const LangContext = createContext(null)

export function useAppContext() {
  return useContext(AppContext);
}

export function useLangContext() {
  return useContext(LangContext)
}