import "./DashboardHeader.scss";

export default function DashboardHeader({
  headerText,
  children
}) {
  return (
    <div className="dashboard-container">
      <div className="dashboard-heading">
        {headerText}
      </div>
      {children}
    </div>
  )
}
