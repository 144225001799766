import { _basePostFetch, _baseGetFetch, ApiEnum } from './baseFetch'

export const getTrackingDta = async (queryParams) => {
  return await _baseGetFetch(`/users/cycles`, queryParams, ApiEnum.instrumentTracking)
}

export const getCycleDetails = async (recordsUuid, serialNumber) => {
  return await _baseGetFetch(`/cycles/summary-datapoints/${recordsUuid}`, { sn: serialNumber })
}

export const getCyclesForCassette = (serNum) => {
  const path = `/instrument-tracking/cycles/tag/${serNum}`

  return _basePostFetch(path, {}, ApiEnum.instrumentTracking)
}

export const getMQTTCycles = async (serialNumber, queryParams) => {
  return await _baseGetFetch(`/cycles/${serialNumber}`, queryParams, ApiEnum.instrumentTracking)
}