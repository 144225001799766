import {_baseGetFetch, _basePostFetch, _basePutFetch} from './baseFetch'

export async function saveWarranties(warrantyDetails) {
  return _basePostFetch(`/warranties`, warrantyDetails)
}

export async function updateWarranties(id, warrantyDetails) {
  return _basePutFetch(`/warranties/${id}`, warrantyDetails)
}

export const getWarrantyModels = () => {
  return _baseGetFetch('/products/models', {ocp_access: 1})
}

export const getWarranty = (queryParams) => {
  return _baseGetFetch(`/warranties`, queryParams)
}

export const getWarrantyById = (id) => {
  return _baseGetFetch(`/warranties/${id}`)
}
