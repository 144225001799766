import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth } from "aws-amplify"
import { Redirect, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './resetpassword.scss'
import Button from '../common/Button'
import PublicPageHeading from "../common/PublicPageHeading"
import TextInputField from "../common/TextInputField"
import { processError } from "../../libs/processError"
import { useFormFields } from "../../libs/hooks"
import { getPasswordResetValidationShema, validateForm } from '../../utils/formValidation'
import {
  LockIcon,
  EyeOffIcon,
  EyeIcon
} from '../../icons'

const ResetPassword = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true)
  const [error, setError] = useState({})
  const [authError, setAuthError] = useState("")
  const [isSubmitting, setSubmitting] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const { t } = useTranslation()
  const [username, setUsername] = useState("")
  const [fields, handleFieldChange] = useFormFields({
    confirmationcode: "",
    password: "",
    confirmpassword: "",
  })
  const userErrorMsg = {
    password: t("error.password"),
    confirmpassword: t("error.confirm_password")
  }

  useEffect(() => {
    setUsername(props.username)
  }, [props.username])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setSubmitting(true)
      setEmailSent(false)
      const resetSchema = getPasswordResetValidationShema(userErrorMsg)
      const validateData = await validateForm(resetSchema, fields)
      
      setError(validateData)

      if(Object.keys(validateData).length > 0) {
        setLoading(false)
        
        return false
      }

      setLoading(true)

      await Auth.forgotPasswordSubmit(username, fields.confirmationcode, fields.password)

      setLoading(false)
      setSubmitting(false)

      setRedirect(true)
    } catch(e) {
      // setAuthError(e.message)
      processError(e)
      setLoading(false)
      setSubmitting(false)
    }
    
  }

  const handleResendCode = async () => {
    try {
      await Auth.forgotPassword(username)
      setEmailSent(true)
      setAuthError(false)
    } catch(e) {
      // setAuthError(e.message)
      processError(e)
    }
      
  }

  const validatePasswordForm = () => {
    return fields.password.length > 0 && fields.confirmpassword.length > 0 && fields.confirmationcode.length > 0
  }

  const toggleShowHidePassword = (e) => {
    document.getElementById('password').type = hidePassword ? "text" : "password"
    setHidePassword(!hidePassword)
  }

  const toggleShowHideConfirmPassword = (e) => {
    document.getElementById('confirmpassword').type = hideConfirmPassword ? "text" : "password"
    setHideConfirmPassword(!hideConfirmPassword)
  }

  if(redirect) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <div className="reset-password-wrapper public-page">
      <div className="center form-container">
        <PublicPageHeading heading={t('access.forgot_password_header')} intro={t("register.email_confirm_reset_password")} />
        {
          authError &&
          <div className="error-message">{authError}</div>
        }
        {
          emailSent &&
          <div className="success-message">{t("register.code_sent")}</div>
        }
        <div className="signup-form">
          <form onSubmit={ handleSubmit } noValidate autoComplete="off">
            <div className="input-fields">
              <div className="input-box"> 
                <input
                  type="text"
                  name="confirmationcode"
                  value={fields.confirmationcode}
                  autoFocus
                  autoComplete="off"
                  placeholder="123456"
                  onChange = {handleFieldChange}
                  maxLength="6"
                />
              </div>
            </div>
            <div className="input-fields row">
              <TextInputField
                  type="password"
                  name="password"
                  id="password"
                  value={fields.password}
                  autoComplete="new-password"
                  placeholder={t("profile-labels.password")}
                  sublabel={t("profile-labels.password_message")}
                  onChange = {handleFieldChange}
                  error= {error.password ? error.password : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<LockIcon className="lock-icon" />}
                  rightIcon={ hidePassword
                    ? <EyeIcon className="eye-icon" onClick={toggleShowHidePassword} />
                    : <EyeOffIcon className="eye-icon" onClick={toggleShowHidePassword} />
                  }
                />
            </div>
            <div className="input-fields row">
              <TextInputField
                  type="password"
                  name="confirmpassword"
                  id="confirmpassword"
                  value={fields.confirmpassword}
                  placeholder={t("profile-labels.confirm_password")}
                  onChange = {handleFieldChange}
                  error= {error.confirmpassword ? error.confirmpassword : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<LockIcon className="lock-icon" />}
                  rightIcon={ hideConfirmPassword
                    ? <EyeIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                    : <EyeOffIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                  }
                />
            </div>
            <Button
              type="submit"
              isLoading={isLoading}
              disabled={!validatePasswordForm()}
              className="large"
            >
              {t("labels.reset_password")}
            </Button>
          </form>
          <div className="resend-confirm-code">
            <div>{t("register.didnt_get_code")}</div>
            <div onClick={handleResendCode} className="resend-link">
              {t('register.resend_code')}
            </div>
          </div>
          <div className="login-link">
            <Link to="/" className="log-in">
              {t("register.login_account")}
            </Link>
        </div>
        </div> 
      </div>
    </div>
  )
}

ResetPassword.propTypes = {
  username: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
}

export default ResetPassword