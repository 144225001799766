import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import NotificationSettings from './NotificationSettings'
import LoadingSpinner from "../../common/LoadingSpinner"
import ToggleSwitch from "../../common/ToggleSwitch"
import CheckboxInput from "../../common/CheckboxInput"
import SimpleButton from '../../common/SimpleButton'
import { toast } from "../../common/Toast"
import { getImageByModel } from "../../../utils/functions"
import { loadCurrentUserNotificationPreferences, clearNotificationsMessages } from '../../../actions/notifications'
import { putCurrentUserEnabledNotificationsPreferences, putCurrentUserNotificationPreference } from "../../../utils/requests/usersAPI"
import { checkPermission, deviceNotificationsReadPermissions, hasEndUserSiteAccess, userNotificationWritePermission } from '../../../utils/permissionValidation'
import './notifications.scss'
import { useAppContext } from '../../../libs/contextLib'

const Notifications = (props) => {
  const { t } = useTranslation()
  const { showFeatureDev } = useAppContext()
  const [notificationCategories, setNotificationCategories] = useState([])
  const [initialNotificationCategories, setInitialNotificationCategories] = useState([])
  const [areNotificationsEnabled, setAreNotificationsEnabled] = useState(false)
  const [initialAreNotificationsEnabled, setInitialAreNotificationsEnabled] = useState(false)
  const [hasPreferenceChanges, setHasPreferenceChanges] = useState(false)
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)  
  
  const hasG4Plus = Object.values(props.products).some(p => p.is_g4_plus)
  const isEndUser = hasEndUserSiteAccess(props.userPermissions)
  
  useEffect(() => {
    props.actions.loadCurrentUserNotificationPreferences(props.cognitoSub)
  }, [props.actions])

  useEffect(() => {
    const filteredNotifications = props.notificationPreferences.notifications?.filter(
      (notification) => notification.allow_suppress && notification.is_enabled && notification.category !== null
    )

    let categories = filteredNotifications?.reduce((acc, notification) => {
      const category = notification.category
      const areDisplayedCategorized = category === "cycles"

      const categoryIndex = acc.findIndex((item) => item.category === category)
      const categoryFound = categoryIndex !== -1

      if(!categoryFound) {
        acc.push ({
          category,
          notifications: [ notification ],
          areDisplayedCategorized,
          isCategoryEmailEnabled: false,
          isCategoryAlertEnabled: false
        })
      } else {
        acc[categoryIndex].notifications.push(notification)
      }

      return acc
    }, [])
    
    categories = categories?.map((category) => category.areDisplayedCategorized 
      ? { 
          ...category, 
          isCategoryAlertEnabled: category.notifications?.some((n) => n?.alert_notifications_enabled), 
          isCategoryEmailEnabled: category.notifications?.some((n) => n?.email_notifications_enabled) 
        } 
      : category
    )

    categories?.sort((a,b) => { 
      return a.category?.toLowerCase()?.localeCompare(b.category?.toLowerCase())
    })

    setNotificationCategories(categories)
    setAreNotificationsEnabled(props.notificationPreferences?.notifications_enabled ?? false)

    setInitialNotificationCategories(JSON.parse(JSON.stringify(categories || [])))
    setInitialAreNotificationsEnabled(props.notificationPreferences?.notifications_enabled ?? false)
  },[props.notificationPreferences])

  useEffect(() => {
    setProducts(props.products)
  }, [props.products])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  },[props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearNotificationsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearNotificationsMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    const hasChanges = JSON.stringify(notificationCategories) !== JSON.stringify(initialNotificationCategories) || 
      areNotificationsEnabled !== initialAreNotificationsEnabled

    setHasPreferenceChanges(hasChanges)
  }, [areNotificationsEnabled, notificationCategories, initialAreNotificationsEnabled, initialNotificationCategories])

  const handleNotificationPreference = (notificationIds, option, emailNotificationStatus, alertNotificationStatus) => {
    let updatedNotificationsState = {}
    switch(option) {
      case "email":
        updatedNotificationsState = { 
          email_notifications_enabled: !(emailNotificationStatus ?? false),
          alert_notifications_enabled: alertNotificationStatus ?? false
        }
        break
      case "alert":
        updatedNotificationsState = { 
          alert_notifications_enabled: !(alertNotificationStatus ?? false),
          email_notifications_enabled: emailNotificationStatus ?? false
        }
        break
      default:
        return
    }

    notificationIds.forEach((notificationId) => {
      const notificationCategoryIndex = notificationCategories.findIndex((category) => category.notifications.some((notification) => notification.notification_id === notificationId))
      const notificationCategoryFound = notificationCategoryIndex !== -1

      if(notificationCategoryFound) {
        const notificationIndex = notificationCategories[notificationCategoryIndex].notifications.findIndex((notification) => notification.notification_id === notificationId)
        const notificationFound = notificationIndex !== -1

        if(notificationFound) {
          notificationCategories[notificationCategoryIndex].notifications[notificationIndex] = {
            ...notificationCategories[notificationCategoryIndex].notifications[notificationIndex],
            ...updatedNotificationsState
          }

          if (notificationCategories[notificationCategoryIndex].areDisplayedCategorized) {
            notificationCategories[notificationCategoryIndex] = {
              ...notificationCategories[notificationCategoryIndex],
              isCategoryEmailEnabled: notificationCategories[notificationCategoryIndex].notifications?.some((n) => n?.email_notifications_enabled),
              isCategoryAlertEnabled: notificationCategories[notificationCategoryIndex].notifications?.some((n) => n?.alert_notifications_enabled)
            }
          }

          setNotificationCategories(JSON.parse(JSON.stringify(notificationCategories)))
        }
      }
    })
  }

  const renderCategorizedNotifications = (notificationGroup) => {
    return (
      <div className="section">
        <p className="section-title">{t([`notifications.category-${notificationGroup?.category}`, "notifications.category-undefined"])}</p>
        <div className="section-content">
          <div className="inline-section">
            {/* <div onClick={() => handleNotificationPreference(notificationGroup?.notifications.map(n => n.notification_id), "alert", notificationGroup?.isCategoryEmailEnabled, notificationGroup?.isCategoryAlertEnabled)}>
              <CheckboxInput
                className={(notificationGroup?.isCategoryAlertEnabled ? " selected" : "")}
                checked={notificationGroup?.isCategoryAlertEnabled ?? false}
                text={t("notifications.myColtene")}
              />
            </div> */}
            <div onClick={() => handleNotificationPreference(notificationGroup?.notifications.filter(n => {
                if (!hasG4Plus || !showFeatureDev) {
                  return !["Device alarm", "Maintenance available", "Maintenance reminders"].includes(n.name)
                }
                return true;
              }).map(n => n.notification_id), "email", notificationGroup?.isCategoryEmailEnabled, notificationGroup?.isCategoryAlertEnabled)}>
              <CheckboxInput
                className={(notificationGroup?.isCategoryEmailEnabled ? " selected" : "")}
                checked={notificationGroup?.isCategoryEmailEnabled ?? false}
                text={t("notifications.email")}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderIndividualNotifications = (notifications) => {
    return (
      <Fragment>
        {notifications?.filter(n => {
          if (!hasG4Plus || !showFeatureDev) {
            return !["Device alarm", "Maintenance available", "Maintenance reminders"].includes(n.name)
          }
          return true;
        }).map((notification, index) => 
          {
            return <div key={index} className="section">
              <p className="section-title capitalize">{t([`notifications.group-${notification.group}`, "notifications.group-undefined"])}</p>
              <div className="section-content">
                <div className="inline-section">
                  {/* <div onClick={() => handleNotificationPreference([ notification?.notification_id ], "alert", notification?.email_notifications_enabled, notification?.alert_notifications_enabled)}>
                    <CheckboxInput
                      className={(notification?.alert_notifications_enabled ? " selected" : "")}
                      checked={notification?.alert_notifications_enabled ?? false}
                      text={t("notifications.myColtene")}
                    />
                  </div> */}
                  <div onClick={() => handleNotificationPreference([notification?.notification_id], "email", notification?.email_notifications_enabled, notification?.alert_notifications_enabled)}>
                    <CheckboxInput
                      className={(notification?.email_notifications_enabled ? " selected" : "")}
                      checked={notification?.email_notifications_enabled ?? false}
                      text={t("notifications.email")} />
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </Fragment>
    )
  }

  const handleSaveNotificationPreferencesChanges = () => {
    const notificationsUpdatePromises = []

    notificationsUpdatePromises.push(putCurrentUserEnabledNotificationsPreferences(props.cognitoSub, { notifications_enabled: areNotificationsEnabled }))

    if (isEndUser) {
      notificationCategories?.forEach((notificationCategory) => {
        notificationCategory?.notifications?.forEach((notification) => {
          const bodyParams = {
            email_notifications_enabled: notification?.email_notifications_enabled ?? false,
            alert_notifications_enabled: notification?.alert_notifications_enabled ?? false
          }
  
          notificationsUpdatePromises.push(putCurrentUserNotificationPreference(props.cognitoSub, notification?.notification_id, bodyParams))
        })
      })
    }

    setShowSpinner(true)

    Promise.allSettled(notificationsUpdatePromises)
      .then((results) => {
        if (results?.some((result) => result.status === "rejected")) {
          toast.error(t("error.something_wrong"))
          return
        }

        setInitialNotificationCategories(JSON.parse(JSON.stringify(notificationCategories || [])))
        setInitialAreNotificationsEnabled(areNotificationsEnabled ?? false)

        toast.success(t("notifications.success-edit-notification-settings"))
        setShowSpinner(false)
      })
      .catch(() => {

        toast.error(t("error.something_wrong"))
        setShowSpinner(false)
      })
  }

  const handleCancelNotificationPreferencesChanges = () => {
    setNotificationCategories(JSON.parse(JSON.stringify(initialNotificationCategories)))
    setAreNotificationsEnabled(initialAreNotificationsEnabled)
  }

  return (
    <div className="notifications-wrapper d-flex flex-column">
      {/*A.S: Hidden until further notice */}
      <div className="section-wrapper">
        {/* <p className="wrapper-title">{t('account.notification_options')}</p>
        <div className="section">
          <p className="section-title capitalize">{t('account.website_notifications')}</p>
          <div className="section-content">
            <div className="inline-section">
              <div className="toggle-option">
                <div onClick={() => setAreNotificationsEnabled(!areNotificationsEnabled)}>
                  <ToggleSwitch checked={areNotificationsEnabled} />
                </div>
                <p className="details">
                {
                  areNotificationsEnabled ? t('account.notifications_enabled') : t('account.notifications_disabled')
                }
                </p>
              </div>
            </div>
            <p className="tooltip">{t('account.notifications_permission_message')}</p>
          </div>
        </div>*/}
        {isEndUser &&
          <>
            {/*<div className="separator"/>*/}
            <p className="wrapper-title">{t('account.email_settings')}</p>
            {notificationCategories?.map((notificationGroup, index) => 
              <Fragment key={index}>
                {notificationGroup.areDisplayedCategorized 
                  ? (renderCategorizedNotifications(notificationGroup)) 
                  : (renderIndividualNotifications(notificationGroup.notifications))
                }
              </Fragment>)       
            }
          </>
        }
        <div className="buttons">
          <SimpleButton
            className="cancel-button"
            disabled={!hasPreferenceChanges}
            onClick={() => handleCancelNotificationPreferencesChanges()}
          >
              {t("labels.cancel")}
          </SimpleButton>
          <SimpleButton
            className="submit-button"
            disabled={!hasPreferenceChanges || showSpinner}
            onClick={() => handleSaveNotificationPreferencesChanges()}
            requiredPermission={userNotificationWritePermission}
          >
            {t("labels.save")}
          </SimpleButton>
        </div>
      </div>

      {(isEndUser && checkPermission(props.userPermissions, deviceNotificationsReadPermissions)) &&
        <div className="section-wrapper">
          <p className="wrapper-title">{t('account.setup_email_notifications')}</p>
          <div className="section">
            <div className="section-title">
              {t('account.my_devices')}
              <div className="disclaimer">
                {t('account.my_devices_disclaimer')}
              </div>
            </div>
            <div className="section-content">
              <div className="grid-section">
                {Object.entries(products)?.filter((item) => item[1].association_active && !item[1].model?.toLowerCase().includes("bravo"))?.map((product, index) => 
                  <div key={index} className="card d-flex">
                    <div className="image-wrapper d-flex flex-align-center">
                      <img
                        src={getImageByModel(product[1].model)}
                        className="img"
                        alt={product[1].model}
                      />
                    </div>
                    <div className="product-wrapper d-flex flex-column">
                      <p className="product-title">{product[1].model}</p>
                      <p className="product-number">{`${t("sn")}: ${product[1].serial_number}`}</p>
                      <button onClick={() => setSelectedProduct(product[1])}>{t("buttons.set_up")}</button>
                    </div>
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>
      }
      {selectedProduct &&
        <NotificationSettings
          isG4Plus={selectedProduct?.is_g4_plus}
          serialNumber={selectedProduct?.serial_number}
          model={selectedProduct?.model}
          modelImage={getImageByModel(selectedProduct?.model)}
          closeModal={() => setSelectedProduct(null)}
        />
      }
      {showSpinner &&
        <div className={"spinner-wrapper-parent"}>
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function stateToProps({ authedUser, userProducts, notifications, userPermissions }) {
  return {
    cognitoSub: authedUser?.cognitoSub, 
    products: userProducts?.products || {},
    isLoading: userProducts?.loading || notifications?.isLoading,
    notificationPreferences: notifications?.notificationPreferences || {},
    errorMessage: notifications?.errorMessage,
    successMessage: notifications?.successMessage,
    userPermissions
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadCurrentUserNotificationPreferences,
        clearNotificationsMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Notifications)
