import { useEffect, useRef } from 'react'

export function useDeepCompareEffect(callback, dependencies) {
  const dependenciesRef = useRef(dependencies)

  if (!dependenciesRef.current.every((dep, index) => _isEqual(dep, dependencies[index]))) {
    dependenciesRef.current = dependencies
  }

  useEffect(callback, dependenciesRef.current)
}

function _isEqual(a, b) {
  if (typeof a !== 'object' || typeof b !== 'object') {
    return a === b
  }

  if (a === null || b === null) {
    return a === b
  }

  const keysA = Object.keys(a)
  const keysB = Object.keys(b)

  if (keysA.length !== keysB.length) {
    return false
  }

  for (let key of keysA) {
    if (!keysB.includes(key)) {
      return false
    }

    if (!_isEqual(a[key], b[key])) {
      return false
    }
  }

  return true
}