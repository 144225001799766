export const SET_REPORT_TICKET = 'SET_REPORT_TICKET'

export function setReportTicket(ticket) {
  return {
    type: SET_REPORT_TICKET,
    ticket
  }
}

export function handleReportTicket(params) {
  return (dispatch) => {
    return dispatch(setReportTicket(params))
  }
}
