import getSymbolFromCurrency from "currency-symbol-map"

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  )

export const uniqBy = (arr, key) => {
  let seen = new Set()

  return arr.filter((it) => {
    let val = it[key]
    if (seen.has(val)) {
      return false
    } else {
      seen.add(val)
      return true
    }
  })
}

export const dynamicSort = (property) => {
  let sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export const formatStoreProductPrice = (amount, currency, interval, intervalCount) => {
  const num = parseFloat(amount).toFixed(2)

  const parts = num.split(".")
  const decimal = parts.length > 1 ? "." + parts[1] : ""
  const formattedWhole = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  let totalPrice = currency ? currency.toUpperCase() + " " + getSymbolFromCurrency(currency) + formattedWhole + decimal : formattedWhole + decimal
  if (interval) {
    totalPrice = totalPrice + "/"
    if (intervalCount === 1) {
      totalPrice = totalPrice + interval
    } else if (intervalCount > 1) {
      totalPrice = totalPrice + intervalCount + " " + interval.slice(0,2)
    }
  }

  return totalPrice
}