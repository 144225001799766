import { setApertures, setAperturesLoading } from "./aperture"
import { setProductTypes } from "./productTypes"
import { setUserProducts, setProductLoading } from "./userProducts"
import { hasEndUserSiteAccess, hasInstrumentTrackingPermission } from '../utils/permissionValidation'
import { getInitialData } from "../utils/requests/sharedAPI"

export function handleIntitialData(params) {
  const isEndUser = hasEndUserSiteAccess(params.permissions)
  const canAccessInstrumentTracking = hasInstrumentTrackingPermission(params.permissions)
  const cognitoSub = params.cognitoSub

  return (dispatch) => {
    isEndUser && dispatch(setProductLoading())
    
    canAccessInstrumentTracking && dispatch(setAperturesLoading())

    return getInitialData({cognitoSub, isEndUser, canAccessInstrumentTracking})
      .then (({ types, products, apertures }) => {
        dispatch(setProductTypes(types))
        isEndUser && dispatch(setUserProducts(products))
        canAccessInstrumentTracking && dispatch(setApertures(apertures))
      })
      .catch(e => {
        console.log(e.message)
      })
  }
}