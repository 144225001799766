import {_baseGetFetch, _basePostFetch,_basePutFetch, _baseDeleteFetch} from "./baseFetch";

export const getNotifications = (serialNumber) => {
  return _baseGetFetch(`/products/notifications?sn=${serialNumber}`)
}

export const postNotification = (queryParams) => {
  return _basePostFetch(`/products/notifications `, queryParams)
}

export const updateNotification = (id, queryParams) => {
  return _basePutFetch(`/products/notifications/${id}`, queryParams)
}

export const deleteNotification = (id) => {
  return _baseDeleteFetch(`/products/notifications/${id}`)
}
