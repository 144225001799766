import {
  LOAD_MEMBERS_PENDING,
  LOAD_MEMBERS_FULFILLED,
  LOAD_MEMBERS_REJECTED,
  LOAD_NEW_MEMBERS_PENDING,
  LOAD_NEW_MEMBERS_FULFILLED,
  LOAD_NEW_MEMBERS_REJECTED,
  LOAD_REQUESTS_PENDING,
  LOAD_REQUESTS_FULFILLED,
  LOAD_REQUESTS_REJECTED,
  LOAD_NEW_REQUESTS_PENDING,
  LOAD_NEW_REQUESTS_FULFILLED,
  LOAD_NEW_REQUESTS_REJECTED,
  POST_MEMBER_PENDING,
  POST_MEMBER_FULFILLED,
  POST_MEMBER_REJECTED,
  PUT_MEMBER_PENDING,
  PUT_MEMBER_FULFILLED,
  PUT_MEMBER_REJECTED,
  PATCH_REQUEST_STATUS_PENDING,
  PATCH_REQUEST_STATUS_FULFILLED,
  PATCH_REQUEST_STATUS_REJECTED,
  DELETE_MEMBER_PENDING,
  DELETE_MEMBER_FULFILLED,
  DELETE_MEMBER_REJECTED,
  LOAD_MEMBERS_FILTER_OPTIONS_PENDING,
  LOAD_MEMBERS_FILTER_OPTIONS_FULFILLED,
  LOAD_MEMBERS_FILTER_OPTIONS_REJECTED,
  LOAD_CURRENT_COMPANY_PENDING,
  LOAD_CURRENT_COMPANY_FULFILLED,
  LOAD_CURRENT_COMPANY_REJECTED,
  CLEAR_MEMBERS_MESSAGES,
} from "../actions/members"
import i18n from 'i18next'

export default function members(state = {}, action) {
  switch (action.type) {
    case LOAD_MEMBERS_PENDING:
    case LOAD_NEW_MEMBERS_PENDING:
    case LOAD_REQUESTS_PENDING:
    case LOAD_NEW_REQUESTS_PENDING:
    case POST_MEMBER_PENDING:
    case PUT_MEMBER_PENDING:
    case DELETE_MEMBER_PENDING:
    case LOAD_MEMBERS_FILTER_OPTIONS_PENDING:
    case LOAD_CURRENT_COMPANY_PENDING:
    case PATCH_REQUEST_STATUS_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_MEMBERS_REJECTED:
    case LOAD_REQUESTS_REJECTED:
    case LOAD_NEW_MEMBERS_REJECTED:
    case LOAD_NEW_REQUESTS_REJECTED:
    case POST_MEMBER_REJECTED:
    case PUT_MEMBER_REJECTED:
    case DELETE_MEMBER_REJECTED:
    case LOAD_MEMBERS_FILTER_OPTIONS_REJECTED:
    case LOAD_CURRENT_COMPANY_REJECTED:
    case PATCH_REQUEST_STATUS_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_MEMBERS_FULFILLED:
      const members = state?.members || []
      const newMembersIds = action.payload.data?.map(item => item.id)
      const differenceMembers = members
        .filter(x => !newMembersIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        members: differenceMembers,
        canLoadMoreMembers: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_MEMBERS_FULFILLED:
      return {
        ...state,
        members: action.payload.data,
        canLoadMoreMembers: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_REQUESTS_FULFILLED:
      const requests = state?.requests || []
      const newRequestsIds = action.payload.data?.map(item => item.id)
      const differenceRequests = requests
        .filter(x => !newRequestsIds.includes(x.id))
        .concat(action.payload.data)

      return {
        ...state,
        requests: differenceRequests,
        canLoadMoreRequests: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_REQUESTS_FULFILLED:
      return {
        ...state,
        requests: action.payload.data,
        canLoadMoreRequests: action.payload.currentPage < action.payload.totalPages,
        isLoading: false,
      }
    case POST_MEMBER_FULFILLED:
      const initialRequests = state?.requests || []

      const newRequest = {
        id: action.payload.id,
        branchId: action.meta.branchId,
        companyId: action.meta.companyId,
        email: action.meta.email,
        status: "invited",
        requestType: "invite",
        lastModifyAt: new Date().toISOString(),
      }
  
      return {
        ...state,
        requests: [newRequest, ...initialRequests],
        isLoading: false,
        successMessage: i18n.t("dealer-portal.invitation_success")
      }
    case PUT_MEMBER_FULFILLED:
      const updatedMembers = state?.members?.map((item) => item.uuid === action.payload.uuid ? { ...action.payload } : item)

      return {
        ...state,
        members: updatedMembers,
        isLoading: false,
        successMessage: i18n.t("dealer-portal.user_edited")
      }
    case PATCH_REQUEST_STATUS_FULFILLED:
      let updatedRequests = state.requests?.filter((item) => item.id !== action.meta.requestId) || []

      if (action.meta.action === "resend") {
        const resentRequest = state.requests?.find((item) => item.id === action.meta.requestId)

        if (resentRequest) {
          updatedRequests = [{...resentRequest, status: "invited", lastModifyAt: new Date().toISOString() }, ...updatedRequests]
        }
      }
      
      return {
        ...state,
        requests: updatedRequests,
        isLoading: false,
        successMessage: i18n.t("dealer-portal.member_request_updated")
      }
    case DELETE_MEMBER_FULFILLED:
      const filteredMembers = state.members?.filter(member => member.uuid !== action.meta.uuid) || []
      return {
        ...state,
        members: filteredMembers,
        isLoading: false,
        successMessage: i18n.t("dealer-portal.user_removed"),
      }
    case LOAD_MEMBERS_FILTER_OPTIONS_FULFILLED:
      return {
        ...state,
        branchesOptions: action.payload?.branches?.map((b) => b.name) || [],
        rolesOptions: action.payload?.roles?.map((r) => r.name) || [],
        isLoading: false,
      }
    case LOAD_CURRENT_COMPANY_FULFILLED:
      return {
        ...state,
        companyId: action.payload.id,
        companyStatus: action.payload.status,
        companyName: action.payload.companyName,
        country: action.payload.country,
        officeAddressOne: action.payload.officeAddressOne,
        city: action.payload.city,
        stateProvinceRegion: action.payload.stateProvinceRegion,
        zipPostalCode: action.payload.zipPostalCode,
        branches: action.payload.branches || [],
        isLoading: false,
      }
    case CLEAR_MEMBERS_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    }
    default:
      return state
  }
}