import config from "../config"

/**
 * 
 * @param {String} topic
 * @param {Object} payload 
 * @returns 
 */
export const publishMqtt = (topic, payload) => {
  const body = {
    topic: topic,
    payload: JSON.stringify(payload)
  }

  return fetch(config.publish.URL,
    {
      method: 'POST',
      headers: {'x-api-key': config.publish.xApiKey, 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }
  )
    .then(response => response.json())
    .catch(error => {
      console.log(error)
    })
}

export const sendMail = (email, emailTemplate, emailVariables) => {
  // See https://synergo.atlassian.net/browse/SC-254
  let source = '';
  if (config.publish.emailSender !== null && config.publish.emailSender !== '') {
      // Custom email sender
      source =  config.publish.emailSender
  }
  const payload = {
    "mail": email,
    "source": source,
    "template": emailTemplate,
    "variables": emailVariables,
    "mqtt_response_topic": "/dev/null",
    "mqtt_response_payload": {
      "result": "email_sent"
    }
  }
  
  return publishMqtt(config.publish.emailTopic, payload)
}

export async function updateFirmware(sn, version) {
  const path = `CAN/CMD/boot_fw_update_request_start_process/PROCESS_0_${version}_1234567890`
  const topic = `${config.publish.environment}/SCICAN/${sn}/${path}`
  
  const payload = {
    "path":path,
    "data":{
      "sn":sn,
      "node":"PROCESS"
    }
  }

  return publishMqtt(topic, payload)

//   const axios = require("axios");

// export async function updateMachine(sn, path, version) {
//   var data = JSON.stringify({
//     topic: `P/SCICAN/${sn}/${path}`,
//     payload: `{"path":CAN/CMD/boot_fw_update_request_start_process/PROCESS_0_${version}_1234567890,"data":{"sn":${sn},"node":"PROCESS"}}`,
//   });
//   const response = await axios({
//     method: "post",
//     url: "https://iot.scicanapi.com/thing/publishmqtt",
//     headers: {
//       "x-api-key": "70efExmuKC4x2RO0mEdzl7GeERTyOblN5rau4WK0",
//     },
//     data,
//   });
//   return response;
// }

}

/**
 * 
 * @param {string} email //current user's email address
 * @param {string} serialNumber // serial number of unit to publish to
 * @param {object} userInfo     //information of currently logged in user.
 * @returns 
 * 
 * userInfo object-
  {
      "account_state": "associated", 
      "account_state_time_modified_utc_seconds": "current Unix timestamp",
      "account_email": "email@example.com",
      "account_company_name": "company'",
      "account_contact_name": "firstname' lastname",
      "account_phone_number": "phone",
      "account_address": "address1, address2",
      "account_city": "city",
      "account_subregion": "region",
      "account_country": "country",
      "account_zip_code": "zipcode",
      "units": [ //all units owned by user.
        {"serial_number":"310800A00114"},
        {"serial_number":"310800A00117"},
        {"serial_number":"720121A00006"},
        {"serial_number":"720121A00004"}
      ]
  }
 */
export const publishAccountUpdate = (serialNumber, userInfo) => {
  const payload = {
    "accounts": userInfo
  }

  const topic = `${config.publish.environment}/scican/srv/${serialNumber}/event/account`
  
  return publishMqtt(topic, payload)
}

export const publishApertureNicknameUpdate = (serialNumber, payload) => {
  const topic = `${config.publish.environment}/scican/srv/${serialNumber}/event/aperture/account`
  return publishMqtt(topic, payload)
}

export const publishClearRemoteSession = (serialNumber, payload) => {
  const topic = `${config.publish.environment}/scican/evn/${serialNumber}/clear_remote_session`
  return publishMqtt(topic, payload)
}
