import React from 'react';
import { LineChart, CartesianGrid, Legend, XAxis, Line } from "recharts";


export default function DTChart2(props) {
  return (
    <LineChart
      width={props.width - 110}
      height={300}
      data={props.historicOutput}
      style={{padding: "0 50px 0 60px"}}
    >
      <CartesianGrid/>
      <XAxis dataKey="time" style={{ fontSize: "0.8em"}}/>
      <Legend layout="vertical" verticalAlign="middle" align="right" wrapperStyle={{ padding: "0 0 0 0px" }}/>
      <Line yAxisId="left" type="step" dataKey="Steam Generator 2 Valve" dot={false} stroke="#33A02C" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Water Drain Valve" dot={false} stroke="#000000" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Fill Steam Generator Valve 1" dot={false} stroke="#42f700" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Water Fill Valve" dot={false} stroke="#3D89BC" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Vacuum Pump" dot={false} stroke="#E31A1C" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Exhaust Valve 1" dot={false} stroke="#02c2c2" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Exhaust Valve 2" dot={false} stroke="#FF7F00" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Water Pump Fill System Generator 1" dot={false} stroke="#6A3D9A" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Water Pump Fill System Generator 2" dot={false} stroke="#007F5F" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Vent Chamber Valve" dot={false} stroke="#3700de" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="Heater Exchanger Fan" dot={false} stroke="#ff00e6" strokeWidth="2"/>
      <Line yAxisId="left" type="step" dataKey="LCD Fan" dot={false} stroke="#fcb103" strokeWidth="2"/>
    </LineChart>
  )
}

