import { ReactComponent as SterilizerDiagramSvg } from "./schematic.svg";
import { useEffect } from "react";
import SterilizerDiagramLegend from "./SterilizerDiagramLegend";

export default function SterilizerDiagram(props) {

  const { data } = props

  useEffect(() => {
    document.getElementById('Vacuum-Pump-##-3').textContent = data.vacuumPump
    document.getElementById('VFW-##-2').textContent = data.VFW
    document.getElementById('Water-Reservoir-##-1').textContent = data.topLevelSwich
    document.getElementById('Water-Reservoir-##-2').textContent = data.bottomLevelSwich
    document.getElementById('Water-Reservoir-##-3').textContent = data.waterReservoir
    document.getElementById('Steam-Generator-2-##-3').textContent = data.steamGenerator2
    document.getElementById('Water-Pump-2-##-3').textContent = data.waterPump2

    document.getElementById('Water-Pump-1-##-3').textContent = data.waterPump1
    document.getElementById('Steam-Generator-1-##-3').textContent = data.steamGenerator1
    document.getElementById('VFB-1-##-2').textContent = data.VFB1
    document.getElementById('VFB-2-##-2').textContent = data.VFB2
    document.getElementById('VVC-##-2').textContent = data.VVC

    document.getElementById('VEC-##-2').textContent = data.VEC
    document.getElementById('VDR-##-2').textContent = data.VDR
    document.getElementById('Heat-Exchanger-##-3').textContent = data.heatExchanger

    document.getElementById('Chamber-temp').textContent = data.chamberTemp
    document.getElementById('Chamber-pressure').textContent = data.chamberPuressure
    document.getElementById('Band-Heater-1-##-2').textContent = data.bandHeater1
    document.getElementById('Band-Heater-2-##-2').textContent = data.bandHeater2
    document.getElementById('VDHE-##-2').textContent = data.VDHE
  }, [data])


  return (
    <div className={"diagram-container"}>
      <div className={"diagram-container-left"}>
        <SterilizerDiagramLegend/>
      </div>
      <SterilizerDiagramSvg className={"diagram-container-right"}/>
    </div>
  )
}
