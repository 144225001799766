import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import GoogleMapWrapper from "../common/GoogleMapWrapper"
import config from "../../utils/config"
import { useWindowSize } from "../../libs/hooks"
import { ExpandLessIcon, ExpandMoreIcon } from "../../icons"
import { getWarrantyById } from "../../utils/requests/warrantiesAPI"
import "./general-information.scss"
import { formatPhone } from "../../utils/functions"

const GeneralInformation = (props) => {
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const [dataWeb, setDataWeb] = useState({})
  const [dataIot, setDataIot] = useState({})
  const [dataGeo, setDataGeo] = useState({})
  const [dataWarranty, setDataWarranty] = useState({})
  const [showContactInfoFromWeb, setShowContactInfoFromWeb] = useState(true)
  const [showContactInfoFromIoT, setShowContactInfoFromIoT] = useState(false)
  const [showWarranty, setShowWarranty] = useState(false)
  const [showGeoLocation, setShowGeoLocation] = useState(false)

  useEffect(() => {
    if (props.generalInformation) {
      setDataWeb(props.generalInformation.registration_contact || {})
      setDataIot(props.generalInformation.iot_contact || {})
      setDataGeo(props.generalInformation.geo_data || {})

      if (props.generalInformation.warranty_id) {
        getWarrantyById(props.generalInformation.warranty_id)
          .then((result) => {
            if (result?.data?.length > 0) {
              setDataWarranty(result.data[0])
            }
          })
      }
    }
  }, [props.generalInformation])

  const toggleContactInfoFromWeb = () => {
    setShowContactInfoFromWeb((prevVal) => !prevVal)
  }

  const toggleContactInfoFromIot = () => {
    setShowContactInfoFromIoT((prevVal) => !prevVal)
  }

  const toggleWarranty = () => {
    setShowWarranty((prevVal) => !prevVal)
  }

  const toggleGeoLocation = () => {
    setShowGeoLocation((prevVal) => !prevVal)
  }

  return (
    <div className="general-information-wrapper">
      <div className={`general-information-expand-wrapper ${!showContactInfoFromWeb ? "wrapper-unactive" : ""}`}>
        <div className="general-information-expand-header" onClick={() => toggleContactInfoFromWeb()}>
          <p className="title">{t("product.contact_information_from_web")}</p>
          <div className="icon">{showContactInfoFromWeb ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        </div>
        {showContactInfoFromWeb && (
          <div className="list-wrapper bg-white d-flex">
            <div className="list-info">
              <p>
                <span className="label">{t("product.contact.customer_title")}: </span>
                <span className="label-data">{dataWeb.title || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.first_name")}: </span>
                <span className="label-data">{dataWeb.first_name || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.middle_name")}: </span>
                <span className="label-data">{dataWeb.middle_name || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.last_name")}: </span>
                <span className="label-data">{dataWeb.last_name || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.email")}: </span>
                <span className="label-data break-email-address">{dataWeb.email || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.phone")}: </span>
                <span className="label-data">{dataWeb.phone ? formatPhone(dataWeb.phone) : t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.fax")}: </span>
                <span className="label-data">{dataWeb.fax || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.country")}: </span>
                <span className="label-data">{dataWeb.country || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.region")}: </span>
                <span className="label-data">{dataWeb.region || t("labels.n_a")}</span>
              </p>
            </div>
            <div className="list-info">
              <p>
                <span className="label">{t("product.contact.city")}: </span>
                <span className="label-data">{dataWeb.city || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.address_1")}: </span>
                <span className="label-data">{dataWeb.address1 || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.address_2")}: </span>
                <span className="label-data">{dataWeb.address2 || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.postal_code")}: </span>
                <span className="label-data">{dataWeb.postal_code || t("labels.n_a")}</span>
              </p>

              <p>
                <span className="label">{t("product.contact.language")}: </span>
                <span className="label-data">{dataWeb.language || t("labels.n_a")}</span>
              </p>
            </div>
          </div>
        )}
      </div>

      <div className={`general-information-expand-wrapper ${!showContactInfoFromIoT ? "wrapper-unactive" : ""}`}>
        <div className="general-information-expand-header" onClick={() => toggleContactInfoFromIot()}>
          <p className="title">{t("product.contact_information_device")}</p>
          <div className="icon">{showContactInfoFromIoT ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        </div>
        {showContactInfoFromIoT && (
          <div className="list-wrapper bg-white d-flex">
            <div className="list-info">
              <p>
                <span className="label">{t("product.contact.customer_title")}: </span>
                <span className="label-data">{dataIot.title || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.first_name")}: </span>
                <span className="label-data">{dataIot.first_name || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.middle_name")}: </span>
                <span className="label-data">{dataIot.middle_name || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.last_name")}: </span>
                <span className="label-data">{dataIot.last_name || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.email")}: </span>
                <span className="label-data break-email-address">{dataIot.email || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.phone")}: </span>
                <span className="label-data">{dataIot.phone ? formatPhone(dataIot.phone) : t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.fax")}: </span>
                <span className="label-data">{dataIot.fax || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.country")}: </span>
                <span className="label-data">{dataIot.country || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.region")}: </span>
                <span className="label-data">{dataIot.region || t("labels.n_a")}</span>
              </p>
            </div>
            <div className="list-info">
              <p>
                <span className="label">{t("product.contact.city")}: </span>
                <span className="label-data">{dataIot.city || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.address_1")}: </span>
                <span className="label-data">{dataIot.address1 || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.address_2")}: </span>
                <span className="label-data">{dataIot.address2 || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.contact.postal_code")}: </span>
                <span className="label-data">{dataIot.postal_code || t("labels.n_a")}</span>
              </p>

              <p>
                <span className="label">{t("product.contact.language")}: </span>
                <span className="label-data">{dataIot.language || t("labels.n_a")}</span>
              </p>
            </div>
          </div>
        )}
      </div>

      <div className={`general-information-expand-wrapper ${!showWarranty ? "wrapper-unactive" : ""}`}>
        <div className="general-information-expand-header" onClick={() => toggleWarranty()}>
          <p className="title">{t("product.warranty")}</p>
          <div className="icon">{showWarranty ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        </div>
        {showWarranty && (
          <>
            <div className="list-wrapper bg-white d-flex">
              <div className="list-info">
                <p>
                  <span className="label">{t("warranty.warranty_registration_date")}: </span>
                  <span className="label-data">{dataWarranty.registration_date || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("warranty.warranty_end_date")}: </span>
                  <span className="label-data">{dataWarranty.registration_date || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("warranty.warranty_info_update_date")}: </span>
                  <span className="label-data">{dataWarranty.update_date || t("labels.n_a")}</span>
                </p>
              </div>
              <div className="list-info empty-list-info"></div>
            </div>
            <div className="list-wrapper bg-white d-flex">
              <div className="list-info">
                <div className="section-title">{t("warranty.promo_info")}</div>
                <p>
                  <span className="label">{t("warranty.promo_message")}: </span>
                  <span className="label-data">{dataWarranty.promo_message || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("warranty.promo_id")}: </span>
                  <span className="label-data">{dataWarranty.promo_id || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("warranty.promo_email_notification")}: </span>
                  <span className="label-data break-email-address">{dataWarranty.promo_email_notification || t("labels.n_a")}</span>
                </p>
              </div>
              <div className="list-info">
                <div className="section-title">{t("warranty.purchase_info")}</div>
                <p>
                  <span className="label">{t("warranty.price")}: </span>
                  <span className="label-data">{dataWarranty.purchase_price || t("labels.n_a")}</span>
                </p>
              </div>
            </div>
            <div className="list-wrapper bg-white d-flex">
              <div className="list-info">
                <div className="section-title">{t("warranty.customer_info")}</div>
                <p>
                  <span className="label">{t("product.contact.customer_title")}: </span>
                  <span className="label-data">{dataWarranty.doctor_title || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.first_name")}: </span>
                  <span className="label-data">{dataWarranty.doctor_first_name || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.middle_name")}: </span>
                  <span className="label-data">{dataWarranty.doctor_middle_name || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.last_name")}: </span>
                  <span className="label-data">{dataWarranty.doctor_last_name || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.email")}: </span>
                  <span className="label-data break-email-address">{dataWarranty.office_email || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.phone")}: </span>
                  <span className="label-data">{dataWarranty.office_phone ? formatPhone(dataWarranty.office_phone) : t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.fax")}: </span>
                  <span className="label-data">{dataWarranty.office_fax || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.company_name")}: </span>
                  <span className="label-data">{dataWarranty.office_name || t("labels.n_a")}</span>
                </p>
              </div>
              <div className="list-info">
                <div className="section-title empty-section"></div>
                <p>
                  <span className="label">{t("warranty.preferred_contact")}: </span>
                  <span className="label-data">phone/email</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.address_1")}: </span>
                  <span className="label-data">{dataWarranty.office_address_1 || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.address_2")}: </span>
                  <span className="label-data">{dataWarranty.office_address_2 || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.city")}: </span>
                  <span className="label-data">{dataWarranty.office_city || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.region")}: </span>
                  <span className="label-data">{dataWarranty.office_region || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.country")}: </span>
                  <span className="label-data">{dataWarranty.office_country || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.postal_code")}: </span>
                  <span className="label-data">{dataWarranty.office_zip_code || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.language")}: </span>
                  <span className="label-data">{dataWarranty.office_language || t("labels.n_a")}</span>
                </p>
              </div>
            </div>
            <div className="list-wrapper bg-white d-flex">
              <div className="list-info">
                <div className="section-title">{t("warranty.dealer_info")}</div>
                <p>
                  <span className="label">{t("product.contact.name")}: </span>
                  <span className="label-data">{dataWarranty.dealer_name || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.email")}: </span>
                  <span className="label-data break-email-address">{dataWarranty.dealer_email || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.phone_1")}: </span>
                  <span className="label-data">{dataWarranty.dealer_phone_1 ? formatPhone(dataWarranty.dealer_phone_1) : t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.phone_2")}: </span>
                  <span className="label-data">{dataWarranty.dealer_phone_2 ? formatPhone(dataWarranty.dealer_phone_2) : t("labels.n_a")}</span>
                </p>
              </div>
              <div className="list-info">
                <div className="section-title">{t("warranty.sales_rep_info")}</div>
                <p>
                  <span className="label">{t("product.contact.name")}: </span>
                  <span className="label-data">{dataWarranty.sales_rep_name || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.email")}: </span>
                  <span className="label-data break-email-address">{dataWarranty.sales_rep_email || t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.phone_1")}: </span>
                  <span className="label-data">{dataWarranty.sales_rep_phone_1 ? formatPhone(dataWarranty.sales_rep_phone_1) : t("labels.n_a")}</span>
                </p>
                <p>
                  <span className="label">{t("product.contact.phone_2")}: </span>
                  <span className="label-data">{dataWarranty.sales_rep_phone_2 ? formatPhone(dataWarranty.sales_rep_phone_2) : t("labels.n_a")}</span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={`general-information-expand-wrapper ${!showGeoLocation ? "wrapper-unactive" : ""}`}>
        <div className="general-information-expand-header" onClick={() => toggleGeoLocation()}>
          <p className="title">{t("product.geo_location")}</p>
          <div className="icon">{showGeoLocation ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        </div>
        {showGeoLocation && (
          <div className="list-wrapper bg-white d-flex">
            <div className="list-info">
              <p>
                <span className="label">{t("product.geolocation.product_ip")}: </span>
                <span className="label-data">{dataGeo.ip || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.geolocation.country_group")}: </span>
                <span className="label-data">{dataGeo.country || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.geolocation.region")}: </span>
                <span className="label-data">{dataGeo.region || t("labels.n_a")}</span>
              </p>
              <p>
                <span className="label">{t("product.geolocation.city")}: </span>
                <span className="label-data">{dataGeo.city || t("labels.n_a")}</span>
              </p>
            </div>
            <div className="list-info product-location">
              {dataGeo.lat && (
                <GoogleMapWrapper className="map-wrapper">
                  <img
                    className="lazy-load"
                    border="0"
                    alt="maps"
                    src={`${config.maps.staticURL}?center=${dataGeo.lat},${dataGeo.lng}&maptype=static&zoom=14&size=${
                      width > 480 ? "640x320" : "320x160"
                    }&scale=1&markers=color:red|${dataGeo.lat},${dataGeo.lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                  />
                  <div className="mask-map">
                    <a href={`${config.maps.searchURL}?q=${dataGeo.lat},${dataGeo.lng}`} target="_blank"></a>
                  </div>
                  <div className="larger-map">
                    <a href={`${config.maps.searchURL}?q=${dataGeo.lat},${dataGeo.lng}`} target="_blank">
                      {t("product.geolocation.view_larger_map")}
                    </a>
                  </div>
                </GoogleMapWrapper>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GeneralInformation
