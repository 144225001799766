import "./modal.scss"
import PropTypes from "prop-types"
import { CloseCircleIcon } from "../../../icons"
import React, { useEffect, useRef } from "react"
import { Auth } from "aws-amplify"
import { hasNoSiteAccess } from "../../../utils/permissionValidation"
import { connect } from "react-redux"
import { keyboardEvents } from "../../../utils/keyboardEvents"


const Modal = (props) => {
  const { toggle, onToggle, showClose = true } = props
  const [isWhiteList, setIsWhiteList] = React.useState("loading")
  const modalRef = useRef(null)

  useEffect(() => {
    modalRef?.current?.focus()
  }, [])

  const closeModal = () => {
    onToggle()
  }

  React.useEffect(() => {
    let intervalId
    if (props.type === "remote") {
      Auth.currentSession()
        .then(async (res) => {
          const userAttr = res.getIdToken().payload
          if (props.role === "dealer_technician") {
            function validateWhiteList() {
              if ((isWhiteList === "loading" || !isWhiteList) && userAttr.email) {
                const noAccess = hasNoSiteAccess(props.userPermissions)
                if (noAccess) {
                  setIsWhiteList(false)
                } else {
                  setIsWhiteList(true)
                }
              }
            }
            validateWhiteList()
            intervalId = setInterval(() => {
              validateWhiteList()
            }, 30000)
          } else {
            setIsWhiteList(true)
          }
        })
        .catch((e) => {
          if (e !== "No current user") {
            console.info(e)
          }
        })
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isWhiteList, props.role, props.type, props.userPermissions])

  return (
    ((toggle && isWhiteList === true && props.type === "remote") || (toggle && props.type !== "remote")) && (
      <div className={`modal-wrapper ${props.className}`}>
        <div
          ref={modalRef}
          className="overflow" 
          onClick={closeModal}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === keyboardEvents.ESC && closeModal()}
        />
        <div className="modal">
          {props.header ? (
            <div className="header">{props.header}</div>
          ) : showClose ? (
            <div 
              className="close"
              tabIndex={0}
              role="button"
              onKeyDown={(e) => e.key === keyboardEvents.ENTER && closeModal()}
            >
              <CloseCircleIcon onClick={closeModal} />
            </div>
          ) : (
            <></>
          )}
          {props.children}
        </div>
      </div>
    )
  )
}
Modal.propTypes = {
  toggle: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
}

function mapStateToProps({ userPermissions }) {
  return {
    userPermissions,
  }
}

export default connect(mapStateToProps)(Modal)
