import React from 'react'
import { connect } from "react-redux"
import { checkPermission } from "../../../utils/permissionValidation"
import "./simple-button.scss"

 const SimpleButton = ({requiredPermission, className='', isLabel = false, disabled=false, userPermissions, ...props}) => {
  return (
    <button 
      className={`simple-button ${className} ${(disabled || (requiredPermission && !checkPermission(userPermissions, requiredPermission))) ? 'disabled' : ''} ${isLabel ? 'label-button' : ''}`}
      {...props}
    >
      {props.children}
    </button>
  )
}

function stateToProps({ userPermissions }) {
  return {
    userPermissions
  }
}

export default connect(stateToProps)(SimpleButton)