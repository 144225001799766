const HighlightedText = (
    {
        text = '', 
        highlight = '', 
        defaultTextClassName = '',
        matchingTextClassName= '',
        nonMatchingTextClassName = ''
    }
) => {
    if (!highlight || !highlight.trim()) {
      return <span className={defaultTextClassName}>{text}</span>
    }

    const escapeRegex = (string) => {
      return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    const regex = new RegExp(`(${escapeRegex(highlight)})`, 'gi')
    const parts = text.split(regex)

    return (
      <span>
          {parts.filter(part => part).map((part, i) => (
            regex.test(part) ?
            <mark className={matchingTextClassName} key={i}>{part}</mark> : 
            <span className={nonMatchingTextClassName} key={i}>{part}</span>
          ))}
      </span>
    )
  }

  export default HighlightedText