import {
  getFirmwareList
} from "../utils/requests/firmwareAPI"

export const LOAD_FIRMWARE_DOWNLOAD = "LOAD_FIRMWARE_DOWNLOAD"
export const LOAD_FIRMWARE_DOWNLOAD_PENDING = "LOAD_FIRMWARE_DOWNLOAD_PENDING"
export const LOAD_FIRMWARE_DOWNLOAD_FULFILLED = "LOAD_FIRMWARE_DOWNLOAD_FULFILLED"
export const LOAD_FIRMWARE_DOWNLOAD_REJECTED = "LOAD_FIRMWARE_DOWNLOAD_REJECTED"

export const CLEAR_FIRMWARE_DOWNLOAD_MESSAGES = "CLEAR_FIRMWARE_DOWNLOAD_MESSAGES"
export const CLEAR_FIRMWARE_DOWNLOAD = "CLEAR_FIRMWARE_DOWNLOAD"

export function loadFirmwareDownload() {
  return {
    type: LOAD_FIRMWARE_DOWNLOAD,
    payload: getFirmwareList()
  }
}

export function clearFirmwareDownloadMessages() {
  return {
    type: CLEAR_FIRMWARE_DOWNLOAD_MESSAGES
  }
}

export function clearFirmwareDownload() {
  return {
    type: CLEAR_FIRMWARE_DOWNLOAD
  }
}

export function handleClearFirmwareDownload() {
  return (dispatch) => {
    return dispatch(clearFirmwareDownload())
  }
}
