import React, { useEffect, useState } from "react"
import "./short-toggle-switch.scss"

const ToggleSwitchShort = (props) => {
  const { checked, onChange } = props
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const triggerToggle = () => {
    if (onChange) {
      setIsChecked(!isChecked)
      onChange()
    }
  }

  return (
    <div onClick={triggerToggle} className={"toggle-wrapper-short" + (isChecked ? " active-toggle-short" : "")}>
      <div className="toggle-switch-short"></div>
    </div>
  )
}

export default ToggleSwitchShort
