import { getProducts, getApertures } from "./usersAPI"
import { getProductTypes } from './productsAPI'

export const getInitialData = ({ cognitoSub, isEndUser, canAccessInstrumentTracking }) => {
  try {
    return Promise.all([
      getProductTypes(),
      ...((isEndUser) ? [getProducts(cognitoSub)] : []),
      ...((canAccessInstrumentTracking) ? [getApertures(cognitoSub)] : [])
    ])
      .then(([types, products, apertures]) => ({
        types: types?.data,
        ...(isEndUser && { products: products.data ? products.data : products }),
        ...(canAccessInstrumentTracking && { apertures: apertures?.data }),
      }))
  } catch(e) {
    console.log(e.message)
  }
}