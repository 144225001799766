import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import ProductActivationStepsModal from './ProductActivationStepsModal'
import PageModal from '../common/PageModal'
import Search from "../common/Search"
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import TooltipedIcon from '../common/TooltipedIcon'
import { CaretDownIcon, ArrowBackThickIcon, VideoPlayIcon, VerifiedIcon, ApertureIcon, UpdateIcon, CheckDoubleIcon, DownloadModernIcon, PdfModernIcon, WasherSmallIcon, SterilizerSmallIcon, InformationIcon } from "../../icons"
import { getImageByModel } from '../../utils/functions'
import { useAppContext } from '../../libs/contextLib'
import './product-details-modal.scss'
import { checkPermission, deviceNotificationsReadPermissions, userNotificationWritePermission } from '../../utils/permissionValidation'
import { connect } from 'react-redux'

const ProductDetailsModal = (props) => {
  const {t} = useTranslation()
  const { showFeatureDev, supportsColteneStore } = useAppContext()
  const {
    canAccessInstrumentTracking,
    selectedProduct,
    rawSerials,
    selectedSerial,
    productSerialNickname,
    hasOnlineAccess,
    hasPrivacyPolicyChecked,
    hasUpdateAvailable,
    productIsAperture,
    isBravoProduct,
    lastCycles,
    documents,
    videos,
    relatedProducts,
    isICTrackProduct,
    linkedDevicesLimit,
    showLimitedLikedDevice,
    onSelectSerial,
    onShowNotifications,
    onShowBravoOnlineAccess,
    handleOpenProductActivationStepsModal,
    handleOpenNicknameSetupModal,
    handleOpenDeleteNicknameModal,
    handleNavigateToProduct,
    handleNavigateToProductPage,
    handleNavigateToInstrumentTracking,
    handleNavigateToInstrumentTrackingReports,
    handleNavigateToStore,
    onShowCycleDetails,
    onDownloadDocument,
    handleOpenDocumentsModal,
    handleOpenVideosModal,
    onSelectVideo,
    onShowLimitedLinkedDevices,
    showSpinner,
    cyclesLoading,
    closeModal, 
    mapSerialToModel
  } = props
  const [serials, setSerials] = useState([])
   
  useEffect(() => {
    if (selectedProduct) {
      setSerials(rawSerials)
    }
  }, [selectedProduct])

  const productHeaderContent = () => {
    return (
      <div className="product-header" onClick={() => handleCloseModal()}>
        <div className="back-button" >
          <div className="back-icon">
            <ArrowBackThickIcon />
          </div>
        </div>
        <p className="title">{t("buttons.back")}</p>
      </div>
    )
  }

  const searchSerial = (value) => {
    if (value) {
      const filteredSerials = rawSerials.filter(serial => serial.serial_number.toLowerCase().includes(value.toLowerCase()) || serial.unit_nickname.toLowerCase().includes(value.toLowerCase()))
      setSerials(filteredSerials)
    } else {
      setSerials(rawSerials)
    }
  }

  const selectSerial = (serial, toggleVisibility) => {    
    onSelectSerial(serial, toggleVisibility)
    toggleVisibility(false)
  }

  const onSelectSerialInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      setSerials(rawSerials)
    } else {
      setSerials(rawSerials)
      toggleVisibility(true)
    }
  }

  const selectSerialInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectSerialInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedSerial ? (selectedSerial.unit_nickname ? selectedSerial.unit_nickname : selectedSerial.serial_number) : t('products.no_product_selected')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectSerialOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchSerial(e)}
          placeholder={t('placeholders.search-filters')}
        />
      </div>
      {serials?.map((serial, index) => 
        <div 
          className={"option cursor-pointer no-wrap option-with-icon" + (serial === selectedSerial ? " selected-option" : "")} 
          key={index}
          onClick={() => selectSerial(serial, toggleVisibility)}
        >
          {serial.unit_nickname ? serial.unit_nickname : serial.serial_number}
          <div className="online-access">
            {serial.association_active ? <VerifiedIcon /> : <VerifiedIcon className="no-access" />}
          </div>
        </div>)
      }
    </div>
  )

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }

  const buildUserFriendlyDate = (date) => {
    if (date) {
      return new Date(date).toLocaleString("en-US", { day: "2-digit", month: "short", year: "numeric" })
    }

    return ""
  }

  return (
    <div className="product-details-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        headerContent={productHeaderContent()}
      >
        <div className="product-details-modal-content">
          <div className="product-card">
            <div className="image-wrapper d-flex flex-align-center">
              <img
                src={getImageByModel(selectedProduct.model)}
                className="img"
                alt={selectedProduct.model}
              />
            </div>
            <div className="product-wrapper">
              <p className="product-name">{isICTrackProduct ? t([`products.${selectedProduct.model}`, selectedProduct.model]) : selectedProduct.model}</p>
              {selectedProduct.products?.length > 0 && 
                <p className="product-serials">{`${selectedProduct.products.length} ${t("products.products")}`}</p>
              }
            </div>
          </div>
          {!isICTrackProduct &&
            <>
              <p className="serial-select-title">{t('products.registered_products')}</p>
              <ClickOutside 
                itemRef="click-outside-wrapper"
                eventItem={selectSerialInput}
                toDisplayItem={selectSerialOptions}
              />
            </>
          }       
          {selectedSerial && !isICTrackProduct &&
            <div className="product-details-wrapper">
              {showFeatureDev && !selectedSerial.association_active &&
                <div className="activation-box-text">
                  <div className="icon-wrapper">
                    <InformationIcon />
                  </div>
                  <div className="activation-wrapper">
                    <div className="title">{t("products.activate_online_access")}
                      <span 
                        className="activation-steps" 
                        onClick={() => handleOpenProductActivationStepsModal()}
                      >
                        {t("products.click_to_activate_online_access")}
                      </span>
                    </div>
                  </div>
               </div>
              }
              <div className="product-status-wrapper">
                <div className="image-wrapper d-flex flex-align-center">
                  <img
                    src={getImageByModel(selectedProduct.model)}
                    className="img"
                    alt={selectedProduct.model}
                  />
                </div>
                <div className="product-serial-wrapper">
                  <div className="product-serial">
                    {`${t("sn")}: ${selectedSerial.serial_number}`}
                  </div>
                  <div className="product-serial-subtitle">
                    {productSerialNickname ?
                      <div className="nickname-wrapper">
                        <span className="product-nickname">{productSerialNickname}</span>
                        <div className="nickname-actions-wrapper">
                          <span 
                            className={"delete-nickname" + ((!hasOnlineAccess || productIsAperture) ? " disabled" : "")}
                            onClick={() => hasOnlineAccess && !productIsAperture && handleOpenDeleteNicknameModal(true)}
                          >
                              {t("labels.delete")}
                          </span>
                          <span
                            className={"edit-nickname" + (!hasOnlineAccess ? " disabled" : "") }
                            onClick={() => hasOnlineAccess && handleOpenNicknameSetupModal(true)}
                          >
                            {t("labels.edit")}
                          </span>
                        </div>
                      </div>
                    :
                      <div 
                        className={"nickname-add-wrapper" + (!hasOnlineAccess ? " disabled" : "") }
                        onClick={() => hasOnlineAccess && handleOpenNicknameSetupModal(true)}
                      >
                        {t("products.add_nickname")}
                      </div>
                    }
                  </div>
                  <div className="product-status-legend">
                    {canAccessInstrumentTracking &&
                      <TooltipedIcon
                        icon={<ApertureIcon className={!productIsAperture ? "unavailable" : ""}/>}
                        tooltipText={productIsAperture ? t("products.aperture_associated") : t("products.aperture_not_associated")}
                      />
                    }
                    <TooltipedIcon
                      icon={<UpdateIcon className={!hasUpdateAvailable ? "unavailable" : ""}/>}
                      tooltipText={hasUpdateAvailable ? t("products.update_avilable") : t("products.update_not_avilable")}
                    />
                    <TooltipedIcon
                      icon={<VerifiedIcon className={!hasOnlineAccess ? "unavailable" : ""}/>}
                      tooltipText={hasOnlineAccess ? t("products.active_online_access") : t("products.inactive_online_access")}
                    />
                    <TooltipedIcon
                      icon={<CheckDoubleIcon className={!hasPrivacyPolicyChecked ? "unavailable" : ""}/>}
                      tooltipText={hasPrivacyPolicyChecked ? t("products.privacy_policy_accepted") : t("products.privacy_policy_not_accepted")}
                    />                
                  </div>
                </div>
              </div>

              {((checkPermission(props.userPermissions, userNotificationWritePermission) && isBravoProduct) || (checkPermission(props.userPermissions, deviceNotificationsReadPermissions)))  &&
                <SimpleButton
                  className="action-button with-bottom-margin"
                  disabled={!hasOnlineAccess} 
                  onClick={() => onShowNotifications()}
                >
                  {t("events.device-notifications")}
                </SimpleButton>
              }

              {isBravoProduct && !selectedSerial.association_active &&
                <SimpleButton
                  className="action-button with-bottom-margin"
                  onClick={() => onShowBravoOnlineAccess()}
                >
                  {t("products.activate_online_access_now")}
                </SimpleButton>
              }

              {showFeatureDev && selectedSerial.update_available &&
                <div className="product-software-wrapper">
                  <div className="update-wrapper">
                    <div className="info-wrapper">
                      <div className="section-title software-title">{t("products.update_available")}</div>
                      <div className="title-version">
                        <span>{`${t("products.current_version")} `}</span>
                        <span className="current-version">{"Lorem Ipsum"}</span>
                      </div>
                      <div className="title-version">
                        <span>{`${t("products.latest_version")} `}</span>
                        <span className="latest-version">{"Dollor sin amenum"}</span>
                      </div>
                      <SimpleButton className="submit-button">
                        {t("products.view_details")}
                      </SimpleButton>
                    </div>
                    <div className="update-dot"/>
                  </div>
                </div>
              }

              <div className="product-cycles-wrapper">
                <div className="section-title">{t("products.last_cycles")}</div>
                <div className="cycles-wrapper">
                  {lastCycles.map((cycle, index) => (
                    <div
                      key={index}
                      className={"cycle-item-wrapper " + `${cycle.className}` } 
                      onClick = {() => onShowCycleDetails(cycle)}
                    >
                      <div className="title">{cycle.title}</div>
                      <div className="time">{buildUserFriendlyDate(cycle.start)}</div>
                    </div>))
                  }
                </div>
                {!cyclesLoading && lastCycles.length === 0 && 
                  <div className="no-cycles-wrapper">
                    <p className="disclaimer">{t("products.no_cycles_available")}</p>
                  </div>
                }
                <SimpleButton
                  className="action-button view-cycles-button"
                  onClick={() => handleNavigateToProductPage()}
                >
                  {t("products.view_cycles_calendar")}
                </SimpleButton>
              </div>

              <div className="product-documents-wrapper">
                <div className="section-title">{t("products.documents_available")}</div>
                <div className="documents-wrapper">
                  {documents.map((item, index) => (
                    <div
                      key={index}
                      className="document-item-wrapper"
                      onClick = {() => onDownloadDocument(item?.file_path, item?.file_name)}
                    >
                      <div className="image-wrapper">
                        <DownloadModernIcon/>
                      </div>
                      <div className="image-wrapper rounded-image">
                        <PdfModernIcon/>
                      </div>
                      <div className="title-wrapper">
                        <p className="title">{item?.document_title ? item?.document_title : item?.file_name}</p>
                        <p className="section">{item?.section_name}</p>
                      </div>
                    </div>))
                  }
                </div>
                {documents.length === 0 && 
                  <div className="no-documents-wrapper">
                    <p className="disclaimer">{t("products.no_documents_available")}</p>
                  </div>
                }
                <SimpleButton 
                  className="action-button view-documents-button"
                  disabled={documents.length === 0}
                  onClick={() => handleOpenDocumentsModal()}
                >
                  {t("products.view_all_documents")}
                </SimpleButton>
              </div>

              <div className="product-videos-wrapper">
                <div className="section-title">{t("products.videos")}</div>
                <div className="videos-wrapper">
                  {videos.map((video, index) => (
                    <div
                      key={index}
                      className="video-item-wrapper"
                      onClick = {() => onSelectVideo(video)}
                    >
                      <div className="thumbnail-wrapper">
                        <div className="thumbnail-icon">
                          <VideoPlayIcon />
                        </div>
                        <img
                          src={video.thumbnail_url}
                          alt="video"
                        />
                      </div>                
                      <p className="title">{video.title}</p>
                    </div>))
                  }
                </div>
                {videos.length === 0 && 
                  <div className="no-videos-wrapper">
                    <p className="disclaimer">{t("products.no_videos_available")}</p>
                  </div>
                }
                <SimpleButton 
                  className="action-button view-videos-button"
                  disabled={videos.length === 0}
                  onClick={() => handleOpenVideosModal()}
                >
                  {t("products.view_all_videos")}
                </SimpleButton>
              </div>
            </div>
          }
          {!selectedSerial && !isICTrackProduct && 
            <EmptyPageResults
              className="fit-within-page"
              title={t('troubleshoot.no-product')}
              subtitle={t('troubleshoot.select-product')}
            />
          }
          {isICTrackProduct &&
            <>
              <p className="serial-select-title">{`${t('products.linked_devices')} (${selectedProduct.products?.length})`}</p>
              <div className="linked-products-wrapper">
                {selectedProduct.products?.slice(0, showLimitedLikedDevice ? linkedDevicesLimit : selectedProduct.products.length)?.map((product, index) => 
                  <div
                    key={`${product.serial_number}-${index}`} 
                    className={"linked-product" + (product.isSciCanProduct ? " with-navigation" : "")}
                    onClick={() => product.isSciCanProduct && handleNavigateToProduct(product.serial_number)}
                  >
                    <img 
                      src={getImageByModel(mapSerialToModel(product.serial_number))} 
                      className="product-image"
                      alt="product"
                    />
                    <div className="product-nickname">{product.unit_nickname}</div>
                    <div className="product-serial">{product.serial_number && `${t("sn")} ${product.serial_number}`}</div>
                  </div>)
                }
              </div>
              {selectedProduct.products?.length > linkedDevicesLimit && showLimitedLikedDevice &&
                <div
                  onClick={() => onShowLimitedLinkedDevices(false)}
                  className="content-limiter"
                >
                  {`+${selectedProduct.products.length - linkedDevicesLimit} ${t("labels.more")}`}
                </div>
              }
              {!showLimitedLikedDevice && 
                <div
                  onClick={() => onShowLimitedLinkedDevices(true)}
                  className="content-limiter"
                >
                  {t("buttons.view_less")}
                </div>
              }

              <div className="product-details-wrapper">
                <div className="product-cycles-wrapper">
                  <div className="section-title with-extra-information">{t("products.instrument_tracking")}</div>
                  <div className="extra-information">{t("products.last_days")}</div>
                  <div className="cycles-wrapper ic-track-cycles-wrapper">
                    {lastCycles.map((cycle, index) => (
                      <div
                        key={index}
                        className={"cycle-item-wrapper " + `${cycle.className}` } 
                        onClick = {() => onShowCycleDetails(cycle)}
                      >
                        <div className="icon-title-wrapper">
                        <div className='icon'>
                          {cycle.type === "washer" ? <WasherSmallIcon /> : <SterilizerSmallIcon />}
                          <div className="title">{cycle.serial_number ? `${t("sn")}: ${cycle.serial_number}` : cycle.nickName}</div>
                        </div>
                          <div className="cycle-num">{cycle.title}</div>
                        </div>
                      </div>))
                    }
                    {!cyclesLoading && lastCycles.length === 0 && 
                      <div className="no-cycles-wrapper">
                        <p className="disclaimer">{t("products.no_cycles_available")}</p>
                      </div>
                    }
                  </div>
                  <div className="cycles-navigation-buttons-wrapper">
                    <SimpleButton
                      className="action-button" 
                      onClick={() => handleNavigateToInstrumentTracking()}
                    >
                      {t("products.view_instrument_tracking_calendar")}
                    </SimpleButton>
                    <SimpleButton
                      className="action-button"
                      onClick={() => handleNavigateToInstrumentTrackingReports()}
                    >
                      {t("products.view_instrument_reprocessing_report")}
                    </SimpleButton>
                  </div>
                </div>

                <div className="product-documents-wrapper">
                  <div className="section-title">{t("products.documents")}</div>
                  <div className="documents-wrapper">
                    {documents.map((item, index) => (
                      <div
                        key={index}
                        className="document-item-wrapper"
                        onClick = {() => onDownloadDocument(item?.file_path, item?.file_name)}
                      >
                        <div className="image-wrapper">
                          <DownloadModernIcon/>
                        </div>
                        <div className="image-wrapper rounded-image">
                          <PdfModernIcon/>
                        </div>
                        <div className="title-wrapper">
                          <p className="title">{item?.document_title ? item?.document_title : item?.file_name}</p>
                          <p className="section">{item?.section_name}</p>
                        </div>
                      </div>))
                    }
                    {documents.length === 0 && 
                      <div className="no-documents-wrapper">
                        <p className="disclaimer">{t("products.no_documents_available")}</p>
                      </div>
                    }
                  </div>
                  <SimpleButton 
                    className="action-button view-documents-button"
                    disabled={documents.length === 0}
                    onClick={() => handleOpenDocumentsModal(true)}
                  >
                    {t("products.view_all_documents")}
                  </SimpleButton>
                </div>

                <div className="product-videos-wrapper">
                  <div className="section-title">{t("products.videos")}</div>
                  <div className="videos-wrapper">
                    {videos.map((video, index) => (
                      <div
                        key={index}
                        className="video-item-wrapper"
                        onClick = {() => onSelectVideo(video)}
                      >
                        <div className="thumbnail-wrapper">
                          <div className="thumbnail-icon">
                            <VideoPlayIcon />
                          </div>
                          <img
                            src={video.thumbnail_url}
                            alt="video"
                          />
                        </div>                
                        <p className="title">{video.title}</p>
                      </div>))
                    }
                    {videos.length === 0 && 
                      <div className="no-videos-wrapper">
                        <p className="disclaimer">{t("products.no_videos_available")}</p>
                      </div>
                    }
                  </div>
                  <SimpleButton 
                    className="action-button view-videos-button"
                    disabled={videos.length === 0}
                    onClick={() => handleOpenVideosModal(true)}
                  >
                    {t("products.view_all_videos")}
                  </SimpleButton>
                </div>

                {supportsColteneStore && 
                  <div className="product-related-products-wrapper">
                    <div className="section-title">{t("products.related_products")}</div>
                    <div className="related-products-wrapper">
                      {relatedProducts.length === 0 && 
                        <div className="no-related-products-wrapper">
                          <p className="disclaimer">{t("products.no_related_products_available")}</p>
                        </div>
                      }
                    </div>
                    <SimpleButton 
                      className="action-button view-store-button"
                      onClick={() => handleNavigateToStore()}
                    >
                      {t("products.go_to_store")}
                    </SimpleButton>
                  </div>
                }
              </div>
            </>
          }
        </div>
      </PageModal>
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function mapStateToProps({ userPermissions }) {
  return {
    userPermissions,
  }
}

export default connect(mapStateToProps)(ProductDetailsModal)

