import ReactGA from 'react-ga4'

const initializeGA = () => {
  const measurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID

  if (measurementId) {
    ReactGA.initialize(measurementId)
    return
  }

  console.log('GA4 Measurement ID is not defined')
}

export default initializeGA