import React, { useState, useRef, useLayoutEffect, useEffect, useCallback } from "react"
import ImageSlide from "./ImageSlide"
import { ColtenePlaceholderProduct } from "../../../icons"

const getPositionX = (event) => {
  return event.type.includes("mouse") ? event.pageX : event.touches[0].clientX
}

const getElementDimensions = (ref) => {
  const width = ref.current.clientWidth
  const height = ref.current.clientHeight
  return { width, height }
}

const ImageSlider = ({
  children,
  onSlideComplete,
  onSlideStart,
  activeIndex = null,
  threshHold = 100,
  transition = 0.3,
  scaleOnDrag = false,
  numberSlides,
}) => {
  const dragging = useRef(false)
  const startPos = useRef(0)
  const currentTranslate = useRef(0)
  const prevTranslate = useRef(0)
  const currentIndex = useRef(activeIndex || 0)
  const sliderRef = useRef("slider")
  const animationRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const setPositionByIndex = useCallback(
    (w = dimensions.width) => {
      currentTranslate.current = currentIndex.current * -w
      prevTranslate.current = currentTranslate.current

      setCurrentSlide(currentIndex.current + 1)
      setSliderPosition()
    },
    [dimensions.width]
  )

  // set width after first render
  // set position by startIndex
  // no animation on startIndex
  useLayoutEffect(() => {
    setDimensions(getElementDimensions(sliderRef))

    setPositionByIndex(getElementDimensions(sliderRef).width)
  }, [setPositionByIndex])

  // watch for a change in activeIndex prop
  useEffect(() => {
    if (activeIndex !== currentIndex.current) {
      transitionOn()
      currentIndex.current = activeIndex
      setPositionByIndex()
    }
  }, [activeIndex, setPositionByIndex])

  // add event listeners
  useEffect(() => {
    // set width if window resizes
    const handleResize = () => {
      transitionOff()
      const { width, height } = getElementDimensions(sliderRef)
      setDimensions({ width, height })
      setPositionByIndex(width)
    }

    const handleKeyDown = ({ key }) => {
      const arrowsPressed = ["ArrowRight", "ArrowLeft"].includes(key)

      if (arrowsPressed) {
        transitionOn()
      }

      if (arrowsPressed && onSlideStart) {
        onSlideStart(currentIndex.current)
      }

      if (key === "ArrowRight" && currentIndex.current < children.length - 1) {
        currentIndex.current += 1
      }

      if (key === "ArrowLeft" && currentIndex.current > 0) {
        currentIndex.current -= 1
      }

      if (arrowsPressed && onSlideComplete) {
        onSlideComplete(currentIndex.current)
      }

      setPositionByIndex()
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [children.length, setPositionByIndex, onSlideComplete, onSlideStart])

  const transitionOn = () => {
    if (sliderRef?.current?.style) {
      sliderRef.current.style.transition = `transform ${transition}s ease-out`
    }
  }

  const transitionOff = () => {
    if (sliderRef?.current?.style) {
      sliderRef.current.style.transition = "none"
    }
  }

  function touchStart(index) {
    return function (event) {
      transitionOn()
      currentIndex.current = index
      startPos.current = getPositionX(event)
      dragging.current = true
      animationRef.current = requestAnimationFrame(animation)
      sliderRef.current.style.cursor = "grabbing"
      // if onSlideStart prop - call it
      if (onSlideStart) {
        onSlideStart(currentIndex.current)
      }
    }
  }

  function touchMove(event) {
    if (dragging.current) {
      const currentPosition = getPositionX(event)
      currentTranslate.current = prevTranslate.current + currentPosition - startPos.current
    }
  }

  function touchEnd() {
    transitionOn()
    cancelAnimationFrame(animationRef.current)
    dragging.current = false
    const movedBy = currentTranslate.current - prevTranslate.current

    // if moved enough negative then snap to next slide if there is one
    if (movedBy < -threshHold && currentIndex.current < children.length - 1) {
      currentIndex.current += 1
    }

    // if moved enough positive then snap to previous slide if there is one
    if (movedBy > threshHold && currentIndex.current > 0) {
      currentIndex.current -= 1
    }

    transitionOn()

    setPositionByIndex()
    sliderRef.current.style.cursor = "grab"
    // if onSlideComplete prop - call it
    if (onSlideComplete) {
      onSlideComplete(currentIndex.current)
    }
  }

  function animation() {
    setSliderPosition()

    if (dragging.current) {
      requestAnimationFrame(animation)
    }
  }

  function setSliderPosition() {
    sliderRef.current.style.transform = `translateX(${currentTranslate.current}px)`
  }

  function progressBarContent() {
    const progressBar = []

    for (let i = 0; i < numberSlides; i++) {
      progressBar.push(<div index={i} className={currentSlide === i + 1 ? "progress-bar-content" : "progress-bar-content unselected"} />)
    }

    return progressBar
  }

  return (
    <div className="image-slider-wrapper">
      <div ref={sliderRef} className="image-slider">
        {children.length > 0 ?
          children.map((child, index) => 
            <div
              key={child.key}
              onTouchStart={touchStart(index)}
              onMouseDown={touchStart(index)}
              onTouchMove={touchMove}
              onMouseMove={touchMove}
              onTouchEnd={touchEnd}
              onMouseUp={touchEnd}
              onMouseLeave={() => {
                if (dragging.current) {
                  touchEnd()
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              className="slide-outer"
            >
              <ImageSlide 
                child={child} 
                sliderWidth={dimensions.width} 
                sliderHeight={dimensions.height} 
                scaleOnDrag={scaleOnDrag} 
              />
            </div>
          )
        :
          <ColtenePlaceholderProduct />
        }
      </div>
      {numberSlides > 1 && (
        <div className="progress-bar-wrapper">{progressBarContent()}</div>
      )}
    </div>
  )
}

export default ImageSlider
