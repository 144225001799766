import { useLayoutEffect, useState } from "react";


//Usage: const [fields, handleFieldChange] = useFormFields({email: "",password: "",remember: "", ...})
export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState)

  return [
    fields,
    function(event) {
      setValues(prevFields => ({
        ...prevFields,
        ...(event.target !== null && event.target.name !== null
          ? {[event.target.name]: (event.target.type === 'checkbox' ) ? event.target.checked : event.target.value} 
          : event.detail !== null && event.detail.name
            ? {[event.detail.name]: (event.detail.type === 'checkbox' ) ? event.detail.checked : event.detail.value} 
            : {})
        // [event.target.name]: event.target.value
      }))
    }
  ]
}

//usage: const [width, height] = useWindowSize()
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, []);

  return windowSize;
}