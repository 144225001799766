import {
    getAllBranches,
    addBranch,
    updateBranch,
    deleteBranch,
    getBranchesCountryOptions,
} from "../utils/requests/companyAPI"

export const LOAD_BRANCHES = "LOAD_BRANCHES"
export const LOAD_BRANCHES_PENDING = "LOAD_BRANCHES_PENDING"
export const LOAD_BRANCHES_FULFILLED = "LOAD_BRANCHES_FULFILLED"
export const LOAD_BRANCHES_REJECTED = "LOAD_BRANCHES_REJECTED"

export const LOAD_NEW_BRANCHES = "LOAD_NEW_BRANCHES"
export const LOAD_NEW_BRANCHES_PENDING = "LOAD_NEW_BRANCHES_PENDING"
export const LOAD_NEW_BRANCHES_FULFILLED = "LOAD_NEW_BRANCHES_FULFILLED"
export const LOAD_NEW_BRANCHES_REJECTED = "LOAD_NEW_BRANCHES_REJECTED"

export const POST_BRANCH = "POST_BRANCH"
export const POST_BRANCH_PENDING = "POST_BRANCH_PENDING"
export const POST_BRANCH_FULFILLED = "POST_BRANCH_FULFILLED"
export const POST_BRANCH_REJECTED = "POST_BRANCH_REJECTED"

export const PUT_BRANCH = "PUT_BRANCH"
export const PUT_BRANCH_PENDING = "PUT_BRANCH_PENDING"
export const PUT_BRANCH_FULFILLED = "PUT_BRANCH_FULFILLED"
export const PUT_BRANCH_REJECTED = "PUT_BRANCH_REJECTED"

export const DELETE_BRANCH = "DELETE_BRANCH"
export const DELETE_BRANCH_PENDING = "DELETE_BRANCH_PENDING"
export const DELETE_BRANCH_FULFILLED = "DELETE_BRANCH_FULFILLED"
export const DELETE_BRANCH_REJECTED = "DELETE_BRANCH_REJECTED"

export const LOAD_BRANCHES_COUNTRY_OPTIONS = "LOAD_BRANCHES_COUNTRY_OPTIONS"
export const LOAD_BRANCHES_COUNTRY_OPTIONS_PENDING = "LOAD_BRANCHES_COUNTRY_OPTIONS_PENDING"
export const LOAD_BRANCHES_COUNTRY_OPTIONS_FULFILLED = "LOAD_BRANCHES_COUNTRY_OPTIONS_FULFILLED"
export const LOAD_BRANCHES_COUNTRY_OPTIONS_REJECTED = "LOAD_BRANCHES_COUNTRY_OPTIONS_REJECTED"

export const CLEAR_BRANCHES_MESSAGES = "CLEAR_BRANCHES_MESSAGES"

export function loadBranches(queryParams, refetch = false) {
    return {
      type: refetch ? LOAD_NEW_BRANCHES : LOAD_BRANCHES,
      payload: getAllBranches(queryParams)
    }
}

export function createBranch(companyId, bodyParams) {
  return {
    type: POST_BRANCH,
    payload: addBranch(companyId, bodyParams)
  }
}

export function putBranch(companyId, branchId, bodyParams) {
  return {
    type: PUT_BRANCH,
    payload: updateBranch(companyId, branchId, bodyParams)
  }
}

export function removeBranch(companyId, branchId) {
  return {
    type: DELETE_BRANCH,
    payload: deleteBranch(companyId, branchId),
    meta: { branchId }
  }
}

export function loadBranchesCountryOptions() {
  return {
    type: LOAD_BRANCHES_COUNTRY_OPTIONS,
    payload: getBranchesCountryOptions()
  }
}

export function clearBranchesMessages() {
  return {
    type: CLEAR_BRANCHES_MESSAGES
  }
}
  
  