import React, { useEffect, useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import DOMPurify from "dompurify"
import StoreBasket from "./StoreBasket"
import ProductCard from "./ProductCard"
import ImageSwiper from "./components/ImageSwiper"
import ImageSlider from "./components/ImageSlider"
import ProductFeatures from "./components/ProductFeatures"
import ProductSummary from "./components/ProductSummary"
import Breadcrumb from "../common/Breadcrumb"
import { BoxTabs, Tab } from "../common/Tabs"
import LoadingSpinner from "../common/LoadingSpinner"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import SimpleButton from "../common/SimpleButton"
import { toast } from "../common/Toast"
import AddEditProductModal from "../AdminStore/AddEditProductModal"
import { useWindowSize } from "../../libs/hooks"
import { loadStoreProductById, clearStoreMessages, setInBasket } from "../../actions/colteneStore"
import { loadAdminStoreProductById, changeProductStatus, clearProductsMessages, clearNewProduct } from "../../actions/adminStore"
import { getUserSubscriptions } from "../../utils/requests/productsAPI"
import "./product-details.scss"

const ProductDetails = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const location = useLocation()
  const isPreview = location.state && location.state.isPreview
  const productId = location.pathname && location.pathname.split("/")[2]
  const { product, newProduct, basket, userCountry, isLoading } = props
  const [activeSubscriptions, setActiveSubscriptions] = useState([])
  const [showStoreBasket, setShowStoreBasket] = useState(false)
  const [showEditProductModal, setShowEditProductModal] = useState(false)
  const [hasAdditionalDetails, setHasAdditionalDetails] = useState(false)
  const [publishTimeout, setPublishTimeout] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false)
  const twentySeconds = 20000
  const ongoingSubscriptionStatuses = ["active", "trialing", "past_due"]

  useLayoutEffect(() => {
    if (productId) {
      if (isPreview) {
        props.actions.loadAdminStoreProductById(productId)
          .then(() => {
            setIsFirstLoad(false)
            if (newProduct && newProduct.id === Number(productId)) {
              setPublishTimeout(twentySeconds)
            }
          })

        return
      }

      props.actions.loadStoreProductById(productId)
        .then(() => setIsFirstLoad(false))
    }
  }, [productId, isPreview])

  useEffect(() => {
    if (!isPreview) {
      getUserSubscriptions()
        .then((subscriptions) => {
          const filteredActiveSubscriptions = subscriptions?.filter((subs) => ongoingSubscriptionStatuses.includes(subs.status))?.map(s => s.product?.id)
          setActiveSubscriptions(filteredActiveSubscriptions  || [])
        })
        .finally(() => {
          setSubscriptionsLoaded(true)
        })
    }
  }, [isPreview])

  useEffect(() => {
    if (publishTimeout) {
      const interval = setInterval(() => {
        setPublishTimeout(publishTimeout - 1000)
      }, 1000)

      if (publishTimeout === 1000) {
        props.actions.clearNewProduct()
      }

      return () => clearInterval(interval)
    }
  }, [publishTimeout])

  useEffect(() => {
    if (product?.features?.length > 0 || product?.specification?.length > 0 || product?.relatedProducts?.length > 0) {
      setHasAdditionalDetails(!isFirstLoad)
    }
  }, [product, isFirstLoad])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearProductsMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearStoreMessages()
      props.actions.clearProductsMessages()
    }
  }, [props.errorMessage])

  const generateTabs = () => {
    let index = 0
    const productTabs = []

    if (product.features?.length > 0 || product.description?.length > 0) {
      productTabs.push(
        <Tab key={index} index={index} label={t("monitoring.overview")}>
          <ProductFeatures features={product.features} description={product.description} />
        </Tab>
      )
      index++
    }

    if (product.specification?.length > 0) {
      productTabs.push(
        <Tab key={index} index={index} label={t("coltene_store.product_details.specification")}>
          <div className="specifications-wrapper">
            <div className="product-specification" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product.specification)}} />
          </div>
        </Tab>
      )
      index++
    }

    if (product.relatedProducts?.length > 0) {
      productTabs.push(
        <Tab key={index} index={index} label={t("coltene_store.product_details.accessories")}>
          <div className="related-products-flex-wrapper">
            <div className="accessories-wrapper">
              {product.relatedProducts.flatMap((product) => {
                return product.prices.filter((p) => p.countryCode === userCountry).map(price => (
                  <ProductCard
                    key={price.id}
                    product={product}
                    price={price}
                    numInBasket={basket[product.id]}
                    isPreview={isPreview}
                    setShowStoreBasket={() => setShowStoreBasket(true)}
                    activeSubscriptions={subscriptionsLoaded && activeSubscriptions}
                  />
                ))
              })}
            </div>
          </div>
        </Tab>
      )
    }

    return productTabs
  }

  const handleOpenEditModal = () => {
    if (isPreview && product) {
      setShowEditProductModal(true)
    }
  }

  const handleChangePublishStatus = () => {
    if (isPreview && product) {
      props.actions.changeProductStatus(product.id, !product.isPublished)
    }
  }

  return (
    <div className="product-details-wrapper">
      <Breadcrumb path={window.location.pathname} pathName={t("coltene_store.product_details.product-details")} withBackNavigation={true} />
      <DashboardHeader 
        headerText={!isFirstLoad ? product?.title : ""} 
      >
        {isPreview &&
          <>
            <SimpleButton 
              className="action-button" 
              onClick={() => handleOpenEditModal()}
              disabled={isLoading}
            >
              {t('labels.edit')}
            </SimpleButton>
            <SimpleButton 
              className="submit-button" 
              onClick={() => handleChangePublishStatus()}
              disabled={isLoading || publishTimeout}
            >
              {`${product?.isPublished ? t("labels.unpublish") : t("labels.publish")}${publishTimeout ? `(${Math.floor(publishTimeout / 1000)})` : ''}`}
            </SimpleButton>
          </>
        }
      </DashboardHeader>
      <div className="separator"/>
      {!isLoading && !isFirstLoad && isMobile && product && (
        <div className="product-details-container">
          <ImageSlider 
            activeIndex={0} 
            threshHold={100} 
            transition={0.5} 
            scaleOnDrag={true} 
            numberSlides={product.images.length}
          >
            {product.images?.map((image, index) =>
              <img key={index} src={image.url} alt={"product"} />
            )}
          </ImageSlider>
          <div className="checkout-details">
            <ProductSummary 
              addToBasket={props.actions.setInBasket} 
              isPreview={isPreview} 
              setShowStoreBasket={() => setShowStoreBasket(true)}
              activeSubscriptions={subscriptionsLoaded && activeSubscriptions}
              {...(!product.isDigital ? { inBasket: basket[product.id]?.quantity || 0 } : {})}
            />
          </div>
          {hasAdditionalDetails &&
            <div className="product-details">
              <BoxTabs className="underlined-box-tab">
                {generateTabs()}
              </BoxTabs>
            </div>
          }
        </div>
      )}
      {!isLoading && !isFirstLoad && !isMobile && product && (
        <div className="product-details-container">
          <div className="product-details">
            <ImageSwiper images={product.images} />
            {hasAdditionalDetails &&
              <BoxTabs className="underlined-box-tab">
                {generateTabs()}
              </BoxTabs>
            }
          </div>
          <div className="checkout-details">
            <ProductSummary 
              addToBasket={props.actions.setInBasket} 
              isPreview={isPreview} 
              setShowStoreBasket={() => setShowStoreBasket(true)}
              activeSubscriptions={subscriptionsLoaded && activeSubscriptions}
              {...(!product.isDigital ? { inBasket: basket[product.id]?.quantity || 0 } : {})}
            />
          </div>
        </div>
      )}
      {isLoading && 
        <LoadingSpinner />
      }
      {isPreview && showEditProductModal && (
        <AddEditProductModal
          editedProduct={product}
          handleClose={() => { setShowEditProductModal(false)}}
          isPreviewUpdate
        />
      )}
      <StoreBasket 
        toggle={showStoreBasket} 
        onToggle={() => setShowStoreBasket(false)} 
      />
    </div>
  )
}

function stateToProps({ colteneStore, adminStore, authedUser }) {
  return {
    userCountry: authedUser?.country,
    product: colteneStore?.productDetails || adminStore?.productDetails,
    newProduct: adminStore?.newProduct,
    isLoading: colteneStore?.isLoading || adminStore?.productLoading,
    basket: colteneStore?.basket || {},
    successMessage: colteneStore?.successMessage || adminStore?.successMessage,
    errorMessage: colteneStore?.errorMessage || adminStore?.errorMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadStoreProductById,
        loadAdminStoreProductById,
        changeProductStatus,
        clearStoreMessages,
        clearProductsMessages,
        clearNewProduct,
        setInBasket,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(ProductDetails)
