import {
  LOAD_NEW_USERS_FULFILLED,
  LOAD_NEW_USERS_PENDING,
  LOAD_NEW_USERS_REJECTED,
  LOAD_USERS_FULFILLED,
  LOAD_USERS_PENDING,
  LOAD_USERS_REJECTED,
  LOAD_USER_DEVICES_FULFILLED,
  LOAD_USER_DEVICES_PENDING,
  LOAD_USER_DEVICES_REJECTED,
  LOAD_USER_SUBSCRIPTIONS_FULFILLED,
  LOAD_USER_SUBSCRIPTIONS_PENDING,
  LOAD_USER_SUBSCRIPTIONS_REJECTED,
  LOAD_END_USER_PERMISSIONS_FULFILLED,
  LOAD_END_USER_PERMISSIONS_PENDING,
  LOAD_END_USER_PERMISSIONS_REJECTED,
  PATCH_USER_PENDING,
  PATCH_USER_FULFILLED,
  PATCH_USER_REJECTED,
  CONFIRM_USER_PENDING,
  CONFIRM_USER_FULFILLED,
  CONFIRM_USER_REJECTED,
  DELETE_USER_PENDING,
  DELETE_USER_FULFILLED,
  DELETE_USER_REJECTED,
  CLEAR_USERS_MESSAGES,
  CLEAR_USER_DEVICES,
  CLEAR_USER_SUBSCRIPTIONS,
  CLEAR_END_USER_PERMISSIONS,
} from "../actions/users"
import i18n from 'i18next'

export default function users(state = {}, action) {
  switch (action.type) {
    case LOAD_USERS_PENDING:
    case LOAD_NEW_USERS_PENDING:
    case LOAD_USER_DEVICES_PENDING:
    case LOAD_USER_SUBSCRIPTIONS_PENDING:
    case LOAD_END_USER_PERMISSIONS_PENDING:
    case PATCH_USER_PENDING:
    case CONFIRM_USER_PENDING:
    case DELETE_USER_PENDING:
      return {
        ...state,
        usersLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_USERS_REJECTED:
    case LOAD_NEW_USERS_REJECTED:
    case LOAD_USER_DEVICES_REJECTED:
    case LOAD_USER_SUBSCRIPTIONS_REJECTED:
    case LOAD_END_USER_PERMISSIONS_REJECTED:
    case PATCH_USER_REJECTED:
    case CONFIRM_USER_REJECTED:
    case DELETE_USER_REJECTED:
      return {
        ...state,
        usersLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
      }
    case LOAD_USERS_FULFILLED:
      const users = state?.users || []
      const newUsersIds = action.payload.data?.map((item) => item.uuid)
      const difference = users
        .filter(x => !newUsersIds.includes(x.uuid))
        .concat(action.payload.data)

      return {
        ...state,
        users: difference,
        canLoadMoreUsers: action.payload.currentPage < action.payload.totalPages,
        usersLoading: false,
      }
    case LOAD_NEW_USERS_FULFILLED:
      return {
        ...state,
        users: action.payload.data,
        canLoadMoreUsers: action.payload.currentPage < action.payload.totalPages,
        usersLoading: false,
      }
    case LOAD_USER_DEVICES_FULFILLED:
      return {
        ...state,
        userDevices: action.payload,
        usersLoading: false,
      }
    case LOAD_USER_SUBSCRIPTIONS_FULFILLED:
      return {
        ...state,
        userSubscriptions: action.payload,
        usersLoading: false,
      }
    case LOAD_END_USER_PERMISSIONS_FULFILLED:
      return {
        ...state,
        endUserPermissions: action.payload,
        usersLoading: false,
      }
    case PATCH_USER_FULFILLED:
      const patchedUsers = state?.users.map((item) => item.uuid === action.payload.uuid ? {...action.payload} : item)

      return {
        ...state,
        users: patchedUsers,
        usersLoading: false,
        successMessage: i18n.t("users-management.success-edit-user")
      }
    case CONFIRM_USER_FULFILLED:
      const confirmedUsers = state?.users.map((item) => item.uuid === action.payload.uuid ? {...action.payload} : item)

      return {
        ...state,
        users: confirmedUsers,
        usersLoading: false,
        successMessage: i18n.t("users-management.success-confirm-user")
      }
    case DELETE_USER_FULFILLED:
      const remainingUsers = state?.users.filter((item) => item.uuid !== action.meta.id)
      
      return {
        ...state,
        users: remainingUsers,
        usersLoading: false,
        successMessage: i18n.t("users-management.success-delete-user")
      }
    case CLEAR_USERS_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    }
    case CLEAR_USER_DEVICES:
      return {
        ...state,
        userDevices: [],
      }
    case CLEAR_USER_SUBSCRIPTIONS:
      return {
        ...state,
        userSubscriptions: [],
      }
    case CLEAR_END_USER_PERMISSIONS:
      return {
        ...state,
        endUserPermissions: [],
      }
    default:
      return state
  }
}
