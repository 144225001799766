import React, { useState, useLayoutEffect, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PageModal from "../common/PageModal"
import SimpleButton from '../common/SimpleButton'
import { toast } from "../common/Toast"
import { SelectMenu } from "../common/SelectMenu"
import Search from "../common/Search"
import {
  loadCompanyRoles,
  putCompany,
  clearCompanyRoles,
} from "../../actions/companies"
import { loadUsers } from "../../actions/users"
import { SearchUsersIcon } from "../../icons"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import { companiesWritePermission } from "../../utils/permissionValidation"
import "./change-admin-modal.scss"

const ChangeAdminModal = (props) => {
  const { t } = useTranslation()
  const { selectedCompany, companyRoles, users, canLoadMoreUsers, showSpinner, setShowSpinner, closeModal } = props

  const [selectedRoleId, setSelectedRoleId] = useState(null)
  const [roleOptions, setRoleOptions] = useState([])
  const [usersSearchParams, setUsersSearchParams] = useState(null)
  const [selectedUsersPage, setSelectedUsersPage] = useState(1)
  const [areUsersResultsVisible, setAreUsersResultsVisible] = useState(false)
  const [newAdminUser, setNewAdminUser] = useState(null)
  const [haveError, setHaveError] = useState({})
  const userResultsRef = useRef(null)

  useLayoutEffect(() => {
    if (!selectedCompany.id) {
      return
    }

    props.actions.loadCompanyRoles(selectedCompany.id)
  }, [selectedCompany])

  useEffect(() => {
    if (companyRoles.length > 0) {
      const mappedRoles = companyRoles.map((role) => ({
        key: role.id,
        value: role.name.replace(/Dealer |Dentist /g, ""),
      }))

      setSelectedRoleId(mappedRoles[0].key)
      setRoleOptions(mappedRoles)
    }
  }, [companyRoles])

  useEffect(() => {
    if (selectedUsersPage && selectedUsersPage > 1) {
      handleLoadUsers(false)
    }
  }, [selectedUsersPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (usersSearchParams !== newAdminUser) {
          setSelectedUsersPage(1)
          handleLoadUsers(true)
        }
      },
      usersSearchParams ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [usersSearchParams])

  useEffect(() => {
    const handleClickOutsideUsersResults = (event) => {
      if (userResultsRef.current && !userResultsRef.current.contains(event.target)) {
        setAreUsersResultsVisible(false)
      }
    }

    document.addEventListener("click", handleClickOutsideUsersResults, true)

    return () => {
      document.removeEventListener("click", handleClickOutsideUsersResults, true)
    }
  }, [])

  useEffect(() => {
    if (newAdminUser?.length > 0) {
      setUsersSearchParams(newAdminUser)
    }
  }, [newAdminUser])

  useEffect(() => {
    if (newAdminUser !== usersSearchParams) {
      setAreUsersResultsVisible(usersSearchParams || usersSearchParams === "" ? true : false)
    }
  }, [usersSearchParams])

  const footerContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => handleCloseModal()}
        >
          {t('buttons.cancel')}
        </SimpleButton>
        <SimpleButton
          className="submit-button"
          onClick={() => handleChangeAdmin()}
          disabled={showSpinner || hasSameInput()}
          requiredPermission={companiesWritePermission}
        >
          {t('labels.save')}
        </SimpleButton>
      </>
    )
  }

  const handleLoadUsers = (withReset) => {
    let queryParams = {
      offset: withReset ? 0 : (selectedUsersPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    if (usersSearchParams?.length > 0) {
      queryParams = { ...queryParams, query: usersSearchParams}
    }

    props.actions.loadUsers(queryParams, withReset)
  }

  const handleLoadMoreUsers = () => {
    setSelectedUsersPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleUsersSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      if (newAdminUser !== value) {
        setUsersSearchParams(value)
      }
    } else if (value?.length === 0 && usersSearchParams?.length > 0) {
      setShowSpinner(true)
      setUsersSearchParams(value)
    }
  }

  const handleSelectNewAdminUser = (user) => {
    setAreUsersResultsVisible(false)

    if (newAdminUser !== user) {
      setNewAdminUser(user)
    }
  }

  const handleCloseModal = () => {
    props.actions.clearCompanyRoles()
    closeModal()
  }

  const hasSameInput = () => {
    return selectedCompany.email === newAdminUser
  }

  const isValidInput = () => {
    let errors = {}
    let isValid = true

    if (!selectedRoleId) {
      errors = { ...errors, role: true }
      isValid = false
    }

    if (!newAdminUser) {
      errors = { ...errors, newAdmin: true }
      isValid = false
    }
  
    setHaveError(errors)
    return isValid
  }

  const handleChangeAdmin = () => {
    if (!isValidInput()) {
      toast.error(t("error.fill_all_fields"))
      return
    }

    const bodyParams = {
      companyName: selectedCompany.companyName,
      country: selectedCompany.country,
      officeAddressOne: selectedCompany.officeAddressOne,
      city: selectedCompany.city,
      stateProvinceRegion: selectedCompany.stateProvinceRegion,
      zipPostalCode: selectedCompany.zipPostalCode,
      status: selectedCompany.status,
      prevAdminRoleId: selectedRoleId,
      email: newAdminUser,
    }

    props.actions.putCompany(selectedCompany.id, bodyParams)

    handleCloseModal()
  }

  return (
    <>
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        className="change-company-admin-modal"
        title={t("company.change_admin")}
        footerContent={footerContent()}
      >
        <div className="change-company-admin-modal-content">
          <div className="row-wrapper">
            <div className="disclaimer">
              <p>
                {t("company.change_admin_disclaimer")}
              </p>
            </div>
          </div>
          <div className="row-wrapper">
            <div className="input-wrapper">
              <p className="input-option">{t('company.current_admin')}</p>
              <input 
                type="text" 
                className="name-input"
                value={selectedCompany?.email || ""}
                disabled
              />
            </div>
            <div className="input-wrapper">
              <p className="input-option">{t('company.new_role')}*</p>
              <SelectMenu
                className={`${haveError["role"] ? 'has-error' : ''}`}
                options={roleOptions}
                initialSelectedKey={selectedRoleId}
                onChange={(option) => setSelectedRoleId(option.key)}
              />
            </div>
          </div>
          <div className="row-wrapper">
            <div className="users-wrapper input-wrapper">
              <div className="input-option">{t("company.new_admin")}*</div>
              <div className="search-with-options-wrapper">
                <div onClick={() => setAreUsersResultsVisible(true)}>
                  <Search
                    className={`${haveError["newAdmin"] ? 'has-error' : ''}`}
                    onSearch={handleUsersSearch}
                    searchText={usersSearchParams}
                    placeholder={t("business-rules.search_by_name_email")}
                    icon={<SearchUsersIcon />}
                    onKeyDown={(e) => { e.key === "Enter" && setAreUsersResultsVisible(false) }}
                  />
                </div>
                {areUsersResultsVisible && (
                  <div ref={userResultsRef} className="options-wrapper">
                    {users.length > 0 && (
                      <>
                        {users.map((user, index) => {
                          return (
                            <div
                              key={`user-option-${index}`}
                              className="result-wrapper d-flex"
                              onClick={() => handleSelectNewAdminUser(user.email)}
                            >
                              <div className="user-name">{`${user.firstName} ${user.middleName || ""} ${user.lastName}`}</div>
                              <div className="user-email">{user.email}</div>
                            </div>
                          )
                        })}
                        {canLoadMoreUsers &&
                          <div className="buttons-wrapper">
                            <SimpleButton className="load-more-button" onClick={() => handleLoadMoreUsers()}>
                              {t("buttons.load_more")}
                            </SimpleButton>
                          </div>
                        }
                      </>
                    )}
                    {users.length === 0 && 
                      <div className="no-results">
                        {t("no_data_available")}
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageModal>
    </>
  )
}

function stateToProps({ companies, users }) {
  return {
    companyRoles: companies?.roles || [],
    users: users?.users || [],
    canLoadMoreUsers: users?.canLoadMoreUsers,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        putCompany,
        loadCompanyRoles,
        loadUsers,
        clearCompanyRoles,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps,dispatchToProps)(ChangeAdminModal)
