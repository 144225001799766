import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Search from "../common/Search"
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import { toast } from "../common/Toast"
import { CaretDownIcon } from "../../icons"
import { getModelsTrobleshoot } from "../../utils/requests/troubleshootingAPI"
import "./troubleshooting.scss"

const Troubleshooting = (props) => {
  const { t } = useTranslation()
  const { model } = props
  const [cycles, setCycles] = useState([])
  const [rawCycles, setRawCycles] = useState(null)
  const [selectedCycle, setSelectedCycle] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)

  useEffect(() => {
    if (model) {
      setShowSpinner(true)

      const modelsTroubleshootParams = {
        model: model
      }

      getModelsTrobleshoot(modelsTroubleshootParams)
        .then((result) => {
          if (result.data) {
            let arr = []

            result.data.map(item => {
              arr.push(item.faults)
            })

            const cyclesResult = [].concat(...arr)
            setCycles(cyclesResult)
            setRawCycles(cyclesResult)
          }
        })
        .catch(() => {
          toast.error(t("error.failure_msg"))
        })
        .finally(() => {
          setShowSpinner(false)
        })

    }
  }, [model])

  const searchCycle = (value) => {
    if (value) {
      const filteredCycles = rawCycles.filter(cycle => cycle.fault.toLowerCase().includes(value.toLowerCase()))
      setCycles(filteredCycles)
    } else {
      setCycles(rawCycles)
    }
  }

  const selectCycle = (cycle, toggleVisibility) => {    
    setCycles(rawCycles)
    setSelectedCycle(cycle)
    toggleVisibility(false)
  }

  const onSelectCycleInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      setCycles(rawCycles)
    } else {
      setCycles(rawCycles)
      toggleVisibility(true)
    }
  }

  const selectCycleInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectCycleInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedCycle ? selectedCycle.fault : t('troubleshoot.no-option')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCycleOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchCycle(e)}
          placeholder={t('placeholders.search-filters')}
        />
      </div>
      {cycles?.map((cycle, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (cycle === selectedCycle ? " selected-option" : "")} 
          key={index}
          onClick={() => selectCycle(cycle, toggleVisibility)}
        >
          {cycle.fault}
        </div>)
      }
    </div>
  )

  return (
    <div className="troubleshooting-wrapper">
      <p className="cycle-select-title">{t('troubleshoot.select-option')}</p>
      <ClickOutside 
        itemRef="click-outside-wrapper"
        eventItem={selectCycleInput}
        toDisplayItem={selectCycleOptions}
      />

      {selectedCycle &&
        <div className="cycle-details-wrapper">
          <div className="cycle-title-wrapper">
            <div className="cycle-title">
              <span>{t('troubleshoot.fault')}</span>
              <span className="cycle-title-number">{selectedCycle.fault}</span>
            </div>
            <div className="cycle-description">
              <span>{selectedCycle.description}</span>
            </div>
          </div>
          {selectedCycle.probable_cause?.length > 0 && 
            <div className="cycle-category d-flex flex-column">
              <h3 className="cycle-category-title d-flex flex-align-center">
                {t('troubleshoot.probable-cause')}
              </h3>
              <ul className="list-none">
                {selectedCycle.probable_cause.map((el, index) => (
                  <li key={index} className="cycle-categories">
                    <ul className="cycle-category-issue-list">
                      {el.split("\\n").map((el, index) => <li key={index} className="cycle-category-issue">{el}</li>)}
                    </ul>
                  </li>
                  ))
                }
              </ul>
            </div>
          }
          {selectedCycle.tech_troubleshooting?.length > 0 && 
            <div className="cycle-category d-flex flex-column">
              <h3 className="cycle-category-title d-flex flex-align-center">
                {t('troubleshoot.tech')}
              </h3>
              <ul className="list-none">
                {selectedCycle.tech_troubleshooting.map((el, index) => (
                  <li key={index} className="cycle-categories">
                    <ul className="cycle-category-issue-list">
                      {el.split("\\n").map((el, index) => <li  key={index} className="cycle-category-issue">{el}</li>)}
                    </ul>
                  </li>
                  ))
                }
              </ul>
            </div>
          }
          {selectedCycle.user_troubleshooting?.length > 0 && 
            <div className="cycle-category d-flex flex-column">
              <h3 className="cycle-category-title d-flex flex-align-center">
                {t('troubleshoot.user-troubleshoot')}
              </h3>
              <ul className="list-none">
                {selectedCycle.user_troubleshooting.map((el, index) => (
                  <li key={index} className="cycle-categories">
                    <ul className="cycle-category-issue-list">
                      {el.split("\\n").map((el, index) => <li key={index} className="cycle-category-issue">{el}</li>)}
                    </ul>
                  </li>
                  ))
                }
              </ul>
            </div>
          }
        </div>
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

export default Troubleshooting
