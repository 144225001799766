import {
  LOAD_LAST_ORDER_FULFILLED,
  LOAD_LAST_ORDER_PENDING,
  LOAD_LAST_ORDER_REJECTED,
  LOAD_NEW_ORDERS_FULFILLED,
  LOAD_NEW_ORDERS_PENDING,
  LOAD_NEW_ORDERS_REJECTED,
  LOAD_ORDERS_FULFILLED,
  LOAD_ORDERS_PENDING,
  LOAD_ORDERS_REJECTED,
  LOAD_NEW_SUBSCRIPTIONS_FULFILLED,
  LOAD_NEW_SUBSCRIPTIONS_PENDING,
  LOAD_NEW_SUBSCRIPTIONS_REJECTED,
  LOAD_SUBSCRIPTIONS_FULFILLED,
  LOAD_SUBSCRIPTIONS_PENDING,
  LOAD_SUBSCRIPTIONS_REJECTED,
  LOAD_ORDER_STATUSES_FULFILLED,
  LOAD_ORDER_STATUSES_PENDING,
  LOAD_ORDER_STATUSES_REJECTED,
  PUT_ACCEPT_ADMIN_REFUND_FULFILLED,
  PUT_ACCEPT_ADMIN_REFUND_PENDING,
  PUT_ACCEPT_ADMIN_REFUND_REJECTED,
  PUT_DECLINE_ADMIN_REFUND_FULFILLED,
  PUT_DECLINE_ADMIN_REFUND_PENDING,
  PUT_DECLINE_ADMIN_REFUND_REJECTED,
  PUT_COMPLETE_ADMIN_REFUND_FULFILLED,
  PUT_COMPLETE_ADMIN_REFUND_PENDING,
  PUT_COMPLETE_ADMIN_REFUND_REJECTED,
  PATCH_REACTIVATE_SUBSCRIPTION_FULFILLED,
  PATCH_REACTIVATE_SUBSCRIPTION_PENDING,
  PATCH_REACTIVATE_SUBSCRIPTION_REJECTED,
  DELETE_CANCEL_SUBSCRIPTION_FULFILLED,
  DELETE_CANCEL_SUBSCRIPTION_PENDING,
  DELETE_CANCEL_SUBSCRIPTION_REJECTED,
  CLEAR_ADMIN_ORDERS_MESSAGES,
} from "../actions/adminOrderHistory"
import i18n from "i18next"

export default function adminOrderHistory(state = {}, action) {
  switch (action.type) {
    case LOAD_SUBSCRIPTIONS_PENDING:
    case LOAD_NEW_SUBSCRIPTIONS_PENDING:
    case PATCH_REACTIVATE_SUBSCRIPTION_PENDING:
    case DELETE_CANCEL_SUBSCRIPTION_PENDING:
      return {
        ...state,
        subscriptionsLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_SUBSCRIPTIONS_REJECTED:
    case LOAD_NEW_SUBSCRIPTIONS_REJECTED:
    case PATCH_REACTIVATE_SUBSCRIPTION_REJECTED:
    case DELETE_CANCEL_SUBSCRIPTION_REJECTED:
      return {
        ...state,
        subscriptionsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ORDERS_PENDING:
    case LOAD_NEW_ORDERS_PENDING:
    case LOAD_ORDER_STATUSES_PENDING:
    case LOAD_LAST_ORDER_PENDING:
    case PUT_ACCEPT_ADMIN_REFUND_PENDING:
    case PUT_DECLINE_ADMIN_REFUND_PENDING:
    case PUT_COMPLETE_ADMIN_REFUND_PENDING:
      return {
        ...state,
        ordersLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_ORDERS_REJECTED:
    case LOAD_NEW_ORDERS_REJECTED:
    case LOAD_ORDER_STATUSES_REJECTED:
    case PUT_ACCEPT_ADMIN_REFUND_REJECTED:
    case PUT_DECLINE_ADMIN_REFUND_REJECTED:
    case PUT_COMPLETE_ADMIN_REFUND_REJECTED:
      return {
        ...state,
        ordersLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_LAST_ORDER_REJECTED:
      return {
        ...state,
        lastOrder: [],
        ordersLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_ORDERS_FULFILLED:
      const orders = state?.orders || []
      const newOrdersIds = action.payload.data?.map((item) => item.id)
      const difference = orders.filter((x) => !newOrdersIds.includes(x.id)).concat(action.payload.data)

      return {
        ...state,
        orders: difference,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        ordersLoading: false,
      }

    case LOAD_NEW_ORDERS_FULFILLED:
      return {
        ...state,
        orders: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        ordersLoading: false,
      }

    case LOAD_SUBSCRIPTIONS_FULFILLED:
      const subscriptions = state?.subscriptions || []
      const newSubscriptionsIds = action.payload.data?.map((item) => item.id)
      const differenceSubscriptions = subscriptions.filter((x) => !newSubscriptionsIds.includes(x.id)).concat(action.payload.data)

      return {
        ...state,
        subscriptions: differenceSubscriptions,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        subscriptionsLoading: false,
      }
    case LOAD_NEW_SUBSCRIPTIONS_FULFILLED:
      return {
        ...state,
        subscriptions: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        subscriptionsLoading: false,
      }

    case LOAD_ORDER_STATUSES_FULFILLED:
      return {
        ...state,
        orderStatuses: action.payload,
        ordersLoading: false,
      }
    case LOAD_LAST_ORDER_FULFILLED:
      return {
        ...state,
        lastOrder: action.payload,
        ordersLoading: false,
      }
    case PUT_ACCEPT_ADMIN_REFUND_FULFILLED:
      const ordersWithAcceptedOrder = state?.orders?.map((item) => (item.id === action.meta.orderId ? { ...item, status: "Refund In Progress" } : item)) || []

      return {
        ...state,
        orders: ordersWithAcceptedOrder,
        ordersLoading: false,
        successMessage: i18n.t("coltene_store.admin_order_history.refund-approved"),
      }
    case PUT_DECLINE_ADMIN_REFUND_FULFILLED:
      const ordersWithDeclinedOrder = state?.orders?.map((item) => (item.id === action.meta.orderId ? { ...item, status: "Paid" } : item)) || []

      return {
        ...state,
        orders: ordersWithDeclinedOrder,
        ordersLoading: false,
        successMessage: action.meta.isReactivate
          ? i18n.t("coltene_store.admin_order_history.order-reactivated")
          : i18n.t("coltene_store.admin_order_history.refund-declined"),
      }
    case PUT_COMPLETE_ADMIN_REFUND_FULFILLED:
      const ordersWithCompletedOrder = state?.orders?.map((item) => (item.id === action.meta.orderId ? { ...item, status: "Refunded" } : item)) || []

      return {
        ...state,
        orders: ordersWithCompletedOrder,
        ordersLoading: false,
        successMessage: i18n.t("coltene_store.admin_order_history.refund-completed"),
      }
    case PATCH_REACTIVATE_SUBSCRIPTION_FULFILLED:
      const reactivatedSubscriptions = state?.subscriptions?.map((item) => (item.id === action.meta.subscriptionId ? { ...item, cancelAtPeriodEnd: false } : item)) || []

      return {
        ...state,
        subscriptions: reactivatedSubscriptions,
        subscriptionsLoading: false,
        successMessage: i18n.t("coltene_store.admin_order_history.reactivated-subscription"),
      }
    case DELETE_CANCEL_SUBSCRIPTION_FULFILLED:
      const canceledSubscriptions = state?.subscriptions?.map((item) => (item.id === action.meta.subscriptionId ? { ...item, cancelAtPeriodEnd: true } : item)) || []

      return {
        ...state,
        subscriptions: canceledSubscriptions,
        subscriptionsLoading: false,
        successMessage: i18n.t("coltene_store.admin_order_history.canceled-subscription"),
      }
    case CLEAR_ADMIN_ORDERS_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    }
    default:
      return state
  }
}
